import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark-bw.png';
import { drawWatermark } from './p5helper';

function P5DisplayRotatingStrokes(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokes, setStrokes] = useState([]);
    const [color1, setColor1] = useState("#F4665CBE");
    const [color2, setColor2] = useState("#4181FFBE");
    const [bgcolor, setBgcolor] = useState("#FFFFFF");
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    const GIF_CAPTURE_FPS = 10;
    const GIF_CAPTURE_FRAME_DURATION = 1 / GIF_CAPTURE_FPS;
    let hasFinished = false;
    let numFramesCaptured = 0;

    let Point = function(d, rot, grot, t) {
        // d is distance from center
        // rot is angle in radians when no rotation
        // grot is global angle rotation at time of adding
        this.d = d;
        this.rot = rot;
        this.grot = grot;
        this.t = t;
    }

    let curStroke = [];

    function current(p5) {
        // If capturing, advance the time by the same increment with each frame captured
        if (props.addGifFrame && props.stopCapture) {
            return numFramesCaptured * GIF_CAPTURE_FRAME_DURATION;
        }
        return p5.millis() * .001;
    }

    function cycleBy(time, interval) {
        return (time - interval * Math.floor(time / interval))
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.bgcolor && parameters.color1 && parameters.color2) {
                setBgcolor(parameters.bgcolor);
                setColor1(parameters.color1);
                setColor2(parameters.color2);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);

        const tempStrokes = [];
        for (let i = 0; i < props.data.length; i++) {
            if (props.data[i] != null) {
                let stroke = props.data[i];
                for (let k = 0; k < stroke.length; k++) {
                    if (stroke[k] != null) {
                        let pt = stroke[k];
                        curStroke.push(new Point(pt.d, pt.rot, pt.grot, pt.t));
                    }
                }
                tempStrokes.push(curStroke);
                curStroke = [];
            }
        }
        setStrokes(tempStrokes);

        p5.strokeCap(p5.ROUND);
        p5.strokeWeight(7);
        p5.curveTightness(-0.5);
        p5.noFill();
        p5.smooth();
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.scale(scaleFactor, scaleFactor);
        p5.background(bgcolor);

        let curTime = current(p5);
        const interval = 8;
        let globalrot = 2 * Math.PI * cycleBy(curTime, interval) / interval;
        // repeats every interval secs

        p5.push();
        p5.translate((p5.width / 2) / scaleFactor, (p5.height / 2) / scaleFactor);
        p5.rotate(globalrot);
        // d, rot, grot

        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                if (i % 2 === 0) {
                    p5.stroke(color1); //255, 102, 92, 190);
                } else {
                    p5.stroke(color2); //65, 129, 255, 190);
                }
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    p5.rotate(strokes[i][j].grot);
                    let x = strokes[i][j].d * Math.cos(-strokes[i][j].grot + strokes[i][j].rot);
                    let y = strokes[i][j].d * Math.sin(-strokes[i][j].grot + strokes[i][j].rot);
                    p5.curveVertex(x, y);
                    p5.rotate(-strokes[i][j].grot);
                }
                p5.endShape();
            }
        }

        p5.pop();

        // Add watermark before capturing this frame
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
        if (props.addGifFrame && props.stopCapture) {  // When this component is created for capturing
            if (hasFinished) return;

            if (numFramesCaptured >= GIF_CAPTURE_FPS * props.durationInSeconds) {
                props.stopCapture();
                hasFinished = true;
            } else {
                // normal - 1000 would keep the display the same as the draw speed real time
                // props.addGifFrame(GIF_CAPTURE_FRAME_DURATION * 1000);

                // this animation: make the GIF playback faster because it feels too sluggish
                props.addGifFrame(GIF_CAPTURE_FRAME_DURATION * 600);
                numFramesCaptured++;
            }
        }
    };



    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayRotatingStrokes;
