import React, { useState, useEffect } from "react";
import Sketch from "react-p5";

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayGradientRects(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [rects, setRects] = useState([]);
    const [renderP5, setRenderP5] = useState(true);
    // mode is set in Draw method but not required in Display because gradient or solid colors are represented in incoming data
    // let mode = "NORMAL"; // or: "SOLID" for solid colors 

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    const Y_AXIS = 1;
    const X_AXIS = 2;

    let gradientRect = function (x, y, w, h, c1, c2, a) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
        this.c1 = c1;
        this.c2 = c2;
        this.axis = a;
        this.offset = 0;
    }

    function setGradient(p5, x, y, w, h, c1, c2, axis, offset) {
        p5.noStroke();
        if (axis === Y_AXIS) {
            // Top to bottom gradient
            if (h > 0) {
                for (let i = y; i < y + h - 1; i++) {
                    let inter = p5.map(i - offset, y, y + h, 0, 1);
                    if (inter < 0) {
                        if (inter > -1) {
                            inter = p5.abs(inter);
                        }
                        else inter = 2 + inter;//inter is between -1 and -2
                    }
                    let c = p5.lerpColor(c1, c2, inter);
                    p5.fill(c);
                    p5.rect(x, i, w, 2);
                }
            } else if (h < 0) {
                for (let i = y; i > y + h + 1; i--) {
                    let inter = p5.map(i, y, y + h, 0, 1);
                    let c = p5.lerpColor(c1, c2, inter);
                    p5.fill(c);
                    p5.rect(x, i - 2, w, 2);
                }
            }
        } else if (axis === X_AXIS) {
            // Left to right gradient
            if (w > 0) {
                for (let i = x; i < x + w - 1; i++) {
                    let inter = p5.map(i - offset, x, x + w, 0, 1);
                    if (inter < 0) {
                        if (inter > -1) {
                            inter = p5.abs(inter);
                        }
                        else inter = 2 + inter;//inter is between -1 and -2
                    }
                    let c = p5.lerpColor(c1, c2, inter);
                    p5.fill(c);
                    p5.rect(i, y, 2, h);
                }
            } else if (w < 0) {
                for (let i = x; i > x + w + 1; i--) {
                    let inter = p5.map(i, x, x + w, 0, 1);
                    let c = p5.lerpColor(c1, c2, inter);
                    p5.fill(c);
                    p5.rect(i - 2, y, 2, h);
                }
            }
        }
    }

    gradientRect.prototype.draw = function (p5) {
        setGradient(p5, this.x, this.y, this.w, this.h, this.c1, this.c2, this.axis, this.offset);
        this.offset++;
        if (this.axis === Y_AXIS && this.offset >= 2 * this.h)
            this.offset = 0;
        else if (this.axis === X_AXIS && this.offset >= 2 * this.w)
            this.offset = 0;
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        
        const tempRects = [];
        props.data.forEach(function (c) {
            tempRects.push(new gradientRect(c.x, c.y, c.w, c.h, c.c1, c.c2, c.axis));
        });
        setRects(tempRects);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor, scaleFactor);
        for (let i = 0; i < rects.length; i++) {
            let r = rects[i];
            r.draw(p5);
        }

        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };


    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayGradientRects;
