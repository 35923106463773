import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawGradientRects(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [gridSize, setGridSize] = useState(25);
    const [mode, setMode] = useState("NORMAL"); // or: "SOLID" for randomized, solid colors
    const [renderP5, setRenderP5] = useState(true);
    
    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    const Y_AXIS = 1;
    const X_AXIS = 2;

    let startcolor, endcolor;
    let curaxis;

    let mousedown = false;
    let startx = 0;
    let starty = 0;
    let firstmove = false;

    let rects = [];
    let isDrawing = false;

    let gradientRect = function (x, y, w, h, c1, c2, a) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
        this.c1 = c1;
        this.c2 = c2;
        this.axis = a;
        this.offset = 0;
    }

    function setGradient(p5, x, y, w, h, c1, c2, axis, offset) {
        // Draw filled rects with no stroke and overlap all rects by one pixel
        p5.noStroke();
        if (axis === Y_AXIS) {
            // Top to bottom gradient
            if (h > 0) {
                for (let i = y; i < y + h - 1; i++) {
                    let inter = p5.map(i - offset, y, y + h, 0, 1);
                    if (inter < 0) {
                        if (inter > -1) {
                            inter = p5.abs(inter);
                        }
                        else inter = 2 + inter;//inter is between -1 and -2
                    }
                    let c = p5.lerpColor(c1, c2, inter);
                    p5.fill(c);
                    p5.rect(x, i, w, 2); 
                }
            } else if (h < 0) {
                for (let i = y; i > y + h + 1; i--) {
                    let inter = p5.map(i, y, y + h, 0, 1);
                    let c = p5.lerpColor(c1, c2, inter);
                    p5.fill(c);
                    p5.rect(x, i - 2, w, 2);
                }
            }
        } else if (axis === X_AXIS) {
            // Left to right gradient
            if (w > 0) {
                for (let i = x; i < x + w - 1; i++) {
                    let inter = p5.map(i - offset, x, x + w, 0, 1);
                    if (inter < 0) {
                        if (inter > -1) {
                            inter = p5.abs(inter);
                        }
                        else inter = 2 + inter;//inter is between -1 and -2
                    }
                    let c = p5.lerpColor(c1, c2, inter);
                    p5.fill(c);
                    p5.rect(i, y, 2, h);
                }
            } else if (w < 0) {
                for (let i = x; i > x + w + 1; i--) {
                    let inter = p5.map(i, x, x + w, 0, 1);
                    let c = p5.lerpColor(c1, c2, inter);
                    p5.fill(c);
                    p5.rect(i - 2, y, 2, h);
                }
            }
        }
    }

    gradientRect.prototype.draw = function (p5) {
        setGradient(this.x, this.y, this.w, this.h, this.c1, this.c2, this.axis, this.offset);
        this.offset++;
        if (this.axis === Y_AXIS && this.offset >= 2 * this.h)
            this.offset = 0;
        else if (this.axis === X_AXIS && this.offset >= 2 * this.w)
            this.offset = 0;
    }

    function snap(x) {
        return (Math.round(x / gridSize) * gridSize);
    }

    const startRect = (p5) => {
        if (mousedown) return;
        startx = snap(p5.mouseX);
        starty = snap(p5.mouseY);

        if (mode === "SOLID") {
            startcolor = p5.color(p5.random(255), p5.random(255), p5.random(255), 180);
            endcolor = startcolor;
        } else {
            startcolor = p5.color(p5.random(255), p5.random(255), p5.random(255));
            endcolor = p5.color(p5.random(255), p5.random(255), p5.random(255));
        }

        curaxis = Y_AXIS;
        firstmove = true;
        mousedown = true;
    }

    const endRect = (p5) => {
        if (!mousedown) return;
        mousedown = false;

        let newx = startx;
        let newy = starty;
        let neww = snap(p5.mouseX) - startx;
        let newh = snap(p5.mouseY) - starty;
        let newcolora = startcolor;
        let newcolorb = endcolor;
        if (neww < 0) {
            newx = startx + neww;
            neww = Math.abs(neww);
            if (curaxis === X_AXIS) {
                newcolora = endcolor;
                newcolorb = startcolor;
            }
        }
        if (newh < 0) {
            newy = starty + newh;
            newh = Math.abs(newh);
            if (curaxis === Y_AXIS) {
                newcolora = endcolor;
                newcolorb = startcolor;
            }
        }
        if (newh === 0) newh = gridSize;
        if (neww === 0) neww = gridSize;
        rects.push(new gradientRect(newx, newy, neww, newh, newcolora, newcolorb, curaxis));
    }

    const touchStarted = (p5) => {
        if (p5.mouseX < 0 || p5.mouseX > p5.width || p5.mouseY < 0 || p5.mouseY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        startRect(p5);
        return false;
    }

    const touchEnded = (p5) => {
        if (isDrawing) {
            endRect(p5);
            isDrawing = false;
        }
        return false;
    }

    const mousePressed = (p5) => {
        if (p5.mouseX < 0 || p5.mouseX > p5.width || p5.mouseY < 0 || p5.mouseY > p5.height) {
            isDrawing = false;
            return;
        }
        if (!isMobile) {
            isDrawing = true;
            startRect(p5);
        }
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile && isDrawing) {
            endRect(p5);
            isDrawing = false;
        }
        return false;
    }


    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempGridSize = gridSize;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.mode) {
                setMode(parameters.mode);
            }
            if (parameters.gridSize) {
                tempGridSize = parameters.gridSize;
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        let tempScaleFactor = props.displaySize / 500.;
        setScaleFactor(tempScaleFactor);

        tempGridSize = tempGridSize * scaleFactor; // allow fractional grid sizes so that you still have even grid sizes on mobile
        setGridSize(tempGridSize);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        for (let i = 0; i < rects.length; i++) {
            setGradient(p5, rects[i].x, rects[i].y, rects[i].w, rects[i].h, rects[i].c1, rects[i].c2, rects[i].axis, rects[i].offset);

            rects[i].offset++;

            if (rects[i].axis === Y_AXIS && rects[i].offset >= 2 * rects[i].h)
                rects[i].offset = 0;
            else if (rects[i].axis === X_AXIS && rects[i].offset >= 2 * rects[i].w)
                rects[i].offset = 0;
        }
        let mousex = snap(p5.mouseX);
        let mousey = snap(p5.mouseY);
        if (mousedown) {
            if (firstmove) {
                let dx = Math.abs(mousex - startx);
                let dy = Math.abs(mousey - starty);
                if (dx > dy) curaxis = X_AXIS;
                else curaxis = Y_AXIS;
                if (p5.dist(p5.mousex, p5.mousey, startx, starty) > 55) {
                    firstmove = false;
                }
            }
            let tempw = snap(p5.mouseX) - startx;
            if (tempw === 0) tempw = gridSize;
            let temph = snap(p5.mouseY) - starty;
            if (temph === 0) temph = gridSize;
            setGradient(p5, startx, starty, tempw, temph, startcolor, endcolor, curaxis, 0);
        }
    };

    const clearDrawingHandler = () => {
        rects = [];
        return true;
    }

    const undoHandler = () => {
        if (rects.length >= 1) {
            rects.pop();
        }
    }

    function scaleValues(grect) {
        return (new gradientRect(grect.x / scaleFactor, grect.y / scaleFactor, grect.w / scaleFactor, grect.h / scaleFactor, grect.c1, grect.c2, grect.axis));
    }

    const submitButtonHandler = () => {
        props.parentCallback(rects.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawGradientRects;
