import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayCirclesSquares(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [shapes, setShapes] = useState([]);
    const [bgColor, setBgColor] = useState('#FFFFFF');
    const [fgColor, setFgColor] = useState('#000000');
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Shape = function(x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function drawSquare(p5, x1, y1, x2, y2) {
        // find square with these two points as opposite corners
        let mx = (x1 + x2) / 2;
        let my = (y1 + y2) / 2;

        p5.beginShape();
        p5.vertex(x1, y1);
        p5.vertex(mx - (y1 - my), my + (x1 - mx));
        p5.vertex(x2, y2);
        p5.vertex(mx + (y1 - my), my - (x1 - mx));
        p5.endShape(p5.CLOSE);
    }

    function drawCircle(p5, x1, y1, x2, y2) {
        // find circle with these two points as diameter
        let mx = (x1 + x2) / 2;
        let my = (y1 + y2) / 2;
        let diam = getDistance(x1, y1, x2, y2);

        p5.ellipse(mx, my, diam, diam);
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.bgColor && parameters.fgColor) {
                setBgColor(parameters.bgColor);
                setFgColor(parameters.fgColor);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.colorMode(p5.RGB);
        p5.ellipseMode(p5.CENTER);
        p5.strokeJoin(p5.ROUND);

        let shapesData = props.data;
        const tempShapes = [];
        for (let i = 0; i < shapesData.length; i++) {
            let shape = shapesData[i];
            tempShapes.push(new Shape(shape.x1, shape.y1, shape.x2, shape.y2));
        }
        setShapes(tempShapes);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(bgColor);
        p5.scale(scaleFactor, scaleFactor);
        p5.stroke(fgColor);
        p5.strokeWeight(8);
        p5.fill(bgColor + "DC");

        // draw rest of shapes
        for (let i = 0; i < shapes.length; i++) {
            if (i % 2 === 0)
                drawSquare(p5, shapes[i].x1, shapes[i].y1, shapes[i].x2, shapes[i].y2);
            else
                drawCircle(p5, shapes[i].x1, shapes[i].y1, shapes[i].x2, shapes[i].y2);
        }
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayCirclesSquares;
