import React, {useState, useEffect} from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawRightTriangles(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [color1, setColor1] = useState('#3B0863'); // dark purple
    const [color2, setColor2] = useState('#C6B6D7'); // light purple
    const [color3, setColor3] = useState('#F3BE00'); // orange
    const [color4, setColor4] = useState('#F3F457'); // yellow
    const [mode, setMode] = useState("NORMAL"); // "NORMAL" or "SEMICIRCLES"
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function (x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let Triangle = function (x1, y1, x2, y2, x3, y3) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
        this.x3 = x3;
        this.y3 = y3;
    }

    let curCornerX, curCornerY;
    let curStroke = [];
    let triangles = [];
    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        curStroke = [];
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
            isDrawing = false;

            let x1, y1, x2, y2;
            x1 = curStroke[0].x;
            y1 = curStroke[0].y;
            x2 = pX;
            y2 = pY;
            const pts = findTriangle(x1, y1, x2, y2);
            triangles.push(new Triangle(pts[0], pts[1], pts[2], pts[3], pts[4], pts[5]));

            curStroke = [];
        }
    }

    function findTriangle(x1, y1, x2, y2) {
        let tx1, ty1, tx2, ty2;

        const d = getDistance(x1, y1, x2, y2);
        const ang = Math.atan2(y2 - y1, x2 - x1);

        tx1 = (x1 + x2) * 0.5 + d * 0.5 * Math.cos(ang + Math.PI / 2);
        ty1 = (y1 + y2) * 0.5 + d * 0.5 * Math.sin(ang + Math.PI / 2);
        tx2 = (x1 + x2) * 0.5 + d * 0.5 * Math.cos(ang - Math.PI / 2);
        ty2 = (y1 + y2) * 0.5 + d * 0.5 * Math.sin(ang - Math.PI / 2);

        let xsum = 0,
            ysum = 0;
        for (let i = 0; i < curStroke.length; i++) {
            xsum += curStroke[i].x;
            ysum += curStroke[i].y;
        }
        const xavg = xsum / curStroke.length;
        const yavg = ysum / curStroke.length;

        const d1 = getDistance(xavg, yavg, tx1, ty1);
        const d2 = getDistance(xavg, yavg, tx2, ty2);

        if (d1 < d2) {
            // closer to 1
            return [x1, y1, x2, y2, tx1, ty1];
        } else {
            return [x1, y1, x2, y2, tx2, ty2];
        }
    }

    function drawTriangle(p5, x1, y1, x2, y2, x3, y3) {
        if (mode === "SEMICIRCLES") {
            let ang = Math.atan2(y2 - y1, x2 - x1);
            let d = getDistance(x2, y2, x1, y1);
            p5.beginShape();
            p5.vertex(x1, y1);
            p5.vertex(x2, y2);
            p5.bezierVertex(x2, y2, x3 + d * 0.5 * Math.cos(ang), y3 + d * 0.5 * Math.sin(ang), x3, y3);
            p5.bezierVertex(x3 - d * 0.5 * Math.cos(ang), y3 - d * 0.5 * Math.sin(ang),  x1, y1, x1, y1);
            p5.endShape(p5.CLOSE);
        } else {
            p5.beginShape();
            p5.vertex(x1, y1);
            p5.vertex(x2, y2);
            p5.vertex(x3, y3);
            p5.endShape(p5.CLOSE);
        }
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }


    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2 && parameters.color3 && parameters.color4) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
                setColor4(parameters.color4);
            }
            if (parameters.mode) {
                setMode(parameters.mode);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.); 
        p5.strokeCap(p5.SQUARE);
        curCornerX = props.displaySize / 2;
        curCornerY = props.displaySize / 2;
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.noStroke();

        // draw rest of triangles
        let i = 0;
        for (i = 0; i < triangles.length; i++) {
            if (i % 4 === 0) {
                p5.fill(color1);
            } else if (i % 4 === 1) {
                p5.fill(color2);
            } else if (i % 4 === 2) {
                p5.fill(color3);
            } else if (i % 4 === 3) {
                p5.fill(color4);
            }
            drawTriangle(p5, triangles[i].x1, triangles[i].y1, triangles[i].x2, triangles[i].y2, triangles[i].x3, triangles[i].y3);
        }

        // draw current triangle
        if (curStroke.length >= 1) {
            if (i % 4 === 0) {
                p5.fill(color1);
            } else if (i % 4 === 1) {
                p5.fill(color2);
            } else if (i % 4 === 2) {
                p5.fill(color3);
            } else if (i % 4 === 3) {
                p5.fill(color4);
            }
            let pts = findTriangle(curStroke[0].x, curStroke[0].y, p5.mouseX, p5.mouseY);
            curCornerX = pts[4] * 0.35 + curCornerX * 0.65;
            curCornerY = pts[5] * 0.35 + curCornerY * 0.65;
            drawTriangle(p5, pts[0], pts[1], pts[2], pts[3], curCornerX, curCornerY);
        }
    };

    const clearDrawingHandler = () => {
        triangles = [];
        return true;
    }

    const undoHandler = () => {
        if (triangles.length >= 1) {
            triangles.splice(triangles.length - 1);
        }
    }

    function scaleValues(tri) {
        return (new Triangle(
            tri.x1 / scaleFactor, tri.y1 / scaleFactor,
            tri.x2 / scaleFactor, tri.y2 / scaleFactor,
            tri.x3 / scaleFactor, tri.y3 / scaleFactor
        ));
    }

    const submitButtonHandler = () => {
        props.parentCallback(triangles.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawRightTriangles;
