import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classes from './EditChallenge.module.css';
import { useHistory, useParams } from 'react-router-dom'
import * as firebaseService from '../../services/firebase.js';
import * as authService from '../../services/auth.js';
import * as trackingService from '../../services/tracking.js';
import { TOOLS_IN_ORDER } from '../../services/p5Tools.js';
import { yyyymmddToDate, DAILY_CHALLENGE_TIMEZONE_OFFSET_IN_MIN, getFriendlyTime, dateToYyyymmdd, IS_PROD } from '../../services/shared';
import * as randWordList from '../../services/randomWords.js'

const DEFAULT_LENGTH = 5;

function EditChallenge({ modalView, closeAction }) {
    const [isAdmin, setIsAdmin] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [lengthInDays, setLengthInDays] = useState(5);
    const [startDate, setStartDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [startDateInputValue, setStartDateInputValue] = useState(dateToYyyymmdd(startDate, new Date().getTimezoneOffset()));
    const [message, setMessage] = useState('');
    const [challenge, setChallenge] = useState(null);
    const [hasChallenge, setHasChallenge] = useState(false);
    const [author, setAuthor] = useState(null);
    const [hasAnySubmission, setHasAnySubmission] = useState(false);
    const [allPrompts, setAllPrompts] = useState([]);
    const [allPromptEntryList, setAllPromptEntryList] = useState([]);  // {id, obj, selected, promptParameters}
    const [isForceDisplay, setIsForceDisplay] = useState(false);
    const [showLink, setShowLink] = useState(false);
    const [copyChallengeMessage, setCopyChallengeMessage] = useState(false);
    const [challengeType, setChallengeType] = useState('normal');  // normal/community/daily
    const [copyChallengeId, setCopyChallengeId] = useState('');
    const [existingPrompts, setExistingPrompts] = useState([]);
    const history = useHistory();
    const params = useParams();

    // Set isAdmin
    useEffect(() => {
        const currentUser = authService.getCurrentUserDirect();
        firebaseService.loadProfile(currentUser.uid).then((profile) => {
            setIsAdmin(['admin'].includes(profile.role));
        });
    }, []);

    // Clear the copy challenge message after 3s
    useEffect(() => {
        const currentMessage = copyChallengeMessage;
        const timeout = setTimeout(() => {
            if (copyChallengeMessage === currentMessage) {
                setCopyChallengeMessage('');
            }
        }, 3000);
        return () => {
            clearTimeout(timeout);
        }
    }, [copyChallengeMessage]);

    // Load all prompts (for the selection list)
    useEffect(() => {
        firebaseService.loadAllPrompts().then(setAllPrompts);
    }, []);

    // Load the challenge (if editing)
    useEffect(() => {
        if (!params.challengeId) {
            setChallenge(null);
            return;
        }
        firebaseService.loadChallenge(params.challengeId).then(setChallenge);
    }, [params.challengeId]);

    // Init basic values for both create and edit cases
    useEffect(() => {
        if (!Object.keys(allPrompts).length) {
            return;
        }

        if (!params.challengeId) { // Creating a new challenge
            randomTitle();

            const promptEntries = Object.entries(allPrompts)
                .filter(([_, obj]) => !IS_PROD || !obj.hide)  // If on localhost, show hidden prompts
                .map(([id, obj]) => ({ id, obj }));
            const promptByTool = new Map(); // key: tool name; value: array of prompts
            promptEntries.forEach((entry) => {
                if (!promptByTool.has(entry.obj.extraData.p5name)) {
                    promptByTool.set(entry.obj.extraData.p5name, []);
                }
                promptByTool.get(entry.obj.extraData.p5name).push(entry);
            });

            // Pre-select `DEFAULT_LENGTH` tools, with no dup tool, and in the order of TOOLS_IN_ORDER
            Array.from(promptByTool.entries())
                // Pick one prompt for each tool
                .map(([toolName, entries]) => [toolName, entries[Math.floor(Math.random() * entries.length)]])
                // Randomly pick DEFAULT_LENGTH tools
                .sort(() => Math.random() - .5).slice(0, DEFAULT_LENGTH)
                // Select them
                .forEach(([_, entry]) => entry.selected = true);

            promptEntries.sort((entryA, entryB) => {
                if (entryA.selected && !entryB.selected) return -1;
                if (!entryA.selected && entryB.selected) return 1;
                return TOOLS_IN_ORDER.indexOf(entryA.obj.extraData.p5name) - TOOLS_IN_ORDER.indexOf(entryB.obj.extraData.p5name);
            });
            setAllPromptEntryList(promptEntries);
        } else if (challenge) { // Editing a challenge
            setHasChallenge(true);
            setTitle(challenge.title);
            setDescription(challenge.description);
            setLengthInDays(challenge.lengthInDays);
            setExistingPrompts(challenge.prompts);
            setChallengeType(challenge.type || 'normal');
            setStartDate(new Date(challenge.startTimestamp));
            setStartDateInputValue(dateToYyyymmdd(new Date(challenge.startTimestamp), challenge.type === 'daily' ? DAILY_CHALLENGE_TIMEZONE_OFFSET_IN_MIN : new Date().getTimezoneOffset()));
            setHasAnySubmission(challenge.prompts.some((day) => day.submissions));
            setAllPromptEntryList(getPromptsEntriesFromChallenge(challenge, allPrompts));
            firebaseService.loadProfile(challenge.author).then(setAuthor);
        }
    }, [challenge, params.challengeId, allPrompts]);

    // Change the date object whenever input changes
    useEffect(() => {
        if (!startDateInputValue) return;

        const date = yyyymmddToDate(startDateInputValue, challengeType === 'daily' ? DAILY_CHALLENGE_TIMEZONE_OFFSET_IN_MIN : undefined);
        if (date) {
            setStartDate(date);
        }
    }, [startDateInputValue, challengeType]);

    function _validate() {
        if (!title) {
            setMessage('Title is required');
            return false;
        }

        if (!lengthInDays || isNaN(lengthInDays)) {
            setMessage('Length in days must be a number');
            return false;
        }

        if (!startDate) {
            setMessage('Start day is required');
            return false;
        }

        // Only require this for creating a challenge
        if (!params.challengeId && startDate.getTime() < new Date().setHours(0, 0, 0, 0)) {
            setMessage('Start day is a past day');
            return false;
        }
        return {
            title,
            description,
            lengthInDays,
            startTimestamp: startDate.getTime(),
            prompts: _getPromptsToSave(),
            type: challengeType,
        };
    }

    function create() {
        const validatedChallenge = _validate();
        if (!validatedChallenge) return;

        setMessage('Creating');

        firebaseService.addChallenge(validatedChallenge).then((id) => {
            trackingService.logAmplitude('Create Challenge - Finish', { 'Challenge ID': id });
            trackingService.logGA('Create challenge', 'Finished', id);
            setMessage('Created!');
            if (modalView) {
                // Close modal and send challenge ID back to Home
                closeAction(id);
            } else {
                history.push(`/c/${id}`);
            }
        }).catch((error) => {
            setMessage(error.message);
        });
    }

    function update() {
        const validatedChallenge = _validate();
        if (!validatedChallenge) return;

        setMessage('Updating');

        firebaseService.updateChallenge(params.challengeId, validatedChallenge).then(() => {
            setMessage('Updated!');
            setShowLink(true);
        }).catch((error) => {
            setMessage(error.message);
        });
    }

    function cancel() {
        if (modalView) {
            closeAction(null);
        } else {
            history.goBack();
        }
    }

    function _findExistingSubmission(promptId) {
        const existingDay = existingPrompts.find((dayEntry) => promptId === dayEntry.promptId);
        return existingDay?.submissions;
    }

    function _getPromptsToSave() {
        return allPromptEntryList.filter(({ selected }) => selected)
            .map(({ id, promptParameters }) => {
                const day = { promptId: id };
                const submissions = _findExistingSubmission(id);
                if (submissions) {
                    day.submissions = submissions;
                }
                if (promptParameters) {
                    day.promptParameters = promptParameters;
                }
                return day;
            });
    }

    function copyChallenge() {
        setCopyChallengeMessage('Copying...');
        firebaseService.loadChallenge(copyChallengeId).then((challengeToCopy) => {
            if (!challengeToCopy) {
                setCopyChallengeMessage('No challenge found matching this id');
                return;
            }

            if (!challengeToCopy.prompts) {
                setCopyChallengeMessage('Challenge has no prompts (currupted challenge)');
                return;
            }

            for (const day of challengeToCopy.prompts) {
                delete day.submissions;
            }
            setLengthInDays(challengeToCopy.lengthInDays);
            setExistingPrompts(challengeToCopy.prompts);
            setAllPromptEntryList(getPromptsEntriesFromChallenge(challengeToCopy, allPrompts));
        });
    }

    function orderPrompt(from, to) {
        [allPromptEntryList[from], allPromptEntryList[to]] = [allPromptEntryList[to], allPromptEntryList[from]];
        setAllPromptEntryList(allPromptEntryList.slice(0));  // Trigger render updates
    }

    function togglePrompt(entry) {
        entry.selected = !entry.selected;
        setLengthInDays(allPromptEntryList.filter(({ selected }) => selected).length);
        setAllPromptEntryList(allPromptEntryList.slice(0));  // Trigger render updates
    }

    function randomTitle() {
        const i = Math.floor(Math.random() * randWordList.noun.length);
        const j = Math.floor(Math.random() * randWordList.adj.length);
        const adj = randWordList.adj[j].charAt(0).toUpperCase() + randWordList.adj[j].slice(1);
        setTitle(adj + " " + randWordList.noun[i])
    }

    const upadtePromptParameters = (entry, value) => {
        entry.promptParameters = value;
        setAllPromptEntryList(allPromptEntryList.slice(0));  // Trigger render updates
    }

    const form = <div className={classes.form}>
        <label className={classes.field}>
            <span className={classes.formLabel}>Title</span>
            <input value={title} required onChange={e => setTitle(e.target.value)} />
            <div className={classes.titleHint}>
                <span className={classes.hintMessage}>Pick a random title, or type a new one your group will appreciate.</span>
                <button onClick={randomTitle} className={classes.purple}>Random title</button>
            </div>
        </label>
        <label className={classes.field}>
            <span className={classes.formLabel}>Start date</span>
            <div className={classes.date}>
                <input type="date" required value={startDateInputValue} onChange={e => setStartDateInputValue(e.target.value)} />
            </div>
            <span className={classes.hintMessage}>
                {hasAnySubmission ? <span className={classes.hintMessage}>NOTE: Works have been submitted already. Changing the date will move the days for those works.<br /></span> : null}
        New prompts will update daily at {hasChallenge ? `${getFriendlyTime(startDate)} your local time` : `midnight (${/\((.*)\)/.exec(new Date().toTimeString())[1]})`} and continue for {lengthInDays} days.</span>
        </label>
        {isAdmin &&
            <label className={classes.field}>
                <span className={classes.formLabel}>Special type</span>
                <div className={classes.timezone}>
                    <select value={challengeType} onChange={e => setChallengeType(e.target.value)}>
                        <option value="normal">Normal</option>
                        {/* <option value="daily">Daily Play (America/Eastern timezone)</option> */}
                        <option value="community">Public community</option>
                    </select>
                </div>
            </label>
        }
        <label className={classes.field}>
            <span className={classes.formLabel}>Welcome note (optional)</span>
            <textarea className={classes.multiLine} value={description} onChange={e => setDescription(e.target.value)} />
            <span className={classes.hintMessage}></span>
        </label>

        {isAdmin &&
            <label className={classes.field}>
                <span className={classes.formLabel}>Copy prompts</span>
                <div>
                    <input value={copyChallengeId} onChange={(e) => setCopyChallengeId(e.target.value)} placeholder="Challenge id to copy from" />
                </div>
                <span className={classes.hintMessage}>
                    <button onClick={copyChallenge} disabled={copyChallengeMessage}>Copy prompts</button>
                    {copyChallengeMessage}</span>
            </label>
        }
        {isAdmin && !isForceDisplay ?
            <div className={classes.forceDisplay}><button onClick={() => setIsForceDisplay(true)}>Show prompts (admin only)</button></div> : ''}
        {/* Hiding prompts for non-admins via CSS because we still want the form elements to be on the page */}
        <div className={isAdmin && isForceDisplay ? classes.promptsAdmin : classes.promptsCreator}>
            <label className={classes.field}>
                <span className={classes.formLabel}>Length in days</span>
                <input type="number" required disabled value={lengthInDays} onChange={e => setLengthInDays(Math.round(Number(e.target.value)))} />
            </label>
            <span className={classes.formLabel}>Prompts</span>
            <ul>
                {allPromptEntryList.map((promptEntry, index) => <li key={promptEntry.id} className={classes.promptListItem}>{index + 1}.
                    <label>
                        <input type="checkbox" checked={!!promptEntry.selected} onChange={() => togglePrompt(promptEntry)} disabled={_findExistingSubmission(promptEntry.id)}></input>
                        {_findExistingSubmission(promptEntry.id) ? <b>[Has submissions] </b> : null}
                        <span className={classes.promptItem}>[{promptEntry.obj.extraData.p5name}] {promptEntry.obj.title} {promptEntry.obj.hide ? <b>[hidden] </b> : null}</span>
                    </label>
                    <input className={classes.parameterInput} type="text" value={promptEntry.promptParameters || ''} onChange={(e) => upadtePromptParameters(promptEntry, e.target.value)} placeholder="Optional parameters in JSON" />
                    <button type="button" className={classes.orderButton} disabled={index === 0} onClick={() => orderPrompt(index, index - 1)}>↑</button>
                    <button type="button" className={classes.orderButton} disabled={index === allPromptEntryList.length - 1} onClick={() => orderPrompt(index, index + 1)}>↓</button>
                </li>)}
            </ul>
        </div>
    </div>

    const CreateContent = <>
        <h1>Host a new challenge</h1>
        {form}
        <div className={classes.buttonHolder}>
            <button className={classes.purpleOutlineLarge} onClick={cancel}>Cancel</button>
            <button className={classes.action} onClick={create}>Start this challenge</button>
            <span className={classes.message}>{message}
                {showLink ? <Link to={{ pathname: `/c/${params.challengeId}` }} className={classes.challengeLink}>→ Go to this challenge</Link> : null}
            </span>
        </div>
    </>

    const EditContent = <>
        <h1>Edit challenge</h1>
        <h2>Started by {author ? author.name : 'Unknown'} <span className={classes.host}>HOST</span></h2>
        {hasChallenge ? <>
            {form}
            <div className={classes.buttonHolder}>
                <button className={classes.purpleOutlineLarge} onClick={cancel}>Cancel</button>
                <button className={classes.action} onClick={update}>Update</button>
                <span className={classes.message}>{message}
                    {showLink ? <Link to={{ pathname: `/c/${params.challengeId}` }} className={classes.challengeLink}>→ Go to this challenge</Link> : null}
                </span>
            </div>
        </> : ''}
    </>

    return (
        <div className={classes.content}>
            <Helmet>
                {hasChallenge ? <title>Edit a challenge | YDays</title> : <title>Create a challenge | YDays</title>}
            </Helmet>
            {hasChallenge ? EditContent : CreateContent}
        </div>
    );
}

function getPromptsEntriesFromChallenge(challenge, allPrompts) {
    const promptsInChallenge = [];
    for (const challengePrompt of challenge.prompts) {
        promptsInChallenge.push(({
            id: challengePrompt.promptId,
            selected: true,
            obj: allPrompts[challengePrompt.promptId],
            promptParameters: challengePrompt.promptParameters,
        }));
    }

    const remainingPrompts = Object.entries(allPrompts)
        .filter(([id]) => promptsInChallenge.every((entry) => entry.id !== id))
        .map(([id, obj]) => ({ id, obj })).sort((entryA, entryB) => TOOLS_IN_ORDER.indexOf(entryA.obj.extraData.p5name) - TOOLS_IN_ORDER.indexOf(entryB.obj.extraData.p5name));
    return [...promptsInChallenge, ...remainingPrompts];
}

export default EditChallenge;
