import React, {useState, useEffect} from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawRotatingStrokes(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [color1, setColor1] = useState("#F4665CBE");
    const [color2, setColor2] = useState("#4181FFBE");
    const [bgcolor, setBgcolor] = useState("#FFFFFF");
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(d, rot, grot, t) {
        // d is distance from center
        // rot is angle in radians when no rotation
        // grot is global angle rotation at time of adding
        this.d = d;
        this.rot = rot;
        this.grot = grot;
        this.t = t;
    }

    let strokes = [];
    let curStroke = [];

    let isDrawing = false;
    let globalrot = 0; // global rotation
    let lastPointTime = 0;

    function addPoint(p5, pX, pY) {
        let d = getDistance(pX, pY, p5.width / 2, p5.height / 2);
        let rot = Math.atan2(pY - p5.height / 2, pX - p5.width / 2);

        curStroke.push(new Point(d, rot, globalrot, current(p5)));
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) return;
        isDrawing = true;

        if (isDrawing && curStroke.length > 0) {
            strokes.push(curStroke);
            curStroke = [];
        }
        strokes.push(curStroke);
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            let cur = current(p5);
            if (cur - lastPointTime > 1 / 45) {
                addPoint(p5, pX, pY);
                lastPointTime = cur;
            }
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            strokes.pop();
            strokes.push(curStroke);
            curStroke = [];
            isDrawing = false;
        }
    }


    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }


    function current(p5) {
        return p5.millis() * .001;
    }

    function cycleBy(time, interval) {
        return (time - interval * Math.floor(time / interval))
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.bgcolor && parameters.color1 && parameters.color2) {
                setBgcolor(parameters.bgcolor);
                setColor1(parameters.color1);
                setColor2(parameters.color2);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        const tempScaleFactor = props.displaySize / 500.;
        setScaleFactor(tempScaleFactor); // apply this scale factor to strokeWeight

        p5.strokeCap(p5.ROUND);
        p5.strokeWeight(7 * tempScaleFactor);
        p5.curveTightness(-0.5);
        p5.noFill();
        p5.smooth();
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        if (isDrawing) {
            if (p5.mouseX < 0 || p5.mouseX > p5.width || p5.mouseY < 0 || p5.mouseY > p5.height) {
                stopDrawing(p5, p5.mouseX, p5.mouseY);
            } else {
                keepDrawing(p5, p5.mouseX, p5.mouseY);
            }
        }

        p5.background(bgcolor);

        let curTime = current(p5);
        const interval = 8;
        globalrot = 2 * Math.PI * cycleBy(curTime, interval) / interval;
        // repeats every interval secs

        p5.push();
        p5.translate(p5.width / 2, p5.height / 2);
        p5.rotate(globalrot);
        // d, rot, grot

        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                if (i % 2 === 0) {
                    p5.stroke(color1);//255, 102, 92, 190);
                } else {
                    p5.stroke(color2);//65, 129, 255, 190);
                }
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    p5.rotate(strokes[i][j].grot);
                    let x = strokes[i][j].d * Math.cos(-strokes[i][j].grot + strokes[i][j].rot);
                    let y = strokes[i][j].d * Math.sin(-strokes[i][j].grot + strokes[i][j].rot);
                    p5.curveVertex(x, y);
                    p5.rotate(-strokes[i][j].grot);
                }
                p5.endShape();
            }
        }

        p5.pop();
    };

    const clearDrawingHandler = () => {
        strokes = [];
        return true;
    }

    const undoHandler = () => {
        if (strokes.length >= 1) {
            strokes.splice(strokes.length - 1);
        }
    }

    function scaleValues(stroke) {
        let newStroke = [];
        for (let i = 0; i < stroke.length; i++) {
            newStroke.push(new Point(
                stroke[i].d / scaleFactor,
                stroke[i].rot,
                stroke[i].grot,
                stroke[i].t
            ));
        }
        return newStroke;
    }

    const submitButtonHandler = () => {
        props.parentCallback(strokes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawRotatingStrokes;
