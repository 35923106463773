import React, {useState, useEffect}  from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayBeforeAfter(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokes, setStrokes] = useState([]);
    const [imagePath, setImagePath] = useState("https://firebasestorage.googleapis.com/v0/b/ydays-618cd.appspot.com/o/assets%2Fbefore-after.png?alt=media&token=9294467e-65ba-4270-aa60-bad5acfa0e64");
    const [imgSrc, setImgSrc] = useState();
    const [strokew, setStrokew] = useState(5);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let curStroke = [];
    let bgImage;
    let isImageLoaded = false;

    function addPoint(p5, pX, pY, t) {
        curStroke.push(new Point(pX, pY, t));
    }

    const preload = (p5) => {
        let parameters = {};
        let tempImagePath = imagePath;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.imagePath) {
                tempImagePath = parameters.imagePath;
                setImagePath(parameters.imagePath);
            }
            if (parameters.strokew) {
                setStrokew(parameters.strokew);
            }
        }
        setImgSrc(p5.loadImage(tempImagePath, " "));

        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);

        const tempScaleFactor = props.displaySize / 500.;
        const tempStrokew = strokew * tempScaleFactor;
        setScaleFactor(tempScaleFactor); // apply this scale factor to strokeWeight
        setStrokew(tempStrokew);

        p5.strokeCap(p5.ROUND);
        p5.strokeJoin(p5.ROUND);
        p5.strokeWeight(tempStrokew);
        p5.noFill();

        let strokeData = props.data;
        const tempStrokes = [];
        for (let i = 0; i < strokeData.length; i++) {
            if (strokeData[i] != null) {
                let stroke = strokeData[i];
                for (let k = 0; k < stroke.length; k++) {
                    if (stroke[k] != null) {
                        let pt = stroke[k];
                        addPoint(p5, pt.x * tempScaleFactor, pt.y * tempScaleFactor, pt.t);
                    }
                }
                tempStrokes.push(curStroke);
                curStroke = [];
            }
        }
        setStrokes(tempStrokes);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        if (isImageLoaded) {
            p5.image(bgImage, 0, 0, p5.width, p5.height);
        } else {
            // Load source image and make a copy of pixels into bgImage
            const d = p5.pixelDensity();
            p5.image(imgSrc, 0, 0, p5.width, p5.height);
            p5.loadPixels();
            bgImage = p5.createImage(d * p5.width, d * p5.height);
            bgImage.loadPixels();
            for (let i = 0; i < d * p5.width; i++) {
                for (let j = 0; j < d * p5.height; j++) {
                    let index = 4 * (j * p5.width * d + i);
                    bgImage.set(i, j, p5.color(p5.pixels[index], p5.pixels[index + 1], p5.pixels[index + 2], 255));
                }
            }
            bgImage.updatePixels();
            isImageLoaded = true;
        }

        p5.strokeWeight(strokew);
        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    p5.curveVertex(strokes[i][j].x, strokes[i][j].y);
                }
                p5.endShape();
            }
        }
        if (props.withWatermark) {
            // This tool is not scaled, so needs to draw the watermark with scaleFactor
            drawWatermark(p5, watermarkImage, scaleFactor);
        }
    };



    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayBeforeAfter;
