import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import * as firebaseService from '../../services/firebase.js';
import * as authService from '../../services/auth.js';
import { ONE_DAY_IN_MILLISECONDS, getTimeDiff, decideSketchSize } from '../../services/shared';
import Spinner from './Spinner';
import Voting from './Voting';
import VoteSummary from './VoteSummary';
import WorkDisplay from './WorkDisplay';
import ShareImageButton from './ShareImageButton';
import ErrorBoundary from '../ErrorBoundary';
import UserTitle from './UserTitle';

import classes from './Review.module.css';
import sharedClasses from '../../shared.module.css';

const SHOW_MORE_BATCH_SIZE = 20;

/**
 * Props:
 * - promptIndex
 * - challengeId
 * - title
 * - arrowButtons
 * - hideChallengeSummaryLink
 * - todayPromptUrl  // After voting, the button url
 * - dailyDate  // Only used to log different event
 */
class Review extends Component {
    state = {
        challenge: null,
        hasChallengeEnded: null,
        prompt: null,
        hasPromptEnded: null,
        isVotingDay: false,
        allSubmissionEntries: [],
        visibleWorks: [],
        isAdmin: false,
        currentUserWork: null,
        loadingMessage: '',
        sketchSize: decideSketchSize('gallery'),
        showAd: false,
        visibleWorkCount: SHOW_MORE_BATCH_SIZE,
        currentUserUid: authService.getCurrentUserDirect()?.uid,
    }

    componentDidMount() {
        this.loadAllData();
        firebaseService.loadCurrentUserProfile().then((profile) => {
            if (profile?.role === 'admin') {
                this.setState({ isAdmin: true });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.promptIndex !== this.props.promptIndex || prevProps.challengeId !== this.props.challengeId) {
            this.loadAllData();
        }
    }

    loadAllData() {
        this.setState({
            challenge: null,
            prompt: null,
            visibleWorks: []
        });

        // Show ad on days 2+
        if (this.props.promptIndex >= 1) {
            this.setState({ showAd: true });
        }

        firebaseService.loadChallenge(this.props.challengeId)
            .then((challenge) => {
                if (!challenge) {
                    this.setState({ loadingMessage: `Eh-oh! Couldn’t find this challenge.` });
                    return;
                }
                this.setState({ challenge });
                this.props.updateChallengeLength && this.props.updateChallengeLength(challenge.prompts.length);
                const promptTimeDiff = getTimeDiff(challenge.startTimestamp, this.props.promptIndex);
                this.setState({ hasPromptEnded: promptTimeDiff > ONE_DAY_IN_MILLISECONDS });
                this.setState({ isVotingDay: promptTimeDiff > ONE_DAY_IN_MILLISECONDS && promptTimeDiff < ONE_DAY_IN_MILLISECONDS * 2 });
                this.setState({ hasChallengeEnded: getTimeDiff(challenge.startTimestamp, challenge.prompts.length) > 0 });

                if (!challenge.prompts[this.props.promptIndex]) {
                    this.setState({ loadingMessage: `Eh-oh! Couldn’t find a prompt for day ${this.props.promptIndex + 1}.` });
                    return;
                }
                firebaseService.loadPrompt(challenge.prompts[this.props.promptIndex].promptId)
                    .then((prompt) => {
                        if (!prompt) {
                            this.setState({ loadingMessage: `Eh-oh! Couldn’t find this prompt.` });
                            return;
                        }
                        this.setState({ prompt });
                    });

                this.loadWorks(Object.entries(challenge.prompts[this.props.promptIndex].submissions || {}).filter(([uid]) => uid === this.state.currentUserUid)).then((works) => {
                    this.setState({ currentUserWork: works[0] });
                });

                const allSubmissionEntries = Object.entries(challenge.prompts[this.props.promptIndex].submissions || {}).filter(([uid]) => uid !== this.state.currentUserUid).sort(() => Math.random() - .5);
                this.setState({ allSubmissionEntries });

                this.loadWorks(allSubmissionEntries.slice(0, this.state.visibleWorkCount)).then((works) => {
                    this.setState({ visibleWorks: works });
                });
            });
    }

    loadWorks = (submissionEntries) => {
        return Promise.all(submissionEntries.map(async (personEntry) => ({
            authorProfile: await firebaseService.loadProfile(personEntry[0]),
            workId: personEntry[1][personEntry[1].length - 1],
            workObj: await firebaseService.loadWork(personEntry[1][personEntry[1].length - 1]),
        })));
    }

    loadMore = () => {
        this.setState({ isLoadMoreLoading: true });

        this.loadWorks(this.state.allSubmissionEntries.slice(this.state.visibleWorkCount, this.state.visibleWorkCount + SHOW_MORE_BATCH_SIZE)).then((works) => {
            this.setState({
                visibleWorks: [...this.state.visibleWorks, ...works],
                visibleWorkCount: this.state.visibleWorkCount + SHOW_MORE_BATCH_SIZE,
                isLoadMoreLoading: false,
            });
        });
    }

    render() {
        const challenge = this.state.challenge;
        const prompt = this.state.prompt;
        if (!challenge || !prompt || !this.state.visibleWorks) {
            return <div className={classes.Gallery}>
                <div className={classes.content}><Spinner /></div>
                <p>{this.state.loadingMessage}</p>
            </div>
        }

        let nextPrompt

        if (this.state.isVotingDay) {
            nextPrompt = <div className={classes.nextPrompt}>
                <div className={classes.headerSection}><h2>Awesome sauce!</h2><p>Ready to check out today’s prompt?</p></div>
                <Link className={sharedClasses.primaryButton} to={{ pathname: this.props.todayPromptUrl }}>Today’s prompt</Link>
            </div>;
        }

        if (!this.state.hasPromptEnded) {
            nextPrompt = <div className={classes.nextPrompt}>
                <div className={classes.headerSection}><h2>Still collecting submissions</h2><p>Come back tomorrow to see what everyone came up with.</p></div>
                <Link className={sharedClasses.primaryButton} to={{ pathname: `/home` }}>Back to home</Link>
            </div>;
        }
        if (this.state.hasChallengeEnded && this.props.promptIndex === challenge.prompts.length - 1) {
            // Show this CTA only if on the last day
            nextPrompt = <div className={classes.nextPrompt}>
                <div className={classes.headerSection}><h2>You’ve completed this challenge!</h2><p>See the summary.</p></div>
                <Link className={sharedClasses.primaryButton} to={{ pathname: `/c/${this.props.challengeId}` }}>Challenge summary</Link>
            </div>;
        }

        return (
            <div className={classes.Gallery}>
                <div className={classes.content}>
                    <div className={classes.PromptHeader}>
                        <h1>{this.props.title}</h1>
                        {this.props.arrowButtons}
                    </div>
                    <div className={classes.topSubmissionArea}>
                        <div className={classes.promptBox}>
                            <div>
                                <h2 className={classes.promptTitle}>{prompt.title}</h2>
                                <span className={classes.promptInstructions}>{prompt.description}</span>
                            </div>
                            <div>
                                {this.state.showAd && this.state.currentUserWork &&
                                    <div className={classes.adLink}>
                                        <span className={classes.runningHead}><span className={classes.emoji} role="img" aria-label="heart">❤️</span> Loving YDays?</span>
                                        <span className={classes.runningText}>Consider <a href="https://www.buymeacoffee.com/ydays" target="_blank" rel="noopener noreferrer">buying us a coffee.</a></span>
                                    </div>}
                            </div>
                        </div>
                        {this.state.currentUserWork &&
                            <>
                                <div className={classes.yourSubmission}>
                                    <p className={classes.authorName}>Your submission</p>
                                    <div className={classes.sketch}>
                                        <ErrorBoundary>
                                            <WorkDisplay workData={this.state.currentUserWork.workObj.data}
                                                workId={this.state.currentUserWork.workId}
                                                prompt={this.state.prompt}
                                                size={this.state.sketchSize}
                                                p5name={this.state.prompt.extraData.p5name}
                                            // challengeDayParameters={this.state.challenge.prompts?.[this.props.promptIndex]?.promptParameters}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                    <ErrorBoundary>
                                        <VoteSummary workId={this.state.currentUserWork.workId} reactions={this.state.currentUserWork.workObj.reactions}></VoteSummary>
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <div className={classes.shareButton}>
                                            <ShareImageButton workId={this.state.currentUserWork.workId} />
                                        </div>
                                    </ErrorBoundary>
                                </div>
                            </>}
                    </div>
                    {this.state.visibleWorks.length && this.state.hasPromptEnded ?
                        <div className={classes.headerSection}>
                            <h2>What did everyone come up with?</h2>
                            <p>Show <span className={classes.promptInstructions}>more love</span> for your challenge mates’ submissions with <span className={classes.promptInstructions}>more emojis!</span> 👏</p>
                        </div>
                        : null}
                    {!this.state.hasPromptEnded && this.state.visibleWorks?.length > 0 ?
                        <div className={classes.headerSection}>
                            <h2>Some submissions so far</h2>
                            <p>Come back tomorrow to see what everyone came up with.</p>
                        </div> : null}
                    <div className={classes.drawings}>
                        {this.state.visibleWorks.map((work) =>
                            <ErrorBoundary key={work.workId}>
                                <div className={this.state.hasPromptEnded ? classes.sketchHolder : classes.spacerHolder}>
                                    {this.state.hasPromptEnded && <div className={classes.authorName}><UserTitle profile={work.authorProfile} /></div>}
                                    <div className={classes.sketch}>
                                        <WorkDisplay workData={work.workObj.data}
                                            workId={work.workId}
                                            prompt={this.state.prompt}
                                            size={this.state.sketchSize}
                                            p5name={this.state.prompt.extraData.p5name}
                                        // challengeDayParameters={this.state.challenge.prompts?.[this.props.promptIndex]?.promptParameters}
                                        />
                                    </div>
                                    {this.state.hasPromptEnded ?
                                        <Voting workId={work.workId}
                                            reactions={work.workObj.reactions}
                                            challengeId={this.props.challengeId}
                                            dayIndex={this.props.promptIndex}
                                            hasChallengeEnded={this.state.hasChallengeEnded}
                                            dailyDate={this.props.dailyDate}
                                        ></Voting>
                                        : null}
                                </div>
                            </ErrorBoundary>
                        )}
                    </div>
                    {this.state.allSubmissionEntries.length > this.state.visibleWorkCount ?
                        <button className={sharedClasses.secondaryButtonOutline} onClick={() => this.loadMore()} disabled={this.state.isLoadMoreLoading}>
                            Show {this.state.allSubmissionEntries.length > this.state.visibleWorkCount + SHOW_MORE_BATCH_SIZE ? SHOW_MORE_BATCH_SIZE : this.state.allSubmissionEntries.length - this.state.visibleWorkCount} more</button>
                        : null}
                    {(!this.props.hideChallengeSummaryLink && this.state.hasChallengeEnded) || (!this.state.hasChallengeEnded && this.props.promptIndex < challenge.prompts.length) ?
                        nextPrompt : ''}
                </div>
            </div>
        );
    }
}

// `withRouter` is needed for *class component* that is not a direct child of router to access `this.props.history`
export default withRouter(Review);
