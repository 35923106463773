import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayGridC(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [grid, setGrid] = useState([]); // 2D array with 0 or 1 values
    const [squaresPerSide, setSquaresPerSide] = useState(15);
    const [fillColor, setFillColor] = useState('#FFFFFF');
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempSquaresPerSide = squaresPerSide;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.squaresPerSide) {
                tempSquaresPerSide = parameters.squaresPerSide;
                setSquaresPerSide(tempSquaresPerSide);
            }
            if (parameters.fillColor) {
                setFillColor(parameters.fillColor);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        p5.ellipseMode(p5.CORNER);

        let tempGrid = [];
        for (let i = 0; i < tempSquaresPerSide; i++) {
            let row = [];
            for (let j = 0; j < tempSquaresPerSide; j++) {
                row.push(0);
            }
            tempGrid.push(row);
        }

        let gridData = props.data[0];
        if (gridData.length === 0) return;
        for (let i = 0; i < tempSquaresPerSide; i++) {
            for (let j = 0; j < tempSquaresPerSide; j++) {
                if (gridData[i][j] === 1) {
                    tempGrid[i][j] = 1;
                }
            }
        }
        setGrid(tempGrid);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        let gridSqW = p5.width / squaresPerSide;
        const inner = 0.5;

        // draw all outer black shapes first
        p5.fill(0);
        for (let i = 0; i < squaresPerSide; i++) {
            for (let j = 0; j < squaresPerSide; j++) {
                if (grid && grid[i] && grid[i][j] === 1) {
                    p5.ellipse(i * gridSqW, j * gridSqW, gridSqW, gridSqW);

                    // check 2 directions
                    // north
                    if (j > 0 && grid[i][j - 1] === 1) {
                        p5.rect(i * gridSqW, (j - 0.5) * gridSqW, gridSqW, gridSqW);
                    }
                    // east
                    if (i > 0 && grid[i - 1][j] === 1) {
                        p5.rect((i - 0.5) * gridSqW, j * gridSqW, gridSqW, gridSqW);
                    }

                    // factor to approximate circle with bezier
                    const circleFactor = 0.552 / 2;

                    // check diagonals
                    // northwest
                    if (i > 0 && j > 0 && grid[i - 1][j - 1] === 1) {
                        p5.beginShape();
                        p5.vertex((i + 0.5) * gridSqW, j * gridSqW);
                        p5.bezierVertex(
                            (i + 0.5 - circleFactor) * gridSqW, j * gridSqW,
                            i * gridSqW, (j - 0.5 + circleFactor) * gridSqW,
                            i * gridSqW, (j - 0.5) * gridSqW);
                        p5.vertex((i - 0.5) * gridSqW, j * gridSqW);
                        p5.bezierVertex(
                            (i - 0.5 + circleFactor) * gridSqW, j * gridSqW,
                            i * gridSqW, (j + 0.5 - circleFactor) * gridSqW,
                            i * gridSqW, (j + 0.5) * gridSqW);
                        p5.endShape(p5.CLOSE);
                    }

                    // northeast
                    if (i < squaresPerSide - 1 && j > 0 && grid[i + 1][j - 1] === 1) {
                        p5.beginShape();
                        p5.vertex((i + 1) * gridSqW, (j + 0.5) * gridSqW);
                        p5.bezierVertex(
                            (i + 1) * gridSqW, (j + 0.5 - circleFactor) * gridSqW,
                            (i + 1.5 - circleFactor) * gridSqW, j * gridSqW,
                            (i + 1.5) * gridSqW, j * gridSqW);
                        p5.vertex((i + 1) * gridSqW, (j - 0.5) * gridSqW);
                        p5.bezierVertex(
                            (i + 1) * gridSqW, (j - 0.5 + circleFactor) * gridSqW,
                            (i + 0.5 + circleFactor) * gridSqW, j * gridSqW,
                            (i + 0.5) * gridSqW, j * gridSqW);
                        p5.endShape(p5.CLOSE);
                    }

                }
            }
        }

        // draw inner white shapes
        p5.fill(fillColor);
        p5.noStroke();
        for (let i = 0; i < squaresPerSide; i++) {
            for (let j = 0; j < squaresPerSide; j++) {
                if (grid && grid[i] && grid[i][j] === 1) {
                    p5.ellipse((i + inner * 0.5) * gridSqW, (j + inner * 0.5) * gridSqW, inner * gridSqW, inner * gridSqW);

                    // check 2 directions
                    // north
                    if (j > 0 && grid[i][j - 1] === 1) {
                        p5.rect((i + inner * 0.5) * gridSqW, (j + inner * 0.5 - 0.75) * gridSqW, inner * gridSqW, inner * gridSqW * 2);
                    }
                    // east
                    if (i > 0 && grid[i - 1][j] === 1) {
                        p5.rect((i + inner * 0.5 - 0.75) * gridSqW, (j + inner * 0.5) * gridSqW, inner * gridSqW * 2, inner * gridSqW);
                    }

                    // look northwest at all 4 corners
                    if (i > 0 && j > 0 && grid[i - 1][j - 1] === 1 && grid[i - 1][j] === 1 && grid[i][j - 1] === 1) {
                        p5.rect((i - 0.5) * gridSqW, (j - 0.5) * gridSqW, gridSqW, gridSqW);
                    }

                }
            }
        }
        if (props.withWatermark) {
            // This tool is not scaled, so needs to draw the watermark with scaleFactor
            drawWatermark(p5, watermarkImage, props.displaySize / 500);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayGridC;
