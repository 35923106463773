import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

let shapes = [];
// See P5DrawTangram for note on making shapes global

function P5DrawLetterShapes(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Dshape = function (points, r, g, b) {
        this.points = points.slice();
        this.rot = 0;
        this.targetrot = 0;
        this.rotating = false;
        this.r = r;
        this.g = g;
        this.b = b;
        this.selected = false;
        this.cx = 0;
        this.cy = 0;
        this.hpoints = [];

        this.l = 0;//length, approximate
        if (points.length < 1) return;

        this.minx = this.maxx = this.points[0][0];
        this.miny = this.maxy = this.points[0][1];
        for (let i = 0; i < this.points.length; i++) {
            let tempx = this.points[i][0];
            let tempy = this.points[i][1];
            if (tempx < this.minx) this.minx = tempx;
            if (tempx > this.maxx) this.maxx = tempx;
            if (tempy < this.miny) this.miny = tempy;
            if (tempy > this.maxy) this.maxy = tempy;
            this.cx += tempx;
            this.cy += tempy;
        }
        //calculate centroid
        this.cx = this.cx / this.points.length;
        this.cy = this.cy / this.points.length;
        let w = this.maxx - this.minx;
        let h = this.maxy - this.miny;
        if (w > h) this.l = w;
        else this.l = h;
    }


    Dshape.prototype.draw = function (p5) {
        //update rotation
        if (this.rotating) {
            let drot = 0.4 * (this.targetrot - this.rot);
            if (drot < 0.01) {
                drot = this.targetrot - this.rot;
                this.rotate(drot, this.cx, this.cy);
                this.rot = this.targetrot;
                this.rotating = false;
            }
            else this.rotate(drot, this.cx, this.cy);
        }

        p5.push();

        //p5.fill(this.color);
        p5.fill(this.r, this.g, this.b);
        p5.beginShape();
        for (let i = 0; i < this.points.length; i++) {
            p5.vertex(this.points[i][0], this.points[i][1]);
        }
        p5.endShape(p5.CLOSE);
        p5.strokeWeight(4 * scaleFactor);
        p5.stroke(255, 0, 0);
        //p5.point(this.cx, this.cy);//draw centroid, for debug
        /*
        //draw hit shape
        p5.strokeWeight(1);
        p5.stroke(255,0,0);
        p5.noFill();
        p5.beginShape();
        for (let i=0;i<this.hpoints.length;i++) {
            p5.vertex(this.hpoints[i][0], this.hpoints[i][1]);
        }
        p5.endShape(p5.CLOSE);
        */
        p5.pop();
    }

    Dshape.prototype.setHitPoints = function (points) {
        this.hpoints = points.slice();
    }

    Dshape.prototype.hitTest = function (px, py) {
        let inside = false;
        let hp = this.points;
        if (this.hpoints.length > 0) hp = this.hpoints;

        for (var i = 0, j = hp.length - 1; i < hp.length; j = i++) {
            var xi = hp[i][0], yi = hp[i][1];
            var xj = hp[j][0], yj = hp[j][1];

            var intersect = ((yi > py) !== (yj > py))
                && (px < (xj - xi) * (py - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;
    }

    Dshape.prototype.translate = function (dx, dy) {
        for (let i = 0; i < this.points.length; i++) {
            this.points[i][0] += dx;
            this.points[i][1] += dy;
        }
        for (let i = 0; i < this.hpoints.length; i++) {
            this.hpoints[i][0] += dx;
            this.hpoints[i][1] += dy;
        }
        this.cx += dx;
        this.cy += dy;
    }

    Dshape.prototype.scale = function (s) {
        for (let i = 0; i < this.points.length; i++) {
            this.points[i][0] *= s;
            this.points[i][1] *= s;
        }
        for (let i = 0; i < this.hpoints.length; i++) {
            this.hpoints[i][0] *= s;
            this.hpoints[i][1] *= s;
        }
        this.cx *= s;
        this.cy *= s;
    }

    Dshape.prototype.rotate = function (dr, x, y) {
        let s = Math.sin(dr);
        let c = Math.cos(dr);
        let px, py, xnew, ynew;
        for (let i = 0; i < this.points.length; i++) {
            px = this.points[i][0] - x;
            py = this.points[i][1] - y;
            xnew = px * c - py * s;
            ynew = px * s + py * c;
            this.points[i][0] = xnew + x;
            this.points[i][1] = ynew + y;
        }
        for (var i = 0; i < this.hpoints.length; i++) {
            px = this.hpoints[i][0] - x;
            py = this.hpoints[i][1] - y;
            xnew = px * c - py * s;
            ynew = px * s + py * c;
            this.hpoints[i][0] = xnew + x;
            this.hpoints[i][1] = ynew + y;
        }

        px = this.cx - x;
        py = this.cy - y;
        xnew = px * c - py * s;
        ynew = px * s + py * c;
        this.cx = xnew + x;
        this.cy = ynew + y;
        this.rot += dr;
    }


    let dragging = false;
    let lastx, lasty;
    let dragindex = 0;
    let clicktime = 0;

    // define shapes here
    let points1 = [[20, 238], [20, 29], [0, 8], [0, 0], [66, 0], [66, 8], [45, 29], [45, 238], [20, 238]];
    let points2 = [[46, 29], [21, 29], [0, 8], [0, 0], [67, 0], [67, 8], [46, 29]];
    let points3 = [[0, 146], [0, 0], [25, 0], [25, 146]];
    let points4 = [[0, 196], [0, 0], [25, 0], [25, 196]];
    // vector6
    let points5 = [[0, 25], [0, 0], [65, 0], [86, 21], [86, 87], [65, 108], [0, 108], [0, 83], [56, 83], [63, 76], [63, 32], [56, 25], [0, 25]];
    // vector7, modified to insert point
    let points6 = [[0, 25], [0, 0], [69, 0], [90, 21], [90, 90], [65, 90], [65, 32], [58, 25], [0, 25]];
    // vector8
    let points7 = [[39, 37], [27, 37], [0, 10], [0, 0], [39, 0], [39, 37]];
    // vector10
    let points8 = [[0, 0], [84, 0], [84, 25], [0, 25], [0, 0]];
    // vector11
    let points9 = [[0, 20], [72, 20], [93, 0], [101, 0], [101, 66], [93, 66], [72, 45], [0, 45], [0, 20]];
    // union
    let points10 = [[0, 0], [0, 25], [37, 25], [44, 32], [44, 80], [67, 80], [67, 32], [77, 25], [114, 25], [114, 0], [0, 0]];

    // hit area points
    let hpoints2 = [[0 - 5, 0 - 5], [67 + 5, 0 - 5], [67 + 5, 29 + 5], [0 - 5, 29 + 5]];
    let hpoints3 = [[0 - 5, 0 - 5], [25 + 5, 0 - 5], [25 + 5, 146 + 5], [0 - 5, 146 + 5]];
    let hpoints4 = [[0 - 5, 0 - 5], [25 + 5, 0 - 5], [25 + 5, 196 + 5], [0 - 5, 196 + 5]];
    let hpoints7 = [[0 - 5, 0 - 5], [39 + 5, 0 - 5], [39 + 5, 37 + 5], [0 - 5, 37 + 5]];
    let hpoints8 = [[0 - 5, 0 - 5], [84 + 5, 0 - 5], [84 + 5, 25 + 5], [0 - 5, 25 + 5]];

    let startstate = {};
    let statestack = [];

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            dragging = false;
            return;
        }
        let foundindex = -1;
        for (let i = 0; i < shapes.length; i++) {
            if (shapes[i].hitTest(pX, pY)) {
                foundindex = i;
            }
        }
        if (foundindex > -1) {
            dragging = true;

            lastx = pX;
            lasty = pY;

            // move selected shape to the front; last index of shapes array
            let sshape = shapes.splice(foundindex, 1);
            shapes.push(sshape[0]);
            dragindex = shapes.length - 1;

            clicktime = p5.millis();
        }
    }

    function keepDrawing(p5, pX, pY) {
        if (dragging) {
            let dx = pX - lastx;
            let dy = pY - lasty;

            shapes[dragindex].translate(dx, dy);

            lastx = pX;
            lasty = pY;
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (dragging) {
            dragging = false;
            if (p5.millis() - clicktime < 300) { // a click instead of a drag
                shapes[dragindex].targetrot = shapes[dragindex].rot + Math.PI / 4;
                shapes[dragindex].rotating = true;

            }
            logState();
        }
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        let tempScaleFactor = props.displaySize / 500.;
        setScaleFactor(tempScaleFactor);

        shapes = [];

        //add shapes here, based on defined verex arrays above
        shapes.push(new Dshape(points1, 0, 0, 0));
        shapes[0].translate(31, 131);

        shapes.push(new Dshape(points2, 0, 0, 0));
        shapes[1].setHitPoints(hpoints2);
        shapes[1].translate(302, 245);

        shapes.push(new Dshape(points3, 0, 0, 0));
        shapes[2].setHitPoints(hpoints3);
        shapes[2].translate(113, 320);

        shapes.push(new Dshape(points4, 0, 0, 0));
        shapes[3].setHitPoints(hpoints4);
        shapes[3].translate(425, 96);

        shapes.push(new Dshape(points5, 0, 0, 0));
        shapes[4].translate(215 - 94, 86);
        shapes.push(new Dshape(points6, 0, 0, 0));
        shapes[5].translate(260 - 90, 250);

        shapes.push(new Dshape(points7, 0, 0, 0));
        shapes[6].setHitPoints(hpoints7);
        shapes[6].translate(282, 41);

        shapes.push(new Dshape(points8, 0, 0, 0));
        shapes[7].setHitPoints(hpoints8);
        shapes[7].translate(302 - 84, 409);

        shapes.push(new Dshape(points9, 0, 0, 0));
        shapes[8].translate(369 - 101, 138);
        shapes.push(new Dshape(points10, 0, 0, 0));
        shapes[9].translate(336, 342);

        for (let i = 0; i < shapes.length; i++) {
            shapes[i].scale(tempScaleFactor);
        }

        startstate = deepCopy(shapes);
        logState();
    };

    function deepCopy(obj) {
        return (JSON.parse(JSON.stringify(obj)));
    }

    function logState() {
        let temp = deepCopy(shapes);//deep copy
        statestack.push(temp);
    }

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.noStroke();

        p5.strokeWeight(3 * scaleFactor);
        for (let i = 0; i < shapes.length; i++) {
            if (dragging && dragindex === i) p5.stroke(shapes[i].r, shapes[i].g, shapes[i].b);
            else p5.noStroke();
            shapes[i].draw(p5);
        }
    };

    const clearDrawingHandler = () => {
        statestack.splice(1);//remove all elements after index 0
        let r = startstate;
        shapes = [];
        for (let i = 0; i < r.length; i++) {
            shapes.push(new Dshape(deepCopy(r[i].points), r[i].r, r[i].g, r[i].b));
        }
        return true;
    }

    const undoHandler = () => {
        if (statestack.length > 1) {
            shapes = [];
            statestack.splice(statestack.length - 1);
            let r = statestack[statestack.length - 1];
            for (let i = 0; i < r.length; i++) {
                shapes.push(new Dshape(deepCopy(r[i].points), r[i].r, r[i].g, r[i].b));
            }
        }
    }

    function scaleValues(dshape) {
        for (let i = 0; i < dshape.points.length; i++) {
            dshape.points[i][0] /= scaleFactor;
            dshape.points[i][1] /= scaleFactor;
        }
        for (let i = 0; i < dshape.hpoints.length; i++) {
            dshape.hpoints[i][0] /= scaleFactor;
            dshape.hpoints[i][1] /= scaleFactor;
        }
        dshape.cx /= scaleFactor;
        dshape.cy /= scaleFactor;
        return dshape;
    }

    const submitButtonHandler = () => {
        let tempshapes = deepCopy(shapes);
        props.parentCallback(tempshapes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawLetterShapes;
