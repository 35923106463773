import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import './P5Display.css';
import watermark from '../../assets/ydays-watermark-bw.png';
import { drawWatermark } from './p5helper';

function P5DisplayRepeater(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokes, setStrokes] = useState([]);
    const [mode, setMode] = useState("NORMAL"); // or: "WORMS" for outlined strokes
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    const GIF_CAPTURE_FPS = 10;
    const GIF_CAPTURE_FRAME_DURATION = 1 / GIF_CAPTURE_FPS;
    let hasFinished = false;
    let numFramesCaptured = 0;

    let Point = function (x, y, d, t) {
        this.x = x;
        this.y = y;
        this.d = d;
        this.t = t;
    }

    let curStroke = [];

    function addPoint(p5, pX, pY, d, t) {
        curStroke.push(new Point(pX, pY, d, t));
    }

    function current(p5) {
        // If capturing, advance the time by the same increment with each frame captured
        if (props.addGifFrame && props.stopCapture) {
            return numFramesCaptured * GIF_CAPTURE_FRAME_DURATION;
        }
        return p5.millis() * .001;
    }

    function cycleBy(time, interval) {
        return (time - interval * Math.floor(time / interval))
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.mode) {
                setMode(parameters.mode);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);

        const tempStrokes = [];
        for (let i = 0; i < props.data.length; i++) {
            if (props.data[i] != null) {
                let stroke = props.data[i];
                for (let k = 0; k < stroke.length; k++) {
                    if (stroke[k] != null) {
                        let pt = stroke[k];
                        addPoint(p5, pt.x, pt.y, pt.d, pt.t);
                    }
                }
                tempStrokes.push(curStroke);
                curStroke = [];
            }
        }
        setStrokes(tempStrokes);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor, scaleFactor);

        let t1, ti;
        let curTime = current(p5);

        const interval = 5;
        curTime = cycleBy(curTime, interval); // repeats every 5 sec

        if (mode === "WORMS") {
            for (let i = 0; i < strokes.length; i++) {
                if (strokes[i][0] != null) {
                    p5.beginShape(p5.LINES);
                    p5.stroke(0);
                    for (let j = 0; j < strokes[i].length - 1; j++) {
                        t1 = strokes[i][j].t;
                        ti = cycleBy(t1, interval);
                        if ((ti < curTime && ti > curTime - interval * .5) ||
                            (ti < curTime + interval && ti > curTime + interval - interval * .5)) {
                            p5.strokeWeight(strokes[i][j].d * scaleFactor);
                            p5.vertex(strokes[i][j].x, strokes[i][j].y);
                            p5.vertex(strokes[i][j + 1].x, strokes[i][j + 1].y);
                        }
                    }
                    p5.endShape();
                }
            }
            for (let i = 0; i < strokes.length; i++) {
                if (strokes[i][0] != null) {
                    p5.beginShape(p5.LINES);
                    p5.stroke(255);
                    for (let j = 0; j < strokes[i].length - 1; j++) {
                        t1 = strokes[i][j].t;
                        ti = cycleBy(t1, interval);
                        if ((ti < curTime && ti > curTime - interval * .5) ||
                            (ti < curTime + interval && ti > curTime + interval - interval * .5)) {
                            p5.strokeWeight(strokes[i][j].d * 0.63 * scaleFactor);
                            p5.vertex(strokes[i][j].x, strokes[i][j].y);
                            p5.vertex(strokes[i][j + 1].x, strokes[i][j + 1].y);
                        }
                    }
                    p5.endShape();
                }
            }
        } else {
            // NORMAL mode
            for (let i = 0; i < strokes.length; i++) {
                if (strokes[i][0] != null) {
                    p5.beginShape(p5.LINES);
                    for (let j = 0; j < strokes[i].length - 1; j++) {
                        t1 = strokes[i][j].t;
                        ti = cycleBy(t1, interval);

                        // this accounts for strokes that cross the 5 sec boundary once
                        // assumes no stroke takes more than 5 to 10 secs
                        if ((ti < curTime && ti > curTime - interval * .5) ||
                            (ti < curTime + interval && ti > curTime + interval - interval * .5)) {
                            p5.strokeWeight(strokes[i][j].d * scaleFactor);
                            p5.vertex(strokes[i][j].x, strokes[i][j].y);
                            p5.vertex(strokes[i][j + 1].x, strokes[i][j + 1].y);
                        }
                    }
                    p5.endShape();
                }
            }
        }

        // Add watermark before capturing this frame
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }

        if (props.addGifFrame && props.stopCapture) {  // When this component is created for capturing
            if (hasFinished) return;

            if (numFramesCaptured >= GIF_CAPTURE_FPS * props.durationInSeconds) {
                props.stopCapture();
                hasFinished = true;
            } else {
                props.addGifFrame(GIF_CAPTURE_FRAME_DURATION * 1000);
                numFramesCaptured++;
            }
        }
    };



    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayRepeater;
