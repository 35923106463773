import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import * as authService from './auth';
import * as trackingService from './tracking';

import { firebaseConfig } from '../SECRET.js';
import { validateUsername, IS_PROD, debugLogger } from './shared';

export function initFirebase() {
    firebase.initializeApp(firebaseConfig);
}

/*
 * Database design:
 * (Enrollment schema design based on https://firebase.google.com/docs/database/web/structure-data#fanout)
 *
 * users/
 *   [user uid]/
 *     name: string
 *     email: string
 *     role: 'user'|'creator'|'admin'
 *     allowReminderEmail: boolean
 *     avatarPath: string
 *     avatar : {
 *        strokes: JSON.stringify([1,0,1...]...)
 *        fgColor: "#FFFFFF"
 *        bgColor: "#191919
 *     challengeIds: string[]  (chanllenge uid)
 *     promptIds: string[]  (chanllenge uid)
 *     workIds: string[] (work uid)
 *     reactedWorks
 *        workId: [emoji]
 *     createdTimestamp: number
 *     timezoneOffset: the timezone offset from the user's last logged in location
 *     username: string;  // ID for the public profile
 *     about: string;
 *     instagram: string;
 *     twitter: string;
 * usernames/
 *    [username]: [user uid]
 * challenges/
 *   [uid]/
 *     title: string
 *     description: string
 *     author: string (user uid)
 *     lengthInDays: number  // TODO: remove this. It should just be the length of `prompts`
 *     startTimestamp: number
 *     dayEndTimeInTimestamp?: number
 *     members: string[] (user uid)
 *     type: 'community'/'daily'/'normal' (undefined will be default to 'normal')
 *     prompts:  // This is a bad naming. A better name would be "days"
 *       promptId: string [prompt uid]
 *       promptParameters: json string
 *       submissions/
 *         [user uid]: string[]  (work uid)
 *       leaderboard/
 *         [work uid]: number (votes)
 * prompts/
 *   [prompt uid]/
 *     title: string
 *     description: string
 *     author: string (user uid)
 *     type: string
 *     hide: boolean
 *     extraData: [any, p5 sketch, link, or whatever needed to display with the prompt]
 *        p5name: string
 *        durationInSeconds: number
 *        parameters: json string
 * works/
 *   [work uid]/
 *     data:
 *        strokesUrl: string
 *        imageRef: string|SPECIAL_HEADLESS_CAPTURE_REF_VALUES  // Image captured by cloud function. It's a storage ref, not a downloadable url
 *        watermarkImageRef: string|SPECIAL_HEADLESS_CAPTURE_REF_VALUES  // Image captured by cloud function. It's a storage ref, not a downloadable url
 *        url: string  // Old captured image url
 *     author: string (user uid)
 *     timestamp: number
 *     promptId: string
 *     challengeId: string
 *     hideToPublic: boolean
 *     reactions/
 *       [emoji]: [user uid list]
 * daily-challenges: string[]  // list of challenge ids
 */

const CHALLENGES_ROOT_NAME = 'challenges';
const USERS_ROOT_NAME = 'users';
const PROMPTS_ROOT_NAME = 'prompts';
const WORKS_ROOT_NAME = 'works';
const DAILY_ROOT_NAME = 'daily-challenge';
const USERNAME_ROOT_NAME = 'usernames';

const CHALLENGES_CACHE = new Map();
const USERS_CACHE = new Map();
const PROMPTS_CACHE = new Map();
const WORKS_CACHE = new Map();


/*********************
 * Challenges
 **********************/

export async function addChallenge(challenge) {
    const userId = authService.getCurrentUserDirect()?.uid;
    if (!userId) {
        throw new Error('Cannot create challenge - User not logged in.')
    }
    challenge.author = userId;
    challenge.members = [userId];
    if (!challenge || !challenge.author || !challenge.members || !challenge.prompts || !challenge.startTimestamp) {
        throw new Error('Cannot create challenge - challenge is invalid.')
    }
    const ref = await firebase.database().ref(CHALLENGES_ROOT_NAME).push(challenge);
    const challengeId = ref.getKey();
    await addToList(`${USERS_ROOT_NAME}/${userId}/challengeIds`, challengeId);
    CHALLENGES_CACHE.set(challengeId, challenge);
    USERS_CACHE.delete(userId);  // user profile is updated. Remove the old cache
    return challengeId;
}

export async function loadChallenge(challengeId) {
    if (!challengeId) {
        throw new Error('Cannot load challenge - challengeId is undefined.')
    }
    if (CHALLENGES_CACHE.has(challengeId)) {
        return CHALLENGES_CACHE.get(challengeId);
    }
    const challenge = await firebase.database().ref(`${CHALLENGES_ROOT_NAME}/${challengeId}`).once('value').then((snapshot) => snapshot.val());
    CHALLENGES_CACHE.set(challengeId, challenge);
    return challenge;
}

export async function loadAllChallenges() {
    const challenges = await firebase.database().ref(`${CHALLENGES_ROOT_NAME}`).once('value').then((snapshot) => snapshot.val());
    Array.from(Object.entries(challenges)).forEach(([challengeId, challenge]) => {
        CHALLENGES_CACHE.set(challengeId, challenge);
    });
    return challenges;
}

export async function updateChallenge(challengeId, challenge) {
    if (!challengeId) {
        throw new Error('Cannot update challenge - challengeId is undefined.')
    }
    if (!challenge) {
        throw new Error('Cannot update challenge - challenge is undefined.')
    }
    CHALLENGES_CACHE.delete(challengeId);  // Remove the cached version. DO NOT update cache here, it can be a partial object
    return firebase.database().ref(`${CHALLENGES_ROOT_NAME}/${challengeId}`).update(challenge);
}

export async function deleteChallenge(challengeId) {
    if (!challengeId) {
        throw new Error('No challenge id provided when deleting a challenge!');
    }

    // Get the challenge, find all members, then delete it.
    const challenge = await loadChallenge(challengeId);
    if (!challenge) {
        throw new Error(`Challenge ${challengeId} not found!`);
    }

    return Promise.all([
        firebase.database().ref(`${CHALLENGES_ROOT_NAME}/${challengeId}`).remove().then(() => {CHALLENGES_CACHE.delete(challengeId);}),
        ...challenge.members.map((uid) => {
            USERS_CACHE.delete(uid);  // Remove the old cache
            return removeFromList(`${USERS_ROOT_NAME}/${uid}/challengeIds/`, challengeId);
        }),
    ]);
}

export async function joinChallenge(challengeId) {
    const userId = authService.getCurrentUserDirect()?.uid;
    if (!userId) {
        throw new Error('Cannot join challenge - User not logged in.')
    }
    if (!challengeId) {
        throw new Error('Cannot join challenge - challengeId is undefined.')
    }
    USERS_CACHE.delete(userId);  // Remove the old cache
    CHALLENGES_CACHE.delete(challengeId);  // Remove the old cache
    return Promise.all([
        addToList(`${CHALLENGES_ROOT_NAME}/${challengeId}/members`, userId),
        addToList(`${USERS_ROOT_NAME}/${userId}/challengeIds`, challengeId)
    ]);
}


export async function updateLeaderboard(challengeId, dayIndex, workId, isAddReaction) {
    if (!challengeId) {
        throw new Error('Cannot update leaderboard - challengeId is undefined.')
    }
    if (isNaN(dayIndex)) {
        throw new Error('Cannot update leaderboard - dayIndex is not a number.')
    }
    if (!workId) {
        throw new Error('Cannot update leaderboard - workId is undefined.')
    }
    CHALLENGES_CACHE.delete(challengeId);  // Remove the old cache
    return firebase.database().ref(`${CHALLENGES_ROOT_NAME}/${challengeId}/prompts/${dayIndex}/leaderboard/${workId}`).transaction((count) => {
        count += (isAddReaction ? 1 : -1);
        return count;
    });
}


/*********************
 * Prompts
 **********************/

export async function addPrompt(prompt) {
    const userId = authService.getCurrentUserDirect()?.uid;
    if (!userId) {
        throw new Error('Cannot create prompt - User not logged in.')
    }
    if (!userId) {
        throw new Error('Cannot create prompt - User not logged in.')
    }
    if (!prompt || !prompt.extraData || !prompt.extraData.p5name) {
        throw new Error('Cannot create prompt - prompt is invalid.')
    }
    prompt.author = userId;

    const ref = await firebase.database().ref(PROMPTS_ROOT_NAME).push(prompt);
    const promptId = ref.getKey();
    await addToList(`${USERS_ROOT_NAME}/${userId}/promptIds`, promptId);
    PROMPTS_CACHE.set(promptId, prompt);
    USERS_CACHE.delete(userId);  // user profile is updated. Remove the old cache
    return promptId;
}

export async function loadPrompt(promptId) {
    if (!promptId) {
        throw new Error('Cannot load prompt - promptId is undefined.')
    }
    if (PROMPTS_CACHE.has(promptId)) {
        return PROMPTS_CACHE.get(promptId);
    }
    const prompt = await firebase.database().ref(`${PROMPTS_ROOT_NAME}/${promptId}`).once('value').then((snapshot) => snapshot.val());
    PROMPTS_CACHE.set(promptId, prompt);
    return prompt;
}

export async function loadAllPrompts() {
    const prompts = await firebase.database().ref(`${PROMPTS_ROOT_NAME}`).once('value').then((snapshot) => snapshot.val());
    Array.from(Object.entries(prompts)).forEach(([promptId, prompt]) => {
        PROMPTS_CACHE.set(promptId, prompt);
    });
    return prompts;
}

export async function updatePrompt(promptId, prompt) {
    if (!promptId) {
        throw new Error('Cannot update prompt - promptId is undefined.')
    }
    if (!prompt) {
        throw new Error('Cannot update prompt - prompt is undefined.')
    }
    PROMPTS_CACHE.delete(promptId);  // Remove the cached version. DO NOT update cache here, it can be a partial object
    return firebase.database().ref(`${PROMPTS_ROOT_NAME}/${promptId}`).update(prompt);
}

export async function deletePrompt(promptId) {
    if (!promptId) {
        throw new Error('No prompt id provided when deleting a prompt!');
    }
    // TODO: How to handle challenges with this prompt?
    // TODO: How to handle work already submitted for this prompt?
    PROMPTS_CACHE.delete(promptId);
    return firebase.database().ref(`${PROMPTS_ROOT_NAME}/${promptId}`).remove();
}


/*********************
 * Profile
 **********************/

export async function loadProfile(userId) {
    if (!userId) {
        throw new Error('Cannot get profile for empty user id');;
    }

    if (USERS_CACHE.has(userId)) {
        return USERS_CACHE.get(userId);
    }

    const profile = await firebase.database().ref(`${USERS_ROOT_NAME}/${userId}`).once('value').then((snapshot) => snapshot.val());
    if (!profile?.email) {
        return handleNoProfile(userId, profile);
    }
    USERS_CACHE.set(userId, profile);
    return profile;
}

export async function loadCurrentUserProfile() {
    const userId = authService.getCurrentUserDirect()?.uid;
    if (!userId) {
        throw new Error('Cannot get profile for a non logged in user');
    }
    return loadProfile(userId);
}

export async function loadAllProfiles() {
    const users = await firebase.database().ref(`${USERS_ROOT_NAME}`).once('value').then((snapshot) => snapshot.val());
    Array.from(Object.entries(users)).forEach(([userId, user]) => {
        USERS_CACHE.set(userId, user);
    });
    return users;
}

export async function updateProfile(profile) {
    const userId = authService.getCurrentUserDirect()?.uid;
    if (!userId) {
        throw new Error('Cannot update profile - User not logged in.')
    }
    if (!profile) {
        throw new Error('Cannot update profile - Empty profile.')
    }
    USERS_CACHE.delete(userId);
    return firebase.database().ref(`${USERS_ROOT_NAME}/${userId}`).update(profile);
}

export async function readUsername(username) {
    if (!username) {
        throw new Error('No username provided when reading!');
    }
    return firebase.database().ref(`${USERNAME_ROOT_NAME}/${username}`).once('value').then(snapshot => snapshot.val());
}

export async function setUsername(username, oldUsername) {
    if (!username) {
        throw new Error('No username provided when setting it!');
    }

    // `oldUsername` can be undefined, for first-time setting

    if (!validateUsername(username)) {
        throw new Error('Invalid username');
    }

    const userId = authService.getCurrentUserDirect()?.uid;
    if (!userId) {
        throw new Error('Cannot set username - Not logged in');
    }

    await Promise.all([
        firebase.database().ref(`${USERNAME_ROOT_NAME}/${username}`).transaction((data) => {
            if (data) {
                throw new Error('username is taken');
            }
            return userId;
        }),
        oldUsername && firebase.database().ref(`${USERNAME_ROOT_NAME}/${oldUsername}`).remove(),
        updateProfile({ username }),
    ]);
}


/*********************
 * Works
 **********************/

export async function loadWork(workId) {
    if (!workId) {
        throw new Error('Cannot load work - workId is undefined.')
    }
    if (WORKS_CACHE.has(workId)) {
        return WORKS_CACHE.get(workId);
    }
    const work = await firebase.database().ref(`${WORKS_ROOT_NAME}/${workId}`).once('value').then((snapshot) => snapshot.val());
    WORKS_CACHE.set(workId, work);
    return work;
}

let _debugStartTime;
export async function createWorkEntry(challengeId, promptId) {
    if (!IS_PROD) {
        _debugStartTime = Date.now();
        debugLogger('[Submit work] Start', _debugStartTime);
    }
    if (!challengeId) {
        throw new Error('Cannot create work - challengeId is undefined.')
    }
    if (!promptId) {
        throw new Error('Cannot create work - promptId is undefined.')
    }
    const userId = authService.getCurrentUserDirect()?.uid;
    if (!userId) {
        throw new Error('Cannot create work - User not logged in.')
    }
    const timestamp = Date.now();
    const ref = await firebase.database().ref(WORKS_ROOT_NAME).push({
        data: {/* `strokesUrl` and `url` will be filled here right after this call */},
        author: userId,
        timestamp,
        challengeId,
        promptId,
        reactions: {}
    });
    const workId = ref.getKey();
    debugLogger('[Submit work] Created entry', `${Date.now() - _debugStartTime}ms`);
    return workId;
}

export async function saveStrokes(workId, strokes) {
    if (!workId) {
        throw new Error('Cannot save strokes - workId is undefined.')
    }

    debugLogger('[Submit work] Start saving strokes', `${Date.now() - _debugStartTime}ms`);
    const url = await saveBlob(new Blob([strokes], { type: 'application/json' }), `strokes/${workId}_${Date.now()}.json`, 'application/json');
    WORKS_CACHE.delete(workId);  // Remove the old cache
    debugLogger('[Submit work] Saved strokes', `${Date.now() - _debugStartTime}ms`);
    await firebase.database().ref(`${WORKS_ROOT_NAME}/${workId}/data/strokesUrl`).set(url);
}

export async function recordWorkSubmission(workId, challengeId, promptId, dayIndex) {
    debugLogger('[Submit work] Saving records', `${Date.now() - _debugStartTime}ms`);
    if (!workId) {
        throw new Error('Cannot record work submission - workId is undefined.')
    }
    const userId = authService.getCurrentUserDirect()?.uid;
    if (!userId) {
        throw new Error('Cannot record work submission - User not logged in.')
    }
    if (!challengeId) {
        throw new Error('Cannot record work submission - challengeId is undefined.')
    }
    if (!promptId) {
        throw new Error('Cannot record work submission - promptId is undefined.')
    }
    if (isNaN(dayIndex)) {
        throw new Error('Cannot record work submission - dayIndex is not a number.')
    }
    await Promise.all([
        addToList(`${CHALLENGES_ROOT_NAME}/${challengeId}/prompts/${dayIndex}/submissions/${userId}`, workId),
        addToList(`${CHALLENGES_ROOT_NAME}/${challengeId}/members`, userId),
        addToList(`${USERS_ROOT_NAME}/${userId}/workIds`, workId),
        addToList(`${USERS_ROOT_NAME}/${userId}/promptIds`, promptId),
        addToList(`${USERS_ROOT_NAME}/${userId}/challengeIds`, challengeId),
    ]).then(() => {
        debugLogger('[Submit work] Saved records', `${Date.now() - _debugStartTime}ms`);
        USERS_CACHE.delete(userId);  // Remove the old cache
        CHALLENGES_CACHE.delete(challengeId);  // Remove the old cache
    });
}

export async function checkImageRef(workId, isWatermark, callback) {
    if (!workId) {
        throw new Error('Cannot check image ref - workId is undefined.')
    }
    return firebase.database().ref(`${WORKS_ROOT_NAME}/${workId}/data/${isWatermark ? 'watermarkImageRef' : 'imageRef'}`).on('value', (snapshot) => {
        callback(snapshot.val());
    });
}

export async function addWorkUrl(workId, url) {
    if (!workId) {
        throw new Error('Cannot add work image url - workId is undefined.')
    }
    if (!url) {
        throw new Error('Cannot add work image url - url is undefined.')
    }
    debugLogger('[Submit work] Adding captured image URL', `${Date.now() - _debugStartTime}ms`);
    WORKS_CACHE.delete(workId);  // Remove the old cache
    return firebase.database().ref(`${WORKS_ROOT_NAME}/${workId}/data`).update({ url });
}

export async function addReaction(workId, emoji) {
    const userId = authService.getCurrentUserDirect()?.uid;
    if (!userId) {
        throw new Error('Cannot add reaction - User not logged in.')
    }
    if (!workId) {
        throw new Error('Cannot add reaction - Work id is undefined.')
    }
    if (!emoji) {
        throw new Error('Cannot add reaction - emoji is undefined.')
    }

    WORKS_CACHE.delete(workId);  // Remove the old cache
    USERS_CACHE.delete(userId);  // Remove the old cache
    return Promise.all([
        addToList(`${WORKS_ROOT_NAME}/${workId}/reactions/${emoji}`, userId),
        addToList(`${USERS_ROOT_NAME}/${userId}/reactedWorks/${workId}`, emoji),
    ]);
}

export async function removeReaction(workId, emoji) {
    const userId = authService.getCurrentUserDirect()?.uid;
    if (!userId) {
        throw new Error('Cannot remove reaction - User not logged in.')
    }
    if (!workId) {
        throw new Error('Cannot remove reaction - Work id is undefined.')
    }
    if (!emoji) {
        throw new Error('Cannot remove reaction - emoji is undefined.')
    }

    WORKS_CACHE.delete(workId);  // Remove the old cache
    USERS_CACHE.delete(userId);  // Remove the old cache
    return Promise.all([
        removeFromList(`${WORKS_ROOT_NAME}/${workId}/reactions/${emoji}`, userId),
        removeFromList(`${USERS_ROOT_NAME}/${userId}/reactedWorks/${workId}`, emoji),
    ]);
}

export async function toggleWorkHideShow(workId) {
    if (!workId) {
        throw new Error('No workId provided when toggling work!');
    }

    WORKS_CACHE.delete(workId);  // Remove the old cache

    let latestValue;
    return firebase.database().ref(`${WORKS_ROOT_NAME}/${workId}`).transaction((work) => {
        if (work) {
            work.hideToPublic = !work.hideToPublic;
        }
        return work;
    }, (error, committed, snapshot) => {
        if (!error && committed) {
            latestValue = snapshot.val().hideToPublic;
            return;
        }

        console.error(committed, error);
        throw new Error('Failed to toggle work');
    }).then(() => latestValue);
}


/*********************
 * Daily challenges
 **********************/

export async function readDailyChallenge() {
    return firebase.database().ref(DAILY_ROOT_NAME).once('value').then(snapshot => snapshot.val());
}

export async function updateDailyChallenge(content) {
    if (!content || !content.length) {
        throw new Error('Cannot update daily challenge list - Invalid data');
    }
    return firebase.database().ref(DAILY_ROOT_NAME).set(content);
}


/*********************
 * Helpers
 **********************/

/* A bug may cause a user signed up, but no default profile is saved
* https://github.com/firebase/firebase-js-sdk/issues/1926
* This part is a fallback for the error - if no profile is found, save a default profile for the user */
async function handleNoProfile(userId, corruptedProfile) {
    const user = authService.getCurrentUserDirect();
    if (user?.uid === userId) {  // It is the current user's profile. We can backfill
        const retryProfile = await new Promise((resolve) => {
            setTimeout(() => {
                // Don't use `loadProfile` here, could cause infinite fetching
                firebase.database().ref(`${USERS_ROOT_NAME}/${userId}`).once('value').then((snapshot) => snapshot.val()).then(resolve);
            }, 200);
        });
        if (retryProfile?.email) {
            return retryProfile;  // Found the profile in retry, return that.
        } else {
            trackingService.logSentry(`Filling default profile`, true);
            // Important: The order of fields below is the overwriting order
            const defaultProfile = {
                ...authService.DEFAULT_PROFILE,
                email: user.email,
                name: user.displayName || user.email.replace(/@.*/, ''),
                createdTimestamp: new Date(user?.metadata?.creationTime || Date.now()).getTime(),
                ...(corruptedProfile || {}),
            }
            return updateProfile(defaultProfile).then(() => defaultProfile);
        }
    } else {  // Not current user. Nothing can be done. (This case hasn't happenned for a long time)
        if (!corruptedProfile) {
            trackingService.logSentry(new Error(`No profile found`));
        } else {
            trackingService.logSentry(new Error(`No basic data found in the profile`));
        }
        return null;
    }
}

export async function saveBlob(blob, filename, contentType) {
    if (!blob) {
        throw new Error('Cannot save blob - blob is undefined.')
    }
    if (!filename) {
        throw new Error('Cannot save blob - filename is undefined.')
    }
    return firebase.storage().ref().child(filename).put(blob, { contentType })
        .then((snapshot) => {
            return snapshot.ref.getDownloadURL();
        }).catch((error) => {
            console.error("Upload failed:", error);
            throw new Error(`Failed to upload ${filename}`);
        });
}

export async function getDownloadUrl(ref) {
    try {
        return firebase.storage().ref(ref).getDownloadURL();
    } catch (error) {
        throw error;
    }
}

function addToList(path, data) {
    return firebase.database().ref(path).transaction((list) => {
        if (!list) {
            list = [];
        }
        if (!Array.isArray(list)) {
            console.error('Transaction addToList found a non-array', path, list)
            throw new Error(`Transaction addToList found a non-array in: ${path}`);
        }

        if (!list.includes(data)) {
            list.push(data);
        }

        return list;
    });
}

function removeFromList(path, data) {
    return firebase.database().ref(path).transaction((list) => {
        if (!list) {
            list = [];
        }
        if (!Array.isArray(list)) {
            console.error('Transaction removeFromList found a non-array', path, list)
            throw new Error(`Transaction removeFromList found a non-array in: ${path}`);
        }

        if (list.includes(data)) {
            list = list.filter(item => item !== data);
        }

        return list;
    });
}
