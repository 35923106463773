/* https://reactjs.org/docs/error-boundaries.html */
import React from 'react';
import { Link } from 'react-router-dom';
import * as trackingService from '../services/tracking';
import classes from './App.module.css';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
        trackingService.logSentry(error);
    }

    render() {
        if (this.state.hasError) {
            return <div className={classes.errorContainer}>
                <p><b>{this.props.errorMessage || 'Something went wrong.'}</b></p>
                <p>Try refreshing the page, or return <Link to="/">Home</Link>.</p>
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
