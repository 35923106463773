import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classes from './Settings.module.css';
import * as firebaseService from '../../services/firebase.js';
import * as authService from '../../services/auth.js';
import * as trackingService from '../../services/tracking.js';
import { validateUsername } from '../../services/shared';
import { TextField, CheckboxField } from '../UI/SettingFields';

function Settings() {
    const history = useHistory();

    // Field values
    const [name, setName] = useState('');  // Display name
    const [about, setAbout] = useState('');
    const [username, setUsername] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [avatarPath, setAvatarPath] = useState(false);
    const [email, setEmail] = useState('');
    const [allowReminderEmail, setAllowReminderEmail] = useState(false);
    const [isEmailPasswordUser, setIsEmailPasswordUser] = useState(false);

    // Extra states for username
    const [usernameHint, setUsernameHint] = useState('');
    const [previousProfile, setPreviousProfile] = useState('');  // Used to free up it when updated
    const [isUsernameValid, setIsUsernameValid] = useState(false);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);

    // Init - read values
    useEffect(() => {
        firebaseService.loadCurrentUserProfile().then((profile) => {
            setName(profile.name);
            setAvatarPath(profile.avatarPath);
            setUsername(profile.username);
            setInstagram(profile.instagram);
            setAbout(profile.about);
            setTwitter(profile.twitter);
            setEmail(profile.email);
            setAllowReminderEmail(!!profile.allowReminderEmail);
            setPreviousProfile(profile);
        });
        setIsEmailPasswordUser(authService.getCurrentUserDirect()?.providerData?.find((provider) => provider.providerId === 'password'));
    }, []);

    async function saveToProfile(object) {
        Array.from(Object.keys(object)).forEach((field) => {
            trackingService.logSimpleEvent('Settings', field);
        });
        await firebaseService.updateProfile(object);
        setPreviousProfile(await firebaseService.loadCurrentUserProfile());
    }

    async function saveUsername() {
        const savingUsername = username;
        trackingService.logSimpleEvent('Settings', 'Set username');
        await firebaseService.setUsername(savingUsername, previousProfile.username);
        setPreviousProfile(await firebaseService.loadCurrentUserProfile());
    }

    function usernameKeyEvent() {
        setUsername(username.replace(/[^a-z0-9-_]/g, ''));

        if (!username) return;

        if (username === previousProfile.username) {
            // No change - disabled button (in html), and show no message
            setUsernameHint('');
            return;
        }

        if (!validateUsername(username)) {
            setUsernameHint(`Not valid. (a-z, 0-9, _, -. Length 4 ~ 12)`);
            setIsUsernameValid(false);
            return;
        } else {
            setIsUsernameValid(true);
        }

        // This is used for the message. The message need to show the checked username, not the current in state
        let cachedUsername = username;
        firebaseService.readUsername(cachedUsername).then((found) => {
            if (found) {
                setUsernameHint(`${cachedUsername} is not available.`);
                setIsUsernameAvailable(false);
            } else {
                setUsernameHint(`${cachedUsername} is available!`);
                setIsUsernameAvailable(true);
            }
        })
    }

    function logout() {
        trackingService.logSimpleEvent('User auth', 'Logout')
        authService.signout().then(() => {
            history.push('/login');
        });
    }

    return (
        <div className={classes.content}>
            <Helmet>
                <title>Settings | YDays</title>
            </Helmet>
            <Link to={{ pathname: `/profile` }} className={classes.back}>← Your profile</Link>
            <h1>Account settings</h1>
            <div className={`${classes.contentBox} ${classes.fields}`}>
                <span className={classes.formLabel}>Display name</span>
                <TextField isValid={name !== previousProfile?.name}
                    saveFn={() => saveToProfile({ name: name })}>
                    <input value={name || ''} onChange={e => setName(e.target.value)} />
                </TextField>

                <span className={classes.formLabel}>Profile page</span>
                <TextField isValid={username !== previousProfile.username && isUsernameAvailable && isUsernameValid}
                    hint={usernameHint}
                    saveFn={saveUsername}>
                    ydays.com/&nbsp;<input className={classes.username} type="text" value={username || ''} onChange={e => setUsername(e.target.value)} onKeyUp={e => usernameKeyEvent(e.target.value)} />
                </TextField>

                <span className={classes.formLabel}>About you</span>
                <TextField isValid={about !== previousProfile?.about}
                    saveFn={() => saveToProfile({ about: about })}>
                    <textarea value={about || ''} onChange={e => setAbout(e.target.value)} />
                </TextField>

                <span className={classes.formLabel}>Instagram</span>
                <TextField isValid={instagram !== previousProfile?.instagram}
                    saveFn={() => saveToProfile({ instagram })}>
                    @&nbsp;<input value={instagram || ''} onChange={e => setInstagram(e.target.value)} />
                </TextField>

                <span className={classes.formLabel}>Twitter</span>
                <TextField isValid={twitter !== previousProfile?.twitter}
                    saveFn={() => saveToProfile({ twitter })}>
                    @&nbsp;<input value={twitter || ''} onChange={e => setTwitter(e.target.value)} />
                </TextField>

                <span className={classes.formLabel}>Your image</span>
                <div className={`${classes.imageHolder} ${classes.spanColumns}`}>
                    <span>{avatarPath ? <img src={avatarPath} width={200} height={200} className={classes.avatar} alt='profile' /> : null}</span>
                    <Link to="/edit-image" className={classes.button} onClick={() => { trackingService.logSimpleEvent('Edit Avatar', 'Start'); }}>Edit image</Link>
                </div>
            </div>
            <div className={`${classes.contentBox} ${classes.fields}`}>
                <span className={classes.formLabel}>Your email</span>
                <span className={classes.spanColumns}>{email}</span>

                {isEmailPasswordUser &&
                    <>
                        <span className={classes.formLabel}></span>
                        <div className={classes.spanColumns}>
                            <Link to="/forgot" className={classes.button}>Reset password</Link>
                        </div>
                    </>
                }

                <span className={classes.formLabel}>Email settings</span>
                <CheckboxField value={allowReminderEmail} setter={setAllowReminderEmail}
                    saveFn={(value) => saveToProfile({ allowReminderEmail: value })}>
                    Send me reminders about challenges I’m part of.
                </CheckboxField>
                <button className={classes.logout} onClick={logout}>Logout</button>
            </div>
        </div >
    );
}

export default Settings;
