// The size all p5 tools are scaled to
const P5_SCALE_SIZE = 500;

// This is the size of the watermark inside 500 * 500
const WATERMARK_WIDTH = 80;
const WATERMARK_HEIGHT = 25;

/* When the p5 tool is scaled, the watermark position and size is based on 500, and doesn't need to scale.
 * When the p5 tool is not scaled, the watermark needs to be calculated */
export function drawWatermark(p5, image, scaleFactor = 1) {
    p5.image(image,
        (P5_SCALE_SIZE - WATERMARK_WIDTH) * scaleFactor,
        (P5_SCALE_SIZE - WATERMARK_HEIGHT) * scaleFactor,
        WATERMARK_WIDTH * scaleFactor,
        WATERMARK_HEIGHT * scaleFactor
    );
}
