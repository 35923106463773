import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayColoredDots(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [dots, setDots] = useState([]);
    const [mode, setMode] = useState("NORMAL"); // or: "SQUIRCLES" for squarish circles
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Dot = function(x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function getAngleInDegs(x1, y1, x2, y2) {
        return Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
    }

    function drawDot(p5, x1, y1, x2, y2) {
        // find circle with these two points as diameter
        let mx = (x1 + x2) / 2;
        let my = (y1 + y2) / 2;
        let diam = getDistance(x1, y1, x2, y2);
        let ang = getAngleInDegs(x1, y1, x2, y2);
        let h = 0, s = 0, b = 0;

        const red = [0, 73, 90];
        const yellow = [58, 88, 100];
        const blue = [234, 75, 90];
        const purple = [266, 77, 74];

        if (diam < 10) {
            h = 0;
            s = 0;
            b = 0;
        } else {
            let colorAmt = (diam - 10) / (70);
            if (colorAmt > 1) colorAmt = 1;

            let f;
            if (ang >= -90 && ang <= 0) {
                // red to yellow
                f = (ang + 90) / 90; // 0 to 1
                h = red[0] * (1 - f) + yellow[0] * f;
                s = red[1] * (1 - f) + yellow[1] * f;
                b = red[2] * (1 - f) + yellow[2] * f;
            } else if (ang >= 0 && ang <= 90) {
                // yellow to blue
                f = (ang) / 90; // 0 to 1
                h = yellow[0] * (1 - f) + blue[0] * f;
                s = yellow[1] * (1 - f) + blue[1] * f;
                b = yellow[2] * (1 - f) + blue[2] * f;
            } else if (ang >= 90 && ang <= 180) {
                // blue to purple
                f = (ang - 90) / 90; // 0 to 1
                h = blue[0] * (1 - f) + purple[0] * f;
                s = blue[1] * (1 - f) + purple[1] * f;
                b = blue[2] * (1 - f) + purple[2] * f;
            } else if (ang >= -180 && ang <= -90) {
                // purple to red
                f = (ang + 180) / 90; // 0 to 1
                h = purple[0] * (1 - f) + 360 * f;
                s = purple[1] * (1 - f) + red[1] * f;
                b = purple[2] * (1 - f) + red[2] * f;
            }
            b = colorAmt * b;
        }

        p5.fill(h, s, b);
        if (mode === "SQUIRCLES") {
            const c = 0.76; // 0.552 is approximation for a circle
            p5.push();
            p5.translate(mx, my);
            p5.scale(diam * 0.5, diam * 0.5);
            p5.beginShape();
            p5.vertex(0, -1);
            p5.bezierVertex(-1 * c, -1,  -1, -1 * c,  -1, 0);
            p5.bezierVertex(-1, 1 * c,  -1 * c, 1,  0, 1);
            p5.bezierVertex(1 * c, 1,  1, 1 * c,  1, 0);
            p5.bezierVertex(1, -1 * c,  1 * c, -1,  0, -1);
            p5.endShape();
            p5.pop();
        } else {
            p5.ellipse(mx, my, diam, diam);
        }
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.mode) {
                setMode(parameters.mode);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.colorMode(p5.HSB);
        p5.ellipseMode(p5.CENTER);

        let dotsData = props.data;
        const tempDots = [];
        for (let i = 0; i < dotsData.length; i++) {
            let dot = dotsData[i];
            tempDots.push(new Dot(dot.x1, dot.y1, dot.x2, dot.y2));
        }
        setDots(tempDots);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor, scaleFactor);
        p5.noStroke();

        for (let i = 0; i < dots.length; i++) {
            drawDot(p5, dots[i].x1, dots[i].y1, dots[i].x2, dots[i].y2);
        }
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayColoredDots;
