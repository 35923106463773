import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayTriangleWedges(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [triangles, setTriangles] = useState([]);
    const [color1, setColor1] = useState(0);
    const [color2, setColor2] = useState(0);
    const [color3, setColor3] = useState(0);
    const [color4, setColor4] = useState(0);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Triangle = function(x1, y1, x2, y2, x3, y3) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
        this.x3 = x3;
        this.y3 = y3;
    }

    function drawTriangle(p5, x1, y1, x2, y2, x3, y3) {
        p5.beginShape();
        p5.vertex(x1, y1);
        p5.vertex(x2, y2);
        p5.vertex(x3, y3);
        p5.endShape(p5.CLOSE);
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2 && parameters.color3 && parameters.color4) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
                setColor4(parameters.color4);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.strokeCap(p5.SQUARE);

        let trianglesData = props.data;
        const tempTriangles = [];
        for (let i = 0; i < trianglesData.length; i++) {
            let tri = trianglesData[i];
            tempTriangles.push(new Triangle(tri.x1, tri.y1, tri.x2, tri.y2, tri.x3, tri.y3));
        }
        setTriangles(tempTriangles);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor, scaleFactor);

        p5.background(255);
        p5.stroke(0);
        p5.fill(0);

        p5.strokeWeight(2);
        for (let i = 0; i < triangles.length; i++) {
            if (i % 4 === 0) {
                p5.fill(color1);
                p5.stroke(color1);
            } else if (i % 4 === 1) {
                p5.fill(color2);
                p5.stroke(color2);
            } else if (i % 4 === 2) {
                p5.fill(color3);
                p5.stroke(color3);
            } else if (i % 4 === 3) {
                p5.fill(color4);
                p5.stroke(color4);
            }
            drawTriangle(p5, triangles[i].x1, triangles[i].y1, triangles[i].x2, triangles[i].y2, triangles[i].x3, triangles[i].y3);
        }
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayTriangleWedges;
