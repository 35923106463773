import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import classes from './EditChallenge.module.css';
import { useParams } from 'react-router-dom';
import * as firebaseService from '../../services/firebase.js';

function CreatePrompt() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('p5');
    const [durationInSeconds, setDurationInSeconds] = useState(0);
    const [p5name, setP5name] = useState('');
    const [hasPrompt, setHasPrompt] = useState(false);
    const [message, setMessage] = useState('');
    const [parameters, setParameters] = useState('');
    const [author, setAuthor] = useState(null);
    const [isHidden, setIsHidden] = useState(false);
    const params = useParams();

    useEffect(() => {
        if (params.promptId) {
            // TODO: Only allow author to edit the prompt
            firebaseService.loadPrompt(params.promptId).then((prompt) => {
                if (!prompt) {
                    setMessage('Prompt not found!')
                    return;
                }

                setHasPrompt(true);
                setTitle(prompt.title);
                setIsHidden(!!prompt.hide);
                setDescription(prompt.description);
                setType(prompt.type);
                setDurationInSeconds(prompt.extraData.durationInSeconds || 0);
                setParameters(prompt.extraData.parameters || '');
                setP5name(prompt.extraData.p5name || '');
                firebaseService.loadProfile(prompt.author).then(setAuthor);
            });
        }
    }, [params.promptId]);

    const _validate = () => {
        if (!title) {
            setMessage('Title is required');
            return false;
        }
        if (!p5name) {
            setMessage('Name of component is required');
            return false;
        }
        if (durationInSeconds == null) {
            setMessage('Duration is required. Put 0 for static result');
            return false;
        }
        return true;
    }

    const create = () => {
        if (!_validate()) return;

        setMessage('Creating');

        firebaseService.addPrompt({
            title,
            description,
            type,
            extraData: { p5name, durationInSeconds, parameters },
            hide: isHidden,
        }).then(() => {
            setMessage('Created!');
        }).catch((error) => {
            setMessage(error.message);
        });
    }

    const update = () => {
        if (!_validate()) return;

        setMessage('Updating');

        firebaseService.updatePrompt(params.promptId, {
            title,
            description,
            type,
            extraData: { p5name, durationInSeconds, parameters },
            hide: isHidden,
        }).then(() => {
            setMessage('Updated!');
        }).catch((error) => {
            setMessage(error.message);
        });
    }

    const form = <div className={classes.fieldsContainer}>
        <label>
            <span>Title</span>
            <input value={title} required onChange={e => setTitle(e.target.value)} />
        </label>
        <label>
            <span>Hide this prompt</span>
            <input checked={isHidden} type="checkbox" onChange={e => setIsHidden(e.target.checked)} />
        </label>
        <label>
            <span>Description / Instruction</span>
            <input value={description} onChange={e => setDescription(e.target.value)} />
        </label>
        <label>
            <span>Name of component</span>
            <input value={p5name} required onChange={e => setP5name(e.target.value)} placeholder="e.g. P5Repeater" />
        </label>
        <label>
            <span>Animation duration (sec)</span>
            <input value={durationInSeconds || 0} type="number" required onChange={e => setDurationInSeconds(Number(e.target.value))} />
        </label>
        <label>
            <span>Parameters</span>
            <input value={parameters} onChange={e => setParameters(e.target.value)} placeholder="Use valid json string e.g. {&quot;color&quot;: &quot;#333&quot;}" />
        </label>
        <label>
            <span>Type</span>
            <select required value={type} onChange={e => setType(e.target.value)}>
                <option value="p5">P5 Sketch</option>
                <option disabled>(Stay tuned for more types)</option>
            </select>
        </label>
    </div>

    const CreateContent = <>
        <h1>Create a prompt</h1>
        {form}
        <button onClick={create}>Create</button>
    </>

    const EditContent = <>
        <h1>Edit a prompt</h1>
        <p>Created by {author ? author.name : 'Unknown'}</p>
        {hasPrompt ? <>
            {form}
            <button onClick={update}>Update</button>
        </> : ''}
    </>

    return (
        <div className={classes.content}>
            <Helmet>
                <title>Create or edit a prompt | YDays</title>
            </Helmet>
            {params.promptId ? EditContent : CreateContent}
            <p>{message}</p>
        </div>
    );
}

export default CreatePrompt;
