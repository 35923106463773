import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark-bw.png';
import { drawWatermark } from './p5helper';

function P5DisplayKeyFrameLoop(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [frames, setFrames] = useState([]); // 5 key frames
    const [strokeWeight, setStrokeWeight] = useState(3);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    const GIF_CAPTURE_FPS = 2.5; // We want 5 frames in 2 seconds
    const GIF_CAPTURE_FRAME_DURATION = 1 / GIF_CAPTURE_FPS;
    let hasFinished = false;
    let numFramesCaptured = 0;

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let globalt = 0; // global time

    let curFrame = 0;
    let numFrames = 5;
    let loopDuration = 2;
    let strokes = [];

    function getFrameFromTime(t) {
        let frameNum = Math.floor((t / loopDuration) * numFrames) % numFrames; // 6 frames in 2 secs
        return frameNum;
    }
    function current(p5) {
        // If capturing, advance the time by the same increment with each frame captured
        if (props.addGifFrame && props.stopCapture) {
            return numFramesCaptured * GIF_CAPTURE_FRAME_DURATION;
        }
        return p5.millis() * .001;
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempStrokeWeight = strokeWeight;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.strokeWeight) {
                tempStrokeWeight = parameters.strokeWeight;
                setStrokeWeight(tempStrokeWeight);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);

        p5.ellipseMode(p5.CORNER);
        p5.strokeWeight(tempStrokeWeight);
        p5.strokeCap(p5.ROUND);

        let framesData = props.data[0];
        const tempFrames = [];
        numFrames = framesData.length;
        let curStroke = [];
        let strokesData;

        for (let i = 0; i < numFrames; i++) {
            strokes = [];
            strokesData = framesData[i];
            for (let j = 0; j < strokesData.length; j++) {
                curStroke = [];
                for (let k = 0; k < strokesData[j].length; k++) {
                    if (strokesData[j][k].x && strokesData[j][k].y) { // Check for null values in the data – TO DO may be a bug in undo?
                        curStroke.push(new Point(strokesData[j][k].x, strokesData[j][k].y, strokesData[j][k].t));
                    }
                }
                strokes.push(curStroke);
            }
            tempFrames.push(strokes);
        }
        setFrames(tempFrames);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor, scaleFactor);
        p5.noFill();
        p5.stroke(0);

        globalt = current(p5);
        curFrame = getFrameFromTime(globalt);

        if (!frames || !frames[curFrame]) return;
        strokes = frames[curFrame] || [];
        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    p5.curveVertex(strokes[i][j].x, strokes[i][j].y);
                }
                p5.endShape();
            }
        }

        // Add watermark before capturing this frame
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }

        if (props.addGifFrame && props.stopCapture) {  // When this component is created for capturing
            if (hasFinished) return;

            if (numFramesCaptured >= GIF_CAPTURE_FPS * props.durationInSeconds) {
                props.stopCapture();
                hasFinished = true;
            } else {
                props.addGifFrame(GIF_CAPTURE_FRAME_DURATION * 1000);
                numFramesCaptured++;
            }
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayKeyFrameLoop;
