/**
 * 1. Copy this file as `SECRET.js`
 * 2. Find the content of this file from https://gitlab.com/pchocho/ydays-app/-/settings/ci_cd under "Variables" - "SECRET".
 * 3. Copy the content and save `SECRET.js`
 */
export const firebaseConfig = {
  apiKey: "AIzaSyDdRtGgzQEUFTRs5rrKKpAkkaqh9Ie8-Bc",
  authDomain: "ydays-618cd.firebaseapp.com",
  databaseURL: "https://ydays-618cd.firebaseio.com",
  projectId: "ydays-618cd",
  storageBucket: "ydays-618cd.appspot.com",
  messagingSenderId: "402631085000",
  appId: "1:402631085000:web:d885a76e8eb689b9624efd"
};
export const SENTRY_KEY = '';
export const AMPLITUDE_API_KEY = '';
export const SENDGRID_API_KEY = '';
