import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Link, Prompt as RouterPrompt } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import * as trackingService from '../../services/tracking.js';
import * as firebaseService from '../../services/firebase.js';
import * as authService from '../../services/auth.js';
import { headlessCapture } from '../../services/exportCanvas';
import CaptureCanvas from '../UI/CaptureCanvas';
import { ONE_DAY_IN_MILLISECONDS, getTimeDiff, decideSketchSize } from '../../services/shared';
import { getP5Tool } from '../../services/p5Tools';
import Spinner from '../UI/Spinner';
import WorkDisplay from '../UI/WorkDisplay';

import classes from './Draw.module.css';

const PROMPT_STATES = {
    NOT_STARTED: 'NOT_STARTED',
    TODAY: 'TODAY',
    ENDED: 'ENDED',
}

const PROMPT_MESSAGES = {
    [PROMPT_STATES.NOT_STARTED]: 'This day’s prompt isn’t available yet.',
    [PROMPT_STATES.ENDED]: 'This day’s prompt has ended already.',
    [PROMPT_STATES.TODAY]: 'Today’s prompt!',
}

/**
 * Props:
 * - promptIndex
 * - challengeId
 * - title
 * - arrowButtons
 * - dailyDate  // Only used to log different event
 */
class Draw extends Component {
    state = {
        challenge: null,
        prompt: null,
        promptState: '',
        isAdmin: false,
        errorMessage: '',
        isForceDisplay: false,
        challengeAuthorName: '',
        submitMessage: '',
        sketchSize: decideSketchSize('prompt'),
        creationArea: null,  // This is the sketch (draw or display), or empty text
        hasUserSubmitted: false,
        timeoutTimer: 0,
        stateCheckingInterval: 0,
    };

    componentDidMount() {
        firebaseService.loadCurrentUserProfile().then((profile) => {
            if (profile?.role === 'admin') {
                this.setState({ isAdmin: true });
            }
        });
        this.loadAllData();
        // scroll to top in case
        window.scrollTo(0, 0);

        this.setState({
            stateCheckingInterval: setInterval(() => {
                this.checkPromptState(this.state.challenge);
            }, 10 * 60 * 1000),
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.promptIndex !== this.props.promptIndex || prevProps.challengeId !== this.props.challengeId) {
            this.loadAllData();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeoutTimer);
        clearInterval(this.state.stateCheckingInterval);
    }

    loadAllData() {
        this.setState({
            challenge: null,
            prompt: null,
            promptState: '',
            isAdmin: false,
            errorMessage: '',
            isForceDisplay: false,
            challengeAuthorName: '',
            submitMessage: '',
            creationArea: null,
            hasUserSubmitted: false,
            timeoutTimer: 0,
        });

        const currentUserUid = authService.getCurrentUserDirect()?.uid;

        const challengeIdLoaded = this.props.challengeId;

        firebaseService.loadChallenge(this.props.challengeId).then((challenge) => {
            if (challengeIdLoaded !== this.props.challengeId) return;  // challengeId updated during fetching

            if (!challenge) {
                throw new Error(`Eh-oh! Couldn’t find this challenge.`);
            }
            this.setState({ challenge });

            this.checkPromptState(challenge);

            firebaseService.loadProfile(challenge.author).then((profile) => {
                this.setState({ challengeAuthorName: profile?.name });
            });

            // See if user has submitted for today already
            const submissions = challenge.prompts?.[this.props.promptIndex]?.submissions || {};
            this.setState({ hasUserSubmitted: !!submissions[currentUserUid] });

            return firebaseService.loadPrompt(challenge.prompts[this.props.promptIndex]?.promptId);
        }).then((prompt) => {
            if (challengeIdLoaded !== this.props.challengeId) return;  // challengeId updated during fetching

            if (!prompt) {
                throw new Error(`Eh-oh! Couldn’t find this prompt.`);
            }
            this.setState({ prompt });

            if (this.state.hasUserSubmitted) {
                const submissions = this.state.challenge.prompts?.[this.props.promptIndex]?.submissions || {};
                // Load the last submission by the current user
                const workId = submissions[currentUserUid][submissions[currentUserUid].length - 1];
                firebaseService.loadWork(workId).then((work) => {
                    this.setState({
                        creationArea: <div className={classes.yourSubmission}>
                            <WorkDisplay workData={work.data}
                                workId={workId}
                                prompt={prompt}
                                size={this.state.sketchSize}
                                p5name={prompt.extraData.p5name}
                                // challengeDayParameters={this.state.challenge.prompts?.[this.props.promptIndex]?.promptParameters}
                            />
                            <div className={classes.yourSubmissionCaption}><button onClick={this.startOverHandler}>Start over and resubmit</button></div>
                            {/* This url pattern matches both normal challenges and daily challenges */}
                            <p><Link to={{ pathname: `${this.props.location.pathname}/view`, search: this.props.location.search }} className={classes.link}>→ See today’s submissions so far</Link></p>
                        </div>
                    })
                });
            }

            if (!this.state.hasUserSubmitted) {
                const InteractiveComponent = getP5Tool(prompt.extraData.p5name);
                this.setState({
                    creationArea: InteractiveComponent ?
                        <InteractiveComponent displaySize={this.state.sketchSize}
                            parameters={this.state.challenge.prompts[this.props.promptIndex]?.promptParameters || prompt.extraData.parameters}
                            history={this.props.history}
                            parentCallback={this.submitDataHandler} />
                        : '(No interactive prompt today~)'

                });
            }
        }).catch((error) => {
            this.setState({ errorMessage: error.message });
        });
    }

    checkPromptState(challenge) {
        if (!challenge) return;

        const diff = getTimeDiff(challenge.startTimestamp, this.props.promptIndex);
        if (diff < 0) {
            this.setState({ promptState: PROMPT_STATES.NOT_STARTED });
        } else if (diff > ONE_DAY_IN_MILLISECONDS) {
            this.setState({ promptState: PROMPT_STATES.ENDED });
        } else {
            this.setState({ promptState: PROMPT_STATES.TODAY });
        }
    }

    startOverHandler = () => {
        const InteractiveComponent = getP5Tool(this.state.prompt.extraData.p5name);
        this.setState({
            creationArea: InteractiveComponent ?
                <InteractiveComponent displaySize={this.state.sketchSize}
                    parameters={this.state.challenge.prompts[this.props.promptIndex]?.promptParameters || this.state.prompt.extraData.parameters}
                    history={this.props.history}
                    parentCallback={this.submitDataHandler} />
                : '(No interactive prompt today~)'

        });
    }

    onCaptured = async (blob, workIdPromise, finishCallback) => {
        const workId = await workIdPromise;
        const fileExtension = this.state.prompt.extraData.durationInSeconds ? 'gif' : 'png';
        const url = await firebaseService.saveBlob(blob, `works/${workId}-${new Date().getTime()}.${fileExtension}`, `image/${fileExtension}`);
        await firebaseService.addWorkUrl(workId, url);
        finishCallback();
    }

    submitDataHandler = (p5data) => {  // Not using async here. We want to control the promise flow in detail
        const promptId = this.state.challenge.prompts[this.props.promptIndex].promptId;
        const saveDatabasePromise = firebaseService.createWorkEntry(this.props.challengeId, promptId).then((workId) => {
            // Save strokes needs workId for file name and database path, so has to `then` here
            return Promise.all([
                firebaseService.saveStrokes(workId, JSON.stringify(p5data)),
                firebaseService.recordWorkSubmission(workId, this.props.challengeId, promptId, this.props.promptIndex),
            ]).then(() => workId);
        });

        // Use two variables to keep the promise in this context, and pass down the resolver to the component handler
        let captureFinishResolver;
        const capturePromise = new Promise((resolve) => { captureFinishResolver = resolve; });

        this.setState({
            submitMessage: 'Submitting your work',
            creationArea: <CaptureCanvas
                workData={p5data}
                prompt={this.state.prompt}
                // challengeDayParameters={this.state.challenge.prompts[this.props.promptIndex]?.promptParameters}
                onCaptured={(blob) => this.onCaptured(blob, saveDatabasePromise, captureFinishResolver)} />,
        });

        // If local capture takes too long, stop the waiting and trigger headless capturing
        const MAX_LOCAL_CAPTURE_WAIT = 15 * 1000;
        const captureTimeoutPromise = saveDatabasePromise.then((workId) => {  // Start counting after we have critical data saved to database;
            return new Promise((resolve) => {
                const timeoutTimer = setTimeout(() => {
                    headlessCapture(workId, 640);  // No need to wait for this
                    resolve();
                }, MAX_LOCAL_CAPTURE_WAIT);
                this.setState({ timeoutTimer });
            });
        });

        Promise.all([saveDatabasePromise, Promise.race([captureTimeoutPromise, capturePromise])])
            .then(([workId]) => {
                trackingService.logAmplitude('Submit Work', { 'Challenge ID': this.props.challengeId, 'Prompt ID': promptId, 'Work ID': workId, 'Daily Play Day': this.props.dailyDate });
                trackingService.logGA('Work', 'Submit work');
                this.setState({ submitMessage: 'Saved. Redirecting...' });
                // This url pattern matches both normal challenges and daily challenges
                this.props.history.push({ pathname: `${this.props.location.pathname}/view`, search: this.props.location.search });
            }).catch((error) => {
                console.error(error);
                this.setState({ submitMessage: error.message });
            });

        // Capture the watermark version
        saveDatabasePromise.then((workId) => {
            // Capture headless requires the strokes saved in the database, so has to `then` here
            headlessCapture(workId, 1080, true);
        });
    }

    render() {
        let promptClass = classes.Prompt;
        if (isMobile) {
            promptClass = `${classes.Prompt} ${classes.Mobile}`;
        }

        const challenge = this.state.challenge;
        const prompt = this.state.prompt;
        if (!challenge || !prompt) {
            return <div className={promptClass}>
                {this.state.errorMessage ?
                    <div className={classes.content}><p>{this.state.errorMessage}</p></div>
                    :
                    <div className={classes.content}><Spinner /></div>
                }
            </div>
        }

        return (
            <div className={promptClass}>
                <RouterPrompt
                    message={(location, action) => {
                        return isMobile && (action === 'POP')
                        && (this.state.promptState === PROMPT_STATES.TODAY || this.state.isForceDisplay)
                            ? `Are you sure you want to leave without saving your drawing?`
                            : true
                    }}
                />
                <div className={classes.content}>
                    {/* <h1>{challenge.title}</h1> */}
                    <div className={classes.sketchLayout}>
                        <div className={classes.sketchText}>
                            <div className={classes.PromptHeader}>
                                <h1>{this.props.title}</h1>
                                {this.props.arrowButtons}
                            </div>
                            <h2>{prompt.title}</h2>
                            <span className={classes.promptInstructions}>{prompt.description}</span>
                        </div>
                        <div>
                            {this.state.promptState === PROMPT_STATES.TODAY || this.state.isForceDisplay ?
                                <>
                                    <div className={classes.sketch}>{this.state.creationArea}</div>
                                    {this.state.submitMessage ? <p className={classes.submitMessage}>{this.state.submitMessage} <Spinner inline /></p> : ''}
                                </> :
                                <p className={classes.promptState}>{PROMPT_MESSAGES[this.state.promptState]}</p>}
                            {this.state.promptState !== PROMPT_STATES.TODAY && this.state.isAdmin && !this.state.isForceDisplay ?
                                <div className={classes.forceDisplay}><button onClick={() => this.setState({ isForceDisplay: true })}>Show drawing tool (admin only)</button></div> : ''}
                        </div>
                    </div>
                    {/* <p className={classes.tiny}>Created by {challenge.author}. Started on {new Date(challenge.startTimestamp).toLocaleDateString()}, lasts {challenge.lengthInDays} days</p> */}
                </div>
            </div>
        );
    }
}

// `withRouter` is needed for *class component* that is not a direct child of router to access `this.props.history`
export default withRouter(Draw);
