import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayGridCirclesCorners(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [shapes, setShapes] = useState([]); // 1D array of Dshapes
    const [shapeColors, setShapeColors] = useState([]); // 1D array with 0,1,2 values
    const [color1, setColor1] = useState('#17131C');
    const [color2, setColor2] = useState('#FFD645');
    const [color3, setColor3] = useState('#FFFFFF'); // Background color
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Dshape = function(points) {
        this.points = points.slice();
    }

    function drawShape(p5, index) {
        if (shapeColors[index] === 0) {
            p5.fill(color3);
            p5.noStroke();
        } else if (shapeColors[index] === 1) {
            p5.fill(color1);
            p5.stroke(color1);
        } else {
            p5.fill(color2);
            p5.stroke(color2);
        }
        p5.beginShape();
        for (let i = 0; i < shapes[index].points.length; i++) {
            p5.vertex(shapes[index].points[i][0], shapes[index].points[i][1]);
        }
        p5.endShape(p5.CLOSE);
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2 && parameters.color3) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);

        const tempShapeColors = props.data[0];
        setShapeColors(tempShapeColors);
        const tempShapes = [];

        if (tempShapeColors.length === 0) return;
        const numShapes = 7;
        // d is the side of a grid square
        const d = p5.width / numShapes;
        let tx, ty; // store center point of circle

        for (let j = 0; j < numShapes; j++) {
            // each row of circles
            for (let i = 0; i < numShapes; i++) {
                tx = 0.5 * d + d * i;
                ty = 0.5 * d + d * j;

                let circlepts = [];
                let nepts = [];
                let septs = [];
                let swpts = [];
                let nwpts = [];

                for (let k = 0; k < 36; k++) {
                    circlepts.push([tx + d * 0.5 * Math.cos(k * p5.TWO_PI / 36), ty + d * 0.5 * Math.sin(k * p5.TWO_PI / 36)]);
                }
                tempShapes.push(new Dshape(circlepts));

                for (let k = 0; k <= 9; k++) {
                    septs.push([tx + d * 0.5 * Math.cos(k * p5.TWO_PI / 36), ty + d * 0.5 * Math.sin(k * p5.TWO_PI / 36)]);
                }
                septs.push([tx + d * 0.5, ty + d * 0.5]);
                tempShapes.push(new Dshape(septs));

                for (let k = 9; k <= 18; k++) {
                    swpts.push([tx + d * 0.5 * Math.cos(k * p5.TWO_PI / 36), ty + d * 0.5 * Math.sin(k * p5.TWO_PI / 36)]);
                }
                swpts.push([tx - d * 0.5, ty + d * 0.5]);
                tempShapes.push(new Dshape(swpts));

                for (let k = 18; k <= 27; k++) {
                    nwpts.push([tx + d * 0.5 * Math.cos(k * p5.TWO_PI / 36), ty + d * 0.5 * Math.sin(k * p5.TWO_PI / 36)]);
                }
                nwpts.push([tx - d * 0.5, ty - d * 0.5]);
                tempShapes.push(new Dshape(nwpts));

                for (let k = 27; k <= 36; k++) {
                    nepts.push([tx + d * 0.5 * Math.cos(k * p5.TWO_PI / 36), ty + d * 0.5 * Math.sin(k * p5.TWO_PI / 36)]);
                }
                nepts.push([tx + d * 0.5, ty - d * 0.5]);
                tempShapes.push(new Dshape(nepts));
            }
        }
        setShapes(tempShapes);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.strokeWeight(0.4);

        for (let i = 0; i < shapes.length; i++) {
            drawShape(p5, i);
        }
        if (props.withWatermark) {
            // This tool is not scaled, so needs to draw the watermark with scaleFactor
            drawWatermark(p5, watermarkImage, props.displaySize / 500);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayGridCirclesCorners;
