import React from 'react';
import { useHistory } from "react-router-dom";
import classes from './LandingSample.module.css';

import P5SketchRepeater from '../P5js/P5DrawRepeater';

function LandingSample() {
    const sketchSize = window.innerWidth < 500 ? window.innerWidth : 500;
    const history = useHistory();


    // TO DO: this doesn't work - want to be able to dynamically resize sketch if window changes size
    // but it sticks with the size set in useState at the top
    // useEffect(() => {
    //     const updateWindowDimensions = (sketchSize) => {
    //         let mySketchSize = sketchSize;
    //         if (window.innerWidth < mySketchSize) {
    //             // for phone browsers
    //             mySketchSize = window.innerWidth;
    //         }
    //         setSketchSize(mySketchSize);
    //     }
    //     updateWindowDimensions(sketchSize);
    //     window.addEventListener('resize', updateWindowDimensions);
    // }, []);

    const handleCloseSketch = () => {
        history.goBack();
    }

    return (
        <div className={classes.Sample}>
            <div className={classes.sketch}>
                <button className={classes.closeTool} onClick={handleCloseSketch}>Close drawing tool</button>
                <P5SketchRepeater displaySize={sketchSize} history={null} parentCallback={null} />
            </div>
        </div>
    );

}

export default LandingSample;
