import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classes from './Challenge.module.css';
import sharedClasses from '../../shared.module.css';
import Spinner from '../UI/Spinner';
import ProgressTable from '../UI/ProgressTable';
import WorksTableChallenge from '../UI/WorksTableChallenge';
import * as trackingService from '../../services/tracking.js';
import * as firebaseService from '../../services/firebase.js';
import * as authService from '../../services/auth.js';
import ErrorBoundary from '../ErrorBoundary.js';
import { saveJoinChallengeId, ONE_DAY_IN_MILLISECONDS } from '../../services/shared';
import { useNowWithUrlOverride } from '../../services/hooks';
import challengeImage from '../../assets/illus-start-rect.png';
import howItWorks3 from '../../assets/illus-group-rect.png';

function Challenge() {
    const params = useParams();
    const [challenge, setChallenge] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [author, setAuthor] = useState('<Spinner />');
    const [isMember, setIsMember] = useState(false);
    const [isAuthor, setIsAuthor] = useState(false);
    const [hasEditPermission, setHasEditPermission] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    // const [dayIndices, setDayIndices] = useState([]);
    const [latestDayIndex, setLatestDayIndex] = useState(-1);
    const [dayProgressMessage, setDayProgressMessage] = useState('');
    const [message, setMessage] = useState('');
    const [loadingMessage, setLoadingMessage] = useState('');
    // const [whichDay, setWhichDay] = useState(-1);
    const history = useHistory();
    const isMountedRef = useRef(null);

    const now = useNowWithUrlOverride();

    useEffect(() => {
        setCurrentUser(authService.getCurrentUserDirect());
    }, []);

    useEffect(() => {
        isMountedRef.current = true;

        firebaseService.loadChallenge(params.challengeId).then((loadedChallenge) => {
            /* Do not set anything if the component is unmounted.
             * This is necessary because this async (promise) is inside a useEffect. */
            if (!isMountedRef.current) return;

            if (!loadedChallenge) {
                setLoadingMessage(`Eh-oh! Challenge not found.`);
                return;
            }

            setChallenge(loadedChallenge);
        });
        return () => isMountedRef.current = false;
    }, [params.challengeId]);

    useEffect(() => {
        if (!challenge) return;

        let currentUid = currentUser?.uid;
        if (challenge.author === currentUid) {
            setHasEditPermission(true);
        }
        // Load author
        firebaseService.loadProfile(challenge.author).then(setAuthor);

        if (currentUser && challenge.author === currentUser.uid) {
            setIsAuthor(true);
        }
        setIsMember((challenge.members || []).includes(currentUid));

        const tempCurrentDayIndex = Math.floor((now.getTime() - challenge.startTimestamp) / ONE_DAY_IN_MILLISECONDS);

        if (tempCurrentDayIndex >= 0)
            setLatestDayIndex(Math.min(tempCurrentDayIndex, challenge.lengthInDays));
        // setDayIndices([...Array(Math.min(tempCurrentDayIndex, challenge.lengthInDays)).keys()]); // create array e.g. [0, 1, ..., tempCurrentDayIndex]
        if (tempCurrentDayIndex < -1) {
            // More than one day before
            setDayProgressMessage('Starts in ' + (-tempCurrentDayIndex) + ' days on ' + (new Date(challenge.startTimestamp).toLocaleDateString()));
        } else if (tempCurrentDayIndex < 0) {
            // One day before
            setDayProgressMessage('Starts in ' + (-tempCurrentDayIndex) + ' day on ' + (new Date(challenge.startTimestamp).toLocaleDateString()));
        } else if (tempCurrentDayIndex >= challenge.lengthInDays) {
            // Ended
            setIsCompleted(true);
            const endDate = new Date(challenge.startTimestamp + ONE_DAY_IN_MILLISECONDS * challenge.lengthInDays);
            const startDate = new Date(challenge.startTimestamp + ONE_DAY_IN_MILLISECONDS);
            setDayProgressMessage(startDate.toLocaleDateString(undefined, {month: 'numeric', day: 'numeric' }) + ' to ' + endDate.toLocaleDateString());
        } else {
            // In progress
            setDayProgressMessage('Day ' + (tempCurrentDayIndex + 1) + ' of ' + challenge.lengthInDays);
        }
    }, [challenge, currentUser, now]);

    useEffect(() => {
        if (currentUser) {
            firebaseService.loadProfile(currentUser.uid).then((profile) => {
                if (profile.role === 'admin') {
                    setHasEditPermission(true);
                }
            });
        }
    }, [currentUser]);

    if (!challenge) {
        return <>
            <Spinner />
            <p>{loadingMessage}</p>
        </>
    }

    const join = () => {
        setMessage('Joining...');
        trackingService.logAmplitude('Join Challenge', { 'Challenge ID': params.challengeId, 'Detail': 'Already signed in' });
        trackingService.logGA('Join challenge', 'Join (logged in)');
        firebaseService.joinChallenge(params.challengeId).then(() => {
            setMessage('You’ve accepted this challenge! Sending you to your home page...');
            setIsMember(true);
            setChallenge({ ...challenge, members: [...challenge.members, currentUser.uid] })
        }).catch((error) => {
            console.error(error);
            setMessage(`Something went wrong: ${error.message}`);
        });
        history.push('/home');
    }


    const handleCopyLink = () => {
        const copyText = document.getElementById("challengeLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy')
        copyText.setSelectionRange(0, 0);
        setMessage('Copied to clipboard!');
    }

    const handleCreateChallenge = () => {
        history.push('/create-challenge');
    }

    const handleSignup = () => {
        history.push('/signup');
    }

    const login = () => {
        trackingService.logAmplitude('Join Challenge', { 'Challenge ID': params.challengeId, 'Detail': 'Not signed in' });
        trackingService.logGA('Join challenge', 'Join (not logged in)');
        saveJoinChallengeId(params.challengeId);
        history.push('/signup');
    }

    const startImageBox = <div className={classes.imageBox}>
        <img src={challengeImage} className={classes.illustrationImage} alt="About YDays" />
    </div>;
    const peopleImageBox = <div className={classes.imageBox}>
        <img src={howItWorks3} className={classes.illustrationImage} alt="About YDays" />
    </div>;


    const challengeMetaTitle = "‘" + challenge.title + "’ on YDays";

    return (
        <div className={classes.content}>
            <Helmet>
                <title>{`${challenge.title} | Challenge summary | YDays`}</title>
                <meta property="og:title" content={challengeMetaTitle} />
            </Helmet>
            {isAuthor && !isCompleted ?
                <>
                    <div className={classes.challengeBox}>
                        {peopleImageBox}
                        <h2>The more, the merrier</h2>
                        <p><b>Share this link</b> to invite people to your {challenge.lengthInDays}-day challenge:</p>
                        <div className={classes.challengeLinkHolder}>
                            <input className={classes.challengeLink} id="challengeLink" value={"https://ydays.com/c/" + params.challengeId} readOnly></input>
                            <button className={classes.copyLink} onClick={handleCopyLink}>Copy</button>
                        </div>
                        <span className={classes.copyMessage}>{message}</span>
                        {challenge.description ? <p className={classes.description}>Your welcome note: <span className={classes.highlight}>{challenge.description}</span></p> : null}
                    </div>
                </>
                : null}
            {currentUser && !isMember && !isCompleted ?
                <div className={classes.contentBox}>
                    {startImageBox}
                    <h2 className={classes.lightWeight}>{author ? <span><b>{author.name}</b> invites you</span> : 'You’ve been invited'} to this {challenge.lengthInDays}-day challenge on&nbsp;
                        <Link to="/about" target="_blank" className={classes.link}>YDays</Link>.</h2>
                    <p>You’ll get a new drawing prompt on this site each day. Are you in?</p>
                    {challenge.description ? <p className={classes.description}><span className={classes.highlight}>{challenge.description}</span></p> : null}
                    <button className={classes.boldFilled} onClick={join}>Join this challenge!</button>
                    <span className={classes.message}>{message}</span>
                </div>
                : null}
            {!currentUser && !isCompleted ?
                <div className={classes.contentBox}>
                    {startImageBox}
                    <h2 className={classes.lightWeight}>{author ? <span><b>{author.name}</b> invites you</span> : 'You’ve been invited'} to this {challenge.lengthInDays}-day challenge on&nbsp;
                        <Link to="/about" target="_blank" className={classes.link}>YDays</Link>.</h2>
                    <p>You’ll get a new drawing prompt on this site each day. Are you in?</p>
                    {challenge.description ? <p className={classes.description}><span className={classes.highlight}>{challenge.description}</span></p> : null}
                    <button className={classes.boldFilled} onClick={login}>Sign in to join the challenge</button>
                </div>
                : null}

            {isCompleted && isMember ?
                <div className={classes.contentBox}>
                    {peopleImageBox} <p className={classes.completionMessage}><b>Congrats! You’ve completed this challenge.</b> <span role="img" aria-label="claps emoji">🙌&nbsp;</span>
                    You and {challenge.members.length - 1} others created some amazing things over {challenge.lengthInDays} days.
                    Let’s see how everyone did.</p>
                    <p><button className={classes.mediumBlackOutline} onClick={handleCreateChallenge}>Start a new challenge</button></p>
                </div>
                : null}

            {isCompleted && !isMember ?
                <div className={classes.contentBox}>
                    {peopleImageBox} <p className={classes.completionMessage}><b>{author ? author.name : 'Someone'}</b>&nbsp;and&nbsp;{challenge.members.length - 1} others
                    created some amazing things over {challenge.lengthInDays} days.
                    Take a look below.</p>
                    <p>{currentUser ? <button className={classes.mediumBlackOutline} onClick={handleCreateChallenge}>Start a new challenge</button>
                        : <button className={classes.mediumBlackOutline} onClick={handleSignup}>Join YDays to start your own challenge</button>}</p>
                </div>
                : null}

            { !isCompleted && isMember ?
                <Link to={{ pathname: `/home` }} className={classes.back}>← Back to home</Link>
                : null}

            <div className={classes.titleBox}>
                <h1>{challenge.title}
                    {hasEditPermission && !isCompleted ? <span className={classes.editLink}>
                        <Link to={{ pathname: `/edit-challenge/${params.challengeId}` }}>Edit</Link>
                    </span> : ''}</h1>
                <span className={classes.dayProgress}>{dayProgressMessage}</span>
            </div>

            { isMember && !isCompleted && latestDayIndex >= 0 ?
                <div className={classes.contentBox}>
                    <div className={classes.columnLayout}>
                        <div className={classes.primaryButtons}>
                            <h2>Today’s menu</h2>
                            {(latestDayIndex - 1) >= 0 && latestDayIndex - 1 <= challenge.lengthInDays - 1 ?
                                <Link to={{ pathname: '/c/' + params.challengeId + '/' + (latestDayIndex - 1) + '/view' }} className={sharedClasses.secondaryButtonPurpleOutline}>
                                    Review Day {latestDayIndex}</Link>
                                : null}
                            {latestDayIndex >= 0 && latestDayIndex <= challenge.lengthInDays - 1 ?
                                <Link to={{ pathname: '/c/' + params.challengeId + '/' + latestDayIndex }} className={sharedClasses.secondaryButtonPurpleOutline}>
                                    Today’s prompt</Link>
                                : null}
                        </div>
                        <div className={classes.members}>
                            <h2>Participants</h2>
                            <ErrorBoundary errorMessage="Eh-oh, couldn’t load the progress table.">
                                <ProgressTable challenge={challenge} challengeId={params.challengeId} compactView></ProgressTable>
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
                : <ErrorBoundary errorMessage="Eh-oh, couldn’t load the progress table.">
                    <ProgressTable challenge={challenge} challengeId={params.challengeId}></ProgressTable>
                </ErrorBoundary>
            }


            {latestDayIndex >= 1 ?
                <div className={classes.contentBox}>
                    <ErrorBoundary errorMessage="Eh-oh, couldn’t load the works table.">
                        <WorksTableChallenge latestDayIndex={latestDayIndex} challengeId={params.challengeId} />
                    </ErrorBoundary>
                </div>
                : null}
        </div>
    );
}

export default Challenge;
