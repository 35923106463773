import React from 'react';
import classes from './VoteSummary.module.css';

const EMOJI_LIST = ['👍', '👏', '😂', '😍', '😎', '😯'];

function VoteSummary(props) {
    return <div className={classes.emojiBar}>
        {EMOJI_LIST.map((emoji) => (
      props.reactions?.[emoji] ? <div key={emoji}
          className={classes.emojiButton}>
          {emoji}
          <span className={classes.emojiCount}>{props.reactions?.[emoji].length}</span>
      </div> : ''
        ))}
    </div>
}

export default VoteSummary;
