import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from "react-router";
import * as authService from '../services/auth';
import * as firebaseService from '../services/firebase';
import * as trackingService from '../services/tracking.js';
import classes from './App.module.css';

import Spinner from './UI/Spinner';

/* What permissions does each role have.
 * - Key: role value in the user's profile
 * - Value: pageRole values it have permission with */
const ROLE_PEMISSIONS = new Map([
    ['admin', ['user', 'creator', 'admin', undefined]],
    ['creator', ['user', 'creator', undefined]],
    ['user', ['user', undefined]],
    [undefined, ['user', undefined]],
]);

const STATUS = {
    CHECKING: 'CHECKING',
    NOT_LOGGED_IN: 'NOT_LOGGED_IN',
    NOT_VERIFIED: 'NOT_VERIFIED',
    HAS_PERMISSION: 'HAS_PERMISSION',
    NO_PERMISSION: 'NO_PERMISSION',
};

// `path` is '/c/:challengeId' etc., while location.pathname is the real '/c/foobarbazblah'
function AuthedRoute({ component: Component, pageRole, path, ...rest }) {
    const [status, setStatus] = useState(STATUS.CHECKING);
    const [verificationMessage, setVerificationMessage] = useState('Email not verified. Please check your inbox and click the link to verify your email.');

    useEffect(() => {
        trackingService.logPageNavigation(path);
    }, [path]);

    useEffect(() => {
        let hasUnmounted;

        const user = authService.getCurrentUserDirect();
        if (!user) {
            setStatus(STATUS.NOT_LOGGED_IN);

            // Draw/review can be shared to anyone, but it redirects to the challenge page if the user is not logged in
            const challengeSubPageMatch = rest.location.pathname.match(/(c|challenge)\/((-|_|\w)+)\/(\d+|\d+\/view)$/);
            if (challengeSubPageMatch && challengeSubPageMatch[2]) {
                rest.history.push(`/c/${challengeSubPageMatch[2]}`);
            }

            return;
        }
        if (!user.emailVerified) {
            setStatus(STATUS.NOT_VERIFIED);
            return;
        }

        firebaseService.loadCurrentUserProfile().then((loadedProfile) => {
            if (hasUnmounted) return;

            if (!ROLE_PEMISSIONS.get(loadedProfile?.role).includes(pageRole)) {
                setStatus(STATUS.NO_PERMISSION);
            } else {
                setStatus(STATUS.HAS_PERMISSION);
            }

            // Remove the url query if the user is not an admin
            if (new URLSearchParams(rest.location.search).get('now') && loadedProfile?.role !== 'admin') {
                rest.history.push(rest.location.pathname);
            }
        });

        return () => {hasUnmounted = true;};
    }, [pageRole, rest]);

    function resendVerificationEmail() {
        authService.resendVerificationEmail().then(() => {
            setVerificationMessage('Verification email sent. Please check your inbox');
        });
    }

    switch (status) {
        case STATUS.CHECKING:
            return <Spinner />
        case STATUS.NOT_LOGGED_IN:
            if (pageRole) {
                return <Route {...rest} render={() => <Redirect to='/login' />} />
            } else {
                return <Route {...rest} render={(props) => <Component {...props} />} />
            }
        case STATUS.NO_PERMISSION:
            return <Route {...rest} render={() => <Redirect to='/' />} />
        case STATUS.NOT_VERIFIED:
        case STATUS.HAS_PERMISSION:
            if (path === '/') {
                return <Route {...rest} render={() => <Redirect to='/home' />} />
            }
            return <>
                {status === STATUS.NOT_VERIFIED ? <p className={classes.warning}>{verificationMessage} <button onClick={resendVerificationEmail}>Resend</button></p> : null}
                <Route {...rest} render={(props) => <Component {...props} />} />
            </>
        default:
            throw new Error('Invalid status');
    }
}

export default withRouter(AuthedRoute);
