import React, { Component } from 'react';

import { decideSketchSize, waitForElementRender } from '../../services/shared';
import { getP5Tool } from '../../services/p5Tools';
import { captureGif, capturePng } from '../../services/exportCanvas.js';

import classes from './Draw.module.css';

/* Props:
 * - prompt
 * - workData
 * - onCaptured
 * - challengeDayParameters
 * - withWatermark
 * - size
 */
class CaptureCanvas extends Component {
    state = {
        submitMessage: '',
        creationArea: null,
        sketchSize: this.props.size || decideSketchSize('prompt'),
        randomClassName: '',
    };

    componentDidMount() {
        this.setState({
            submitMessage: 'Preparing to capture the image...',
            randomClassName: `capturing-${`${Math.random()}`.slice(2)}`,
        });
        // Wait for `this.state.randomClassName` to propagate
        setTimeout(() => {
            this.props.prompt.extraData.durationInSeconds ? this._captureGif() : this._captureStillImage();
        });
    }

    _captureStillImage = () => {
        const InteractiveComponent = getP5Tool(this.props.prompt.extraData.p5name, true);
        this.setState({
            creationArea: <InteractiveComponent data={typeof this.props.workData === 'string' ? JSON.parse(this.props.workData) : this.props.workData}
                // parameters={this.props.challengeDayParameters || this.props.prompt.extraData.parameters}
                parameters={this.props.prompt.extraData.parameters}
                withWatermark={this.props.withWatermark}
                displaySize={this.state.sketchSize} />
        });

        waitForElementRender(`.${this.state.randomClassName} canvas`).then((canvasEl) => {
            this.setState({ submitMessage: `Capturing a png...` });
            // Wait the time before p5 creates the canvas and draw the image.
            // TODO: Maybe wire this into p5 tools like gif?
            setTimeout(() => {
                capturePng(canvasEl).then((blob) => {
                    this.props.onCaptured(blob);
                    this.setState({ submitMessage: `Captured a png!` });
                });
            }, 100);
        }).catch((error) => {
            console.error('Failed to capture', error);
            this.setState({ submitMessage: 'Failed to capture. No canvas element found' });
        });
    }

    _captureGif = () => {
        const InteractiveComponent = getP5Tool(this.props.prompt.extraData.p5name, true);

        /* These 2 variables are passed down to the p5 component `InteractiveComponent`.
         * We need to define them here, and wrap it in a function. Because we need the props when we create the component in the `setState` below,
         * But the real functionality of them will only available after the component is rendered, and `captureGif` is initialized.
         * So this dependency "loop" of : `props -> component -> gif methods -> props` is handled by:
         * 1. Using `propName={(args) => tempVar && tempVar(args)}` as props
         * 2. Then `tempVar = realGifMethod` later */
        let addGifFrameForP5, stopGifCaptureForP5;
        this.setState({
            creationArea: <InteractiveComponent
                data={typeof this.props.workData === 'string' ? JSON.parse(this.props.workData) : this.props.workData}
                displaySize={this.state.sketchSize}
                // parameters={this.props.challengeDayParameters || this.props.prompt.extraData.parameters}
                parameters={this.props.prompt.extraData.parameters}
                withWatermark={this.props.withWatermark}
                durationInSeconds={this.props.prompt.extraData.durationInSeconds}
                addGifFrame={(frameInMilliseconds) => addGifFrameForP5 && addGifFrameForP5(frameInMilliseconds)}
                stopCapture={() => stopGifCaptureForP5 && stopGifCaptureForP5()} />
        });

        waitForElementRender(`.${this.state.randomClassName} canvas`).then((canvasEl) => {
            const [addGifFrame, stopCapture] = captureGif(canvasEl, (blob) => {
                this.props.onCaptured(blob);
                this.setState({ submitMessage: `Captured a gif. Uploading...` });
            });
            this.setState({ submitMessage: `Capturing a ${this.props.prompt.extraData.durationInSeconds}s gif...` });
            addGifFrameForP5 = addGifFrame;
            stopGifCaptureForP5 = () => {
                this.setState({ submitMessage: 'Processing gif... (please wait)' });
                stopCapture();
            }
        }).catch((error) => {
            console.error('Failed to capture', error);
            this.setState({ submitMessage: 'Failed to capture. No canvas element found' });
        });
    }

    render() {
        return (
            <>
                <div className={`${classes.sketch} ${this.state.randomClassName}`}>{this.state.creationArea}</div>
                {this.state.submitMessage ? <p>{this.state.submitMessage}</p> : ''}
            </>
        );
    }
}

export default CaptureCanvas;
