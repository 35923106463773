import React, {useState, useEffect} from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawCircles(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [mode, setMode] = useState("NORMAL"); // or: "DIAMONDS" for rounded diamond shapes, or "RECTS" for rounded rects
    const [bgColor, setBgColor] = useState("#FFFFFF"); // background
    const [fgColor, setFgColor] = useState("#000000"); // foreground
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let circleMaxSize = 60;

    let Circle = function(x, y, d, clr) {
        this.x = x;
        this.y = y;
        this.d = d;
        this.clr = clr;
    }

    Circle.prototype.draw = function(p5) {
        p5.fill(this.clr);
        p5.noStroke();
        let c;
        if (mode === "DIAMONDS") {
            c = 0.4; // 0.552 is approximation for a circle
            p5.push();
            p5.translate(this.x, this.y);
            p5.scale(this.d * 0.5, this.d * 0.5);

            p5.beginShape();
            p5.vertex(0, -1);
            p5.bezierVertex(-1 * c, -1,  -1, -1 * c,  -1, 0);
            p5.bezierVertex(-1, 1 * c,  -1 * c, 1,  0, 1);
            p5.bezierVertex(1 * c, 1,  1, 1 * c,  1, 0);
            p5.bezierVertex(1, -1 * c,  1 * c, -1,  0, -1);
            p5.endShape();
            p5.pop();
        } else if (mode === "RECTS") {
            c = 0.76; // 0.552 is approximation for a circle
            p5.push();
            p5.translate(this.x, this.y);
            p5.scale(this.d * 0.5, this.d * 0.5);
            p5.beginShape();
            p5.vertex(0, -1);
            p5.bezierVertex(-1 * c, -1,  -1, -1 * c,  -1, 0);
            p5.bezierVertex(-1, 1 * c,  -1 * c, 1,  0, 1);
            p5.bezierVertex(1 * c, 1,  1, 1 * c,  1, 0);
            p5.bezierVertex(1, -1 * c,  1 * c, -1,  0, -1);
            p5.endShape();
            p5.pop();
        } else {
            p5.ellipse(this.x, this.y, this.d, this.d);
        }
    }

    let circles = [];

    let sizes = [100, 92, 81, 70, 60, 50, 39, 28, 20, 28, 39, 50, 60, 70, 81, 92];
    let sizeIndex = 0;
    let isFgColor = true;

    let lastCircleX, lastCircleY;
    let strokeIndexes = []; // store the starting indices of each new stroke, for use by undo callback
    let isDrawing = false;

    function distance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function addCircle(pX, pY) {
        circles.push(new Circle(pX, pY, circleMaxSize * sizes[sizeIndex] / 100., (isFgColor ? bgColor : fgColor)));

        isFgColor = !isFgColor;
        sizeIndex++;
        if (sizeIndex > sizes.length - 1) sizeIndex = 0;

        lastCircleX = pX;
        lastCircleY = pY;
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;

        addCircle(pX, pY);
        strokeIndexes.push(circles.length - 1);
        ;
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            if ((circles.length === 0) ||
				(distance(pX, pY, lastCircleX, lastCircleY) > 6 * scaleFactor)) {
                addCircle(pX, pY);
            }
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        isDrawing = false;
    }


    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.mode) {
                setMode(parameters.mode);
            }
            if (parameters.bgColor && parameters.fgColor) {
                setBgColor(parameters.bgColor);
                setFgColor(parameters.fgColor);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        const tempScaleFactor = props.displaySize / 500.;
        setScaleFactor(tempScaleFactor); // apply this scale factor to circle sizes
        circleMaxSize = circleMaxSize * tempScaleFactor;
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(bgColor);

        for (let i = 0; i < circles.length; i++) {
            let c = circles[i];
            c.draw(p5);
        }
    };

    const clearDrawingHandler = () => {
        circles = [];
        return true;
    }

    const undoHandler = () => {
        if (strokeIndexes.length >= 1) {
            const lastStrokeI = strokeIndexes[strokeIndexes.length - 1];
            if (lastStrokeI <= circles.length - 1) {
                circles.splice(lastStrokeI);
                strokeIndexes.pop(strokeIndexes.length - 1);
            }
        }
    }

    function scaleValues(circle) {
        return (new Circle(circle.x / scaleFactor, circle.y / scaleFactor, circle.d / scaleFactor, circle.clr));
    }

    const submitButtonHandler = () => {
        // props.parentCallback("circleArray", circles.map(scaleValues), '/circles.json');
        props.parentCallback(circles.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawCircles;
