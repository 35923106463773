import React, { useState, useEffect, useCallback } from 'react';
import * as firebaseService from '../../services/firebase.js';
import * as trackingService from '../../services/tracking';
import { decideSketchSize } from '../../services/shared';
import WorkDisplay from './WorkDisplay';
import ErrorBoundary from '../ErrorBoundary.js';

import VoteSummary from './VoteSummary';
import Backdrop from './Backdrop';
import closeButton from '../../assets/icon-close.svg';
import sharedClasses from '../../shared.module.css';
import classes from './WorkGrid.module.css';
import Spinner from './Spinner.js';

const SHOW_MORE_BATCH_SIZE = 30;  // common multiples of 2 & 3, so the last row of the grid is full

/* props:
 * - gridColumns: 2 or 3
 * - skipHidden: whether to show works that is `hideToPublic`. (On challenge and review page, always show them)
 * - alignBottom: if true, align grid contents to bottom
 * - ModalTitle, ModalDayLabel: The components in the modal
 * - children: the template code inside this component https://reactjs.org/docs/composition-vs-inheritance.html#containment
 * - skipSentryLog
 */
function WorkGrid({ workIds, gridColumns, skipHidden, alignBottom, ModalTitle, ModalDayLabel, children, skipSentryLog }) {
    const [loadedLength, setLoadedLength] = useState(SHOW_MORE_BATCH_SIZE);  // This is against `workIds` - the full list, including hidden and invalid works
    const [visibleEntries, setVisibleEntries] = useState([]);  // This is the real visible cards on the page
    const [modalIndex, setModalIndex] = useState(null);  // Index of the modal inside `visibleEntries`
    const [totalLength, setTotalLength] = useState(0);  // Estimation of total valid works. (we don't know if a work is hidden until we "load more" to that work)
    const [isLoadingContent, setIsLoadingContent] = useState(false);
    const [modalWorkRefreshFlag, setModalWorkRefreshFlag] = useState(true);

    // Validate required fields
    useEffect(() => {
        if (![2, 3].includes(gridColumns)) {
            throw new Error(`${gridColumns} is not a valid grid size`);
        }

        if (!children) {
            throw new Error(`No template provided to the grid`);
        }
    }, [gridColumns, children]);

    // Load all data whenever needed.
    useEffect(() => {
        let hasUnmounted = false;
        setIsLoadingContent(true);
        /* Here we fetch the works need to be displayed, and filter out unwanted works.
         * Because we don't know if a work is hidden until we fetch that work, there are two minor issues with this:
         * 1. The total work count in the modal can reduce as user loads more works (reduced by the number of new hidden works found)
         * 2. The last row may not be a full 2 / 3 work row (This is patched by loading more works after each fetch if needed) */
        Promise.all(workIds.slice(0, loadedLength).map(async (workId) => {
            if (hasUnmounted) return;

            const workObj = await firebaseService.loadWork(workId);
            if (!workObj) {
                const warningMessage = `Skipping a deleted work ${workId}`;
                console.info(warningMessage);
                if (!skipSentryLog) trackingService.logSentry(new Error(warningMessage), true);
                return null;
            }
            if (skipHidden && workObj.hideToPublic) return null;

            const challenge = await firebaseService.loadChallenge(workObj.challengeId);
            if (!challenge) {
                const warningMessage = `Skipping a work in a deleted challenge ${workId}`;
                console.info(warningMessage);
                if (!skipSentryLog) trackingService.logSentry(new Error(warningMessage), true);
                return null;
            }
            if (skipHidden && challenge.title.match(/\[test\]/i)) {
                // Hide works from "[test]" challenges in public profile
                return null;
            }
            const dayIndex = challenge.prompts.findIndex((day) => day.promptId === workObj.promptId);
            const daySubmission = challenge.prompts[dayIndex].submissions?.[workObj.author];
            if (!daySubmission || !daySubmission.length) {
                const warningMessage = `Skipping a work that doesn't exist in the submissions list ${workId}`;
                console.info(warningMessage);
                if (!skipSentryLog) trackingService.logSentry(new Error(warningMessage), true);
                return null;
            }
            if (daySubmission.indexOf(workId) !== daySubmission.length - 1) {
                const warningMessage = `Skipping a work that is not the last submission from this user ${workId}`;
                console.info(warningMessage);
                if (!skipSentryLog) trackingService.logSentry(new Error(warningMessage), true);
                return null;
            }

            const [authorProfile, prompt] = await Promise.all([
                firebaseService.loadProfile(workObj.author),
                firebaseService.loadPrompt(workObj.promptId),
            ]);
            if (!prompt) {
                const warningMessage = `Skipping a work in a deleted prompt ${workId}`;
                console.info(warningMessage);
                if (!skipSentryLog) trackingService.logSentry(new Error(warningMessage), true);
                return null;
            }
            const emojiCounts = Array.from(Object.values(workObj.reactions || {})).flat().length;

            return { authorProfile, workId, workObj, prompt, challenge, dayIndex, emojiCounts };
        })).then((res) => {
            if (hasUnmounted) return;

            const updatedVisibleEntries = res.filter(Boolean);
            if (loadedLength < workIds.length && updatedVisibleEntries.length % SHOW_MORE_BATCH_SIZE !== 0) {
                /* If some hidden/invalid works cause this batch is not a full batch, load more works to fit the batch size
                    * This may cause the loading calculation happen multiple times. But fetching is cached, so it shouldn't be too bad/slow. */
                setLoadedLength((loadedLength) => loadedLength + (SHOW_MORE_BATCH_SIZE - updatedVisibleEntries.length % SHOW_MORE_BATCH_SIZE));
            }
            setVisibleEntries(updatedVisibleEntries);
            setTotalLength(workIds.length > loadedLength ? updatedVisibleEntries.length + (workIds.length - loadedLength) : updatedVisibleEntries.length); // Here we assume all remaining works are not hidden and valid. It's just best guess.
            setIsLoadingContent(false);
        });

        return () => {hasUnmounted = true;};
    }, [workIds, loadedLength, skipHidden, skipSentryLog]);

    // Auto load more when modal navs
    useEffect(() => {
        if (visibleEntries.length === 0 || modalIndex === null) return;

        if (modalIndex >= visibleEntries.length) {
            showMore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalIndex, visibleEntries]);

    // Prevent the previous drawing stay on the page while new one is loading
    useEffect(() => {
        // ... by using a flag value to toggle the drawing off and on
        setModalWorkRefreshFlag(false);
        setTimeout(() => {
            setModalWorkRefreshFlag(true);
        });
    }, [modalIndex]);

    function showMore() {
        setLoadedLength(loadedLength + SHOW_MORE_BATCH_SIZE);
    }

    function openModal(entry) {
        const index = visibleEntries.indexOf(entry);
        if (index === -1) {
            console.error('visibleEntries[modalIndex] is not found in the entry list');
        } else {
            setModalIndex(index);
        }
        window.addEventListener('keydown', keyDownHandler);
    }

    // Use `useCallback` so that we can have the same reference to use in `removeEventListener`
    const keyDownHandler = useCallback((event) => {
        switch (event.key) {
            case 'ArrowLeft':
                navigateModal(false);
                break;
            case 'ArrowRight':
                navigateModal(true);
                break;
            case 'Escape':
                closeModal();
                break;
            default:
            // Nothing;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function closeModal() {
        setModalIndex(null);
        window.removeEventListener('keydown', keyDownHandler);
    }

    function navigateModal(isNext) {
        /* `keyDownHandler` called this function inside callback, which always has stale state.
         * So all state values needs to be get from callback setter. Otherwise the keyboard nav will mess up modal index. */

        // This does no change, just to get the latest state value.
        let latestTotalLength;
        setTotalLength((currentTotalLength) => {
            latestTotalLength = currentTotalLength;
            return currentTotalLength;
        });
        setModalIndex((currentIndex) => {
            if ((currentIndex === 0 && !isNext) || (currentIndex === latestTotalLength - 1 && isNext)) return currentIndex;

            return currentIndex + (isNext ? 1 : - 1);
        });
    }

    return <>
        <ul className={`${gridColumns === 2 ? classes.gridColumn2 : classes.gridColumn3}
        ${alignBottom ? classes.gridAlignBottom : null}`}>
            {visibleEntries.map((entry) => <li key={entry.workId}>
                <ErrorBoundary>
                    <children.type {...children.props} {...entry} onClick={() => openModal(entry)}>
                        <WorkDisplay workData={entry.workObj.data}
                            workId={entry.workId}
                            prompt={entry.prompt}
                            size={decideSketchSize(`grid-${gridColumns}`)}
                            p5name={entry.prompt.extraData.p5name}
                        // challengeDayParameters={entry.challenge.prompts?.[entry.dayIndex]?.promptParameters}
                        />
                    </children.type>
                </ErrorBoundary>
            </li>)}
        </ul>
        {workIds.length > loadedLength &&
            <button className={sharedClasses.secondaryButtonOutline}
                onClick={() => showMore()}
                disabled={isLoadingContent}>Load more</button>
        }
        <Backdrop show={modalIndex !== null} clicked={closeModal}></Backdrop>
        {modalIndex !== null &&
            <div className={classes.Modal}>
                <div className={classes.closeButton}>
                    <img src={closeButton} className={classes.icon} alt="X" onClick={closeModal} />
                </div>
                {visibleEntries[modalIndex] ? <>
                    <div className={classes.sketchContainer}>
                        <div className={classes.modalTitle}><ModalTitle modalEntry={visibleEntries[modalIndex]} /></div>
                        <div className={classes.sketch}>
                            {modalWorkRefreshFlag &&
                                <ErrorBoundary>
                                    <WorkDisplay workData={visibleEntries[modalIndex].workObj.data}
                                        workId={visibleEntries[modalIndex].workId}
                                        size={decideSketchSize('modal')}
                                        prompt={visibleEntries[modalIndex].prompt}
                                        // challengeDayParameters={visibleEntries[modalIndex].challenge.prompts?.[visibleEntries[modalIndex].dayIndex]?.promptParameters}
                                        p5name={visibleEntries[modalIndex].prompt.extraData.p5name} />
                                </ErrorBoundary>
                            }
                        </div>
                        <VoteSummary workId={visibleEntries[modalIndex]?.workId} reactions={visibleEntries[modalIndex]?.workObj.reactions}></VoteSummary>
                    </div>
                    <div className={classes.sketchInfo}>
                        <div className={classes.sketchDescription}>
                            <h2>{visibleEntries[modalIndex]?.prompt.title}</h2>
                            <p>{visibleEntries[modalIndex]?.prompt.description}</p>
                        </div>
                        <div className={classes.sketchBottomInfo}>
                            <div className={classes.dayLabel}><ModalDayLabel modalEntry={visibleEntries[modalIndex]} /></div>
                            <div className={classes.pagingButtons}>
                                <button className={classes.flatButton}
                                    disabled={modalIndex === 0}
                                    onClick={() => navigateModal(false)}>←</button>
                                <span className={classes.pagingIndexCount}>{modalIndex + 1} of {totalLength}</span>
                                <button className={classes.flatButton}
                                    disabled={modalIndex === totalLength - 1}
                                    onClick={() => navigateModal(true)}>→</button>
                            </div>
                        </div>
                    </div>
                </>
                    :
                    <div className={classes.modalLoading}>
                        <Spinner />
                    </div>
                }
            </div>}
    </>
}

export default WorkGrid;
