import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawWiggle(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokeWeight, setStrokeWeight] = useState(6);
    const [wiggleX, setWiggleX] = useState(1.0);
    const [wiggleY, setWiggleY] = useState(1.0);
    const [cycleTime, setCycleTime] = useState(0.5); // length of animation, needs to match duration of the prompt in database
    const [strokeCap, setStrokeCap] = useState("ROUND"); // "ROUND", "SQUARE", or "PROJECT"
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function (x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let strokes = [];
    let curStroke = [];
    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) return;

        if (isDrawing && curStroke.length > 0) {
            strokes.push(curStroke);
            curStroke = [];
        }
        strokes.push(curStroke);
        addPoint(p5, pX, pY);
        isDrawing = true;
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            strokes.pop();
            strokes.push(curStroke);
            curStroke = [];
            isDrawing = false;
        }
    }


    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempStrokeWeight = strokeWeight;
        let tempStrokeCap = strokeCap;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.wiggleX && parameters.wiggleY) {
                setWiggleX(parameters.wiggleX);
                setWiggleY(parameters.wiggleY);
            }
            if (parameters.cycleTime) {
                setCycleTime(parameters.cycleTime);
            }
            if (parameters.strokeCap) {
                tempStrokeCap = parameters.strokeCap;
                setStrokeCap(tempStrokeCap);
            }
            if (parameters.strokeWeight) {
                tempStrokeWeight = parameters.strokeWeight;
                setStrokeWeight(tempStrokeWeight);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.); // apply this scale factor to strokeWeight
        if (tempStrokeCap === "SQUARE")
            p5.strokeCap(p5.SQUARE);
        else if (tempStrokeCap === "PROJECT")
            p5.strokeCap(p5.PROJECT);
        else
            p5.strokeCap(p5.ROUND);
        p5.strokeWeight(tempStrokeWeight * scaleFactor);
        p5.strokeJoin(p5.ROUND);
        p5.stroke(0);
        p5.noFill();
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        let t1, wx, wy;
        let curTime = current(p5);
        const indexOffset = 3;
        const angleOffset = 40.0;

        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    // amount to wiggle
                    t1 = 1 * Math.sin(strokes[i][j].t * indexOffset + curTime * (2 * Math.PI / cycleTime));
                    // fixed angle per point
                    wx = wiggleX * t1 * Math.cos(strokes[i][j].t * angleOffset);
                    wy = wiggleY * t1 * Math.sin(strokes[i][j].t * angleOffset);
                    p5.curveVertex(strokes[i][j].x + wx, strokes[i][j].y + wy);
                }
                p5.endShape();
            }
        }

    };

    const clearDrawingHandler = () => {
        strokes = [];
        return true;
    }

    const undoHandler = () => {
        if (strokes.length >= 1) {
            strokes.splice(strokes.length - 1);
        }
    }

    function scaleValues(stroke) {
        let newStroke = [];
        for (let i = 0; i < stroke.length; i++) {
            newStroke.push(new Point(
                stroke[i].x / scaleFactor,
                stroke[i].y / scaleFactor,
                stroke[i].t
            ));
        }
        return newStroke;
    }

    const submitButtonHandler = () => {
        props.parentCallback(strokes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawWiggle;
