import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawSplatter3 (props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [color1, setColor1] = useState("#5718A7");
    const [color2, setColor2] = useState("#66F4EC");
    const [color3, setColor3] = useState("#FFCA41");
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, d, t) {
        this.x = x;
        this.y = y;
        this.d = d;
        this.t = t;
    }

    let strokes = [];
    let strokew = 10;
    let curStroke = [];
    let isDrawing = false;
    let lastPointX, lastPointY;

    function addPoint(p5, pX, pY) {
        let sw = strokew;
        if (curStroke.length > 0) {
            const d = getDistance(pX, pY, lastPointX, lastPointY);
            let f = 5.0 / d;
            if (f < .1) f = .1;
            if (f > 2) f = 2;
            sw *= f;
        }
        curStroke.push(new Point(pX, pY, sw, current(p5)));

        lastPointX = pX;
        lastPointY = pY;
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) return;
        isDrawing = true;

        if (isDrawing && curStroke.length > 0) {
            strokes.push(curStroke);
            curStroke = [];
        }
        strokes.push(curStroke);
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            strokes.pop();
            strokes.push(curStroke);
            curStroke = [];
            isDrawing = false;
        }
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2 && parameters.color3) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.); // apply this scale factor to strokeWeight
        p5.strokeCap(p5.ROUND);
        p5.curveTightness(-.5);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.noFill();

        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                if (i % 3 === 0) {
                    p5.stroke(color1);
                } else if (i % 3 === 1) {
                    p5.stroke(color2);
                } else {
                    p5.stroke(color3);
                }

                p5.beginShape(p5.LINES);
                p5.strokeWeight(2 * scaleFactor);
                for (let j = 0; j < strokes[i].length - 1; j++) {
                    p5.curveVertex(strokes[i][j].x, strokes[i][j].y);
                    p5.curveVertex(strokes[i][j + 1].x, strokes[i][j + 1].y);
                }
                p5.endShape();

                for (let j = 0; j < strokes[i].length - 1; j++) {
                    p5.beginShape(p5.LINES);
                    p5.strokeWeight(strokes[i][j].d * scaleFactor);
                    p5.vertex(strokes[i][j].x, strokes[i][j].y);
                    p5.vertex(strokes[i][j + 1].x, strokes[i][j + 1].y);
                    p5.endShape();
                }
            }
        }
    };

    const clearDrawingHandler = () => {
        strokes = [];
        return true;
    }

    const undoHandler = () => {
        if (strokes.length >= 1) {
            strokes.splice(strokes.length - 1);
        }
    }

    function scaleValues(stroke) {
        let newStroke = [];
        for (let i = 0; i < stroke.length; i++) {
            newStroke.push(new Point(
                stroke[i].x / scaleFactor,
                stroke[i].y / scaleFactor,
                stroke[i].d,
                stroke[i].t
            ));
        }
        return newStroke;
    }

    const submitButtonHandler = () => {
        // props.parentCallback("repeaterArray", strokes.map(scaleValues), '/repeater.json');
        props.parentCallback(strokes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawSplatter3;
