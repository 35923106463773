import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import classes from './ResetPassword.module.css';
import * as authService from '../../services/auth.js';

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const preventDefault = (evt) => {
        evt.preventDefault();
    }

    const sendPasswordReset = () => {
        authService.sendPasswordResetEmail(email).then(() => {
            setMessage('Please check your inbox for an email to reset your password');
        }).catch((error) => {
            console.error(error);
            setMessage(error.message);
        });
    }

    return <div className={classes.content}>
        <Helmet>
            <title>Reset your password | YDays</title>
        </Helmet>
        <div className={classes.formContainer}>
            <form onSubmit={preventDefault}>
                <h1>Reset your password</h1>
                <p>Enter the email address associated with your account, and we’ll email you a link to reset your password.</p>
                <label className={classes.field}>
                    <span className={classes.fieldLabel}>Email</span>
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)}></input>
                </label>
                <button onClick={sendPasswordReset}>Send reset link</button>
                {message ? <p className={classes.message}>{message}</p> : null}
            </form>
        </div>
    </div>
}

export default ResetPassword;
