import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayArcGridConnector(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [segments, setSegments] = useState([]); // 1D array of Dshapes
    const [squaresPerSide, setSquaresPerSide] = useState(22); 
    const [strokeWidth, setStrokeWidth] = useState(16);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Segment = function(x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    function getGridCoord(p5, pX, pY) {
        // Get the grid coordinate: drawing should go edge to edge, inset with half the strokeWidth on either side
        let i = Math.round(((pX - scaleFactor * strokeWidth * 0.5) / (p5.width - scaleFactor * strokeWidth)) * squaresPerSide);
        let j = Math.round(((pY - scaleFactor * strokeWidth * 0.5) / (p5.height - scaleFactor * strokeWidth)) * squaresPerSide);
        if (i < 0) i = 0;
        if (j < 0) j = 0;
        if (i > squaresPerSide) i = squaresPerSide;
        if (j > squaresPerSide) j = squaresPerSide;
        return [i, j];
    }

    function drawSegment(p5, x1, y1, x2, y2) {
        let [i1, j1] = getGridCoord(p5, x1, y1);
        let [i2, j2] = getGridCoord(p5, x2, y2);
        const gridw = (p5.width - scaleFactor * strokeWidth) / squaresPerSide;
        const cf = 0.5522847498; // bezier approximation of circle

        // Use these values so that drawings go edge to edge, taking the pen width into consideration
        const os = scaleFactor * strokeWidth * 0.5; // offset

        let radius;
        let dx = i2 - i1;
        let dy = j2 - j1;

        let sign = 1;

        if (dy === 0 || dx === 0) {
            // straight line
            p5.line(i1 * gridw + os, j1 * gridw + os, i2 * gridw + os, j2 * gridw + os);
        } else if (Math.abs(dx) >= Math.abs(dy)) {
            // east quarter and west quarter
            // draw straight segment, then quarter arc
            if (dx < 0) sign = -1;

            radius = Math.abs(dy * gridw);
            p5.beginShape();
            p5.vertex(i1 * gridw + os, j1 * gridw + os);
            p5.vertex(i2 * gridw - sign * radius + os, j1 * gridw + os);
            p5.bezierVertex(i2 * gridw - sign * radius + sign * radius * cf + os, j1 * gridw + os,
                i2 * gridw + os, j2 * gridw - (dy * gridw) * cf + os,
                i2 * gridw + os, j2 * gridw + os);
            p5.endShape();
        } else {
            // north quarter and south quarter
            // draw straight segment, then quarter arc
            if (dy < 0) sign = -1;

            radius = Math.abs(dx * gridw);
            p5.beginShape();
            p5.vertex(i1 * gridw + os, j1 * gridw + os);
            p5.vertex(i1 * gridw + os, j2 * gridw - sign * radius + os);
            p5.bezierVertex(i1 * gridw + os, j2 * gridw - sign * radius + sign * radius * cf + os,
                i2 * gridw - (dx * gridw) * cf + os, j2 * gridw + os,
                i2 * gridw + os, j2 * gridw + os);
            p5.endShape();
        }
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempStrokeWidth = strokeWidth;
        let tempSquaresPerSide = squaresPerSide;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.strokeWidth) {
                tempStrokeWidth = parameters.strokeWidth;
                setStrokeWidth(tempStrokeWidth);
            }
            if (parameters.squaresPerSide) {
                tempSquaresPerSide = parameters.squaresPerSide;
                setSquaresPerSide(tempSquaresPerSide);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        const tempScaleFactor = props.displaySize / 500.;
        setScaleFactor(tempScaleFactor);
        p5.strokeCap(p5.PROJECT);

        let segmentsData = props.data;
        const tempSegments = [];
        for (let i = 0; i < segmentsData.length; i++) {
            let seg = segmentsData[i];
            tempSegments.push(new Segment(tempScaleFactor * seg.x1, tempScaleFactor * seg.y1, tempScaleFactor * seg.x2, tempScaleFactor * seg.y2));
        }
        setSegments(tempSegments);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        //p5.scale(scaleFactor, scaleFactor);

        p5.noFill(0);
        p5.stroke(0);
        p5.strokeWeight(scaleFactor * strokeWidth);

        for (let i = 0; i < segments.length; i++) {
            drawSegment(p5, segments[i].x1, segments[i].y1, segments[i].x2, segments[i].y2);
        }
        if (props.withWatermark) {
            // This tool is not scaled, so needs to draw the watermark with scaleFactor
            drawWatermark(p5, watermarkImage, scaleFactor);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayArcGridConnector;
