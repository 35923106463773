import GIF from 'gif.js';
import * as firebaseService from './firebase';
import { CLOUD_FUNCTION_ROOT } from './shared';

export function headlessCapture(workId, size = 500, withWatermark) {
    return fetch(`${CLOUD_FUNCTION_ROOT}/captureImage?work=${workId}&size=${size}${withWatermark ? '&watermark=true' : ''}`, { mode: 'cors' })
        .catch(() => {/* Ignore all errors from headless. Those are tracked and handled in cloud functions. */});
}

export async function downloadFile(ref) {
    // Get file blob
    const url = await firebaseService.getDownloadUrl(ref);
    const res = await fetch(url);
    const blob = await res.blob();

    // Make it an <a> tag
    const element = document.createElement('a');
    element.style.display = 'none';
    element.href = window.URL.createObjectURL(blob);
    element.setAttribute('download', `YDays-${ref.replace(/.*\//g, '')}`);

    // Trigger it
    document.body.appendChild(element);
    element.click();

    // Clean up
    setTimeout(() => {
        window.URL.revokeObjectURL(element.href);
        document.body.removeChild(element);
    }, 5000);  // Wait a little white before deleting. Safari may fail if deleted too soon
}

export function captureGif(canvasElement, callback) {
    // Documentation: https://github.com/jnordberg/gif.js
    const gif = new GIF({
        workers: 4,
        // If the browser supports WebAssembly, use wasm version.
        // The wasm version of the worker is not published on gif.js. I found it on https://github.com/jnordberg/GLSLWASMGIF
        workerScript: window['WebAssembly'] ? '/gif.worker-wasm.js' : '/gif.worker.js',
    });

    let finishArgs = [];
    let finishResolver;
    const finishPromise = new Promise((resolve) => finishResolver = resolve);

    // For some reason, `on('finished)` can run multiple times. We only need it once.
    let hasFinishedRan = false;
    gif.on('finished', (blob) => {
        if (!hasFinishedRan) {
            Promise.resolve(callback(blob, ...finishArgs)).then(() => finishResolver());
            hasFinishedRan = true;
        }
    });

    const addFrame = (delay) => {
        // Debug helper: Copy the base64 url to a new tab to view the captured frame.
        gif.addFrame(canvasElement, { copy: true, delay });
    }

    return [addFrame, function() {  // Cannot use arrow-function here, we need to get `arguments`
        gif.render();
        finishArgs = arguments;
        return finishPromise;
    }];
}

export function capturePng(canvasElement) {
    return new Promise((resolve) => {
        canvasElement.toBlob(resolve, 'image/png');
    });
}

// Matches /functions/src/capture.js
export const SPECIAL_HEADLESS_CAPTURE_REF_VALUES = {
    CAPTURING: 'capturing',
    ERROR: 'error',
};
