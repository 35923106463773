import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawArcs(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokeCap, setStrokeCap] = useState("ROUND"); // or: "SQUARE" or "PROJECT"
    const [strokeWeight, setStrokeWeight] = useState(5);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function (x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let Arc = function (a, b, c, d, e, f, t1, t2, t3) {
        this.a = a;
        this.b = b;
        this.c = c;
        this.d = d;
        this.e = e;
        this.f = f;
        this.t1 = t1;
        this.t2 = t2;
        this.t3 = t3;
    }

    let curStroke = [];
    let arcs = [];

    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function findCenter(a, b, c, d, e, f) {
        // find point (p, q) equidistant from three points (a, b), (c, d), (e, f)
        // solution from: https://www.quora.com/Given-3-points-in-the-Cartesian-plane-how-can-you-find-the-coordinates-of-the-center-of-the-circle-that-intersects-all-three-points-if-there-exists-such-a-circle
        let p, q, u, v, w;
        u = 0.5 * (a * a + b * b - c * c - d * d);
        v = 0.5 * (a * a + b * b - e * e - f * f);
        w = (a - c) * (b - f) - (a - e) * (b - d);
        p = (u * (b - f) - v * (b - d)) / w;
        q = (v * (a - c) - u * (a - e)) / w;
        return [p, q];
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        curStroke = [];
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
            isDrawing = false;

            let a, b, c, d, e, f, l, mid, t1, t2, t3;
            if (curStroke.length >= 3) {
                l = curStroke.length;
                a = curStroke[0].x;
                b = curStroke[0].y;
                t1 = curStroke[0].t;
                mid = Math.round(l * .5);
                [c, d] = findAvgMidpoint();
                t2 = curStroke[mid].t;
                e = curStroke[l - 1].x;
                f = curStroke[l - 1].y;
                t3 = curStroke[l - 1].t;
                arcs.push(new Arc(a, b, c, d, e, f, t1, t2, t3));
            }
            curStroke = [];
        }
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempStrokeCap = strokeCap;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.strokeCap) {
                tempStrokeCap = parameters.strokeCap;
                setStrokeCap(tempStrokeCap);
            }
            if (parameters.strokeWeight) {
                setStrokeWeight(parameters.strokeWeight);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.rectMode(p5.CENTER);
        p5.ellipseMode(p5.CENTER);
        if (tempStrokeCap === "SQUARE") {
            p5.strokeCap(p5.SQUARE);
        } else if (tempStrokeCap === "PROJECT") {
            p5.strokeCap(p5.PROJECT);
        } else {
            p5.strokeCap(p5.ROUND);
        }
    };

    function drawArc(p5, a, b, c, d, e, f) {
        let p, q, dist, ang1, ang3;
        [p, q] = findCenter(a, b, c, d, e, f);
        if (drawCenter) {
            p5.fill(255, 0, 0);
            p5.noStroke();
            p5.ellipse(p, q, 10, 10);
        }

        p5.noFill();
        dist = getDistance(p, q, a, b);
        ang1 = Math.atan2(b - q, a - p);
        ang3 = Math.atan2(f - q, e - p);

        // from https://stackoverflow.com/questions/17592800/how-to-find-the-orientation-of-three-points-in-a-two-dimensional-space-given-coo
        // Let p, q, and r be the three poitns:
        // k=(q.y - p.y)*(r.x - q.x)-(q.x - p.x) * (r.y - q.y);
        // if(k==0): They are all colinear
        // if(k>0) : They are all clockwise
        // if(k<0) : They are counter clockwise

        p5.stroke(0);
        let k = (d - b) * (e - c) - (c - a) * (f - d);
        if (k >= 0) {
            // clockwise
            p5.arc(p, q, dist * 2, dist * 2, ang3, ang1);
        } else {
            // counter-clockwise
            p5.arc(p, q, dist * 2, dist * 2, ang1, ang3);
        }

        if (drawPoints) {
            p5.fill(0);
            p5.rect(a, b, 10, 10);
            p5.rect(c, d, 10, 10);
            p5.rect(e, f, 10, 10);
        }
    }


    const drawUnderlyingPath = false;
    const drawPoints = false;
    const drawCenter = false;

    function findAvgMidpoint() {
        // average of points from .25 to .75 of path
        let i1 = Math.round(curStroke.length * 0.25 + 0.5);
        let i2 = Math.round(curStroke.length * 0.75 + 0.5);
        if (i1 >= curStroke.length) i1 = curStroke.length - 1;
        if (i2 >= curStroke.length) i2 = curStroke.length - 1;
        let xsum = 0, ysum = 0;
        for (let i = i1; i <= i2; i++) {
            xsum += curStroke[i].x;
            ysum += curStroke[i].y;
        }
        return [xsum / (i2 - i1 + 1), ysum / (i2 - i1 + 1)];
    }


    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        if (drawUnderlyingPath) {
            p5.fill(170);
            p5.stroke(240);
            for (let i = 0; i < curStroke.length; i++) {
                p5.ellipse(curStroke[i].x, curStroke[i].y, 3, 3);
                if (i > 0) {
                    p5.line(curStroke[i - 1].x, curStroke[i - 1].y, curStroke[i].x, curStroke[i].y);
                }
            }
        }

        // draw current stroke
        let a, b, c, d, e, f, l;
        if (curStroke.length >= 3) {
            l = curStroke.length;
            a = curStroke[0].x;
            b = curStroke[0].y;
            [c, d] = findAvgMidpoint();
            e = curStroke[l - 1].x;
            f = curStroke[l - 1].y;
            p5.strokeWeight(1.6 * strokeWeight * scaleFactor);
            drawArc(p5, a, b, c, d, e, f);
        }

        // draw rest of arcs
        p5.strokeWeight(strokeWeight * scaleFactor);
        for (let i = 0; i < arcs.length; i++) {
            drawArc(p5, arcs[i].a, arcs[i].b, arcs[i].c, arcs[i].d, arcs[i].e, arcs[i].f);
        }
    };

    const clearDrawingHandler = () => {
        arcs = [];
        return true;
    }

    const undoHandler = () => {
        if (arcs.length >= 1) {
            arcs.splice(arcs.length - 1);
        }
    }

    function scaleValues(arc) {
        return (new Arc(
            arc.a / scaleFactor, arc.b / scaleFactor,
            arc.c / scaleFactor, arc.d / scaleFactor,
            arc.e / scaleFactor, arc.f / scaleFactor,
            arc.t1, arc.t2, arc.t3
        ));
    }

    const submitButtonHandler = () => {
        props.parentCallback(arcs.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawArcs;
