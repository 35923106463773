import React, { useEffect, useState } from 'react';
import { Redirect, Switch, BrowserRouter } from 'react-router-dom';
import * as authService from '../services/auth';
import * as trackingService from '../services/tracking';
import * as firebaseService from '../services/firebase';
// import { useNowWithUrlOverride } from '../services/hooks';
import { joinSavedChallengeId, addToMarketingAfterGoogleSignin } from '../services/shared.js';

import AuthedRoute from './AuthedRoute';
import ScrollToTop from './UI/ScrollToTop';
import Header from './UI/Header';
import Footer from './UI/Footer';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import EditAvatar from './pages/EditAvatar';
import DrawPage from './pages/DrawPage';
import ReviewPage from './pages/ReviewPage';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import EditChallenge from './pages/EditChallenge';
import EditPrompt from './pages/EditPrompt';
import Landing from './pages/Landing';
import LandingSample from './pages/LandingSample';
import Challenge from './pages/Challenge';
import Admin from './pages/Admin';
import Spinner from './UI/Spinner';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
// import DailyPlay from './pages/DailyPlay';
// import DailyPlayWeek from './pages/DailyPlayWeek';
import FAQ from './pages/FAQ';
import CaptureDebug from './pages/CaptureDebug';
import ErrorBoundary from './ErrorBoundary.js';
import classes from './App.module.css';

function App() {
    const [isReadyToRender, setIsReadyToRender] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    // const now = useNowWithUrlOverride();

    useEffect(() => {
        authService.getCurrentUserCallback(() => {
            const user = authService.getCurrentUserDirect();
            setIsUserLoggedIn(!!user);
            setIsReadyToRender(true);

            if (user) {
                firebaseService.updateProfile({ timezoneOffset: new Date().getTimezoneOffset() });
                trackingService.setUser(user);

                /* Methods for finishing the signup flow of Google signin.
                 * After sign up, these information are saved in localStorage and called here after the Google redirect. */
                joinSavedChallengeId();
                addToMarketingAfterGoogleSignin();
            }
        });
        authService.checkRedirectLogin();
    }, []);

    if (!isReadyToRender) {
        return <Spinner />;
    }

    return (
        <BrowserRouter>
            <ScrollToTop />
            <div className={classes.pageContainer}>
                <div className={classes.content}>
                    <Header isUserLoggedIn={isUserLoggedIn}></Header>
                    <ErrorBoundary>
                        <Switch>
                            {/* The `pageRole` is the permission required for the user's role to view the page
                              * - empty: Anyone, even not logged-in, can view the page
                              * - user: Any logged in user can view (all users are creators at this moment)
                              * - creator: Logged in user, with creator role can view
                              * - admin: Only admins can view */}

                            {/* Landing */}
                            <AuthedRoute path='/' exact component={Landing}/>
                            <AuthedRoute path='/sample-drawing' exact component={LandingSample}/>

                            {/* Auth pages */}
                            <AuthedRoute path='/signup' exact component={Login}/>
                            <AuthedRoute path='/login' exact component={Login}/>
                            <AuthedRoute path='/forgot' exact component={ResetPassword}/>

                            {/* User's own pages */}
                            <AuthedRoute path='/home' exact component={Home} pageRole='user'/>
                            <AuthedRoute path='/profile' exact component={Profile} pageRole='user'/>
                            <AuthedRoute path='/p/:userId' exact component={Profile}/> {/* User id or username. Also availeble as `/:userId` */}
                            <AuthedRoute path='/settings' exact component={Settings} pageRole='user'/>
                            <AuthedRoute path='/edit-image' exact component={EditAvatar} pageRole='user'/>

                            {/* Daily challenge pages */}
                            {/* TODO: overview page <AuthedRoute path='/daily' exact component={} /> */}
                            {/* <Redirect from='/daily/today' to={{ pathname: `/daily/${dateToYyyymmdd(now, DAILY_CHALLENGE_TIMEZONE_OFFSET_IN_MIN)}`, search: window.location.search }} />
                            <Redirect from='/daily/yesterday' to={{ pathname: `/daily/${dateToYyyymmdd(new Date(now.getTime() - ONE_DAY_IN_MILLISECONDS), DAILY_CHALLENGE_TIMEZONE_OFFSET_IN_MIN)}/view`, search: window.location.search }} />
                            <AuthedRoute path='/daily/:date' exact component={DailyPlay} pageRole='user' />
                            <AuthedRoute path='/daily/:date/view' exact component={DailyPlay} pageRole='user' />
                            <AuthedRoute path='/daily/:year/week/:week' exact component={DailyPlayWeek} /> */}

                            {/* Normal challenge participation pages */}
                            <AuthedRoute path='/c/:challengeId' exact component={Challenge}/>
                            <AuthedRoute path='/challenge/:challengeId' exact component={Challenge}/>
                            <AuthedRoute path='/c/:challengeId/:promptIndex/view' exact component={ReviewPage}/> {/* Redirect to the challenge page inside the router for non user */}
                            <AuthedRoute path='/challenge/:challengeId/:promptIndex/view' exact component={ReviewPage}/> {/* Redirect to the challenge page inside the router for non user */}
                            <AuthedRoute path='/c/:challengeId/:promptIndex' exact component={DrawPage}/> {/* Redirect to the challenge page inside the router for non user */}
                            <AuthedRoute path='/challenge/:challengeId/:promptIndex' exact component={DrawPage}/> {/* Redirect to the challenge page inside the router for non user */}

                            {/* Creation pages */}
                            <AuthedRoute path='/create-prompt' exact component={EditPrompt} pageRole='admin'/>
                            <AuthedRoute path='/edit-prompt/:promptId' exact component={EditPrompt} pageRole='admin'/>
                            <AuthedRoute path='/create-challenge' exact component={EditChallenge} pageRole='creator'/>
                            <AuthedRoute path='/edit-challenge/:challengeId' exact component={EditChallenge} pageRole='creator'/>

                            {/* Admin/hidden pages */}
                            <AuthedRoute path='/_admin' exact component={Admin} pageRole='admin' />
                            <AuthedRoute path='/_debug/capture' exact component={CaptureDebug} /> {/* Headless browser (not logged in) needs to access it */}

                            {/* Informational pages */}
                            <AuthedRoute path='/about' exact component={Landing}/>
                            <AuthedRoute path='/terms' exact component={Terms}/>
                            <AuthedRoute path='/privacy' exact component={Privacy}/>
                            <AuthedRoute path='/faq' exact component={FAQ}/>

                            {/* NOTE: All the top level routes should be added to invalid username list in firebase.js */}
                            {/* Keep this line as the last route, since this is a top level wildcard */}
                            <AuthedRoute path='/:userId' exact component={Profile}/> {/* User id or username. Also available as `/p/:userId` */}

                            <Redirect to={{ pathname: '/', search: window.location.search }} />
                        </Switch>
                    </ErrorBoundary>
                </div>
                <Footer className={classes.footer} />
            </div>
        </BrowserRouter>
    );
}

export default App;
