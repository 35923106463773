import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseService from './firebase';

export const DEFAULT_PROFILE = {
    role: 'creator',
    avatarPath: 'https://firebasestorage.googleapis.com/v0/b/ydays-618cd.appspot.com/o/avatars%2Fdefault.png?alt=media&token=1c196653-288f-4b7f-9089-ac4775330823',
    allowReminderEmail: true,
}

const actionCodeSettings = { url: 'https://ydays.com/home' };

export function signUpEmail(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password).then((result) => {
        const user = result.user;
        return user.sendEmailVerification(actionCodeSettings).then(() => {
            return firebaseService.updateProfile({
                ...DEFAULT_PROFILE,
                email: user.email,
                name: user.email.replace(/@.*/, ''),
                createdTimestamp: new Date(user?.metadata?.creationTime || Date.now()).getTime(),
            });
        });
    });
}

export function resendVerificationEmail() {
    return getCurrentUserDirect().sendEmailVerification(actionCodeSettings);
}

export function signInEmail(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
}

export function sendPasswordResetEmail(email) {
    return firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
}

export function signout() {
    return firebase.auth().signOut();
}

export function signInGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
}

export function checkRedirectLogin() {
    return firebase.auth().getRedirectResult().then((result) => {
        if (result.user) {
            return firebaseService.loadProfile(result.user.uid).then((profile) => {
                if (!profile) {  // This is first time login, initialize the profile in DB
                    return firebaseService.updateProfile({
                        ...DEFAULT_PROFILE,
                        email: result.user.email,
                        name: result.user.displayName,
                        createdTimestamp: new Date(result.user?.metadata?.creationTime || Date.now()).getTime(),
                    });
                }
            })
        }
    });
}

// This accepts a setter callback, and it's updates with the change. This is currently only used at the App component.
export function getCurrentUserCallback(callback) {
    firebase.auth().onAuthStateChanged((user) => {
        if (!user) {
            callback();
        } else {
            user.reload().then(callback);
        }
    });
}

// This only gets the current state of the value, and it is null before auth init finishes.
export function getCurrentUserDirect() {
    const user = firebase.auth().currentUser;
    return user;
}
