import React, {useState, useEffect} from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawBeforeAfter(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [imagePath, setImagePath] = useState("https://firebasestorage.googleapis.com/v0/b/ydays-618cd.appspot.com/o/assets%2Fbefore-after.png?alt=media&token=9294467e-65ba-4270-aa60-bad5acfa0e64");
    const [imgSrc, setImgSrc] = useState(null);
    const [strokew, setStrokew] = useState(5);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let strokes = [];
    let curStroke = [];
    let isDrawing = false;
    let bgImage;
    let isImageLoaded = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) return;
        isDrawing = true;

        if (isDrawing && curStroke.length > 0) {
            strokes.push(curStroke);
            curStroke = [];
        }
        strokes.push(curStroke);
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            strokes.pop();
            strokes.push(curStroke);
            curStroke = [];
            isDrawing = false;
        }
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }

    const preload = (p5) => {
        let parameters = {};
        let tempImagePath = imagePath;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.imagePath) {
                tempImagePath = parameters.imagePath;
                setImagePath(parameters.imagePath);
            }
            if (parameters.strokew) {
                setStrokew(parameters.strokew);
            }
        }
        setImgSrc(p5.loadImage(tempImagePath, " "));
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);

        const tempScaleFactor = props.displaySize / 500.;
        const tempStrokew = strokew * tempScaleFactor;
        setScaleFactor(tempScaleFactor); // apply this scale factor to strokeWeight
        setStrokew(tempStrokew);
        p5.strokeCap(p5.ROUND);
        p5.strokeJoin(p5.ROUND);
        p5.strokeWeight(tempStrokew);
        p5.noFill();
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        if (isImageLoaded) {
            p5.image(bgImage, 0, 0, p5.width, p5.height);
        } else {
            // Load source image and make a copy of pixels into bgImage
            const d = p5.pixelDensity();
            p5.image(imgSrc, 0, 0, p5.width, p5.height);
            p5.loadPixels();
            bgImage = p5.createImage(d * p5.width, d * p5.height);
            bgImage.loadPixels();
            for (let i = 0; i < d * p5.width; i++) {
                for (let j = 0; j < d * p5.height; j++) {
                    let index = 4 * (j * p5.width * d + i);
                    bgImage.set(i, j, p5.color(p5.pixels[index], p5.pixels[index + 1], p5.pixels[index + 2], 255));
                }
            }
            bgImage.updatePixels();
            isImageLoaded = true;
        }

        p5.strokeWeight(strokew);
        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    p5.curveVertex(strokes[i][j].x, strokes[i][j].y);
                }
                p5.endShape();
            }
        }
    };

    const clearDrawingHandler = () => {
        strokes = [];
        return true;
    }

    const undoHandler = () => {
        if (strokes.length >= 1) {
            strokes.splice(strokes.length - 1);
        }
    }

    function scaleValues(stroke) {
        let newStroke = [];
        for (let i = 0; i < stroke.length; i++) {
            newStroke.push(new Point(
                stroke[i].x / scaleFactor,
                stroke[i].y / scaleFactor,
                stroke[i].t
            ));
        }
        return newStroke;
    }

    const submitButtonHandler = () => {
        // props.parentCallback("repeaterArray", strokes.map(scaleValues), '/repeater.json');
        props.parentCallback(strokes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
           {renderP5 && <Sketch
                preload={preload}
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawBeforeAfter;
