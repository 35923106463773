import P5DrawCircles from '../components/P5js/P5DrawCircles';
import P5DrawRepeater from '../components/P5js/P5DrawRepeater';
import P5DrawGridC from '../components/P5js/P5DrawGridC';
import P5DrawArcs from '../components/P5js/P5DrawArcs';
import P5DrawSplatter3 from '../components/P5js/P5DrawSplatter3';
import P5DrawKeyFrameLoop from '../components/P5js/P5DrawKeyFrameLoop';
import P5DrawGradientRects from '../components/P5js/P5DrawGradientRects';
import P5DrawMandalaRepeater from '../components/P5js/P5DrawMandalaRepeater';
import P5DrawFilledClosedShapes from '../components/P5js/P5DrawFilledClosedShapes';
import P5DrawOffKilterGrid from '../components/P5js/P5DrawOffKilterGrid';
import P5DrawTriangleWedges from '../components/P5js/P5DrawTriangleWedges';
import P5DrawColoredDots from '../components/P5js/P5DrawColoredDots';
import P5DrawColoredSquares from '../components/P5js/P5DrawColoredSquares';
import P5DrawBeforeAfter from '../components/P5js/P5DrawBeforeAfter';
import P5DrawGridTriangles from '../components/P5js/P5DrawGridTriangles';
import P5DrawGridCirclesCorners from '../components/P5js/P5DrawGridCirclesCorners';
import P5DrawFlowingPen from '../components/P5js/P5DrawFlowingPen';
import P5DrawWavyPen from '../components/P5js/P5DrawWavyPen';
import P5DrawRotatingStrokes from '../components/P5js/P5DrawRotatingStrokes';
import P5DrawTangram from '../components/P5js/P5DrawTangram';
import P5DrawRightTriangles from '../components/P5js/P5DrawRightTriangles';
import P5DrawCirclesSquares from '../components/P5js/P5DrawCirclesSquares';
import P5DrawGridRadial from '../components/P5js/P5DrawGridRadial';
import P5DrawInkStroke from '../components/P5js/P5DrawInkStroke';
import P5DrawLetterShapes from '../components/P5js/P5DrawLetterShapes';
import P5DrawArcGridConnector from '../components/P5js/P5DrawArcGridConnector';
import P5DrawFatThinLines from '../components/P5js/P5DrawFatThinLines';
import P5DrawReflection from '../components/P5js/P5DrawReflection';
import P5DrawAngleLines from '../components/P5js/P5DrawAngleLines';
import P5DrawHatchLines from '../components/P5js/P5DrawHatchLines';
import P5DrawAlternatingPen from '../components/P5js/P5DrawAlternatingPen';
import P5DrawWiggle from '../components/P5js/P5DrawWiggle';

import P5DisplayCircles from '../components/P5js/P5DisplayCircles';
import P5DisplayRepeater from '../components/P5js/P5DisplayRepeater';
import P5DisplayGridC from '../components/P5js/P5DisplayGridC';
import P5DisplayArcs from '../components/P5js/P5DisplayArcs';
import P5DisplaySplatter3 from '../components/P5js/P5DisplaySplatter3';
import P5DisplayKeyFrameLoop from '../components/P5js/P5DisplayKeyFrameLoop';
import P5DisplayGradientRects from '../components/P5js/P5DisplayGradientRects';
import P5DisplayMandalaRepeater from '../components/P5js/P5DisplayMandalaRepeater';
import P5DisplayFilledClosedShapes from '../components/P5js/P5DisplayFilledClosedShapes';
import P5DisplayOffKilterGrid from '../components/P5js/P5DisplayOffKilterGrid';
import P5DisplayTriangleWedges from '../components/P5js/P5DisplayTriangleWedges';
import P5DisplayColoredDots from '../components/P5js/P5DisplayColoredDots';
import P5DisplayColoredSquares from '../components/P5js/P5DisplayColoredSquares';
import P5DisplayBeforeAfter from '../components/P5js/P5DisplayBeforeAfter';
import P5DisplayGridTriangles from '../components/P5js/P5DisplayGridTriangles';
import P5DisplayGridCirclesCorners from '../components/P5js/P5DisplayGridCirclesCorners';
import P5DisplayFlowingPen from '../components/P5js/P5DisplayFlowingPen';
import P5DisplayWavyPen from '../components/P5js/P5DisplayWavyPen';
import P5DisplayRotatingStrokes from '../components/P5js/P5DisplayRotatingStrokes';
import P5DisplayTangram from '../components/P5js/P5DisplayTangram';
import P5DisplayRightTriangles from '../components/P5js/P5DisplayRightTriangles';
import P5DisplayCirclesSquares from '../components/P5js/P5DisplayCirclesSquares';
import P5DisplayGridRadial from '../components/P5js/P5DisplayGridRadial';
import P5DisplayInkStroke from '../components/P5js/P5DisplayInkStroke';
import P5DisplayLetterShapes from '../components/P5js/P5DisplayLetterShapes';
import P5DisplayArcGridConnector from '../components/P5js/P5DisplayArcGridConnector';
import P5DisplayFatThinLines from '../components/P5js/P5DisplayFatThinLines';
import P5DisplayReflection from '../components/P5js/P5DisplayReflection';
import P5DisplayAngleLines from '../components/P5js/P5DisplayAngleLines';
import P5DisplayHatchLines from '../components/P5js/P5DisplayHatchLines';
import P5DisplayAlternatingPen from '../components/P5js/P5DisplayAlternatingPen';
import P5DisplayWiggle from '../components/P5js/P5DisplayWiggle';

export function getP5Tool(name, isDisplay) {
    switch (name) {
        case 'P5Circles':
            return isDisplay ? P5DisplayCircles : P5DrawCircles;
        case 'P5WavyPen':
            return isDisplay ? P5DisplayWavyPen : P5DrawWavyPen;
        case 'P5Wiggle':
            return isDisplay ? P5DisplayWiggle : P5DrawWiggle;
        case 'P5TriangleWedges':
            return isDisplay ? P5DisplayTriangleWedges : P5DrawTriangleWedges;
        case 'P5OffKilterGrid':
            return isDisplay ? P5DisplayOffKilterGrid : P5DrawOffKilterGrid;
        case 'P5CirclesSquares':
            return isDisplay ? P5DisplayCirclesSquares : P5DrawCirclesSquares;
        case 'P5ColoredSquares':
            return isDisplay ? P5DisplayColoredSquares : P5DrawColoredSquares;
        case 'P5Repeater':
            return isDisplay ? P5DisplayRepeater : P5DrawRepeater;
        case 'P5Reflection':
            return isDisplay ? P5DisplayReflection : P5DrawReflection;
        case 'P5GridC':
            return isDisplay ? P5DisplayGridC : P5DrawGridC;
        case 'P5Arcs':
            return isDisplay ? P5DisplayArcs : P5DrawArcs;
        case 'P5Splatter3':
            return isDisplay ? P5DisplaySplatter3 : P5DrawSplatter3;
        case 'P5FatThinLines':
            return isDisplay ? P5DisplayFatThinLines : P5DrawFatThinLines;
        case 'P5AngleLines':
            return isDisplay ? P5DisplayAngleLines : P5DrawAngleLines;
        case 'P5RotatingStrokes':
            return isDisplay ? P5DisplayRotatingStrokes : P5DrawRotatingStrokes;
        case 'P5FilledClosedShapes':
            return isDisplay ? P5DisplayFilledClosedShapes : P5DrawFilledClosedShapes;
        case 'P5Tangram':
            return isDisplay ? P5DisplayTangram : P5DrawTangram;
        case 'P5LetterShapes':
            return isDisplay ? P5DisplayLetterShapes : P5DrawLetterShapes;
        case 'P5GridTriangles':
            return isDisplay ? P5DisplayGridTriangles : P5DrawGridTriangles;
        case 'P5KeyFrameLoop':
            return isDisplay ? P5DisplayKeyFrameLoop : P5DrawKeyFrameLoop;
        case 'P5HatchLines':
            return isDisplay ? P5DisplayHatchLines : P5DrawHatchLines;
        case 'P5InkStroke':
            return isDisplay ? P5DisplayInkStroke : P5DrawInkStroke;
        case 'P5Gradients':
            return isDisplay ? P5DisplayGradientRects : P5DrawGradientRects;
        case 'P5GridCirclesCorners':
            return isDisplay ? P5DisplayGridCirclesCorners : P5DrawGridCirclesCorners;
        case 'P5RightTriangles':
            return isDisplay ? P5DisplayRightTriangles : P5DrawRightTriangles;
        case 'P5AlternatingPen':
            return isDisplay ? P5DisplayAlternatingPen : P5DrawAlternatingPen;
        case 'P5ColoredDots':
            return isDisplay ? P5DisplayColoredDots : P5DrawColoredDots;
        case 'P5FlowingPen':
            return isDisplay ? P5DisplayFlowingPen : P5DrawFlowingPen;
        case 'P5ArcGridConnector':
            return isDisplay ? P5DisplayArcGridConnector : P5DrawArcGridConnector;
        case 'P5GridRadial':
            return isDisplay ? P5DisplayGridRadial : P5DrawGridRadial;
        case 'P5MandalaRepeater':
            return isDisplay ? P5DisplayMandalaRepeater : P5DrawMandalaRepeater;
        case 'P5BeforeAfter':
            return isDisplay ? P5DisplayBeforeAfter : P5DrawBeforeAfter;
        default:
            console.error(`Unknown promopt component ${name}`)
    }
}

// Hard coding the list of all our drawing tools, in preferred order
export const TOOLS_IN_ORDER = [
    'P5Circles', 'P5WavyPen',
    'P5Wiggle',
    'P5TriangleWedges', 'P5OffKilterGrid',
    'P5CirclesSquares',
    'P5ColoredSquares', 'P5Repeater',
    'P5Reflection',
    'P5GridC', 'P5Arcs',
    'P5Splatter3',
    'P5FatThinLines', 'P5AngleLines',
    'P5RotatingStrokes',
    'P5FilledClosedShapes', 'P5Tangram', 'P5LetterShapes',
    'P5GridTriangles', 'P5KeyFrameLoop',
    'P5HatchLines',
    'P5InkStroke',
    'P5Gradients', 'P5GridCirclesCorners',
    'P5RightTriangles',
    'P5AlternatingPen',
    'P5ColoredDots',
    'P5FlowingPen', 'P5ArcGridConnector',
    'P5GridRadial',
    'P5MandalaRepeater', 'P5BeforeAfter',
];
