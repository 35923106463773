import React, { useState, useEffect } from 'react';
import classes from './ProgressTable.module.css';
import * as firebaseService from '../../services/firebase.js';
import { ONE_DAY_IN_MILLISECONDS, getTimeDiff } from '../../services/shared';
import UserTitle from './UserTitle';

function ProgressTable(props) {
    const [tableData, setTableData] = useState([]);  // Array holds {id, name, isHost, submittedStatus: Array<true|false|null>}
    const [emojisGiven, setEmojisGiven] = useState([]);  // Array holds Objects { uid: [emoji list], uid: [emoji list], etc } for each member
    const [hasEnded, setHasEnded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const MAX_INITIAL_DISPLAY = 20;

    // These are arrays in case of ties
    const [topRecipients, setTopRecipients] = useState([]); // { id, name, avatarPath, username, emojisReceived, numReceived }
    const [topGivers, setTopGivers] = useState([]); // { id, name, avatarPath, username, emojisGiven, numGiven }
    const [perfectAttendees, setPerfectAttendees] = useState([]);

    useEffect(() => {
    // Need this temp value, because `hasEnded` is not updated here immediately after calling `setHasEnded`
        setHasEnded(getTimeDiff(props.challenge.startTimestamp, props.challenge.lengthInDays) > 0);
    }, [props.challenge]);

    useEffect(() => {
        let hasUnmounted = false;
        Promise.all(props.challenge.members.map(async (uid) => ({ id: uid, obj: await firebaseService.loadProfile(uid) })))
            .then((members) => {
                if (members.length > MAX_INITIAL_DISPLAY) {
                    if (hasUnmounted) return;
                    setIsTruncated(true);
                }
                Promise.all(members.map(async (member) => {
                    const submittedStatusArray = getSubmittedStatusArray(member.id);
                    const isPerfect = submittedStatusArray.every(Boolean);
                    return {
                        id: member.id,
                        name: member.obj?.name,
                        avatarPath: member.obj?.avatarPath,
                        username: member.obj?.username,
                        isHost: member.id === props.challenge.author,
                        submittedStatus: getSubmittedStatusArray(member.id),
                        perfectAttendance: isPerfect,
                        emojisReceived: hasEnded ? await getEmojisReceived(member.id) : [],
                    }
                })).then((data) => {
                    if (hasUnmounted) return;
                    setTableData(data);
                });
            });
        return () => { hasUnmounted = true; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.challenge, hasEnded]);

    useEffect(() => {
        if (props.compactView || !hasEnded) return;
        // emojisGiven is set row by row, we only want to run this after emojisGiven is completed populating
        if (emojisGiven.length < tableData.length) return;

        // Now that tableData is set, emojisGiven should be set too
        // Populate the recognition in state
        let userRows = tableData.map(row => ({ ...row }));
        // Iterate through emojisGiven and tally per user, add as property to users
        for (let i = 0; i < emojisGiven.length; i++) {
            for (const [userid, emojis] of Object.entries(emojisGiven[i])) {
                let user = userRows.find(row => row.id === userid);
                // TODO: This is a temp fix. The real issus is state management, including calling `setEmojisGiven` repeatedly in a loop. (why user can be undefined is hard to debug without cleaning up this first)
                if (user) {
                    if (!user.emojisGiven) user.emojisGiven = [];
                    user.emojisGiven = [...user.emojisGiven, emojis];
                }
            }
        }
        // Find max values
        const maxReceived = Math.max.apply(Math, userRows.map(function(user) {
            return user.emojisReceived ? user.emojisReceived.flat().length : 0
        }));
        const maxGiven = Math.max.apply(Math, userRows.map(function(user) {
            return user.emojisGiven ? user.emojisGiven.flat().length : 0
        }));
        // Go back through users and add users who match
        let tempTopRecipients = [];
        let tempTopGivers = [];
        let tempPerfectAttendees = [];
        userRows.forEach(user => {
            if (user.emojisGiven && user.emojisGiven.flat().length === maxGiven) {
                // { id, name, avatarPath, emojisGiven, numGiven }
                tempTopGivers.push({
                    id: user.id,
                    name: user.name,
                    avatarPath: user.avatarPath,
                    username: user.username,
                    emojisGiven: user.emojisGiven.flat(),
                    numGiven: user.emojisGiven.flat().length
                })
            }
            if (maxReceived && user.emojisReceived && user.emojisReceived.flat().length === maxReceived) {
                // { id, name, avatarPath, emojisReceived, numReceived }
                tempTopRecipients.push({
                    id: user.id,
                    name: user.name,
                    avatarPath: user.avatarPath,
                    username: user.username,
                    emojisReceived: user.emojisReceived.flat(),
                    numReceived: user.emojisReceived.flat().length
                })
            }
            if (user.perfectAttendance) {
                tempPerfectAttendees.push({
                    id: user.id,
                    name: user.name,
                    avatarPath: user.avatarPath,
                    username: user.username,
                })
            }
        });
        setTopGivers(tempTopGivers);
        setTopRecipients(tempTopRecipients);
        setPerfectAttendees(tempPerfectAttendees);
    }, [tableData, hasEnded, props.compactView, emojisGiven]);

    const getSubmittedStatusArray = (uid) => {
        const result = [];
        for (let day = 0; day < props.challenge.prompts.length; day++) {
            if (props.challenge.prompts[day].submissions?.[uid]?.length) {
                result.push(true);
                continue;
            }

            const diff = getTimeDiff(props.challenge.startTimestamp, day);
            if (diff > ONE_DAY_IN_MILLISECONDS) {
                result.push(false);
            } else {
                result.push(null);
            }
        }
        return result;
    }

    const getEmojisReceived = (uid) => {
    // This is counting votes for the latest submission
        const workIdsFromThisAuthor = props.challenge.prompts.map((oneDay) => oneDay.submissions?.[uid] ? oneDay.submissions[uid][oneDay.submissions[uid].length - 1] : null);
        return Promise.all(workIdsFromThisAuthor.map((workId) => workId ? firebaseService.loadWork(workId) : null))
            .then((works) => {
                // stores received emojis in a "row" array, one per day
                let emojisReceivedArray = [];
                // this gathers the emojis given to the user with uid, storing them in object with uid/emojis key/value pairs
                let emojisGivenByUser = {}; // [];

                for (let k = 0; k < works.length; k++) {
                    let work = works[k];
                    if (work) {
                        let emojisReceived = [];

                        if (work.reactions) {
                            for (const [key, value] of Object.entries(work.reactions)) {
                                for (let i = 0; i < value.length; i++) {
                                    // key is emoji, value[i] is user id of the emoji giver
                                    emojisReceived.push(key);

                                    const userid = value[i];
                                    let emojiArray;
                                    const userEmojis = emojisGivenByUser[userid];
                                    if (userEmojis != null) {
                                        emojiArray = [...userEmojis, key];
                                    } else {
                                        emojiArray = [key];
                                    }
                                    emojisGivenByUser[userid] = emojiArray;
                                }
                            }
                        }
                        emojisReceivedArray.push(emojisReceived);
                    } else {
                        emojisReceivedArray.push([]);
                    }
                }
                // merge the emojisGivenByUser into emojisGiven in state
                setEmojisGiven(emojisGiven => emojisGiven.concat(emojisGivenByUser));
                return emojisReceivedArray;
            });
    }

    const handleShowAllMembers = () => {
        setIsTruncated(false);
    }

    const progressTable = <table className={classes.progress}>
        <thead>
            <tr>
                <th className={classes.day}>Day:</th>
                {props.challenge?.prompts?.map((_, index) =>
                    <th key={index}>{index + 1}
                    </th>)}
                {hasEnded && !props.compactView ? <th className={classes.totals}>Total emojis</th> : null}
            </tr>
        </thead>
        <tbody>
            {tableData.map((row, index) => {
                if (!row) return null;
                else if (!isTruncated || (isTruncated && index < MAX_INITIAL_DISPLAY)) return <tr key={index}>
                    <td className={classes.username}>
                        <UserTitle profile={row}>
                            {row.isHost && <span className={classes.host}>HOST</span>}
                        </UserTitle>
                    </td>
                    {row.submittedStatus.map((status, index) => {
                        return <td key={index}
                            className={`${status ? classes.submitted : status === false ? classes.skipped : classes.na} ${hasEnded && !props.compactView ? classes.showVotes : ''}`}>
                            {hasEnded && !props.compactView && status && (row.emojisReceived?.[index]?.length || 0)}
                        </td>
                    })}
                    {hasEnded && !props.compactView && row.emojisReceived &&
                        <td className={classes.totals}>{row.emojisReceived.flat().length}</td>}
                </tr>
                else return null;
            })}
        </tbody>
        {isTruncated ?
            <tfoot>
                <tr>
                    <td colSpan="2">
                        <span className={classes.textLink} onClick={handleShowAllMembers}>Show {tableData.length - MAX_INITIAL_DISPLAY} more</span>
                    </td>
                </tr>
            </tfoot>
            : null}
    </table>

    return props.compactView ?
        progressTable
        :
        <>
            {
                // TODO: Break this component down to participants and recognition
                props.hideParticipants ? null :
                    <div className={`${classes.contentBox} ${classes.members}`}>
                        <h2>Participants</h2>
                        {progressTable}
                    </div>
            }
            {hasEnded ?
                <div className={classes.contentBox}>
                    <h2>Recognition</h2>
                    <div className={classes.emojiAwards}>
                        <div className={classes.emojiSection}>
                            <h3 className={classes.recognition}>Recipient of most emojis:</h3>
                            {topRecipients?.length ?
                                topRecipients.map((user, index) => <div key={index} className={classes.userLarge}>
                                    <div className={classes.avatarLargeHolder}>
                                        <img src={user.avatarPath} width={80} height={80} className={classes.avatarLarge} alt='profile' />
                                        <i className={classes.awardRibbon}></i>
                                    </div>
                                    <div className={classes.userLargeRightSide}>
                                        <h4><UserTitle profile={user} noImage /></h4>
                                        <p>Received {user.numReceived} emojis</p>
                                        <p>{user.emojisReceived}</p>
                                    </div>
                                </div>)
                                :
                                <p>No one received emojis in this challenge. <span role="img" aria-label="sob">😭</span></p>
                            }
                        </div>
                        <div className={classes.emojiSection}>
                            <h3 className={classes.recognition}>Most generous giver of emojis:</h3>
                            {topGivers?.length ?
                                topGivers.map((user, index) => <div key={index} className={classes.userLarge}>
                                    <div className={classes.avatarLargeHolder}>
                                        <img src={user.avatarPath} width={80} height={80} className={classes.avatarLarge} alt='profile' />
                                        <i className={classes.awardRibbon}></i>
                                    </div>
                                    <div className={classes.userLargeRightSide}>
                                        <h4><UserTitle profile={user} noImage /></h4>
                                        <p>Gave {user.numGiven} emojis</p>
                                        <p>{user.emojisGiven}</p>
                                    </div>
                                </div>)
                                :
                                <p>No one gave emojis in this challenge. <span role="img" aria-label="cry">😢</span></p>
                            }
                        </div>
                    </div>
                    {perfectAttendees && perfectAttendees.length > 0 ?
                        <div>
                            <h3 className={classes.recognition}>Perfect attendance:</h3>
                            <div className={classes.attendanceAwards}>
                                {perfectAttendees.map((user, index) => <div key={index} className={classes.userMed}>
                                    <div className={classes.avatarMedHolder}>
                                        <img src={user.avatarPath} width={48} height={48} className={classes.avatarMed} alt='profile' />
                                        <i className={classes.awardStar}></i>
                                    </div>
                                    <div className={classes.userMedRightSide}>
                                        <h4><UserTitle profile={user} noImage /></h4>
                                        <p>Submitted {props.challenge.lengthInDays} days</p>
                                    </div>
                                </div>)}
                            </div>
                        </div> : null
                    }
                </div>
                : null}
        </>
    ;
}

export default ProgressTable;
