import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import classes from './Admin.module.css';
import CaptureCanvas from '../UI/CaptureCanvas';
import * as firebaseService from '../../services/firebase';

function CaptureDebug() {
    const [work, setWork] = useState(null);
    const [workId, setWorkId] = useState('');
    const [prompt, setPrompt] = useState(null);
    const [challenge, setChallenge] = useState(null);
    const [dayIndex, setDayIndex] = useState(null);
    const [message, setMessage] = useState('Loading...');
    const [hasError, setHasError] = useState(false);
    const [imageBlobUrl, setImageBlobUrl] = useState('');
    const [blob, setBlob] = useState(null);
    const [strokes, setStrokes] = useState('');
    const [uploadedUrl, setUploadedUrl] = useState('');
    const [fileExtension, setFileExtension] = useState('');
    const [withWatermark, setWithWatermark] = useState(false);
    const [size, setSize] = useState(500);

    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const workId = query.get('work');
        if (!workId) {
            setMessage('No work id found in the URL');
            return;
        }

        setWorkId(workId);
        setWithWatermark(query.get('watermark') === 'true');

        const size = Number(query.get('size'));
        if (!isNaN(size)) {
            setSize(size);
        }
    }, [location]);

    useEffect(() => {
        if (!workId) return;
        setMessage('Loading work...');
        firebaseService.loadWork(workId).then((work) => {
            if (!work) {
                setMessage(`No work matches the id ${workId}`);
                setHasError(true);
                return;
            }

            setWork(work);
        });
    }, [workId]);

    useEffect(() => {
        if (!work) return;

        if (!work.data || !work.data.strokesUrl) {
            setMessage(`Work has no data.
                Go to https://console.cloud.google.com/storage/browser/ydays-618cd.appspot.com/strokes,
                Search for the workId in the "Filter" field.
                If there's a file for it, add the "Authenticated URL" of the file as data.strokesUrl for this work in our database.
                If no file is found, then the user closed before saving anything, we cannot recover it.`);
            setHasError(true);
            return;
        }

        fetch(work.data.strokesUrl).then((res) => res.json()).then((data) => {
            setStrokes(data);
        });

        const promptMatch = new URLSearchParams(location.search).get('prompt');
        const promptId = work.promptId || (promptMatch && promptMatch[1]);

        if (!promptId) {
            setMessage('No prompt specified in this work. Please add it manually in the url. e.g. `/_debug/capture?work=1111111&prompt=22222222`');
            setHasError(true);
            return;
        }

        setMessage('Loading prompt...');
        firebaseService.loadPrompt(promptId).then((prompt) => {
            if (!prompt) {
                setMessage(`No prompt matches the id ${promptId}`);
                setHasError(true);
                return;
            }

            setPrompt(prompt);
            setMessage('Capturing...');
        });
    }, [work, location]);

    useEffect(() => {
        if (!work) return;

        firebaseService.loadChallenge(work.challengeId).then((challenge) => {
            if (!challenge) {
                setMessage(`No challenge matches the id ${work.challengeId}`);
                setHasError(true);
                return;
            }

            setChallenge(challenge);
        });
    }, [work]);

    useEffect(() => {
        if (!challenge || !work) return;

        setDayIndex(challenge.prompts.findIndex((day) => day.promptId === work.promptId));
    }, [challenge, work]);

    useEffect(() => {
        if (!prompt) return;

        setFileExtension(prompt.extraData.durationInSeconds ? 'gif' : 'png');
    }, [prompt]);

    const onCaptured = (blob) => {
        const url = URL.createObjectURL(blob);
        setBlob(blob);
        setImageBlobUrl(url);
        setMessage('Captured');
    };

    const upload = async () => {
        setMessage('Uploading...');
        const url = await firebaseService.saveBlob(blob, `works/${workId}-${new Date().getTime()}.${fileExtension}`, `image/${fileExtension}`);
        setUploadedUrl(url);
        setMessage('Uploaded');
        return url;
    };

    const saveForWork = async () => {
        const url = await upload();
        setMessage('Updating database...');
        await firebaseService.addWorkUrl(workId, url);
        setMessage('Saved!');
    };

    return (
        <div className={classes.content}>
            <div>Instruction:
                <ul>
                    <li>Set the work id in the url like "/_debug/capture?work=1111111" to capture an image normally.</li>
                    <li>Optional query: `&watermark=true`</li>
                    <li>Optional query: `&size=500` (or any number). It's the canvas size, the final image size is this number * screen pixel density</li>
                </ul>
            </div>
            <div>
                <div>
                    {work && prompt && strokes && challenge && <CaptureCanvas
                        workData={strokes}
                        prompt={prompt}
                        notUsed={dayIndex}  // Quick hack for the linter, delete when re-enable next line
                        // challengeDayParameters={challenge.prompts[dayIndex].promptParameters}
                        onCaptured={onCaptured}
                        size={size}
                        withWatermark={withWatermark} /> }
                </div>
                <div>
                    <p>
                        <span className={hasError ? 'output-error' : ''}>{message}</span>
                        {blob && <button onClick={saveForWork}>Save this image as the captured image of this work</button>}
                    </p>
                    <div>Information about the image:
                        <ul>
                            {/* This class name is read by the headless browser */}
                            {fileExtension && <li>fileExtension: <span className="output-file-extension">{fileExtension}</span></li>}
                            <li>Download URL: {uploadedUrl ? <a href={uploadedUrl} target="_blank" rel="noopener noreferrer">link</a> : 'Not uploaded yet'}</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* This class name is read by the headless browser */}
            {imageBlobUrl && <img className="ouput-image" src={imageBlobUrl} alt="captured" />}
        </div>
    );
}

export default CaptureDebug;
