import React, {useState, useEffect} from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawColoredSquares(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let Dot = function(x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    let curDot = [];
    let dots = [];

    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curDot.push(new Point(pX, pY, current(p5)));
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function getAngleInDegs(x1, y1, x2, y2) {
        return Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        curDot = [];
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
            isDrawing = false;

            let x1, y1, x2, y2;
            x1 = curDot[0].x;
            y1 = curDot[0].y;
            x2 = pX;
            y2 = pY;
            dots.push(new Dot(x1, y1, x2, y2));

            curDot = [];
        }
    }

    function drawDot(p5, x1, y1, x2, y2) {
        // find circle with these two points as diameter
        let mx = (x1 + x2) / 2;
        let my = (y1 + y2) / 2;
        let diam = getDistance(x1, y1, x2, y2);
        let ang = getAngleInDegs(x1, y1, x2, y2);
        let h = 0, s = 0, b = 0;

        const red = [0, 73, 90]; // north
        const yellow = [54, 88, 100]; // east
        const green = [145, 95, 73]; // south
        const blue = [220, 75, 100]; // west

        if (diam < 10) {
            h = 0;
            s = 0;
            b = 0;
        } else {
            let colorAmt = (diam - 10 * scaleFactor) / (70 * scaleFactor);
            if (colorAmt > 1) colorAmt = 1;

            let f;
            if (ang >= -90 && ang <= 0) {
                // red to yellow
                f = (ang + 90) / 90; // 0 to 1
                h = red[0] * (1 - f) + yellow[0] * f;
                s = red[1] * (1 - f) + yellow[1] * f;
                b = red[2] * (1 - f) + yellow[2] * f;
            } else if (ang >= 0 && ang <= 90) {
                // yellow to blue
                f = (ang) / 90; // 0 to 1
                h = yellow[0] * (1 - f) + green[0] * f;
                s = yellow[1] * (1 - f) + green[1] * f;
                b = yellow[2] * (1 - f) + green[2] * f;
            } else if (ang >= 90 && ang <= 180) {
                // blue to purple
                f = (ang - 90) / 90; // 0 to 1
                h = green[0] * (1 - f) + blue[0] * f;
                s = green[1] * (1 - f) + blue[1] * f;
                b = green[2] * (1 - f) + blue[2] * f;
            } else if (ang >= -180 && ang <= -90) {
                // purple to red
                f = (ang + 180) / 90; // 0 to 1
                h = blue[0] * (1 - f) + 360 * f;
                s = blue[1] * (1 - f) + red[1] * f;
                b = blue[2] * (1 - f) + red[2] * f;
            }
            b = colorAmt * b;
        }

        p5.fill(h, s, b);
        p5.rect(mx, my, diam, diam);
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }


    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.colorMode(p5.HSB);
        p5.rectMode(p5.CENTER);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.noStroke();

        // draw rest of dots
        for (let i = 0; i < dots.length; i++) {
            drawDot(p5, dots[i].x1, dots[i].y1, dots[i].x2, dots[i].y2);
        }

        // draw current dot
        if (curDot.length >= 1) {
            drawDot(p5, curDot[0].x, curDot[0].y, p5.mouseX, p5.mouseY);
        }
    }

    const clearDrawingHandler = () => {
        dots = [];
        return true;
    }

    const undoHandler = () => {
        if (dots.length >= 1) {
            dots.splice(dots.length - 1);
        }
    }

    function scaleValues(dot) {
        return (new Dot(
            dot.x1 / scaleFactor, dot.y1 / scaleFactor,
            dot.x2 / scaleFactor, dot.y2 / scaleFactor
        ));
    }

    const submitButtonHandler = () => {
        props.parentCallback(dots.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawColoredSquares;
