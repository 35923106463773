import React, { Component, useEffect, useState } from 'react';
import * as firebaseService from '../../services/firebase.js';
import Spinner from './Spinner';
import WorkGrid from './WorkGrid';
import UserTitle from './UserTitle';
import ErrorBoundary from '../ErrorBoundary.js';
import classes from './WorksTable.module.css';

const BY_DAY = 'BY_DAY';
const BY_MEMBER = 'BY_MEMBER';
const BY_EMOJI_COUNT = 'BY_EMOJI_COUNT';

class WorksTableChallenge extends Component {
    state = {
        challenge: null,
        prompts: [],
        sortMode: null,
        memberProfileMap: new Map(),
        latestDay: null,
        workIds: [],
        isReady: false,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.latestDayIndex !== this.props.latestDayIndex) {
            this.loadAllData();
        }
    }

    componentDidMount() {
        this.loadAllData();
    }

    loadAllData() {
        this.setState({
            challenge: null,
            prompts: [],
            workIds: [],
        });

        firebaseService.loadChallenge(this.props.challengeId)
            .then((challenge) => {
                const latestDay = Number(this.props.latestDayIndex) || challenge.prompts.length;
                this.setState({ challenge, latestDay });

                const loadPromptsPromise = Promise.all(
                    challenge.prompts.slice(0, latestDay).map((promptDay) => firebaseService.loadPrompt(promptDay.promptId))
                ).then((prompts) => {
                    this.setState({ prompts });
                });

                const loadMembersPromise = Promise.all(challenge.members.map(async (id) => ([id, await firebaseService.loadProfile(id)]))).then((entries) => {
                    this.setState({ memberProfileMap: new Map(entries) });
                })

                Promise.all([loadPromptsPromise, loadMembersPromise]).then(() => {
                    this.handleSortTab(BY_EMOJI_COUNT);
                    this.setState({ isReady: true });
                });
            })
    };

    handleSortTab(tabId) {
        if (this.state.sortMode === tabId) return;

        let allWorkIds = [];
        switch (tabId) {
            case BY_DAY:
                for (let i = 0; i < this.state.latestDay; i++) {
                    const worksThisDay = Object.values(this.state.challenge.prompts[i].submissions || {})
                        .map((list) => list[list.length - 1])
                        .flat()
                        .sort((a, b) => a > b ? -1 : 1);  // Make the order deterministic
                    allWorkIds = [...allWorkIds, ...worksThisDay];
                }
                break;
            case BY_MEMBER:
                // sort by author name - alphabetical
                const workByUsers = new Map();  // { userId, works }
                for (let i = 0; i < this.state.latestDay; i++) {
                    Object.entries(this.state.challenge.prompts[i].submissions || {}).forEach(([userId, workIds]) => {
                        if (!workByUsers.has(userId)) {
                            workByUsers.set(userId, []);
                        }
                        workByUsers.get(userId).push(workIds[workIds.length - 1]);
                    });
                }
                allWorkIds = Array.from(workByUsers.entries())
                    .sort((a, b) => this.state.memberProfileMap.get(a[0])?.name.toLowerCase() > this.state.memberProfileMap.get(b[0])?.name.toLowerCase() ? 1 : -1)
                    .map(([userId, workIds]) => workIds.sort((a, b) => a > b ? -1 : 1))  // Make the order deterministic)
                    .flat();
                break;
            case BY_EMOJI_COUNT:
                const combinedLeaderboard = this.state.challenge.prompts.map((day) => day.leaderboard).reduce((combined, current) => ({ ...combined, ...current }), {});
                // Add works with 0 vote
                for (let i = 0; i < this.state.latestDay; i++) {
                    Object.values(this.state.challenge.prompts[i].submissions || {})
                        .map((list) => list[list.length - 1])
                        .forEach((workId) => {
                            combinedLeaderboard[workId] = combinedLeaderboard[workId] || 0;
                        });
                }
                allWorkIds = Array.from(Object.entries(combinedLeaderboard))
                    .sort((a, b) => {
                        if (b[1] === a[1]) {
                            return a[0] > b[0] ? -1 : 1;  // Make the order deterministic)
                        } else {
                            return b[1] - a[1];
                        }
                    })
                    .map(([id, count]) => id);
                break;
            default:
                throw new Error(`Invalid sort type ${tabId}`);
        }
        this.setState({ sortMode: tabId, workIds: allWorkIds });
    }

    render() {
        if (!this.state.isReady) {
            return <div className={classes.Gallery}>
                <div className={classes.content}><Spinner /></div>
            </div>
        }

        if (!this.state.workIds.length) {
            return <div className={classes.Gallery}>
                <div className={classes.content}>No drawings have been submitted for this challenge yet.</div>
            </div>
        }

        return (
            <div className={classes.Works}>
                <div className={classes.content}>
                    {/* <h2 className={classes.promptTitle}>{this.state.prompt.title}</h2>
                    <p><span className={classes.promptInstructions}>{this.state.prompt.description}</span></p> */}
                    <div className={classes.headerTop}>
                        <h2 className={classes.promptTitle}>Explore</h2>
                        <div className={classes.tabs}>
                            <button
                                className={`${classes.tab} ${this.state.sortMode === BY_DAY ? classes.selected : ''}`}
                                onClick={() => this.handleSortTab(BY_DAY)}>By day</button>
                            <button
                                className={`${classes.tab} ${this.state.sortMode === BY_MEMBER ? classes.selected : ''}`}
                                onClick={() => this.handleSortTab(BY_MEMBER)}>By participant</button>
                            <button
                                className={`${classes.tab} ${this.state.sortMode === BY_EMOJI_COUNT ? classes.selected : ''}`}
                                onClick={() => this.handleSortTab(BY_EMOJI_COUNT)}>Most emojis</button>
                        </div>
                    </div>
                    <WorkGrid workIds={this.state.workIds} gridColumns={3} skipHidden={false} alignBottom
                        ModalTitle={ModalTitle} ModalDayLabel={ModalDayLabel}>
                        <ChallengePageWorkTemplate sortMode={this.state.sortMode} />
                    </WorkGrid>
                </div>
            </div >
        );
    }
}

function ModalTitle({ modalEntry }) {
    return <UserTitle profile={modalEntry?.authorProfile} />;
}

function ModalDayLabel({ modalEntry }) {
    return `Day ${modalEntry?.dayIndex + 1}`;
}

function ChallengePageWorkTemplate({
    sortMode,  // Prop from this file above
    authorProfile, workId, workObj, prompt, challenge, dayIndex, emojiCounts,  // The work
    onClick,  // Pass down event listener (from WorkGrid)
    children,  // Content inside (from WorkGrid)
}) {
    const [tagContent, setTagContent] = useState('');
    useEffect(() => {
        switch(sortMode) {
            case BY_DAY:
                setTagContent(`Day ${dayIndex + 1}`);
                break;
            case BY_EMOJI_COUNT:
                setTagContent(`${emojiCounts} 👍`);
                break;
            default:
                setTagContent('');
        }
    }, [sortMode, dayIndex, emojiCounts]);

    return <div className={classes.sketchHolder}>
        <div className={classes.aboveSketch}>
            <div className={classes.authorName}>
                <UserTitle profile={authorProfile}>
                    {tagContent && <span className={classes.tag}>{tagContent}</span>}
                </UserTitle>
            </div>
        </div>
        <ErrorBoundary>
            <div className={classes.sketch} onClick={onClick}>{children}</div>
        </ErrorBoundary>
    </div>
};

export default WorksTableChallenge;
