import React from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";
import './P5Draw.css';

let grid = [];

function P5DrawAvatar(props) {
    // init local vars
    let canvas_width = props.displaySize || 500;
    let canvas_height = props.displaySize || 500;
    grid = props.strokes || []; // pull from profile or init
    let squaresPerSide = 15;
    let gridSquaresRecord = []; // record user input to redraw/animate; not used
    let lastGridSquareTouched = [];
    let lastColor = 1;
    let isDrawing = false;
    let showCurveConnectors = false;
    const yblack = '#191919';
    const ywhite = '#FFFFFF';
    const ypurple = '#6F6CF4';
    const ycyan = '#66F4EC';
    const yyellow = '#FCFF74';
    let bgColor = props.bgColor || ywhite;
    let fgColor = props.fgColor || yblack;
    let colorIndex = 0;
    const bgs = [ywhite, yblack, ywhite, ypurple, ycyan, yblack, yyellow,
		 ypurple, ywhite, ycyan, yyellow, yblack, ycyan, ypurple, yyellow, ycyan];
    const fgs = [yblack, ywhite, ypurple, ywhite, yblack, ycyan, ypurple,
		 yyellow, ycyan, ywhite, yblack, yyellow, ypurple, ycyan, ycyan, yyellow];

    // Square / Point object
    let Square = function(i, j, t) {
        this.i = i;
        this.j = j;
        this.t = t;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        canvas_width = canvas_height = props.displaySize;

        //squaresPerSide = 15; //displayWidth * .06;
        p5.ellipseMode(p5.CORNER);

        // If null stroke state was passed then init the grid array
        // otherwise skip this step and use the stroke state passed in
        if( props.strokes === '') {
            for (let i = 0; i < squaresPerSide; i++) {
                let row = [];
                for (let j = 0; j < squaresPerSide; j++) {
                    row.push(0);
                }
                grid.push(row);
            }
        }
    };

    const draw = (p5) => {

        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(p5.color(bgColor));

        let gridSqW = p5.width / squaresPerSide;

        p5.fill(p5.color(fgColor));
        p5.stroke(p5.color(fgColor));
        for (let i = 0; i < squaresPerSide; i++) {
            for (let j = 0; j < squaresPerSide; j++) {
                if (grid[i] && grid[i][j] && grid[i][j] === 1) {
                    p5.ellipse(i * gridSqW, j * gridSqW, gridSqW, gridSqW);
                    // check 2 directions
                    // north
                    if (j > 0 && grid[i][j - 1] === 1) {
                        p5.rect(i * gridSqW, (j - 0.5) * gridSqW, gridSqW, gridSqW);
                    }
                    // east
                    if (i > 0 && grid[i - 1][j] === 1) {
                        p5.rect((i - 0.5) * gridSqW, j * gridSqW, gridSqW, gridSqW);
                    }

                    // factor to approximate circle with bezier
                    const circleFactor = 0.552 / 2;

                    // check diagonals
                    // northwest
                    if (i > 0 && j > 0 && grid[i - 1][j - 1] === 1) {
                        if (showCurveConnectors) {
                            p5.beginShape();
                            p5.vertex((i + 0.5) * gridSqW, j * gridSqW);
                            p5.bezierVertex(
                                (i + 0.5 - circleFactor) * gridSqW, j * gridSqW,
                                i * gridSqW, (j - 0.5 + circleFactor) * gridSqW,
                                i * gridSqW, (j - 0.5) * gridSqW);
                            p5.vertex((i - 0.5) * gridSqW, j * gridSqW);
                            p5.bezierVertex(
                                (i - 0.5 + circleFactor) * gridSqW, j * gridSqW,
                                i * gridSqW, (j + 0.5 - circleFactor) * gridSqW,
                                i * gridSqW, (j + 0.5) * gridSqW);
                            p5.endShape(p5.CLOSE);
                        } else {
                            p5.rect(i * gridSqW, j * gridSqW, gridSqW * 0.5, gridSqW * 0.5);
                            p5.rect((i - 0.5) * gridSqW, (j - 0.5) * gridSqW, gridSqW * 0.5, gridSqW * 0.5);
                        }
                    }

                    // northeast
                    if (i < squaresPerSide - 1 && j > 0 && grid[i + 1][j - 1] === 1) {
                        if (showCurveConnectors) {
                            p5.beginShape();
                            p5.vertex((i + 1) * gridSqW, (j + 0.5) * gridSqW);
                            p5.bezierVertex(
                                (i + 1) * gridSqW, (j + 0.5 - circleFactor) * gridSqW,
                                (i + 1.5 - circleFactor) * gridSqW, j * gridSqW,
                                (i + 1.5) * gridSqW, j * gridSqW);
                            p5.vertex((i + 1) * gridSqW, (j - 0.5) * gridSqW);
                            p5.bezierVertex(
                                (i + 1) * gridSqW, (j - 0.5 + circleFactor) * gridSqW,
                                (i + 0.5 + circleFactor) * gridSqW, j * gridSqW,
                                (i + 0.5) * gridSqW, j * gridSqW);
                            p5.endShape(p5.CLOSE);
                        } else {
                            p5.rect((i + 0.5) * gridSqW, j * gridSqW, gridSqW * 0.5, gridSqW * 0.5);
                            p5.rect((i + 1) * gridSqW, (j - 0.5) * gridSqW, gridSqW * 0.5, gridSqW * 0.5);
                        }
                    }
                }
            }
        }
    };

    // Translate cursor position into grid indices
    function getGridSquare(pX, pY) {
        let i = Math.round(-0.5 + (pX / canvas_width) * squaresPerSide);
        let j = Math.round(-0.5 + (pY / canvas_height) * squaresPerSide);
        if (i < 0) i = 0;
        if (j < 0) j = 0;
        if (i > squaresPerSide - 1) i = squaresPerSide - 1;
        if (j > squaresPerSide - 1) j = squaresPerSide - 1;
        return [i, j];
    }

    // toggle the color of a grid index
    function toggleGridSquare(i, j) {
        let color = (grid[i][j] === 0 ? 1 : 0);
        grid[i][j] = color;
        lastGridSquareTouched = [i, j];
        return color;
    }

    // set drawing = true
    // update grid state
    // append to gridSquaresRecord array
    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        let i, j;
        [i, j] = getGridSquare(pX, pY);
        lastColor = toggleGridSquare(i, j);
        gridSquaresRecord.push(new Square(i, j, current(p5))); // not used
    }

    // update grid state
    // append to gridSquaresRecord array
    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            let i, j;
            [i, j] = getGridSquare(pX, pY);
            if (i !== lastGridSquareTouched[0] | j !== lastGridSquareTouched[1]) {
                if (grid[i][j] !== lastColor) {
                    toggleGridSquare(i, j);
                    gridSquaresRecord.push(new Square(i, j, current(p5))); // not used
                }
            }
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    // set drawing = false
    function stopDrawing(pX, pY) {
        isDrawing = false;
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }

    const clearDrawingHandler = () => {
        for (let i = 0; i < squaresPerSide; i++) {
            for (let j = 0; j < squaresPerSide; j++) {
                grid[i][j] = 0;
            }
        }
        gridSquaresRecord = [];
        return true;
    }

    const undoHandler = () => {
        if (gridSquaresRecord.length >= 1) {
            let last = gridSquaresRecord[gridSquaresRecord.length - 1];
            toggleGridSquare(last.i, last.j);
            gridSquaresRecord.splice(gridSquaresRecord.length - 1);
        }
    }

    const colorsHandler = () => {
        colorIndex++;
        if (colorIndex >= bgs.length) colorIndex = 0;
        bgColor = bgs[colorIndex];
        fgColor = fgs[colorIndex];
    }

    const submitButtonHandler = () => {
        props.parentCallback(grid, fgColor, bgColor);
        return true;
    }

    return (
        <div className="drawingToolContainer">
            <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />
            <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={colorsHandler}>Change colors</button>
                <button onClick={submitButtonHandler}>Save</button>
            </div>
        </div>);
};

export default P5DrawAvatar;
