import React, { useState, useEffect } from 'react';
import classes from '../pages/Settings.module.css';

export function TextField({ isValid = true, hint, saveFn, children }) {
    const [isUpdating, setIsUpdating] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => { setMessage(''); }, 2000);
        return () => clearTimeout(timer);
    }, [message]);

    function update() {
        setMessage('Saving...');
        setIsUpdating(true);

        saveFn().then(() => {
            setIsUpdating(false);
            setMessage('Updated!');
        });
    }

    return <>
        <span className={classes.fillRow}>
            {children}
        </span>
        <button onClick={update} disabled={!isValid || isUpdating}>Update</button>
        <span className={classes.message}>{message || hint}</span>
    </>;
}

export function CheckboxField({ saveFn, setter, value, children }) {
    const [message, setMessage] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => { setMessage(''); }, 2000);
        return () => clearTimeout(timer);
    }, [message]);

    function update(e) {
        setMessage('Saving...');
        const value = e.target.checked;
        setter(value);
        saveFn(value).then(() => {
            setMessage('Updated!');
        });
    }

    return <label className={classes.spanColumns}>
        <input type="checkbox" checked={value} onChange={update} />
        { children }&nbsp;
        <span className={classes.message}>{message}</span>
    </label>;
}
