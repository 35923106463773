import React, {useState, useEffect} from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

let grid = []; // 2D array with 0 or 1 values

function P5DrawGridC(props) {
    const [squaresPerSide, setSquaresPerSide] = useState(15);
    const [fillColor, setFillColor] = useState('#FFFFFF');
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let gridSquaresRecord = []; // record user input to redraw/animate
    let lastGridSquareTouched = [];
    let lastColor = 1;

    // add a new Square every time the user draws
    let Square = function(i, j, t) {
        this.i = i;
        this.j = j;
        this.t = t;
    }

    let isDrawing = false;

    function getGridSquare(p5, pX, pY) {
        let i = Math.round(-0.5 + (pX / p5.width) * squaresPerSide);
        let j = Math.round(-0.5 + (pY / p5.height) * squaresPerSide);
        if (i < 0) i = 0;
        if (j < 0) j = 0;
        if (i > squaresPerSide - 1) i = squaresPerSide - 1;
        if (j > squaresPerSide - 1) j = squaresPerSide - 1;
        return [i, j];
    }

    function toggleGridSquare(i, j) {
        let color = (grid[i][j] === 0 ? 1 : 0);
        grid[i][j] = color;
        lastGridSquareTouched = [i, j];
        return color;
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        let i, j;
        [i, j] = getGridSquare(p5, pX, pY);
        lastColor = toggleGridSquare(i, j);
        gridSquaresRecord.push(new Square(i, j, current(p5)));
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            let i, j;
            [i, j] = getGridSquare(p5, pX, pY);
            if (i !== lastGridSquareTouched[0] | j !== lastGridSquareTouched[1]) {
                if (grid[i][j] !== lastColor) {
                    toggleGridSquare(i, j);
                    gridSquaresRecord.push(new Square(i, j, current(p5)));
                }
            }
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(pX, pY) {
        isDrawing = false;
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }


    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempSquaresPerSide = squaresPerSide;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.squaresPerSide) {
                tempSquaresPerSide = parameters.squaresPerSide;
                setSquaresPerSide(tempSquaresPerSide);
            }
            if (parameters.fillColor) {
                setFillColor(parameters.fillColor);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);

        let tempGrid = [];
        for (let i = 0; i < tempSquaresPerSide; i++) {
            let row = [];
            for (let j = 0; j < tempSquaresPerSide; j++) {
                row.push(0);
            }
            tempGrid.push(row);
        }
        grid = tempGrid;
        p5.ellipseMode(p5.CORNER);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        let gridSqW = p5.width / squaresPerSide;
        const inner = 0.5;

        // draw all outer black shapes first
        p5.fill(0);
        for (let i = 0; i < squaresPerSide; i++) {
            for (let j = 0; j < squaresPerSide; j++) {
                if (grid[i][j] === 1) {
                    p5.ellipse(i * gridSqW, j * gridSqW, gridSqW, gridSqW);

                    // check 2 directions
                    // north
                    if (j > 0 && grid[i][j - 1] === 1) {
                        p5.rect(i * gridSqW, (j - 0.5) * gridSqW, gridSqW, gridSqW);
                    }
                    // east
                    if (i > 0 && grid[i - 1][j] === 1) {
                        p5.rect((i - 0.5) * gridSqW, j * gridSqW, gridSqW, gridSqW);
                    }

                    // factor to approximate circle with bezier
                    const circleFactor = 0.552 / 2;

                    // check diagonals
                    // northwest
                    if (i > 0 && j > 0 && grid[i - 1][j - 1] === 1) {
                        p5.beginShape();
                        p5.vertex((i + 0.5) * gridSqW, j * gridSqW);
                        p5.bezierVertex(
                            (i + 0.5 - circleFactor) * gridSqW, j * gridSqW,
                            i * gridSqW, (j - 0.5 + circleFactor) * gridSqW,
                            i * gridSqW, (j - 0.5) * gridSqW);
                        p5.vertex((i - 0.5) * gridSqW, j * gridSqW);
                        p5.bezierVertex(
                            (i - 0.5 + circleFactor) * gridSqW, j * gridSqW,
                            i * gridSqW, (j + 0.5 - circleFactor) * gridSqW,
                            i * gridSqW, (j + 0.5) * gridSqW);
                        p5.endShape(p5.CLOSE);
                    }

                    // northeast
                    if (i < squaresPerSide - 1 && j > 0 && grid[i + 1][j - 1] === 1) {
                        p5.beginShape();
                        p5.vertex((i + 1) * gridSqW, (j + 0.5) * gridSqW);
                        p5.bezierVertex(
                            (i + 1) * gridSqW, (j + 0.5 - circleFactor) * gridSqW,
                            (i + 1.5 - circleFactor) * gridSqW, j * gridSqW,
                            (i + 1.5) * gridSqW, j * gridSqW);
                        p5.vertex((i + 1) * gridSqW, (j - 0.5) * gridSqW);
                        p5.bezierVertex(
                            (i + 1) * gridSqW, (j - 0.5 + circleFactor) * gridSqW,
                            (i + 0.5 + circleFactor) * gridSqW, j * gridSqW,
                            (i + 0.5) * gridSqW, j * gridSqW);
                        p5.endShape(p5.CLOSE);
                    }

                }
            }
        }

        // draw inner white shapes
        p5.fill(fillColor);
        p5.noStroke();
        for (let i = 0; i < squaresPerSide; i++) {
            for (let j = 0; j < squaresPerSide; j++) {
                if (grid[i][j] === 1) {
                    p5.ellipse((i + inner * 0.5) * gridSqW, (j + inner * 0.5) * gridSqW, inner * gridSqW, inner * gridSqW);

                    // check 2 directions
                    // north
                    if (j > 0 && grid[i][j - 1] === 1) {
                        p5.rect((i + inner * 0.5) * gridSqW, (j + inner * 0.5 - 0.75) * gridSqW, inner * gridSqW, inner * gridSqW * 2);
                    }
                    // east
                    if (i > 0 && grid[i - 1][j] === 1) {
                        p5.rect((i + inner * 0.5 - 0.75) * gridSqW, (j + inner * 0.5) * gridSqW, inner * gridSqW * 2, inner * gridSqW);
                    }

                    // look northwest at all 4 corners
                    if (i > 0 && j > 0 && grid[i - 1][j - 1] === 1 && grid[i - 1][j] === 1 && grid[i][j - 1] === 1) {
                        p5.rect((i - 0.5) * gridSqW, (j - 0.5) * gridSqW, gridSqW, gridSqW);
                    }

                }
            }
        }
    };

    const clearDrawingHandler = () => {
        for (let i = 0; i < squaresPerSide; i++) {
            for (let j = 0; j < squaresPerSide; j++) {
                grid[i][j] = 0;
            }
        }
        gridSquaresRecord = [];
        return true;
    }

    const undoHandler = () => {
        if (gridSquaresRecord.length >= 1) {
            let last = gridSquaresRecord[gridSquaresRecord.length - 1];
            toggleGridSquare(last.i, last.j);
            gridSquaresRecord.splice(gridSquaresRecord.length - 1);
        }
    }

    const submitButtonHandler = () => {
        props.parentCallback([grid, gridSquaresRecord]);
        return true;
    }


    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawGridC;
