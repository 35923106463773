import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as firebaseService from './services/firebase.js';
import * as trackingService from './services/tracking';
import * as serviceWorker from './serviceWorker';
import { refreshAfterLongTimeInactive, disableReactDevTools } from './services/shared';

firebaseService.initFirebase();
refreshAfterLongTimeInactive();

if (process.env.NODE_ENV !== 'development') {
    trackingService.init();
    disableReactDevTools();
}

// Init react app
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// `unregister()` to turn it off
serviceWorker.register({
    // When a new version is found during refresh, ignore any old SW or cache.
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                    window.location.reload();
                }
            });
            waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        }
    }
});
