import * as firebaseService from './firebase';
import * as authService from './auth';
import * as sendgridService from './sendgrid';

export const IS_PROD = window.location.origin.includes('ydays.com');

export const CLOUD_FUNCTION_ROOT = 'https://us-central1-ydays-618cd.cloudfunctions.net';
// To test a local function:
// export const CLOUD_FUNCTION_ROOT =  'http://localhost:5001/ydays-618cd/us-central1';

export function getNow(urlQuery = window.location.search) {
    const urlToday = new URLSearchParams(urlQuery).get('now');
    return urlToday ? new Date(urlToday) : new Date();
}

const CHALLENGE_ID_TO_JOIN_KEY = 'CHALLENGE_ID_TO_JOIN';
export function saveJoinChallengeId(id) {
    localStorage.setItem(CHALLENGE_ID_TO_JOIN_KEY, id);
}
export function joinSavedChallengeId() {
    const challengeId = localStorage.getItem(CHALLENGE_ID_TO_JOIN_KEY);
    if (challengeId) {
        firebaseService.joinChallenge(challengeId);
        localStorage.removeItem(CHALLENGE_ID_TO_JOIN_KEY);
    }
}

export const ADD_TO_MARKETING_EMAIL_KEY = 'ADD_TO_MARKETING_EMAIL';
export function saveAllowMarketingForGoogleSignin() {
    localStorage.setItem(ADD_TO_MARKETING_EMAIL_KEY, true);
}
export function addToMarketingAfterGoogleSignin() {
    const allow = localStorage.getItem(ADD_TO_MARKETING_EMAIL_KEY);
    if (allow) {
        const user = authService.getCurrentUserDirect();
        if (user?.email) {
            sendgridService.addToMarketingList({ email: user.email, name: user.displayName });
            localStorage.removeItem(ADD_TO_MARKETING_EMAIL_KEY);
        }
    }
}

export const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
export function getTimeDiff(start, day) {
    return getNow().getTime() - (start + day * ONE_DAY_IN_MILLISECONDS)
}

export const DAILY_CHALLENGE_TIMEZONE_OFFSET_IN_MIN = 5 * 60;  // This means -05:00 timezone. It's the value of date.getTimezoneOffset()

/* Convert the minutes value from date.getTimezoneOffset() to a timezone string.
 * Input: 480 (California). Output: '-08:00' (note that the sign if opposite) */
function timezoneOffsetMinToString(timezoneOffsetInMinutes) {
    // timezoneOffsetInMinutes can be negative, so `Math.abs`
    const hourPart = `${Math.floor(Math.abs(timezoneOffsetInMinutes) / 60)}`.padStart(2, '0'); // "08"
    // Timezone can have minutes(!), you can't just assume "00"
    const minutePart = `${Math.abs(timezoneOffsetInMinutes) % 60}`.padStart(2, '0');  // "00"
    const timezoneSign = `${timezoneOffsetInMinutes > 0 ? '-' : '+'}`;  // Offset is the opposite number of timezone
    return `${timezoneSign}${hourPart}:${minutePart}`;  // -08:00
}

/** 2020-01-01 format to Date in the timezone specified */
export function yyyymmddToDate(dateString, timezoneInMin = new Date().getTimezoneOffset()) {
    if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return null;
    }

    const ISOString = `${dateString}T00:00:00${timezoneOffsetMinToString(timezoneInMin)}`;

    try {
        const date = new Date(ISOString);
        if (date.toString() === 'Invalid Date') {
            console.error('Invalid date from yyyymmdd', dateString, timezoneInMin);
            return null;
        }
        return date;
    } catch (_) {
        console.error('Invalid date from yyyymmdd', dateString, timezoneInMin);
        return null;
    }
}

/* From a date in user's timezone to a specific timezone.
 * Input: 2020-01-01T05:00:00 and 300 (New York offset), user in California
 * Output: 2020-01-01T08:00:00 (then use date.getHours(), etc.)
 */
export function convertTimezone(date, timezoneOffsetInMin) {
    if (timezoneOffsetInMin === undefined) {  // It could be 0, for users in UK
        throw new Error('timezone offset is required');
    }
    const utc = date.getTime() + (date.getTimezoneOffset() * 60 * 1000);
    return new Date(utc - timezoneOffsetInMin * 60 * 1000);
}

/* Convert a date object to a yyyy-mm-dd string in a specific timezone
 * Input: new Date(2020-01-01T23:00:00) and 300 (New York offset), user in California
 * Output: 2020-01-02 note the day in New York is already Jan 2nd
 */
export function dateToYyyymmdd(date, timezoneOffsetInMin) {
    const newDate = convertTimezone(date, timezoneOffsetInMin);
    return `${newDate.getFullYear()}-${`${newDate.getMonth() + 1}`.padStart(2, '0')}-${`${newDate.getDate()}`.padStart(2, '0')}`;
}

export function getFriendlyTime(date) {
    try {
        let hour = date.getHours();
        let mins = date.getMinutes();
        let isPM = false;
        if (hour === 0 && mins === 0) {
            return ("midnight");
        } else if (hour === 12 && mins === 0) {
            return ("noon");
        } else {
            if (hour >= 12) {
                isPM = true;
                if (hour > 12) hour -= 12;
            }
            if (hour === 0) {
                hour = 12;
            }
            if (mins === 0) {
                return (hour + (isPM ? "pm" : "am"));
            } else {
                return (hour + ":" + (mins < 10 ? "0" + mins : mins) + (isPM ? "pm" : "am"));
            }
        }
    } catch(_) {
        return '';
    }
}

export function getFriendlyDate(date) {
    try {
        if (!date) return '';
        return date.toLocaleDateString(undefined, { weekday: 'long', month: 'long', day: 'numeric' });  // Tuesday, February 12
    } catch(_) {
        return '';
    }
}

export function getMonthDayYear(date) {
    try {
        if (!date) return '';
        return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' }); // September 14, 2020
    } catch(_) {
        return '';
    }
}

export function decideSketchSize(type) {
    const windowWidth = window.innerWidth;
    let sketchSize;

    switch (type) {
        case 'grid-2':
        case 'gallery':
            sketchSize = 430;
            if (windowWidth < sketchSize + 80) {
                sketchSize = windowWidth - 80;
            }
            return sketchSize;
        case 'grid-3':
            sketchSize = 270;
            if (windowWidth < sketchSize * 2 + 110) {
                sketchSize = (windowWidth - 110) * .5;
            }
            return sketchSize;
        case 'modal':
            sketchSize = 500;
            if (windowWidth < sketchSize + 40) {
                sketchSize = windowWidth - 40;
            }
            return sketchSize;
        case 'prompt':
        default:
            sketchSize = 500;
            if (windowWidth <= 320) {
                // iPhone 5/SE and smaller
                // On smaller screens, make the canvas smaller so that the button at the bottom is visible.
                // TODO: Handle it using window.height, since what we want is the vertical space, and this current number is calculated based on iPhone SE's screen ratio
                sketchSize = windowWidth - 60;
            } else if (windowWidth < sketchSize + 40) {
                sketchSize = windowWidth - 40;
            }
            return sketchSize;
    }
}

export function waitForElementRender(selector, timeout = 20 * 1000) {
    return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
            try {
                const element = document.querySelector(selector);
                if (element) {
                    clearInterval(interval);
                    resolve(element);
                }
            } catch (error) {
                console.error(error);
                clearInterval(interval);
                reject(error);
            }
        }, 10);

        // Auto fail it after 5s
        setTimeout(() => {
            clearInterval(interval);
            reject('Timeout');
        }, timeout);
    });
}

export function debugLogger() {
    if (IS_PROD) return;
    console.debug(...arguments);
}

const PAGE_WITH_DRAWING_REGEXPS = [
    /sample-drawing/,
    /edit-image/,
    /\/(c|challenge)\/(-|_|\w)+\/\d+$/,  // Prompt pages
    /daily\/\d{4}-\d{2}-\d{2}$/, // Daily Play draw pages
];
export function checkPageHasDrawing(location = window.location) {
    return PAGE_WITH_DRAWING_REGEXPS.find((exp) => location.pathname.match(exp));
}

/* Force refresh the page when user left ydays tab for more than 1h (or 20h when on drawing page)
 * This doesn't resolve if the tab is still visible (e.g. User keeps computer on, not sleeping, and left for 10min. Or user keeps ydays open, and goes to coding on vscode, while the browser window is still open on ydays).
 * These cases should be rare enough to ignore. */
const INACTIVE_REFRESH_THRESHOLD = 60 * 60 * 1000;
const INACTIVE_DRAWING_REFRESH_THRESHOLD = 20 * 60 * 60 * 1000;
export function refreshAfterLongTimeInactive() {
    let leftPageTimestamp;
    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
            // User left YDays tab for more than INACTIVE_REFRESH_THRESHOLD, and just came back. And this page is not a drawing page
            if (!leftPageTimestamp) return;

            const timeInactive = Date.now() - leftPageTimestamp;

            if ((timeInactive > INACTIVE_REFRESH_THRESHOLD && !checkPageHasDrawing()) || timeInactive > INACTIVE_DRAWING_REFRESH_THRESHOLD) {
                window.location.reload();
            }
            debugLogger('[Inactive refresh]', document.visibilityState, new Date())
        } else {
            leftPageTimestamp = Date.now();
            debugLogger('[Inactive refresh]', document.visibilityState, new Date())
        }
    });
}

export function disableReactDevTools() {
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== "object") {
        return;
    }

    for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
        if (prop === "renderers") {
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map()
        } else {
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
                typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === "function"
                    ? () => {}
                    : null;
        }
    }
}

export function getYearWeekOfDailyChallenge(dailyChallenge) {
    const startTime = convertTimezone(new Date(dailyChallenge.startTimestamp), DAILY_CHALLENGE_TIMEZONE_OFFSET_IN_MIN);
    const year = startTime.getFullYear();
    const firstDayInYear = convertTimezone(new Date(year, 0, 1), DAILY_CHALLENGE_TIMEZONE_OFFSET_IN_MIN);
    const week = Math.ceil((startTime.getTime() - firstDayInYear.getTime()) / ONE_DAY_IN_MILLISECONDS / 7);
    return { year, week };
}

export function getDailyChallengeFromYearWeek(year, week, allDailyChallenges) {
    const targetDayTimestamp = new Date(year, 0, week * 7).getTime();
    for (const dailyChallenge of allDailyChallenges) {
        if (dailyChallenge.startTimestamp < targetDayTimestamp && dailyChallenge.startTimestamp + ONE_DAY_IN_MILLISECONDS * dailyChallenge.prompts.length > targetDayTimestamp) {
            return dailyChallenge;
        }
    }
}

export const INVALID_USERNAMES = ['signup', 'login', 'forgot', 'home', 'profile', 'settings', 'edit-image',
    'create-prompt', 'create-challenge', '_admin', 'admin', '_debug', 'about', 'terms', 'privacy', 'faq',
    'debug', 'account', 'premium', 'member', 'membership', 'subscription', 'payment', 'cart', 'platform',
    'support', 'search', 'discover', 'explore', 'glossary', 'upload', 'rules', 'games', 'game', 'match',
    'week', 'day', 'year', 'month', 'days', 'ydays',
    'user', 'users', 'forms', 'form', 'help', 'tools', 'education', 'build', 'engage', 'stories',
    'blog', 'business', 'design', 'product', 'tips', 'announcements', 'updates', 'safety', 'shopping', 'shop',
    'store', 'community', 'creators', 'features', 'partnerships', 'advertising', 'sign-up', 'log-in', 'join',
    'review', 'challenge', 'prompt', 'image', 'hello', 'teams', 'team', 'groups', 'group', 'daily',
    '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030',
    'null', 'undefined', 'reset', 'none',
];
export function validateUsername(username) {
    return !INVALID_USERNAMES.includes(username) && /^[a-z0-9_-]{4,12}$/.test(username);
}
