import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import './P5Display.css';
import watermark from '../../assets/ydays-watermark-bw.png';
import { drawWatermark } from './p5helper';

function P5DisplayWiggle(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokes, setStrokes] = useState([]);
    const [strokeWeight, setStrokeWeight] = useState(6);
    const [wiggleX, setWiggleX] = useState(1.0);
    const [wiggleY, setWiggleY] = useState(1.0);
    const [cycleTime, setCycleTime] = useState(0.5); // length of animation, needs to match duration of the prompt in database
    const [strokeCap, setStrokeCap] = useState("ROUND"); // "ROUND", "SQUARE", or "PROJECT"
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    const GIF_CAPTURE_FPS = 10;
    const GIF_CAPTURE_FRAME_DURATION = 1 / GIF_CAPTURE_FPS;
    let hasFinished = false;
    let numFramesCaptured = 0;

    let Point = function (x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let curStroke = [];

    function addPoint(p5, pX, pY, t) {
        curStroke.push(new Point(pX, pY, t));
    }

    function current(p5) {
        // If capturing, advance the time by the same increment with each frame captured
        if (props.addGifFrame && props.stopCapture) {
            return numFramesCaptured * GIF_CAPTURE_FRAME_DURATION;
        }
        return p5.millis() * .001;
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempStrokeWeight = strokeWeight;
        let tempStrokeCap = strokeCap;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.wiggleX && parameters.wiggleY) {
                setWiggleX(parameters.wiggleX);
                setWiggleY(parameters.wiggleY);
            }
            if (parameters.cycleTime) {
                setCycleTime(parameters.cycleTime);
            }
            if (parameters.strokeCap) {
                tempStrokeCap = parameters.strokeCap;
                setStrokeCap(tempStrokeCap);
            }
            if (parameters.strokeWeight) {
                tempStrokeWeight = parameters.strokeWeight;
                setStrokeWeight(tempStrokeWeight);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.); // apply this scale factor to strokeWeight
        if (tempStrokeCap === "SQUARE")
            p5.strokeCap(p5.SQUARE);
        else if (tempStrokeCap === "PROJECT")
            p5.strokeCap(p5.PROJECT);
        else
            p5.strokeCap(p5.ROUND);
        p5.strokeWeight(tempStrokeWeight);
        p5.strokeJoin(p5.ROUND);
        p5.stroke(0);
        p5.noFill();

        const tempStrokes = [];
        for (let i = 0; i < props.data.length; i++) {
            if (props.data[i] != null) {
                let stroke = props.data[i];
                for (let k = 0; k < stroke.length; k++) {
                    if (stroke[k] != null) {
                        let pt = stroke[k];
                        addPoint(p5, pt.x, pt.y, pt.t);
                    }
                }
                tempStrokes.push(curStroke);
                curStroke = [];
            }
        }
        setStrokes(tempStrokes);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor, scaleFactor);

        let t1, wx, wy;
        let curTime = current(p5);
        const indexOffset = 3;
        const angleOffset = 40.0;

        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    // amount to wiggle
                    t1 = 1 * Math.sin(strokes[i][j].t * indexOffset + curTime * (2 * Math.PI / cycleTime));
                    // fixed angle per point
                    wx = wiggleX * t1 * Math.cos(strokes[i][j].t * angleOffset);
                    wy = wiggleY * t1 * Math.sin(strokes[i][j].t * angleOffset);
                    p5.curveVertex(strokes[i][j].x + wx, strokes[i][j].y + wy);
                }
                p5.endShape();
            }
        }

        // Add watermark before capturing this frame
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }

        if (props.addGifFrame && props.stopCapture) {  // When this component is created for capturing
            if (hasFinished) return;

            if (numFramesCaptured >= GIF_CAPTURE_FPS * props.durationInSeconds) {
                props.stopCapture();
                hasFinished = true;
            } else {
                props.addGifFrame(GIF_CAPTURE_FRAME_DURATION * 1000);
                numFramesCaptured++;
            }
        }
    };



    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayWiggle;
