import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

let shapes = [];
let startstate = [];
let statestack = [];

// Peter: Making shapes global because when it's declared inside the function,
// sketches that have parameters passed in appear blank, with shapes being empty.
// Also tried putting into state, but couldn't get it to work as you try to set state
// for every mouse drag position change.

function P5DrawTangram(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    // const [shapes, setShapes] = useState([]); 
    const [colors, setColors] = useState(["#FFF61F", "#FF5C46", "#30CDFF", "#CCE530", "#FD52A4", "#1A98DF", "#28D999"]);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Dshape = function (points, color) {
        this.points = points.slice();
        this.rot = 0;
        this.targetrot = 0;
        this.rotating = false;
        this.color = color;
        this.selected = false;
        this.cx = 0;
        this.cy = 0;

        this.l = 0; // length, approximate
        if (points.length < 1) return;

        this.minx = this.maxx = this.points[0][0];
        this.miny = this.maxy = this.points[0][1];
        for (let i = 0; i < this.points.length; i++) {
            let tempx = this.points[i][0];
            let tempy = this.points[i][1];
            if (tempx < this.minx) this.minx = tempx;
            if (tempx > this.maxx) this.maxx = tempx;
            if (tempy < this.miny) this.miny = tempy;
            if (tempy > this.maxy) this.maxy = tempy;
            this.cx += tempx;
            this.cy += tempy;
        }
        // calculate centroid
        this.cx = this.cx / this.points.length;
        this.cy = this.cy / this.points.length;
        let w = this.maxx - this.minx;
        let h = this.maxy - this.miny;
        if (w > h) this.l = w;
        else this.l = h;
    }

    Dshape.prototype.draw = function (p5) {
        // update rotation
        if (this.rotating) {
            let drot = 0.4 * (this.targetrot - this.rot);
            if (drot < 0.01) {
                drot = this.targetrot - this.rot;
                this.rotate(drot, this.cx, this.cy);
                this.rot = this.targetrot;
                this.rotating = false;
            }
            else this.rotate(drot, this.cx, this.cy);
        }

        p5.push();

        p5.fill(this.color);
        p5.beginShape();
        for (let i = 0; i < this.points.length; i++) {
            p5.vertex(this.points[i][0], this.points[i][1]);
        }
        p5.endShape(p5.CLOSE);
        p5.pop();
    }

    Dshape.prototype.hitTest = function (px, py) {
        let inside = false;
        for (let i = 0, j = this.points.length - 1; i < this.points.length; j = i++) {
            let xi = this.points[i][0], yi = this.points[i][1];
            let xj = this.points[j][0], yj = this.points[j][1];

            let intersect = ((yi > py) !== (yj > py))
                && (px < (xj - xi) * (py - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;
    }

    Dshape.prototype.translate = function (dx, dy) {
        for (let i = 0; i < this.points.length; i++) {
            this.points[i][0] += dx;
            this.points[i][1] += dy;
        }
        this.cx += dx;
        this.cy += dy;
    }

    Dshape.prototype.scale = function (s) {
        for (let i = 0; i < this.points.length; i++) {
            this.points[i][0] *= s;
            this.points[i][1] *= s;
        }
        this.cx *= s;
        this.cy *= s;
    }

    Dshape.prototype.rotate = function (dr, x, y) {
        let s = Math.sin(dr);
        let c = Math.cos(dr);
        let px, py, xnew, ynew;
        for (let i = 0; i < this.points.length; i++) {
            px = this.points[i][0] - x;
            py = this.points[i][1] - y;
            xnew = px * c - py * s;
            ynew = px * s + py * c;
            this.points[i][0] = xnew + x;
            this.points[i][1] = ynew + y;
        }

        px = this.cx - x;
        py = this.cy - y;
        xnew = px * c - py * s;
        ynew = px * s + py * c;
        this.cx = xnew + x;
        this.cy = ynew + y;
        this.rot += dr;
    }

    let dragging = false;
    let lastx, lasty;
    let dragindex = 0;
    let clicktime = 0;

    // define shapes here
    let points1 = [[0, 0], [250, 250], [0, 500]];
    let points2 = [[0, 0], [250, 250], [500, 0]];
    let points3 = [[500, 0], [500, 250], [375, 125]];
    let points4 = [[500, 250], [375, 375], [250, 250], [375, 125]];
    let points5 = [[125, 375], [375, 375], [250, 500], [0, 500]];
    let points6 = [[250, 250], [375, 375], [125, 375]];
    let points7 = [[500, 250], [500, 500], [250, 500]];

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            dragging = false;
            return;
        }
        let foundindex = -1;
        for (let i = 0; i < shapes.length; i++) {
            if (shapes[i].hitTest(pX, pY)) {
                foundindex = i;
            }
        }
        if (foundindex > -1) {
            dragging = true;

            lastx = pX;
            lasty = pY;

            // move selected shape to the front; last index of shapes array
            let sshape = shapes.splice(foundindex, 1);
            shapes.push(sshape[0]);
            dragindex = shapes.length - 1;

            clicktime = p5.millis();
        }
    }

    function keepDrawing(p5, pX, pY) {
        if (dragging) {
            let dx = pX - lastx;
            let dy = pY - lasty;

            shapes[dragindex].translate(dx, dy);

            lastx = pX;
            lasty = pY;
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (dragging) {
            dragging = false;
            if (p5.millis() - clicktime < 300) { // a click instead of a drag
                shapes[dragindex].targetrot = shapes[dragindex].rot + Math.PI / 4;
                shapes[dragindex].rotating = true;
            }
            logState();
        }
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempColors = colors;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.colors) {
                tempColors = parameters.colors;
                setColors(tempColors);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        let tempScaleFactor = props.displaySize / 500.;
        setScaleFactor(tempScaleFactor);

        // add shapes here, based on defined verex arrays above
        shapes = [];
        shapes.push(new Dshape(points1, tempColors[0]));
        shapes.push(new Dshape(points2, tempColors[1]));
        shapes.push(new Dshape(points3, tempColors[2]));
        shapes.push(new Dshape(points4, tempColors[3]));
        shapes.push(new Dshape(points5, tempColors[4]));
        shapes.push(new Dshape(points6, tempColors[5]));
        shapes.push(new Dshape(points7, tempColors[6]));

        for (let i = 0; i < shapes.length; i++) {
            shapes[i].scale(0.5 * tempScaleFactor);
            shapes[i].translate(tempScaleFactor * 125, tempScaleFactor * 125);
        }

        startstate = deepCopy(shapes);
        logState();
    };

    function deepCopy(obj) {
        return (JSON.parse(JSON.stringify(obj)));
    }

    function logState() {
        let temp = deepCopy(shapes);//deep copy
        statestack.push(temp);
    }

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.noStroke();

        p5.strokeWeight(3 * scaleFactor);
        for (let i = 0; i < shapes.length; i++) {
            if (dragging && dragindex === i) p5.stroke(shapes[i].color);
            else p5.noStroke();
            shapes[i].draw(p5);
        }
    };

    const clearDrawingHandler = () => {
        statestack.splice(1);//remove all elements after index 0
        let r = startstate;
        shapes = [];
        for (let i = 0; i < r.length; i++) {
            shapes.push(new Dshape(deepCopy(r[i].points), r[i].color));
        }
        return true;
    }

    const undoHandler = () => {
        if (statestack.length > 1) {
            shapes = [];
            statestack.splice(statestack.length - 1);
            let r = statestack[statestack.length - 1];
            for (let i = 0; i < r.length; i++) {
                shapes.push(new Dshape(deepCopy(r[i].points), r[i].color));
            }
        }
    }

    function scaleValues(dshape) {
        for (let i = 0; i < dshape.points.length; i++) {
            dshape.points[i][0] /= scaleFactor;
            dshape.points[i][1] /= scaleFactor;
        }
        dshape.cx /= scaleFactor;
        dshape.cy /= scaleFactor;
        return dshape;
    }

    const submitButtonHandler = () => {
        let tempshapes = deepCopy(shapes);
        props.parentCallback(tempshapes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawTangram;
