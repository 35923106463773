import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayFlowingPen(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [r1, setR1] = useState(214);
    const [g1, setG1] = useState(237);
    const [b1, setB1] = useState(242);
    const [r2, setR2] = useState(42);
    const [g2, setG2] = useState(49);
    const [b2, setB2] = useState(51);
    const [strokes, setStrokes] = useState([]);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, d, t) {
        this.x = x;
        this.y = y;
        this.d = d;
        this.t = t;
    }

    let curStroke = [];

    function addPoint(p5, pX, pY, d, t) {
        curStroke.push(new Point(pX, pY, d, t));
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.r1 && parameters.g1 && parameters.b1 && parameters.r2 && parameters.g2 && parameters.b2) {
                setR1(parameters.r1);
                setG1(parameters.g1);
                setB1(parameters.b1);
                setR2(parameters.r2);
                setG2(parameters.g2);
                setB2(parameters.b2);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);

        p5.strokeCap(p5.ROUND);
        p5.strokeJoin(p5.ROUND);
        p5.strokeWeight(1.5 / scaleFactor);
        p5.curveTightness(-0.5);

        let strokeData = props.data;
        const tempStrokes = [];
        for (let i = 0; i < strokeData.length; i++) {
            if (strokeData[i] != null) {
                let stroke = strokeData[i];
                for (let k = 0; k < stroke.length; k++) {
                    if (stroke[k] != null) {
                        let pt = stroke[k];
                        addPoint(p5, scaleFactor * pt.x, scaleFactor * pt.y, pt.d, pt.t);
                    }
                }
                tempStrokes.push(curStroke);
                curStroke = [];
            }
        }
        setStrokes(tempStrokes);
    };

    let lastX1, lastY1, lastX2, lastY2;
    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        let r, rP; // radius, or half the stroke width
        let xP, yP; // prev point
        let xN, yN; // next point
        let angP, angN, angA; // ang to prev, ang to next, ang avg
        let x, y; // cur point
        let curX1, curY1, curX2, curY2;
        let d;

        if (!strokes) return;
        let starti = strokes.length - 1;
        if (!strokes[starti]) return;
        let startj = strokes[starti].length - 2;
        if (starti < 0 || startj <= 0) return;

        //p5.scale(scaleFactor, scaleFactor); // to reproduce the exact stroke weights, need to scale each x, y point on set up instead
        p5.background(255);
        starti = 0;
        startj = 1;

        for (let i = starti; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                for (let j = startj; j < strokes[i].length - 1; j++) {
                    d = strokes[i][j].d;
                    p5.fill(r2 * (1 - d) + r1 * d, g2 * (1 - d) + g1 * d, b2 * (1 - d) + b1 * d);
                    p5.stroke(r2 * (1 - d) + r1 * d, g2 * (1 - d) + g1 * d, b2 * (1 - d) + b1 * d);

                    xP = strokes[i][j - 1].x;
                    yP = strokes[i][j - 1].y;
                    x = strokes[i][j].x;
                    y = strokes[i][j].y;
                    xN = strokes[i][j + 1].x;
                    yN = strokes[i][j + 1].y;

                    angP = Math.atan2(yP - y, xP - x) + Math.PI * .5;
                    angN = Math.atan2(y - yN, x - xN) + Math.PI * .5;

                    angA = (angP + angN) / 2;

                    rP = scaleFactor * Math.min(strokes[i][j - 1].d * 30, 80) * .5;
                    r = scaleFactor * Math.min(d * 30, 80) * .5;

                    p5.beginShape();

                    if (j === 1) {
                        lastX1 = xP + rP * Math.cos(angP);
                        lastY1 = yP + rP * Math.sin(angP);
                        lastX2 = xP - rP * Math.cos(angP);
                        lastY2 = yP - rP * Math.sin(angP);
                    }

                    p5.vertex(lastX1, lastY1);
                    p5.vertex(lastX2, lastY2);

                    curX1 = x - r * Math.cos(angA);
                    curY1 = y - r * Math.sin(angA);
                    curX2 = x + r * Math.cos(angA);
                    curY2 = y + r * Math.sin(angA);

                    if (getDistance(curX1, curY1, lastX2, lastY2) + getDistance(curX2, curY2, lastX1, lastY1) < getDistance(curX2, curY2, lastX2, lastY2) + getDistance(curX1, curY1, lastX1, lastY1)) {
                        p5.vertex(curX1, curY1);
                        p5.vertex(curX2, curY2);
                    } else {
                        p5.vertex(curX2, curY2);
                        p5.vertex(curX1, curY1);
                    }

                    p5.endShape(p5.CLOSE);

                    // draw round 'caps'
                    p5.ellipse((lastX1 + lastX2) / 2, (lastY1 + lastY2) / 2, getDistance(lastX1, lastY1, lastX2, lastY2), getDistance(lastX1, lastY1, lastX2, lastY2));
                    p5.ellipse((curX1 + curX2) / 2, (curY1 + curY2) / 2, getDistance(curX1, curY1, curX2, curY2), getDistance(curX1, curY1, curX2, curY2));

                    lastX1 = curX1;
                    lastY1 = curY1;
                    lastX2 = curX2;
                    lastY2 = curY2;

                }
            }
        }
        if (props.withWatermark) {
            // This tool is not scaled, so needs to draw the watermark with scaleFactor
            drawWatermark(p5, watermarkImage, scaleFactor);
        }
    };



    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayFlowingPen;
