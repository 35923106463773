import React from 'react';
import classes from './Spinner.module.css';

function Spinner(props) {
    if (props.inline) {
        return (<span className={classes.MiniLoader} />);
    } else {
        // See comment in CSS about marginCollapsingBox here
        return (<div className={classes.marginCollapsingBox}><div className={classes.Loader}>Loading...</div></div>);
    }
}

export default Spinner;
