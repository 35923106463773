import React, { useState, useEffect } from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayAngleLines(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [lines, setLines] = useState([]);
    const [strokeWeight1, setStrokeWeight1] = useState(6);
    const [strokeWeight2, setStrokeWeight2] = useState(3);
    const [color1, setColor1] = useState("#000000");
    const [color2, setColor2] = useState("#000000");
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Line = function(x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
            }
            if (parameters.strokeWeight1 && parameters.strokeWeight2) {
                setStrokeWeight1(parameters.strokeWeight1);
                setStrokeWeight2(parameters.strokeWeight2);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.strokeCap(p5.SQUARE);

        let linesData = props.data;
        const tempLines = [];
        for (let i = 0; i < linesData.length; i++) {
            let l = linesData[i];
            tempLines.push(new Line(l.x1, l.y1, l.x2, l.y2));
        }
        setLines(tempLines);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor, scaleFactor);

        p5.stroke(0);
        p5.noFill();

        // draw rest of lines
        let i;
        for (i = 0; i < lines.length; i++) {
            if (i % 2 === 0) {
                p5.stroke(color1);
                p5.strokeWeight(strokeWeight1);
            } else if (i % 2 === 1) {
                p5.stroke(color2);
                p5.strokeWeight(strokeWeight2);
            }
            p5.line(lines[i].x1, lines[i].y1, lines[i].x2, lines[i].y2);
        }

        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayAngleLines;
