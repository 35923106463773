import React, {useState, useEffect} from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

/** Draw mirror image */
function P5DrawReflection(props) {
    const [scaleFactor, setScaleFactor] = useState(500 / 500.);
    const [strokeWeight1, setStrokeWeight1] = useState(5);
    const [strokeWeight2, setStrokeWeight2] = useState(0.7);
    const [color1, setColor1] = useState("#000000");
    const [color2, setColor2] = useState("#000000");
    const [reflectDirection, setReflectDirection] = useState(0); // 0 is left-right, 1 is top-bottom
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);


    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let strokes = [];
    let curStroke = [];
    let lastX = 0;
    let lastY = 0;

    let isDrawing = false;
    let redrawAllOnce = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) return;
        isDrawing = true;

        if (isDrawing && curStroke.length > 0) {
            strokes.push(curStroke);
            curStroke = [];
        }
        strokes.push(curStroke);
        addPoint(p5, pX, pY);
        lastX = pX;
        lastY = pY;
        drawStrokes(p5, false);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            let dist = getDistance(pX, pY, lastX, lastY);
            if ((curStroke.length === 0) || (dist > 5 * scaleFactor)) {
                addPoint(p5, pX, pY);
                lastX = pX;
                lastY = pY;
                drawStrokes(p5, false);
            }
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            strokes.pop();
            strokes.push(curStroke);
            curStroke = [];
            isDrawing = false;
            redrawAllOnce = true;
        }
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
            }
            if (parameters.strokeWeight1 && parameters.strokeWeight2) {
                setStrokeWeight1(parameters.strokeWeight1);
                setStrokeWeight2(parameters.strokeWeight2);
            }
            if (parameters.reflectDirection) {
                setReflectDirection(parameters.reflectDirection);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.); // apply this scale factor to strokeWeight

        p5.strokeCap(p5.ROUND);
        p5.strokeJoin(p5.ROUND);
        p5.curveTightness(0);

        p5.background(255);
        p5.noFill();
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes

        if (redrawAllOnce) {
            p5.background(255);
            drawStrokes(p5, true);
            redrawAllOnce = false;
        }
    }

    const drawStrokes = (p5, drawAll) => {
        // if !drawAll, just add last stroke

        let x, y; // cur point

        if (!strokes) return;
        let starti = strokes.length - 1;
        if (!strokes[starti]) return;
        if (starti < 0) return;

        if (drawAll) {
            p5.background(255);
            starti = 0;
        }

        p5.stroke(color2);
        p5.strokeWeight(strokeWeight2 * scaleFactor);
        for (let i = starti; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    if (reflectDirection === 0) {
                        x = p5.width - strokes[i][j].x;
                        y = strokes[i][j].y;
                    } else {
                        x = strokes[i][j].x;
                        y = p5.height - strokes[i][j].y;
                    }
                    p5.curveVertex(x, y);
                }
                p5.endShape();
            }
        }
        p5.stroke(color1);
        p5.strokeWeight(strokeWeight1 * scaleFactor);
        for (let i = starti; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    x = strokes[i][j].x;
                    y = strokes[i][j].y;
                    p5.curveVertex(x, y);
                }
                p5.endShape();
            }
        }
    };

    const clearDrawingHandler = (p5) => {
        strokes = [];
        redrawAllOnce = true;
        return true;
    }

    const undoHandler = (p5) => {
        if (strokes.length >= 1) {
            strokes.splice(strokes.length - 1);
        }
        redrawAllOnce = true;
    }

    function scaleValues(stroke) {
        let newStroke = [];
        for (let i = 0; i < stroke.length; i++) {
            newStroke.push(new Point(
                stroke[i].x / scaleFactor,
                stroke[i].y / scaleFactor,
                stroke[i].t
            ));
        }
        return newStroke;
    }

    const submitButtonHandler = () => {
        props.parentCallback(strokes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawReflection;
