import React, { useState, useEffect } from 'react';
import { getP5Tool } from '../../services/p5Tools';
import { SPECIAL_HEADLESS_CAPTURE_REF_VALUES } from '../../services/exportCanvas';
import * as firebaseService from '../../services/firebase.js';
import Spinner from './Spinner';

function WorkDisplay({ workData, size, p5name, workId, prompt, challengeDayParameters }) {
    const [p5FallbackElement, setP5FallbackElement] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (!workData) {
            setErrorMessage('No work data provided');
            return;
        }

        if (workData.imageRef && !Array.from(Object.values(SPECIAL_HEADLESS_CAPTURE_REF_VALUES)).includes(workData.imageRef)) {
            firebaseService.getDownloadUrl(workData.imageRef).then((url) => {
                if (url) {
                    setImageUrl(url);
                } else if (workData.url) {
                    setImageUrl(workData.url);
                }
            });
            return;  // Prefers `imageRef` (headless capture) than `url` (user capture)
        }

        if (workData.url) {
            setImageUrl(workData.url);
            return;
        }

        /** Some work may not have an image URL - either some error, or user closed page before saving
         * So we need to have a fallback to the display sketch here. */
        console.info('No image URL found, falling back to sketch display', workId)

        if (!workData.strokesUrl) {
            setErrorMessage('Cannot find this work');
            return;
        }

        fetch(workData.strokesUrl).then((res) => res.json()).then((data) => {
            const P5Tool = getP5Tool(p5name, true);
            setP5FallbackElement(<P5Tool
                data={data}
                displaySize={size}
                parameters={prompt.extraData.parameters}
                // parameters={challengeDayParameters || prompt.extraData.parameters}
            />);
        });
    }, [workData, p5name, size, prompt, challengeDayParameters, workId]);

    if (imageUrl) {
        return <img src={imageUrl} alt="work" width={size} height={size} data-work-id={workId} loading="lazy" />
    }

    if (p5FallbackElement) {
        return <div data-work-id={workId}>
            {p5FallbackElement}
        </div>;
    }

    return <div style={{ width: `${size}px`, height: `${size}px` }} data-work-id={workId}>
        {errorMessage || <Spinner/>}
    </div>;
}

export default WorkDisplay;
