import React, {useState, useEffect} from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawKeyFrameLoop(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokeWeight, setStrokeWeight] = useState(3);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let globalt = 0; // global time
    let offsett = 0;
    let pauset = 0;
    let lastStroket = 0;
    let isPaused = false;

    let frames = [[], [], [], [], []]; // 5 key frames
    let curFrame = 0;
    const numFrames = 5;
    const loopDuration = 2;
    let strokes = [];
    // let strokew = 3;  // Not used
    let curStroke = [];

    let frameOrder = []; // store record of which frame got the strokes in order, for undo purposes

    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;

        if (isDrawing && curStroke.length > 0) {
            // didn't call stopDrawing before calling startDrawing again
            strokes.push(curStroke);
            frameOrder.push(curFrame);
            curStroke = [];
        }

        offsett += 0.1; // add a tiny lag in case it *just* advanced
        globalt = current(p5);
        curFrame = getFrameFromTime(globalt);
        pauset = globalt;
        isPaused = true;

        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            strokes.push(curStroke);
            frameOrder.push(curFrame);
            curStroke = [];
            isDrawing = false;
            lastStroket = current(p5);
        }
    }


    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }


    function current(p5) {
        return (p5.millis() * 0.001 - offsett);
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempStrokeWeight = strokeWeight;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.strokeWeight) {
                tempStrokeWeight = parameters.strokeWeight;
                setStrokeWeight(tempStrokeWeight);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        const tempScaleFactor = props.displaySize / 500.;
        setScaleFactor(tempScaleFactor);
        p5.strokeWeight(tempStrokeWeight * tempScaleFactor);
        p5.strokeCap(p5.ROUND);
    };

    function getFrameFromTime(t) {
        let frameNum = Math.floor((t / loopDuration) * numFrames) % numFrames; // 6 frames in 2 secs
        return frameNum;
    }

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        if (isPaused) {
            // pause

            if (!isDrawing) {
                // pause an extra .25 secs after you stop drawing
                const cur = current(p5);
                if (cur > lastStroket + .25) {
                    offsett = offsett + (cur - pauset);
                    isPaused = false;
                    globalt = current(p5);
                }
            }
        } else {
            // update globalt
            globalt = current(p5);
        }
        curFrame = getFrameFromTime(globalt);

        p5.fill(0);
        p5.noStroke();
        const rectw = p5.width / numFrames;
        const px = rectw * curFrame;
        p5.rect(px, p5.height - 2, rectw, 2);

        p5.noFill();
        p5.stroke(0);

        strokes = frames[curFrame] || [];
        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    p5.curveVertex(strokes[i][j].x, strokes[i][j].y);
                }
                p5.endShape();
            }
        }
        if (curStroke) {
            p5.beginShape();
            for (let i = 0; i < curStroke.length; i++) {
                p5.curveVertex(curStroke[i].x, curStroke[i].y);
            }
            p5.endShape();
        }
    };

    const clearDrawingHandler = () => {
        strokes = [];
        frames = [];
        for (let i = 0; i < numFrames; i++) {
            curStroke = [];
            frames.push(curStroke);
        }
        frameOrder = [];
        return true;
    }

    const undoHandler = () => {
        if (frameOrder.length >= 1) {
            const whichFrame = frameOrder[frameOrder.length - 1];
            if (frames[whichFrame] && frames[whichFrame].length >= 1) {
                frames[whichFrame].pop();
                frameOrder.pop();
            }
        }
    }

    function scaleValues(strokes) {
        let newFrame = [];
        for (let i = 0; i < strokes.length; i++) {
            let newStroke = [];
            if (strokes[i][0] != null) {
                for (let j = 0; j < strokes[i].length; j++) {
                    newStroke.push(new Point(
                        strokes[i][j].x / scaleFactor,
                        strokes[i][j].y / scaleFactor,
                        strokes[i][j].t));
                }
                newFrame.push(newStroke);
            }
        }
        return newFrame;
    }

    const submitButtonHandler = () => {
        props.parentCallback([frames.map(scaleValues), frameOrder]);
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawKeyFrameLoop;
