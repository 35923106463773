import { AMPLITUDE_API_KEY, SENTRY_KEY } from '../SECRET';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from 'react-ga4';
import { IS_PROD, debugLogger } from './shared';
const amplitude = require('amplitude-js');

let amplitudeInstance;

export function init() {
    if (!IS_PROD) return;

    amplitudeInstance = amplitude.getInstance();
    amplitudeInstance.init(AMPLITUDE_API_KEY);

    Sentry.init({
        dsn: SENTRY_KEY,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });

    ReactGA.initialize('G-3ZCQSNYT6B');
}

export function setUser(user) {
    if (!IS_PROD) {
        debugLogger('[Tracking - setUser]', user.email, user.uid);
        return;
    }

    if (amplitudeInstance) {
        const identify = new amplitude.Identify().set('email', user.email);
        amplitudeInstance.setUserId(user.uid);
        amplitudeInstance.identify(identify);
    }

    Sentry.setUser({
        id: user.uid,
        email: user.email,
    });

    ReactGA.set({
        userId: user.uid
    });
}

export function logPageNavigation(path) {
    if (!IS_PROD) {
        debugLogger('[Tracking - logPageNavigation]', path);
        return;
    }

    logAmplitude(`Navigate to ${path}`, { 'Full path': window.location.pathname });
    logGA('Navigation', 'Navigation', path);
    ReactGA.set({ page: path }); // Update the user's current page
    ReactGA.send({ hitType: "pageview", page: path });
}

// Log a simple event without additional data to both Amplitude and GA
export function logSimpleEvent(category, action = '') {
    if (!IS_PROD) {
        debugLogger('[Tracking - logSimpleEvent]', category, action);
        return;
    }

    logAmplitude(`${category}${action && ' - '}${action}`);
    logGA(category, action);
}

// Amplitude data can be an object
export function logAmplitude(type, data) {
    if (!IS_PROD) {
        debugLogger('[Tracking - logAmplitude]', type, data);
        return;
    }

    if (amplitudeInstance) {
        amplitudeInstance.logEvent(type, data);
    };
}

// GA fields are strings
export function logGA(category, action, label) {
    if (!IS_PROD) {
        debugLogger('[Tracking - logGA]', category, action, label);
        return;
    }

    ReactGA.event({
        category,
        action,
        label,
    });
}

export function logSentry(content, isMessage) {
    if (!IS_PROD) {
        debugLogger('[Tracking - logSentry]', content);
        return;
    }

    if (isMessage) {
        Sentry.captureMessage(content);
    } else {
        Sentry.captureException(content);
    }
}
