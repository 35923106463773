import React, { useState, useEffect } from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayTangram(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [shapes, setShapes] = useState([]);
    const [renderP5, setRenderP5] = useState(true);
    // colors is needed in Draw component, but here the colors are sent in through the saved data
    // let colors = ["#FFF61F", "#FF5C46", "#30CDFF", "#CCE530", "#FD52A4", "#1A98DF", "#28D999"];

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Dshape = function (points, color) {
        this.points = points.slice();
        this.rot = 0;
        this.targetrot = 0;
        this.rotating = false;
        this.color = color;
        this.selected = false;
        this.cx = 0;
        this.cy = 0;

        this.l = 0; // length, approximate
        if (points.length < 1) return;

        this.minx = this.maxx = this.points[0][0];
        this.miny = this.maxy = this.points[0][1];
        for (let i = 0; i < this.points.length; i++) {
            let tempx = this.points[i][0];
            let tempy = this.points[i][1];
            if (tempx < this.minx) this.minx = tempx;
            if (tempx > this.maxx) this.maxx = tempx;
            if (tempy < this.miny) this.miny = tempy;
            if (tempy > this.maxy) this.maxy = tempy;
            this.cx += tempx;
            this.cy += tempy;
        }
        // calculate centroid
        this.cx = this.cx / this.points.length;
        this.cy = this.cy / this.points.length;
        let w = this.maxx - this.minx;
        let h = this.maxy - this.miny;
        if (w > h) this.l = w;
        else this.l = h;
    }

    Dshape.prototype.draw = function (p5) {
        // update rotation
        if (this.rotating) {
            let drot = 0.4 * (this.targetrot - this.rot);
            if (drot < 0.01) {
                drot = this.targetrot - this.rot;
                this.rotate(drot, this.cx, this.cy);
                this.rot = this.targetrot;
                this.rotating = false;
            }
            else this.rotate(drot, this.cx, this.cy);
        }

        p5.push();

        p5.fill(this.color);
        p5.beginShape();
        for (let i = 0; i < this.points.length; i++) {
            p5.vertex(this.points[i][0], this.points[i][1]);
        }
        p5.endShape(p5.CLOSE);
        p5.pop();
    }

    Dshape.prototype.scale = function (s) {
        for (let i = 0; i < this.points.length; i++) {
            this.points[i][0] *= s;
            this.points[i][1] *= s;
        }
        this.cx *= s;
        this.cy *= s;
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.strokeCap(p5.SQUARE);

        let shapesData = props.data;
        const tempShapes = [];

        for (let i = 0; i < shapesData.length; i++) {
            let s = shapesData[i];
            tempShapes.push(new Dshape(s.points, s.color));
        }
        setShapes(tempShapes);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor);

        p5.noStroke();
        for (let i = 0; i < shapes.length; i++) {
            shapes[i].draw(p5);
        }

        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayTangram;
