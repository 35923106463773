import React, { useEffect, useState } from 'react';
import * as firebaseService from '../../services/firebase.js';
import * as trackingService from '../../services/tracking.js';
import Spinner from './Spinner';
import Backdrop from './Backdrop';
import { IS_PROD, debugLogger } from '../../services/shared.js';
import { shareToFriend } from '../../services/sendgrid';
import { SPECIAL_HEADLESS_CAPTURE_REF_VALUES, headlessCapture, downloadFile } from '../../services/exportCanvas';
import sharedClasses from '../../shared.module.css';
import classes from './ShareImageButton.module.css';

const BUTTON_TEXT = {
    GENERATING: 'Generating shareable image',
    READY: 'Download image',
    DOWNLOADING: <>Downloading image&nbsp; <Spinner inline /></>,
    FAILED: 'Downloading failed. Try again',
}
let _debugClickStartTime;  // A value for logging the time in each stage. Not using a state, because this is within the function call, where the state value won't be updated.

function ShareImageButton({ workId }) {
    const [buttonContent, setButtonContent] = useState(BUTTON_TEXT.READY);
    const [imageRef, setImageRef] = useState(null);
    const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
    const [shareEmails, setShareEmails] = useState('');
    const [sendButtonText, setSendButtonText] = useState('Send');
    const [sendCopyToMe, setSendCopyToMe] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [emailMessage, setEmailMessage] = useState('This drawing made me think of you!');

    useEffect(() => {
        if (!IS_PROD) {
            _debugClickStartTime = Date.now();
            debugLogger('[Share button] Checking image ref', _debugClickStartTime);
        }
        let hasFoundOnce = false;  // Only trigger for the first ref received. If the capturing is triggered multiple times (by this button, or anywhere else), ignore following responses
        firebaseService.checkImageRef(workId, true, (value) => {
            if (hasFoundOnce) return;

            switch(value) {
                case SPECIAL_HEADLESS_CAPTURE_REF_VALUES.CAPTURING:
                    setButtonContent(BUTTON_TEXT.GENERATING);
                    break;
                case SPECIAL_HEADLESS_CAPTURE_REF_VALUES.ERROR:
                case null:
                case undefined:
                case '':
                    // If no image is there, request to capture one
                    debugLogger('[Share button] No ref found. Requesting capturing', `${Date.now() - _debugClickStartTime}ms`);
                    headlessCapture(workId, 1080, true);
                    break;
                default:
                    hasFoundOnce = true;
                    debugLogger('[Share button] Found ref. Start downloading', value, `${Date.now() - _debugClickStartTime}ms`);
                    setImageRef(value);
                    setButtonContent(BUTTON_TEXT.READY);
            }
        });
    }, [workId]);

    function shareImage() {
        trackingService.logSimpleEvent('Download Image', 'Click download');
        setButtonContent(BUTTON_TEXT.DOWNLOADING);
        if (navigator && navigator.share && navigator.canShare) {  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#browser_compatibility
            // Get the binary and trigger the WebShare
            debugLogger('[Share button] Trying WebShare', `${Date.now() - _debugClickStartTime}ms`);
            firebaseService.getDownloadUrl(imageRef).then((url) => {
                if (!url) throw new Error(`No download url found for ${imageRef}`);
                return fetch(url).then((res) => res.blob());
            }).then((blob) => {
                if (!blob) throw new Error(`No blob found for ${imageRef}`);
                const file = new File([blob], `YDays-${imageRef.replace(/.*\//g, '')}`, { type: `image/${imageRef.replace(/.*\./g, '')}` });
                const payload = { files: [file] };
                if (!navigator.canShare(payload)) {  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/canShare#browser_compatibility
                    throw new Error(`Cannot share this file ${imageRef}`);
                }
                debugLogger('[Share button] Trigger WebShare', `${Date.now() - _debugClickStartTime}ms`);
                return navigator.share(payload).catch((error) => {
                    // User canceling the panel is a reject as well. Ignoring that case.
                    if (!error.message.includes('cancel')) throw error;
                });
            }).catch((error) => {
                // It is expected to fail for the 1st run, with "webshare must be triggered by user action" error
                if (!error.message.includes('user gesture')) {
                    console.error(error);
                    trackingService.logSentry(error);
                }
                debugLogger('[Share button] WebShare failed. Falling back to download', `${Date.now() - _debugClickStartTime}ms`);
                return downloadFile(imageRef);
            }).then(() => {
                trackingService.logSimpleEvent('Download Image', 'Downloaded');
                setButtonContent(BUTTON_TEXT.READY);
            }).catch((error) => {
                console.error(error);
                trackingService.logSentry(error);
                setButtonContent(BUTTON_TEXT.FAILED);
            });
        } else {
            // WebShare is not supported. Trigger the download image
            debugLogger('[Share button] Trigger downloading', `${Date.now() - _debugClickStartTime}ms`);
            downloadFile(imageRef).then(() => {
                trackingService.logSimpleEvent('Download Image', 'Downloaded');
                setButtonContent(BUTTON_TEXT.READY);
            }).catch((error) => {
                console.error(error);
                trackingService.logSentry(error);
                setButtonContent('Failed to download. Try again');
            });
        }
    }

    function openSendInput() {
        setSendButtonText('Send');
        setShareEmails('');
        setIsSending(false);
        setIsEmailDialogOpen(true);
    }

    async function sendEmail() {
        try {
            setSendButtonText('Sending...');
            setIsSending(true);
            trackingService.logSimpleEvent('Share Image', 'Email share');
            const [imageUrl, profile] = await Promise.all([
                firebaseService.getDownloadUrl(imageRef),
                firebaseService.loadCurrentUserProfile(),
            ]);
            const parsedEmails = shareEmails.split(',').map((email) => email.trim()).filter(Boolean);
            const toEmails = sendCopyToMe ? [...parsedEmails, profile.email] : parsedEmails;
            await shareToFriend({
                toEmails,
                authorName: profile.name,
                authorAvatar: profile.avatarPath,
                workImage: imageUrl,
                message: emailMessage,
            });
            setSendButtonText('Sent!');
            setTimeout(() => {
                setShareEmails('');
                setIsSending(false);
                setIsEmailDialogOpen(false);
            }, 1000);
        } catch (error) {
            setSendButtonText(`Failed to send: ${error?.errors?.[0]?.message}`);
            setTimeout(() => {
                setSendButtonText('Send');
                setIsSending(false);
            }, 2000);
        }
    }

    return <>
        <button className={sharedClasses.secondaryButtonOutline} onClick={shareImage} disabled={!imageRef}>{buttonContent}</button>
        {imageRef && <button className={`${sharedClasses.secondaryButtonOutline} ${classes.emailButton}`} onClick={openSendInput} disabled={isEmailDialogOpen}>Send as an e-card</button>}
        <Backdrop show={isEmailDialogOpen} clicked={() => setIsEmailDialogOpen(false)}></Backdrop>
        {isEmailDialogOpen && <div className={classes.sendEmailDialog}>
            <h2>Send your drawing to a friend</h2>
            <label>
                Email(s)
                <input value={shareEmails} onChange={(event) => setShareEmails(event.target.value)} type="text" placeholder="Email address(es), separated by commas" />
            </label>
            <label>
                Your message (optional)
                <textarea value={emailMessage} onChange={(event) => setEmailMessage(event.target.value)}></textarea>
            </label>
            <label>
                <input checked={sendCopyToMe} onChange={(event) => setSendCopyToMe(event.target.checked)} type="checkbox" />
                Send a copy to my email
            </label>
            <button className={sharedClasses.primaryButton} onClick={sendEmail} disabled={isSending || !shareEmails}>{sendButtonText}</button>
            <button className={`${sharedClasses.closeButton} ${sharedClasses.corner}`} onClick={() => setIsEmailDialogOpen(false)}></button>
        </div>}
    </>;
}

export default ShareImageButton;
