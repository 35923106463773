import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayAlternatingPen(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokes, setStrokes] = useState([]);
    const [penColors, setPenColors] = useState(["#FF5C46AA", "#000000FF"]); // 2nd, 4th, etc strokes will be drawn after 1st, 3rd
    const [penWeights, setPenWeights] = useState([24, 6]);
    const [strokeCap, setStrokeCap] = useState("ROUND");
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let curStroke = [];

    function addPoint(p5, pX, pY, t) {
        curStroke.push(new Point(pX, pY, t));
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.penColors && parameters.penWeights) {
                setPenColors(parameters.penColors);
                setPenWeights(parameters.penWeights);
            }
            if (parameters.strokeCap) {
                setStrokeCap(parameters.strokeCap);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);

        if (strokeCap === "SQUARE")
            p5.strokeCap(p5.SQUARE);
        else if (strokeCap === "PROJECT")
            p5.strokeCap(p5.PROJECT);
        else
            p5.strokeCap(p5.ROUND);
        p5.strokeJoin(p5.ROUND);
        p5.curveTightness(0);
        p5.noFill();

        let strokeData = props.data;
        const tempStrokes = [];
        for (let i = 0; i < strokeData.length; i++) {
            if (strokeData[i] != null) {
                let stroke = strokeData[i];
                for (let k = 0; k < stroke.length; k++) {
                    if (stroke[k] != null) {
                        let pt = stroke[k];
                        addPoint(p5, pt.x, pt.y, pt.t);
                    }
                }
                tempStrokes.push(curStroke);
                curStroke = [];
            }
        }
        setStrokes(tempStrokes);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor, scaleFactor);

        const numStrokes = penColors.length;

        for (let i = 0; i < strokes.length; i += 2) {
            if (strokes[i][0] != null) {
                p5.stroke(penColors[i % numStrokes]);
                p5.strokeWeight(penWeights[i % numStrokes]);

                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    p5.curveVertex(strokes[i][j].x, strokes[i][j].y);
                }
                p5.endShape();
            }
        }
        for (let i = 1; i < strokes.length; i += 2) {
            if (strokes[i][0] != null) {
                p5.stroke(penColors[i % numStrokes]);
                p5.strokeWeight(penWeights[i % numStrokes]);

                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    p5.curveVertex(strokes[i][j].x, strokes[i][j].y);
                }
                p5.endShape();
            }
        }

        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };



    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayAlternatingPen;
