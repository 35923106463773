import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayCircles(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [circles, setCircles] = useState([]);
    const [mode, setMode] = useState("NORMAL"); // or: "DIAMONDS" for rounded diamond shapes, or "RECTS" for rounded rects
    const [bgColor, setBgColor] = useState("#FFFFFF"); // background, don't need fgColor because circle color is stored in data
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Circle = function(x, y, d, clr) {
        this.x = x;
        this.y = y;
        this.d = d;
        this.clr = clr;
    }

    Circle.prototype.draw = function(p5) {
        p5.fill(this.clr);
        p5.noStroke();
        let c;
        if (mode === "DIAMONDS") {
            c = 0.4; // 0.552 is approximation for a circle
            p5.push();
            p5.translate(this.x, this.y);
            p5.scale(this.d * 0.5, this.d * 0.5);

            p5.beginShape();
            p5.vertex(0, -1);
            p5.bezierVertex(-1 * c, -1,  -1, -1 * c,  -1, 0);
            p5.bezierVertex(-1, 1 * c,  -1 * c, 1,  0, 1);
            p5.bezierVertex(1 * c, 1,  1, 1 * c,  1, 0);
            p5.bezierVertex(1, -1 * c,  1 * c, -1,  0, -1);
            p5.endShape();
            p5.pop();
        } else if (mode === "RECTS") {
            c = 0.76; // 0.552 is approximation for a circle
            p5.push();
            p5.translate(this.x, this.y);
            p5.scale(this.d * 0.5, this.d * 0.5);
            p5.beginShape();
            p5.vertex(0, -1);
            p5.bezierVertex(-1 * c, -1,  -1, -1 * c,  -1, 0);
            p5.bezierVertex(-1, 1 * c,  -1 * c, 1,  0, 1);
            p5.bezierVertex(1 * c, 1,  1, 1 * c,  1, 0);
            p5.bezierVertex(1, -1 * c,  1 * c, -1,  0, -1);
            p5.endShape();
            p5.pop();
        } else {
            p5.ellipse(this.x, this.y, this.d, this.d);
        }
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.mode) {
                setMode(parameters.mode);
            }
            if (parameters.bgColor) {
                setBgColor(parameters.bgColor);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        
        const tempCircles = [];
        props.data.forEach(function(c) {
            tempCircles.push(new Circle(c.x, c.y, c.d, c.clr));
        });
        setCircles(tempCircles);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(bgColor);
        p5.scale(scaleFactor, scaleFactor);
        //const curtimefactor = p5.millis() * .16;
        for (let i = 0; i < circles.length; i++) {
            let c = circles[i];
            c.draw(p5);
            //if (i > curtimefactor) return;
        }
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };



    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayCircles;
