import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classes from '../pages/Home.module.css';
import sharedClasses from '../../shared.module.css';
import ProgressTable from '../UI/ProgressTable';
import * as firebaseService from '../../services/firebase.js';
import * as authService from '../../services/auth.js';
import { ONE_DAY_IN_MILLISECONDS, getFriendlyTime } from '../../services/shared';
import Backdrop from './Backdrop';
import { useNowWithUrlOverride } from '../../services/hooks';
import closeButton from '../../assets/icon-close.svg';

function ChallengeSummary(props) {
    const history = useHistory();
    const [numberCompletedToday, setNumberCompletedToday] = useState(0);
    const [currentDayIndex, setCurrentDayIndex] = useState(0);
    const [dayProgressMessage, setDayProgressMessage] = useState('');
    const [todayGreeting, setTodayGreeting] = useState('');
    const [todayMessage, setTodayMessage] = useState('');
    const [hasReviewed, setHasReviewed] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [lengthInDays, setLengthInDays] = useState();
    const [isAuthor, setIsAuthor] = useState(false);
    const [numberInChallenge, setNumberInChallenge] = useState(0);
    const [showShareModal, setShowShareModal] = useState(false);
    const [message, setMessage] = useState('');
    const [currentUserUid] = useState(authService.getCurrentUserDirect()?.uid);
    const now = useNowWithUrlOverride();

    useEffect(() => {
        setCurrentDayIndex(Math.floor((now.getTime() - props.challenge.startTimestamp) / ONE_DAY_IN_MILLISECONDS));
        setLengthInDays(props.challenge.lengthInDays);
        if (props.challenge.author === currentUserUid) {
            setIsAuthor(true);
        }
        setNumberInChallenge(props.challenge.members ? props.challenge.members.length : 0);
    }, [props.challenge, now, currentUserUid]);

    useEffect(() => {
        const submissions = props.challenge.prompts?.[currentDayIndex]?.submissions || {};
        setNumberCompletedToday(Object.keys(submissions).length);
        setHasSubmitted(!!submissions[currentUserUid]);
    }, [props.challenge, currentDayIndex, currentUserUid]);

    useEffect(() => {
        let hasUnmounted = false;
        const yesterdaySubmissions = props.challenge.prompts?.[currentDayIndex - 1]?.submissions || {};
        Promise.all(Array.from(Object.values(yesterdaySubmissions)).flat().map((workId) => firebaseService.loadWork(workId))).then((works) => {
            if (hasUnmounted) return;

            for (const work of works) {
                if (Array.from(Object.values(work?.reactions || {})).flat().includes(currentUserUid)) {
                    setHasReviewed(true);
                    return;  // Break out of the whole process when we found the first vote
                }
            }
            setHasReviewed(false);
        });
        return () => { hasUnmounted = true; };
    }, [props.challenge, currentDayIndex, currentUserUid]);

    useEffect(() => {
        if (currentDayIndex < -1) {
            // More than one day before
            setDayProgressMessage('Starts in ' + (-currentDayIndex) + ' days');
            setTodayGreeting("Get excited");
            setTodayMessage("Come back to start this challenge in " + (-currentDayIndex) + " days.");
        } else if (currentDayIndex < 0) {
            // One day before
            setDayProgressMessage('Starts in ' + (-currentDayIndex) + ' day');
            setTodayGreeting("The anticipation is palpable");
            setTodayMessage("This challenge goes live at " + getFriendlyTime(new Date(props.challenge.startTimestamp)) + " your time — make sure to come back!");
        } else if (currentDayIndex >= lengthInDays) {
            // Ended
            setDayProgressMessage('Completed on ' + new Date(props.challenge.startTimestamp + ONE_DAY_IN_MILLISECONDS * props.challenge.lengthInDays).toLocaleDateString());
            setTodayGreeting("About this challenge");
            setTodayMessage("Your challenge is over. See how everyone did in the challenge summary.");
        } else {
            // In progress
            setDayProgressMessage('Day ' + (currentDayIndex + 1) + ' of ' + lengthInDays);
            const todayStrings = ['Today', 'Whoo hoo!', 'Up next', 'Let’s get going!', 'Time to get creative', 'On deck for today', 'You got this!', 'Ready to go?', 'Latest on this challenge', 'Today’s the day', 'Seize the day', 'Get pumped!'];
            const chooseString = Math.floor(Math.random() * todayStrings.length);
            setTodayGreeting(todayStrings[chooseString]);

            if (hasSubmitted) {
                setTodayMessage("You’ve submitted for today already. Great job!");
            } else if (isAuthor && numberInChallenge === 1 && !numberCompletedToday) {
                setTodayMessage("YDays is more fun with others — invite people to join your challenge.");
            } else if (!numberCompletedToday) {
                setTodayMessage("Heyo, none of your teammates have completed today’s prompt yet. You can be the first.");
            } else if (numberCompletedToday === 1) {
                setTodayMessage("One of your teammates has completed today’s prompt so far. Let’s get started.");
            } else {
                setTodayMessage("Hurrah, " + numberCompletedToday + " of your teammates have completed today’s prompt. Let’s get started.");
            }
        }
    }, [props.challenge, lengthInDays, currentDayIndex, numberCompletedToday, hasSubmitted, numberInChallenge, isAuthor]);

    const handleOpenReview = (challengeId, day) => {
        history.push('/c/' + challengeId + '/' + day + '/view');
    }

    const handleOpenPrompt = (challengeId, day) => {
        history.push('/c/' + challengeId + '/' + day);
    }

    const handleOpenSummary = (challengeId) => {
        history.push('/c/' + challengeId);
    }

    const handleOpenModal = () => {
        setMessage('');
        setShowShareModal(true);
    }
    const handleCloseModal = () => {
        setMessage('');
        setShowShareModal(false);
    }
    const handleCopyLink = () => {
        const copyText = document.getElementById("challengeLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy')
        copyText.setSelectionRange(0, 0);
        setMessage('Copied to clipboard!');
    }
    let challengeCompleted = currentDayIndex >= lengthInDays + 1;

    return <div className={challengeCompleted ? classes.contentBoxMinimize : classes.contentBox}>
        <div className={classes.challengeTop}>
            <Link className={classes.kicker} to={{ pathname: `/c/${props.challengeId}` }}>{props.challenge.title}&nbsp;›</Link>
            <p className={classes.dayProgress}>{dayProgressMessage}</p>
        </div>
        {!challengeCompleted ?
            <>
                <h3>{todayGreeting}</h3>
                <div className={classes.challengeLayout}>
                    <p className={classes.challengeMessage}>{todayMessage}</p>
                    <div className={classes.challengeButtons}>
                        {(currentDayIndex - 1) >= 0 && currentDayIndex - 1 <= lengthInDays - 1 ? <button onClick={() => handleOpenReview(props.challengeId, (currentDayIndex - 1))} className={hasReviewed ? sharedClasses.primaryButtonOutline : sharedClasses.primaryButton}>Review Day {currentDayIndex}</button> : null}
                        {currentDayIndex >= 0 && currentDayIndex <= lengthInDays - 1 ? <button onClick={() => handleOpenPrompt(props.challengeId, currentDayIndex)} className={hasSubmitted ? sharedClasses.primaryButtonOutline : sharedClasses.primaryButton}>Today’s prompt</button> : null}
                        {currentDayIndex >= lengthInDays ? <button onClick={() => handleOpenSummary(props.challengeId)} className={sharedClasses.primaryButton}>Challenge summary</button> : null}
                    </div>
                    <div className={classes.challengeMembers}>
                        <ProgressTable
                            challenge={props.challenge}
                            challengeId={props.challengeId}
                            currentDayIndex={currentDayIndex >= 0 && currentDayIndex < lengthInDays ? currentDayIndex : 0}
                            compactView></ProgressTable>
                        {isAuthor && currentDayIndex < lengthInDays ? <button className={classes.mediumBlackOutline} onClick={handleOpenModal}>+ Invite more people</button> : null}
                    </div>
                </div>
                <Backdrop show={showShareModal} clicked={handleCloseModal}></Backdrop>
                {showShareModal ?
                    <div className={classes.shareModal}>
                        <div className={classes.closeButton}>
                            <img src={closeButton} className={classes.icon} alt="X" onClick={handleCloseModal} />
                        </div>
                        <p className={classes.shareMessage}><b>Share this link</b> to invite people to your challenge. They can join up until the last day.</p>
                        <div className={classes.challengeLinkHolder}>
                            <input className={classes.challengeLink} id="challengeLink" value={"https://ydays.com/c/" + props.challengeId} readOnly></input>
                            <button className={classes.copyLink} onClick={handleCopyLink}>Copy</button>
                        </div>
                        <span className={classes.copyMessage}>{message}&nbsp;</span>
                    </div>
                    : null}
            </>
            : null
        }
    </div >
}

export default ChallengeSummary;
