import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom'

import * as firebaseService from '../../services/firebase.js';
import sharedClasses from '../../shared.module.css';
import ErrorBoundary from '../ErrorBoundary.js';
import Review from '../UI/Review';

function ReviewPage() {
    const params = useParams();
    const [promptIndex, setPromptIndex] = useState(null);
    const [arrowButtons, setArrowButtons] = useState(null);
    const [title, setTitle] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [challengeLength, setChallengeLength] = useState(0);

    useEffect(() => {
        setPromptIndex(Number(params.promptIndex));
        setTitle(`Review Day ${Number(params.promptIndex) + 1}`);
    }, [params.promptIndex]);

    useEffect(() => {
        if (isAdmin) {
            setArrowButtons(<>
                <Link to={{ pathname: `/c/${params.challengeId}/${promptIndex}` }}
                    className={sharedClasses.flatButton}>
                ←</Link>
                <Link to={{ pathname: `/c/${params.challengeId}/${promptIndex + 1}` }}
                    className={sharedClasses.flatButton}
                    disabled={promptIndex >= challengeLength - 1}>
                →</Link>
            </>);
        }
    }, [isAdmin, promptIndex, params.challengeId, challengeLength]);

    useEffect(() => {
        firebaseService.loadCurrentUserProfile().then((profile) => {
            setIsAdmin(profile?.role === 'admin');
        });
    }, []);

    return <>
        <Helmet>
            <title>{`Review day ${promptIndex + 1} | YDays`}</title>
        </Helmet>

        <ErrorBoundary errorMessage="Eh-oh, couldn’t load the review page.">
            <Review promptIndex={promptIndex}
                challengeId={params.challengeId}
                arrowButtons={arrowButtons}
                title={title}
                todayPromptUrl={`/c/${params.challengeId}/${promptIndex + 1}`}
                updateChallengeLength={setChallengeLength} />
        </ErrorBoundary>
    </>;
}

export default ReviewPage;
