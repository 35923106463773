import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams, useHistory } from 'react-router-dom'
import ErrorBoundary from '../ErrorBoundary.js';
import * as firebaseService from '../../services/firebase.js';
import sharedClasses from '../../shared.module.css';
import Draw from '../UI/Draw';

function DrawPage() {
    const params = useParams();
    const [promptIndex, setPromptIndex] = useState(null);
    const [arrowButtons, setArrowButtons] = useState(null);
    const [title, setTitle] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const index = Number(params.promptIndex);
        if (isNaN(index)) {
            history.push('/');
        }
        setPromptIndex(index);
        setTitle(`Day ${index + 1}: Prompt`);
    }, [params.promptIndex, history]);

    useEffect(() => {
        if (isAdmin) {
            setArrowButtons(<>
                <Link to={{ pathname: `/c/${params.challengeId}/${promptIndex - 1}/view` }}
                    className={sharedClasses.flatButton}
                    disabled={promptIndex === 0}>
                ←</Link>
                <Link to={{ pathname: `/c/${params.challengeId}/${promptIndex}/view` }}
                    className={sharedClasses.flatButton}>→</Link>
            </>);
        }
    }, [isAdmin, promptIndex, params.challengeId]);

    useEffect(() => {
        firebaseService.loadCurrentUserProfile().then((profile) => {
            setIsAdmin(profile?.role === 'admin');
        });
    }, []);

    return <>
        <Helmet>
            <title>{`Day ${promptIndex + 1} Prompt | YDays`}</title>
        </Helmet>
        <ErrorBoundary errorMessage="Eh-oh, couldn’t load works.">
            <Draw promptIndex={promptIndex}
                challengeId={params.challengeId}
                arrowButtons={arrowButtons}
                title={title} />
        </ErrorBoundary>
    </>;
}

export default DrawPage;
