import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { Helmet } from 'react-helmet';

import classes from './EditAvatar.module.css';
import P5DrawAvatar from '../P5js/P5DrawAvatar';
import * as trackingService from '../../services/tracking.js';
import * as firebaseService from '../../services/firebase.js';
import * as authService from '../../services/auth.js';
import { capturePng } from '../../services/exportCanvas.js';
import { decideSketchSize } from '../../services/shared';

function EditAvatar() {
    const currentUser = authService.getCurrentUserDirect();
    const [message, setMessage] = useState('');
    const history = useHistory();
    const location = useLocation();
    const [strokes, setStrokes] = useState(''); // stroke state from DB
    const [fgColor, setFgColor] = useState(''); // foreground color from DB
    const [bgColor, setBgColor] = useState(''); // background color from DB
    const [isReady, setIsReady] = useState(false);// is profile data loaded

    // Reactive update state/UI once profile data is here
    useEffect(()=>{
        getAvatarStrokes(currentUser.uid);
    },[currentUser.uid]);

    // Init the stroke state from user profile
    const getAvatarStrokes = async (uid) => {

        // get the profile
        let profile = await firebaseService.loadProfile(uid);

        if(!profile?.avatar) {
            setIsReady(true);
            return;
        }

        // if profile, set all the available props
        // from its avatar property
        if( profile.avatar.hasOwnProperty('strokes') ) {
            try {
                setStrokes(JSON.parse(profile.avatar.strokes));
            } catch(err) {
                console.warn(`JSON.parse can't operate on: ${profile.avatar.strokes}`);
            }
        }
        if( profile.avatar.hasOwnProperty('fgColor') ) {
            setFgColor(profile.avatar.fgColor);
        }
        if( profile.avatar.hasOwnProperty('fgColor') ) {
            setBgColor(profile.avatar.bgColor);
        }

        // tell the component the profile data is read
        setIsReady(true);
    }

    const submitDataHandler = (strokesFinal, fgColorFinal, bgColorFinal) => {
        trackingService.logSimpleEvent('Edit Avatar', 'Submit');

        // update the state before capturePNG causes
        // reactive rerender of the component
        setStrokes(strokesFinal);
        setFgColor(fgColorFinal);
        setBgColor(bgColorFinal);

        // Save the avatar stroke & color data
        firebaseService.updateProfile({
            avatar : {
                strokes: JSON.stringify(strokesFinal),
                fgColor: fgColorFinal,
                bgColor: bgColorFinal
            }
        });

        setMessage('Saving image!');
        capturePng(document.querySelector('canvas.p5Canvas'))
            .then((blob) => {
                return firebaseService.saveBlob(blob, `avatars/${currentUser.uid}.png`, "image/png");
            })
            .then((url) => {
                return firebaseService.updateProfile({ avatarPath: url })
            })
            .then(() => {
                if (location?.state?.returnHome) {
                    // Was sent this parameter if got to this page directly from the home page, so return to home
                    history.push('/home');
                } else {
                    // Got here from the settings page
                    history.push('/settings');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    let promptClass = classes.Prompt;
    if (isMobile) {
        promptClass = `${classes.Prompt} ${classes.Mobile}`;
    }

    const interactivePromptComponent = <P5DrawAvatar displaySize={decideSketchSize('prompt')} history={history} parentCallback={submitDataHandler} strokes={strokes} fgColor={fgColor} bgColor={bgColor} />;

    return (
        <div className={promptClass}>
            <Helmet>
                <title>Edit your avatar | YDays</title>
            </Helmet>
            <div className={classes.content}>
                <div className={classes.sketchLayout}>
                    <div className={classes.sketchText}>
                        <div className={classes.PromptHeader}>
                            <Link to={{ pathname: `/settings` }} className={classes.back}>← Your settings</Link>
                            <h1>Edit your image</h1>
                            <p><span className={classes.promptInstructions}>Draw a picture to represent you on YDays.</span></p>
                        </div>
                    </div>
                    <div className={classes.sketch}>{isReady && interactivePromptComponent}<span className={classes.message}>{message}</span></div>

                </div>
            </div>
        </div>
    );
}

export default EditAvatar;
