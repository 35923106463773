export const adj = ["agreeable",
    "amused",
    "ancient",
    "angry",
    "annoyed",
    "anxious",
    "arrogant",
    "ashamed",
    "average",
    "awful",
    "bad",
    "beautiful",
    "better",
    "big",
    "bitter",
    "black",
    "blue",
    "boiling",
    "brave",
    "breezy",
    "brief",
    "bright",
    "broad",
    "broken",
    "bumpy",
    "calm",
    "careful",
    "charming",
    "cheerful",
    "chilly",
    "clumsy",
    "cold",
    "colossal",
    "combative",
    "comfortable",
    "confused",
    "cooing",
    "cool",
    "cooperative",
    "courageous",
    "crazy",
    "creepy",
    "cruel",
    "cuddly",
    "curly",
    "curved",
    "damp",
    "dangerous",
    "deafening",
    "deep",
    "defeated",
    "defiant",
    "delicious",
    "delightful",
    "depressed",
    "determined",
    "dirty",
    "disgusted",
    "disturbed",
    "dizzy",
    "dry",
    "dull",
    "dusty",
    "eager",
    "early",
    "elated",
    "embarrassed",
    "empty",
    "encouraging",
    "energetic",
    "enthusiastic",
    "envious",
    "evil",
    "excited",
    "exuberant",
    "faint",
    "fair",
    "faithful",
    "fantastic",
    "fast",
    "fat",
    "few",
    "fierce",
    "filthy",
    "fine",
    "flaky",
    "flat",
    "fluffy",
    "foolish",
    "forlorn",
    "frail",
    "frantic",
    "fresh",
    "friendly",
    "frightened",
    "funny",
    "fuzzy",
    "gentle",
    "giant",
    "gigantic",
    "good",
    "gorgeous",
    "greasy",
    "great",
    "green",
    "grieving",
    "grubby",
    "grumpy",
    "handsome",
    "happy",
    "hard",
    "harsh",
    "healthy",
    "heavy",
    "helpful",
    "helpless",
    "high",
    "hilarious",
    "hissing",
    "hollow",
    "homeless",
    "horrible",
    "hot",
    "huge",
    "hungry",
    "hurt",
    "hushed",
    "husky",
    "icy",
    "ill",
    "immense",
    "itchy",
    "jealous",
    "jittery",
    "jolly",
    "juicy",
    "kind",
    "large",
    "late",
    "lazy",
    "light",
    "little",
    "lively",
    "lonely",
    "long",
    "loose",
    "loud",
    "lovely",
    "low",
    "lucky",
    "magnificent",
    "mammoth",
    "many",
    "massive",
    "melodic",
    "melted",
    "mighty",
    "miniature",
    "moaning",
    "modern",
    "mute",
    "mysterious",
    "narrow",
    "nasty",
    "naughty",
    "nervous",
    "new",
    "nice",
    "nosy",
    "numerous",
    "nutty",
    "obedient",
    "obnoxious",
    "odd",
    "old",
    "orange",
    "ordinary",
    "outrageous",
    "panicky",
    "perfect",
    "petite",
    "plastic",
    "pleasant",
    "precious",
    "pretty",
    "prickly",
    "proud",
    "puny",
    "purple",
    "purring",
    "quaint",
    "quick",
    "quickest",
    "quiet",
    "rainy",
    "rapid",
    "rare",
    "raspy",
    "ratty",
    "red",
    "relieved",
    "repulsive",
    "resonant",
    "ripe",
    "roasted",
    "robust",
    "rotten",
    "rough",
    "round",
    "sad",
    "salty",
    "scary",
    "scattered",
    "scrawny",
    "screeching",
    "selfish",
    "shaggy",
    "shaky",
    "shallow",
    "sharp",
    "shivering",
    "short",
    "shrill",
    "silent",
    "silky",
    "silly",
    "skinny",
    "slimy",
    "slippery",
    "slow",
    "small",
    "smart",
    "smiling",
    "smooth",
    "soft",
    "solid",
    "sore",
    "sour",
    "spicy",
    "splendid",
    "spotty",
    "square",
    "squealing",
    "stale",
    "steady",
    "steep",
    "sticky",
    "strange",
    "striped",
    "strong",
    "stupendous",
    "successful",
    "sweet",
    "swift",
    "tall",
    "tame",
    "tan",
    "tart",
    "tasteless",
    "tasty",
    "tense",
    "terrible",
    "testy",
    "thirsty",
    "thoughtful",
    "thoughtless",
    "thundering",
    "tight",
    "tiny",
    "tired",
    "tough",
    "tricky",
    "troubled",
    "ugliest",
    "ugly",
    "uneven",
    "upset",
    "uptight",
    "vast",
    "victorious",
    "vivacious",
    "voiceless",
    "wasteful",
    "watery",
    "weak",
    "weary",
    "wet",
    "whispering",
    "wicked",
    "wide",
    "wide-eyed",
    "witty",
    "wonderful",
    "wooden",
    "yellow",
    "young",
    "yummy",
    "zany"];

export const noun = ["aardvarks",
    "albatrosses",
    "alligators",
    "alpacas",
    "anacondas",
    "angelfish",
    "anglerfish",
    "anteaters",
    "antelopes",
    "ants",
    "apes",
    "aphids",
    "armadillos",
    "baboons",
    "badgers",
    "barnacles",
    "basilisks",
    "bats",
    "bears",
    "beavers",
    "bees",
    "beetles",
    "birds",
    "bison",
    "blackbirds",
    "boars",
    "bobcats",
    "bugs",
    "butterflies",
    "buzzards",
    "camels",
    "caterpillars",
    "catfish",
    "cats",
    "centipedes",
    "chameleons",
    "cheetahs",
    "chickadees",
    "chickens",
    "chimpanzee",
    "chipmunks",
    "clams",
    "clownfish",
    "cobras",
    "cougars",
    "coyotes",
    "crabs",
    "crawdads",
    "crayfish",
    "crickets",
    "crocodiles",
    "crows",
    "dingos",
    "dinosaurs",
    "dogs",
    "dolphins",
    "donkeys",
    "doves",
    "dragonflies",
    "dragons",
    "ducks",
    "eagles",
    "earthworms",
    "eels",
    "egrets",
    "elephants",
    "emus",
    "falcons",
    "ferrets",
    "finches",
    "fireflies",
    "flamingos",
    "foxes",
    "frogs",
    "gazelles",
    "geckos",
    "geese",
    "gerbils",
    "giraffes",
    "goats",
    "goldfish",
    "gophers",
    "gorillas",
    "grasshoppers",
    "guppies",
    "hamsters",
    "hedgehogs",
    "hippopotami",
    "hummingbirds",
    "hyenas",
    "iguanas",
    "jaguars",
    "jellyfish",
    "kangaroos",
    "koalas",
    "ladybugs",
    "larks",
    "lemmings",
    "lemurs",
    "leopards",
    "lizards",
    "llamas",
    "lobsters",
    "manatees",
    "meadowlarks",
    "meerkats",
    "narwhals",
    "newts",
    "nightingales",
    "octopi",
    "opossums",
    "ostriches",
    "otters",
    "owls",
    "pandas",
    "panthers",
    "parrots",
    "pelicans",
    "penguins",
    "pigeons",
    "platypuses",
    "porcupines",
    "raccoons",
    "roadrunners",
    "salamanders",
    "seahorses",
    "sparrows",
    "squirrels",
    "starfish",
    "swans",
    "turtles",
    "walruses",
    "woodpeckers",
    "yaks",
    "zebras",
    "apples",
    "apricots",
    "avocados",
    "bananas",
    "blueberries",
    "cherries",
    "coconuts",
    "cucumbers",
    "figs",
    "grapefruits",
    "grapes",
    "guava",
    "jujubes",
    "kumquats",
    "lemons",
    "limes",
    "loquats",
    "lychees",
    "mangoes",
    "melons",
    "nectarines",
    "oranges",
    "papayas",
    "peaches",
    "pears",
    "persimmons",
    "pineapples",
    "plantains",
    "plums",
    "pomegranates",
    "prunes",
    "raisins",
    "raspberries",
    "strawberries",
    "tamarillo",
    "tangerines",
    "watermelons"];
