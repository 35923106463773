import { useState, useEffect } from 'react';
import { getNow } from './shared';

export function useNowWithUrlOverride() {
    const [now, setNow] = useState(new Date());

    useEffect(() => {
        setNow(getNow());
    }, []);

    return now;
}
