import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawInkStroke(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [mode, setMode] = useState("NORMAL"); // or: SOLID for full black strokes
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let strokes = [];
    let curStroke = [];
    let lastX = 0;
    let lastY = 0;

    let isDrawing = false;
    let redrawAllOnce = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) return;
        isDrawing = true;

        if (isDrawing && curStroke.length > 0) {
            strokes.push(curStroke);
            curStroke = [];
        }
        strokes.push(curStroke);
        addPoint(p5, pX, pY);
        lastX = pX;
        lastY = pY;
        drawStrokes(p5, false);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            let dist = getDistance(pX, pY, lastX, lastY);
            if ((curStroke.length === 0) || (dist > 5 * scaleFactor)) {
                addPoint(p5, pX, pY);
                lastX = pX;
                lastY = pY;
                drawStrokes(p5, false);
            }
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            strokes.pop();
            strokes.push(curStroke);
            curStroke = [];
            isDrawing = false;
        }
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.mode) {
                setMode(parameters.mode);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        const tempScaleFactor = props.displaySize / 500.; // apply this scale factor to strokeWeight
        setScaleFactor(tempScaleFactor);

        p5.strokeCap(p5.ROUND);
        p5.strokeJoin(p5.ROUND);
        p5.strokeWeight(1.5 * tempScaleFactor);
        p5.curveTightness(-0.5);

        p5.background(255);
    };

    let lastX1, lastY1, lastX2, lastY2;

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes

        if (redrawAllOnce) {
            p5.background(255);
            drawStrokes(p5, true);
            redrawAllOnce = false;
        }
    }

    const drawStrokes = (p5, drawAll) => {
        // if !drawAll, just add last stroke

        let r = 8 * scaleFactor,
            rP; // radius, or half the stroke width
        let xP, yP; // prev point
        let xN, yN; // next point
        let angP, angN, angA; // ang to prev, ang to next, ang avg
        let x, y; // cur point
        let curX1, curY1, curX2, curY2;
        let d;

        if (!strokes) return;
        let starti = strokes.length - 1;
        if (!strokes[starti]) return;
        let startj = 1;
        if (starti < 0 || startj <= 0) return;

        if (drawAll) {
            p5.background(255);
            starti = 0;
            startj = 1;
        }

        for (let i = starti; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                for (let j = startj; j < strokes[i].length - 1; j++) {
                    if (mode === "SOLID") {
                        p5.fill(0);
                        p5.stroke(0);
                        d = 20 - 20 * j / (strokes[i].length);
                    } else {
                        p5.fill(255 * (1 - j / (strokes[i].length)));
                        p5.stroke(255 * (1 - j / (strokes[i].length)));
                        d = 22 - 22 * j / (strokes[i].length);
                    }

                    xP = strokes[i][j - 1].x;
                    yP = strokes[i][j - 1].y;
                    x = strokes[i][j].x;
                    y = strokes[i][j].y;
                    xN = strokes[i][j + 1].x;
                    yN = strokes[i][j + 1].y;

                    angP = Math.atan2(yP - y, xP - x) + Math.PI * .5;
                    angN = Math.atan2(y - yN, x - xN) + Math.PI * .5;

                    angA = (angP + angN) / 2;

                    rP = scaleFactor * d * 0.5; // prev
                    r = scaleFactor * d * 0.5; // cur

                    p5.beginShape();

                    if (j === 1) {
                        lastX1 = xP + rP * Math.cos(angP);
                        lastY1 = yP + rP * Math.sin(angP);
                        lastX2 = xP - rP * Math.cos(angP);
                        lastY2 = yP - rP * Math.sin(angP);
                    }

                    p5.vertex(lastX1, lastY1);
                    p5.vertex(lastX2, lastY2);

                    curX1 = x - r * Math.cos(angA);
                    curY1 = y - r * Math.sin(angA);
                    curX2 = x + r * Math.cos(angA);
                    curY2 = y + r * Math.sin(angA);

                    if (getDistance(curX1, curY1, lastX2, lastY2) + getDistance(curX2, curY2, lastX1, lastY1) < getDistance(curX2, curY2, lastX2, lastY2) + getDistance(curX1, curY1, lastX1, lastY1)) {
                        p5.vertex(curX1, curY1);
                        p5.vertex(curX2, curY2);
                    } else {
                        p5.vertex(curX2, curY2);
                        p5.vertex(curX1, curY1);
                    }

                    p5.endShape(p5.CLOSE);

                    if (mode === "SOLID") {
                        p5.ellipse((lastX1 + lastX2) / 2, (lastY1 + lastY2) / 2, getDistance(lastX1, lastY1, lastX2, lastY2), getDistance(lastX1, lastY1, lastX2, lastY2));
                        p5.ellipse((curX1 + curX2) / 2, (curY1 + curY2) / 2, getDistance(curX1, curY1, curX2, curY2), getDistance(curX1, curY1, curX2, curY2));
                    }
                    lastX1 = curX1;
                    lastY1 = curY1;
                    lastX2 = curX2;
                    lastY2 = curY2;
                }
            }
        }
    };

    const clearDrawingHandler = (p5) => {
        strokes = [];
        redrawAllOnce = true;
        return true;
    }

    const undoHandler = (p5) => {
        if (strokes.length >= 1) {
            strokes.splice(strokes.length - 1);
        }
        redrawAllOnce = true;
    }

    function scaleValues(stroke) {
        let newStroke = [];
        for (let i = 0; i < stroke.length; i++) {
            newStroke.push(new Point(
                stroke[i].x / scaleFactor,
                stroke[i].y / scaleFactor,
                stroke[i].t
            ));
        }
        return newStroke;
    }

    const submitButtonHandler = () => {
        // props.parentCallback("repeaterArray", strokes.map(scaleValues), '/repeater.json');
        props.parentCallback(strokes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                // preload={preload}
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawInkStroke;
