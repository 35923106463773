import React, { useState, useEffect } from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayArcs(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [arcs, setArcs] = useState([]);
    const [strokeCap, setStrokeCap] = useState("ROUND"); // or: "SQUARE" or "PROJECT"
    const [strokeWeight, setStrokeWeight] = useState(5);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Arc = function(a, b, c, d, e, f, t1, t2, t3) {
        this.a = a;
        this.b = b;
        this.c = c;
        this.d = d;
        this.e = e;
        this.f = f;
        this.t1 = t1;
        this.t2 = t2;
        this.t3 = t3;
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function findCenter(a, b, c, d, e, f) {
        // find point (p, q) equidistant from three points (a, b), (c, d), (e, f)
        // solution from: https://www.quora.com/Given-3-points-in-the-Cartesian-plane-how-can-you-find-the-coordinates-of-the-center-of-the-circle-that-intersects-all-three-points-if-there-exists-such-a-circle
        let p, q, u, v, w;
        u = 0.5 * (a * a + b * b - c * c - d * d);
        v = 0.5 * (a * a + b * b - e * e - f * f);
        w = (a - c) * (b - f) - (a - e) * (b - d);
        p = (u * (b - f) - v * (b - d)) / w;
        q = (v * (a - c) - u * (a - e)) / w;
        return [p, q];
    }

    function drawArc(p5, a, b, c, d, e, f) {
        let p, q, dist, ang1, ang3;
        [p, q] = findCenter(a, b, c, d, e, f);

        p5.noFill();
        dist = getDistance(p, q, a, b);
        ang1 = Math.atan2(b - q, a - p);
        ang3 = Math.atan2(f - q, e - p);

        // from https://stackoverflow.com/questions/17592800/how-to-find-the-orientation-of-three-points-in-a-two-dimensional-space-given-coo
        // Let p, q, and r be the three poitns:
        // k=(q.y - p.y)*(r.x - q.x)-(q.x - p.x) * (r.y - q.y);
        // if(k==0): They are all colinear
        // if(k>0) : They are all clockwise
        // if(k<0) : They are counter clockwise

        p5.stroke(0);
        let k = (d - b) * (e - c) - (c - a) * (f - d);
        if (k >= 0) {
            // clockwise
            p5.arc(p, q, dist * 2, dist * 2, ang3, ang1);
        } else {
            // counter-clockwise
            p5.arc(p, q, dist * 2, dist * 2, ang1, ang3);
        }
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempStrokeCap = strokeCap;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.strokeCap) {
                tempStrokeCap = parameters.strokeCap;
                setStrokeCap(tempStrokeCap);
            }
            if (parameters.strokeWeight) {
                setStrokeWeight(parameters.strokeWeight);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.rectMode(p5.CENTER);
        p5.ellipseMode(p5.CENTER);
        if (tempStrokeCap === "SQUARE") {
            p5.strokeCap(p5.SQUARE);
        } else if (tempStrokeCap === "PROJECT") {
            p5.strokeCap(p5.PROJECT);
        } else {
            p5.strokeCap(p5.ROUND);
        }

        let arcsData = props.data;
        const tempArcs = [];
        for (let i = 0; i < arcsData.length; i++) {
            let arc = arcsData[i];
            tempArcs.push(new Arc(arc.a, arc.b, arc.c, arc.d, arc.e, arc.f, arc.t1, arc.t2, arc.t3));
        }
        setArcs(tempArcs);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor, scaleFactor);

        p5.strokeWeight(strokeWeight);
        
        for (let i = 0; i < arcs.length; i++) {
            drawArc(p5, arcs[i].a, arcs[i].b, arcs[i].c, arcs[i].d, arcs[i].e, arcs[i].f);
        }
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayArcs;
