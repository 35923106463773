import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawTriangleWedges (props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [color1, setColor1] = useState(0);
    const [color2, setColor2] = useState(0);
    const [color3, setColor3] = useState(0);
    const [color4, setColor4] = useState(0);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function (x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let Triangle = function (x1, y1, x2, y2, x3, y3) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
        this.x3 = x3;
        this.y3 = y3;
    }

    let curStroke = [];
    let triangles = [];

    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        curStroke = [];
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
            isDrawing = false;

            let x1, y1, x2, y2;
            x1 = curStroke[0].x;
            y1 = curStroke[0].y;
            x2 = pX;
            y2 = pY;
            const pts = findTriangle(x1, y1, x2, y2);
            triangles.push(new Triangle(pts[0], pts[1], pts[2], pts[3], pts[4], pts[5]));

            curStroke = [];
        }
    }

    function findTriangle(x1, y1, x2, y2) {
        let tx, ty;

        const dx = (x2 - x1);
        const dy = (y2 - y1);

        const ang = Math.atan2(dy, dx);
        tx = scaleFactor * 10 * (Math.cos(ang + Math.PI / 2));
        ty = scaleFactor * 10 * (Math.sin(ang + Math.PI / 2));

        return [x1, y1, x2 - tx, y2 - ty, x2 + tx, y2 + ty];
    }

    function drawTriangle(p5, x1, y1, x2, y2, x3, y3) {
        p5.beginShape();
        p5.vertex(x1, y1);
        p5.vertex(x2, y2);
        p5.vertex(x3, y3);
        p5.endShape(p5.CLOSE);
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }


    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2 && parameters.color3 && parameters.color4) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
                setColor4(parameters.color4);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.); 
        p5.strokeCap(p5.SQUARE);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        // draw rest of triangles
        p5.strokeWeight(2 * scaleFactor);
        let i;
        for (i = 0; i < triangles.length; i++) {
            if (i % 4 === 0) {
                p5.fill(color1);
                p5.stroke(color1);
            } else if (i % 4 === 1) {
                p5.fill(color2);
                p5.stroke(color2);
            } else if (i % 4 === 2) {
                p5.fill(color3);
                p5.stroke(color3);
            } else if (i % 4 === 3) {
                p5.fill(color4);
                p5.stroke(color4);
            }
            drawTriangle(p5, triangles[i].x1, triangles[i].y1, triangles[i].x2, triangles[i].y2, triangles[i].x3, triangles[i].y3);
        }

        if (curStroke.length >= 1) {
            if (i % 4 === 0) {
                p5.fill(color1);
                p5.stroke(color1);
            } else if (i % 4 === 1) {
                p5.fill(color2);
                p5.stroke(color2);
            } else if (i % 4 === 2) {
                p5.fill(color3);
                p5.stroke(color3);
            } else if (i % 4 === 3) {
                p5.fill(color4);
                p5.stroke(color4);
            }
            let pts = findTriangle(curStroke[0].x, curStroke[0].y, p5.mouseX, p5.mouseY);
            drawTriangle(p5, pts[0], pts[1], pts[2], pts[3], pts[4], pts[5]);
        }
    };

    const clearDrawingHandler = () => {
        triangles = [];
        return true;
    }

    const undoHandler = () => {
        if (triangles.length >= 1) {
            triangles.splice(triangles.length - 1);
        }
    }

    function scaleValues(tri) {
        return (new Triangle(
            tri.x1 / scaleFactor, tri.y1 / scaleFactor,
            tri.x2 / scaleFactor, tri.y2 / scaleFactor,
            tri.x3 / scaleFactor, tri.y3 / scaleFactor
        ));
    }

    const submitButtonHandler = () => {
        props.parentCallback(triangles.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawTriangleWedges;
