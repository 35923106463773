import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayReflection(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokes, setStrokes] = useState([]);
    const [strokeWeight1, setStrokeWeight1] = useState(5);
    const [strokeWeight2, setStrokeWeight2] = useState(0.7);
    const [color1, setColor1] = useState('#000000');
    const [color2, setColor2] = useState('#000000');
    const [reflectDirection, setReflectDirection] = useState(0); // 0 is left-right, 1 is top-bottom
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }
    let curStroke = [];

    function addPoint(p5, pX, pY, t) {
        curStroke.push(new Point(pX, pY, t));
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
            }
            if (parameters.strokeWeight1 && parameters.strokeWeight2) {
                setStrokeWeight1(parameters.strokeWeight1);
                setStrokeWeight2(parameters.strokeWeight2);
            }
            if (parameters.reflectDirection) {
                setReflectDirection(parameters.reflectDirection);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);

        p5.strokeCap(p5.ROUND);
        p5.strokeJoin(p5.ROUND);
        p5.strokeWeight(1.5 * scaleFactor);
        p5.curveTightness(-0.5);

        let strokeData = props.data;
        const tempStrokes = [];
        for (let i = 0; i < strokeData.length; i++) {
            if (strokeData[i] != null) {
                let stroke = strokeData[i];
                for (let k = 0; k < stroke.length; k++) {
                    if (stroke[k] != null) {
                        let pt = stroke[k];
                        addPoint(p5, scaleFactor * pt.x, scaleFactor * pt.y, pt.t);
                    }
                }
                tempStrokes.push(curStroke);
                curStroke = [];
            }
        }
        setStrokes(tempStrokes);

        p5.noFill();
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes

        let x, y; // cur point

        if (!strokes) return;
        let starti = strokes.length - 1;
        if (!strokes[starti]) return;
        if (starti < 0) return;

        p5.background(255);
        starti = 0;

        p5.stroke(color2);
        p5.strokeWeight(strokeWeight2 * scaleFactor);
        for (let i = starti; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    if (reflectDirection === 0) {
                        x = p5.width - strokes[i][j].x;
                        y = strokes[i][j].y;
                    } else {
                        x = strokes[i][j].x;
                        y = p5.height - strokes[i][j].y;
                    }
                    p5.curveVertex(x, y);
                }
                p5.endShape();
            }
        }
        p5.stroke(color1);
        p5.strokeWeight(strokeWeight1 * scaleFactor);
        for (let i = starti; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    x = strokes[i][j].x;
                    y = strokes[i][j].y;
                    p5.curveVertex(x, y);
                }
                p5.endShape();
            }
        }

        if (props.withWatermark) {
            // This tool is not scaled, so needs to draw the watermark with scaleFactor
            drawWatermark(p5, watermarkImage, scaleFactor);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayReflection;
