import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayHatchLines(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [shapes, setShapes] = useState([]);
    const [numLines, setNumLines] = useState(20);
    const [fgcolor, setFgcolor] = useState("#000000");
    const [bgcolor, setBgcolor] = useState("#FFFFFF");
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Shape = function(x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }
    
    function drawShape(p5, x1, y1, x2, y2) {
        const w = x2 - x1;
        const h = y2 - y1;

        p5.push();
        p5.translate(x1, y1);
        // draw corner to corner
        p5.line(0, 0, w, h);
        for (let i = 1; i < numLines; i++) {
            // draw hatch lines to one side of line
            p5.line(w * (i / numLines), 0, w, h * (1 - i / numLines));
            // draw "inverse" hatch lines on other side
            p5.line(w * (1 - i / numLines), h, 0, h * (i / numLines));
        }
        p5.pop();
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.numLines) {
                setNumLines(parameters.numLines);
            }
            if (parameters.fgcolor && parameters.bgcolor) {
                setFgcolor(parameters.fgcolor);
                setBgcolor(parameters.bgcolor);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.strokeCap(p5.SQUARE);

        let shapesData = props.data;
        const tempShapes = [];
        for (let i = 0; i < shapesData.length; i++) {
            let s = shapesData[i];
            tempShapes.push(new Shape(s.x1, s.y1, s.x2, s.y2));
        }
        setShapes(tempShapes);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(bgcolor);
        p5.scale(scaleFactor, scaleFactor);

        p5.noFill();
        p5.stroke(fgcolor);
        p5.strokeWeight(1);

        let i;
        for (i = 0; i < shapes.length; i++) {
            drawShape(p5, shapes[i].x1, shapes[i].y1, shapes[i].x2, shapes[i].y2);
        }

        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayHatchLines;
