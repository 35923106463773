import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawAngleLines(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokeWeight1, setStrokeWeight1] = useState(6);
    const [strokeWeight2, setStrokeWeight2] = useState(3);
    const [color1, setColor1] = useState("#000000");
    const [color2, setColor2] = useState("#000000");
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let Line = function(x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    let curStroke = [];
    let lines = [];

    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    const angleFactor = 0.4;

    function findLine(x1, y1, x2, y2) {
        let x2mod = x2;
        let y2mod = y2;

        const dx = Math.abs(x2 - x1);
        const dy = Math.abs(y2 - y1);
        if (dy > dx) {
            // more vertical
            if (x2 < x1) {
                x2mod = x1 - dy * angleFactor;
            } else {
                x2mod = x1 + dy * angleFactor;
            }
        } else {
            // more horizontal
            if (y2 < y1) {
                y2mod = y1 - dx * angleFactor;
            } else {
                y2mod = y1 + dx * angleFactor;
            }
        }
        return [x1, y1, x2mod, y2mod];
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        curStroke = [];
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
            isDrawing = false;

            let x1 = curStroke[0].x;
            let y1 = curStroke[0].y;
            let x2 = pX;
            let y2 = pY;
            const linePoints = findLine(x1, y1, x2, y2);
            lines.push(new Line(linePoints[0], linePoints[1], linePoints[2], linePoints[3]));

            curStroke = [];
        }
    }


    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
            }
            if (parameters.strokeWeight1 && parameters.strokeWeight2) {
                setStrokeWeight1(parameters.strokeWeight1);
                setStrokeWeight2(parameters.strokeWeight2);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.strokeCap(p5.SQUARE);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        p5.stroke(0);
        p5.noFill();

        // draw rest of lines
        let i;
        for (i = 0; i < lines.length; i++) {
            if (i % 2 === 0) {
                p5.stroke(color1);
                p5.strokeWeight(strokeWeight1 * scaleFactor);
            } else if (i % 2 === 1) {
                p5.stroke(color2);
                p5.strokeWeight(strokeWeight2 * scaleFactor);
            }
            p5.line(lines[i].x1, lines[i].y1, lines[i].x2, lines[i].y2);
        }

        // draw current stroke
        if (curStroke.length >= 1) {
            if (i % 2 === 0) {
                p5.stroke(color1);
                p5.strokeWeight(strokeWeight1 * scaleFactor);
            } else if (i % 2 === 1) {
                p5.stroke(color2);
                p5.strokeWeight(strokeWeight2 * scaleFactor);
            }
            let myline = findLine(curStroke[0].x, curStroke[0].y, p5.mouseX, p5.mouseY);
            p5.line(myline[0], myline[1], myline[2], myline[3]);
        }
    };

    const clearDrawingHandler = () => {
        lines = [];
        curStroke = [];
        return true;
    }

    const undoHandler = () => {
        if (lines.length >= 1) {
            lines.splice(lines.length - 1);
        }
        if (curStroke.length >= 1) {
            curStroke.pop();
        }
    }

    function scaleValues(l) {
        return (new Line(
            l.x1 / scaleFactor,
            l.y1 / scaleFactor,
            l.x2 / scaleFactor,
            l.y2 / scaleFactor
        ));
    }

    const submitButtonHandler = () => {
        props.parentCallback(lines.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawAngleLines;
