import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classes from './Home.module.css';
import sharedClasses from '../../shared.module.css';
import * as firebaseService from '../../services/firebase.js';
import * as authService from '../../services/auth.js';
import * as trackingService from '../../services/tracking.js';
import ChallengeSummary from '../UI/ChallengeSummary.js';
import ErrorBoundary from '../ErrorBoundary.js';
// import DailyPlayCard from '../UI/DailyPlayCard.js';
import { getFriendlyDate } from '../../services/shared.js';
import howItWorks3 from '../../assets/illus-group-rect.png';
import CreateChallenge from './EditChallenge.js';
import Backdrop from '../UI/Backdrop';
import { useNowWithUrlOverride } from '../../services/hooks';
import closeButton from '../../assets/icon-close.svg';
import { ONE_DAY_IN_MILLISECONDS } from '../../services/shared';

function Home() {
    const [displayName, setDisplayName] = useState('');
    const [avatarImagePath, setAvatarImagePath] = useState(null);
    const [hasDefaultAvatar, setHasDefaultAvatar] = useState(true);
    const [canCreateContent, setCanCreateContent] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [activeChallenges, setActiveChallenges] = useState([]);
    const [completedChallenges, setCompletedChallenges] = useState([]);
    const [showCreateChallengeModal, setShowCreateChallengeModal] = useState(false);
    const history = useHistory();

    // const COMMUNITY_CHALLENGE_ID = '-MOSHVIVBHDUrnTEQZSL';
    // const [isInCommunityChallenge, setIsInCommunityChallenge] = useState(false);

    const now = useNowWithUrlOverride();

    useEffect(() => {
        const currentUser = authService.getCurrentUserDirect();
        Promise.all([
            firebaseService.loadProfile(currentUser.uid),
            firebaseService.readDailyChallenge(),
        ]).then(([profile, dailyChallengeIds]) => {
            setCanCreateContent(['admin', 'creator'].includes(profile.role));
            setIsAdmin(['admin'].includes(profile.role));
            setDisplayName(profile.name);
            setAvatarImagePath(profile.avatarPath);
            setHasDefaultAvatar(profile.avatarPath && profile.avatarPath === authService.DEFAULT_PROFILE.avatarPath);
            if (profile.challengeIds) {
                // if (profile.challengeIds.includes(COMMUNITY_CHALLENGE_ID)) {
                //     setIsInCommunityChallenge(true);
                // }

                Promise.all(profile.challengeIds.filter((id) => !dailyChallengeIds.includes(id)).map(async (id) => ({
                    challengeId: id,
                    challenge: await firebaseService.loadChallenge(id),
                }))).then((allchallenges) => {

                    let active = allchallenges.filter(challenge => Math.floor((now.getTime() - challenge.challenge.startTimestamp) / ONE_DAY_IN_MILLISECONDS) < challenge.challenge.lengthInDays);
                    setActiveChallenges(active);

                    let ended = allchallenges.filter(challenge => Math.floor((now.getTime() - challenge.challenge.startTimestamp) / ONE_DAY_IN_MILLISECONDS) >= challenge.challenge.lengthInDays);
                    setCompletedChallenges(ended);

                })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }, [now]);

    const handleEditImage = () => {
        trackingService.logSimpleEvent('Edit Avatar', 'Start');
        history.push({
            pathname: '/edit-image',
            state: { returnHome: 'true' }
        });
    }

    const handleOpenCreateChallengeModal = () => {
        trackingService.logSimpleEvent('Create Challenge', 'Start');
        setShowCreateChallengeModal(true);
    }
    const handleCloseCreateChallengeModal = (newChallengeId) => {
        setShowCreateChallengeModal(false);
        if (newChallengeId) {
            // Received ID from EditChallenge
            firebaseService.loadChallenge(newChallengeId).then((challenge) => {
                setActiveChallenges([{ challenge, challengeId: newChallengeId }, ...activeChallenges]);
            });
            // Scroll to top of page
            window.scrollTo(0, 0);
        }
    }

    // const handleGotoCommunityChallenge = () => {
    //     history.push('/c/' + COMMUNITY_CHALLENGE_ID);
    // }

    let message = '';
    let editAvatar = '';
    let dateMessage = <p>It’s {getFriendlyDate(now)}. Welcome to YDays, your home for unique drawing challenges with friends.</p>;

    if (!displayName) {
        message = <div className={classes.welcome}><div><h1>Hello!</h1>{dateMessage}</div></div>;
    } else {
        message = <div className={classes.welcome}>
            <div>
                <h1>Hello, <Link to='/profile'>{displayName}</Link>!</h1>{dateMessage}
            </div>
            <span className={classes.avatar}>{avatarImagePath && !hasDefaultAvatar ?
                <img src={avatarImagePath} alt='profile' onClick={handleEditImage} />
                : null}
            </span>
        </div>;
    }
    if (hasDefaultAvatar) {
        editAvatar = <div className={`${classes.contentBox} ${classes.spaceBelow}`}>
            <div className={classes.contentBoxLayout}>
                <div className={classes.instructions}>
                    <h3>Express yourself</h3>
                    <p>Create an image to represent you on YDays.</p>
                </div>
                <div className={classes.avatarLarge}>
                    <img src={avatarImagePath} alt='profile' onClick={handleEditImage} />
                </div>
                <button className={sharedClasses.primaryButton} onClick={handleEditImage}>Draw your image</button>
            </div>
        </div>;
    }

    return (
        <div className={classes.content}>
            <Helmet>
                <title>Home | YDays</title>
            </Helmet>
            {message}
            {editAvatar}

            {/* <ErrorBoundary errorMessage="Eh-oh, couldn’t load the Daily Play.">
                <DailyPlayCard />
            </ErrorBoundary> */}

            <span className={classes.headerEyebrow}>Your private challenges</span>
            <div className={classes.headerLayout}>
                <h2>Active + upcoming challenges</h2>
                {/* {isInCommunityChallenge ?
                    null :
                    <button className={classes.mediumBlackOutline} onClick={handleGotoCommunityChallenge}>Join the community challenge</button>} */}
            </div>

            <ErrorBoundary errorMessage="Eh-oh, couldn’t load the challenge summary.">
                {activeChallenges.sort((a, b) => (a.challenge.startTimestamp + a.challenge.lengthInDays * ONE_DAY_IN_MILLISECONDS < b.challenge.startTimestamp + b.challenge.lengthInDays * ONE_DAY_IN_MILLISECONDS) ? 1 : -1)
                    .map((challengeEntry) => challengeEntry ? <ChallengeSummary key={challengeEntry.challengeId} challengeId={challengeEntry.challengeId} challenge={challengeEntry.challenge} isAdmin={isAdmin}></ChallengeSummary> : null)}
            </ErrorBoundary>

            {canCreateContent ?
                <div className={classes.contentBox}>
                    <div className={classes.contentBoxLayout}>
                        <div className={classes.instructions}>
                            <h3>Host a new challenge</h3>
                            <p>Start a private 5-day drawing challenge and invite your friends or teammates.</p>
                        </div>
                        <div className={classes.challengeImage}>
                            <img src={howItWorks3} alt="People cheering" />
                        </div>
                        <button className={sharedClasses.primaryButtonOutline} onClick={handleOpenCreateChallengeModal}>Start a challenge</button>
                    </div>
                </div>
                : null}

            {completedChallenges.length > 0 ? <h2>Completed challenges</h2> : null}

            <ErrorBoundary errorMessage="Eh-oh, couldn’t load the challenge summary.">
                {completedChallenges.sort((a, b) => (a.challenge.startTimestamp + a.challenge.lengthInDays * ONE_DAY_IN_MILLISECONDS < b.challenge.startTimestamp + b.challenge.lengthInDays * ONE_DAY_IN_MILLISECONDS) ? 1 : -1)
                    .map((challengeEntry) => challengeEntry ? <ChallengeSummary key={challengeEntry.challengeId} challengeId={challengeEntry.challengeId} challenge={challengeEntry.challenge} isAdmin={isAdmin}></ChallengeSummary> : null)}
            </ErrorBoundary>

            <Backdrop show={showCreateChallengeModal} clicked={() => handleCloseCreateChallengeModal(null)}></Backdrop>
            {showCreateChallengeModal ?
                <div className={classes.newChallengeModal}>
                    <div className={classes.closeButton}>
                        <img src={closeButton} className={classes.icon} alt="X" onClick={() => handleCloseCreateChallengeModal(null)} />
                    </div>
                    <CreateChallenge modalView={true} closeAction={handleCloseCreateChallengeModal} />
                </div>
                : null}
        </div>
    );
}

export default Home;
