import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import logo from '../../assets/ydays-logo-horiz.svg';
import classes from './Header.module.css';

const PAGES_WITHOUT_HEADER = ['/', '/about', '/sample-drawing'];

function Header(props) {
    const location = useLocation();

    if (PAGES_WITHOUT_HEADER.includes(location.pathname)) {
        return null;
    }

    return (
        <header className={classes.header}>
            <div className={classes.topNav}>
                {props.isUserLoggedIn ?
                    <Link to={{ pathname: '/home', search: location.search }}><img src={logo} className={classes.logo} alt="logo" /></Link>
                    : <Link to={{ pathname: '/', search: location.search }}><img src={logo} className={classes.logo} alt="logo" /></Link>
                }
                <div className={classes.pageLinks}>
                    {props.isUserLoggedIn ?
                        <>
                            <Link to={{ pathname: '/home', search: location.search }}>Home</Link>
                            <Link to={{ pathname: '/profile', search: location.search }}>Profile</Link>
                        </>
                        :
                        <Link to={{ pathname: '/signup', search: location.search }}>Sign up / Log in</Link>
                    }
                </div>
            </div>
        </header>
    );
}

export default Header;
