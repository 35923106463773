import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayFilledClosedShapes(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokes, setStrokes] = useState([]);
    const [color1, setColor1] = useState("#FF5C46C8"); 
    const [color2, setColor2] = useState("#FFF61FC8"); 
    const [color3, setColor3] = useState("#3063FFC8"); 
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let curStroke = [];

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2 && parameters.color3) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        let strokeData = props.data;
        const tempStrokes = [];
        for (let i = 0; i < strokeData.length; i++) {
            if (strokeData[i] != null) {
                let stroke = strokeData[i];
                for (let k = 0; k < stroke.length; k++) {
                    if (stroke[k] != null) {
                        let pt = stroke[k];
                        curStroke.push(new Point(pt.x, pt.y, pt.t));
                    }
                }
                tempStrokes.push(curStroke);
                curStroke = [];
            }
        }
        setStrokes(tempStrokes);
        p5.noStroke();
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.scale(scaleFactor, scaleFactor);

        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                if (i % 3 === 0) {
                    p5.fill(color1);
                } else if (i % 3 === 1) {
                    p5.fill(color2);
                } else {
                    p5.fill(color3);
                }
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    p5.curveVertex(strokes[i][j].x, strokes[i][j].y);
                }
                p5.endShape();
            }
        }
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }
    };



    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayFilledClosedShapes;