import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawFatThinLines(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [color1, setColor1] = useState(0);
    const [color2, setColor2] = useState(0);
    const [color3, setColor3] = useState(0);
    const [color4, setColor4] = useState(0);
    const [color5, setColor5] = useState(0);
    const [color6, setColor6] = useState(0);
    const [bgColors, setBgColors] = useState(["#FFFFFF"]);
    const [strokeScale, setStrokeScale] = useState(0.2);
    const [strokeCap, setStrokeCap] = useState("SQUARE"); // or: "ROUND"
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function (x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let Shape = function (x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    let bgIndex = 0;
    let curShape = [];
    let shapes = [];

    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curShape.push(new Point(pX, pY, current(p5)));
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        curShape = [];
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
            isDrawing = false;

            let x1, y1, x2, y2;
            x1 = curShape[0].x;
            y1 = curShape[0].y;
            x2 = pX;
            y2 = pY;

            if (bgColors.length > 1) {
                // cycle colors
                let dist = getDistance(x1, y1, x2, y2);
                if (dist > 2 * scaleFactor) {
                    shapes.push(new Shape(x1, y1, x2, y2));
                } else {
                    bgIndex++;
                    if (bgIndex >= bgColors.length) {
                        bgIndex = 0;
                    }
                }
            } else {
                shapes.push(new Shape(x1, y1, x2, y2));
            }
            curShape = [];
        }
    }

    function drawLine(p5, x1, y1, x2, y2) {
        const d = getDistance(x1, y1, x2, y2);
        let myStroke = 1;
        if (d > 0)
            myStroke = (p5.width - d) * strokeScale;// * scaleFactor;
        // myStroke = ((p5.width - d) / p5.width) * 100 * scaleFactor;
        if (myStroke < 1) myStroke = 1;
        p5.strokeWeight(myStroke);
        p5.line(x1, y1, x2, y2);
    }


    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempStrokeCap = strokeCap;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2 && parameters.color3 && parameters.color4 && parameters.color5 && parameters.color6) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
                setColor4(parameters.color4);
                setColor5(parameters.color5);
                setColor6(parameters.color6);
            }
            if (parameters.bgColors) {
                setBgColors(parameters.bgColors);
            }
            if (parameters.strokeScale) {
                setStrokeScale(parameters.strokeScale);
            }
            if (parameters.strokeCap) {
                tempStrokeCap = parameters.strokeCap;
                setStrokeCap(tempStrokeCap);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        if (tempStrokeCap === "ROUND") {
            p5.strokeCap(p5.ROUND);
        } else {
            p5.strokeCap(p5.SQUARE);
        }
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(bgColors[bgIndex]);

        // draw rest of shapes
        let i;
        for (i = 0; i < shapes.length; i++) {
            if (i % 6 === 0) {
                p5.stroke(color1);
            } else if (i % 6 === 1) {
                p5.stroke(color2);
            } else if (i % 6 === 2) {
                p5.stroke(color3);
            } else if (i % 6 === 3) {
                p5.stroke(color4);
            } else if (i % 6 === 4) {
                p5.stroke(color5);
            } else if (i % 6 === 5) {
                p5.stroke(color6);
            }
            drawLine(p5, shapes[i].x1, shapes[i].y1, shapes[i].x2, shapes[i].y2);
        }
        // draw current shape
        if (curShape.length >= 1) {
            if (i % 6 === 0) {
                p5.stroke(color1);
            } else if (i % 6 === 1) {
                p5.stroke(color2);
            } else if (i % 6 === 2) {
                p5.stroke(color3);
            } else if (i % 6 === 3) {
                p5.stroke(color4);
            } else if (i % 6 === 4) {
                p5.stroke(color5);
            } else if (i % 6 === 5) {
                p5.stroke(color6);
            }
            drawLine(p5, curShape[0].x, curShape[0].y, p5.mouseX, p5.mouseY);
        }
    };

    const clearDrawingHandler = () => {
        shapes = [];
        return true;
    }

    const undoHandler = () => {
        if (shapes.length >= 1) {
            shapes.splice(shapes.length - 1);
        }
    }

    function scaleValues(s) {
        return (new Shape(
            s.x1 / scaleFactor, s.y1 / scaleFactor,
            s.x2 / scaleFactor, s.y2 / scaleFactor
        ));
    }

    const submitButtonHandler = () => {
        props.parentCallback([shapes.map(scaleValues), bgColors[bgIndex]]);
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawFatThinLines;
