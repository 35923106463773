import React, {useState, useEffect} from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

let shapes = []; // 1D array of Dshapes
let shapeColors = []; // 1D array with 0,1,2,3,4 values

function P5DrawGridRadial(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [color0, setColor0] = useState('#FFFFFF');
    const [color1, setColor1] = useState('#FD5146');
    const [color2, setColor2] = useState('#FFD645');
    const [color3, setColor3] = useState('#58C9D0'); 
    const [color4, setColor4] = useState('#1C0049'); 
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let shapeIndexRecord = []; // record user input to redraw/animate and undo
    let lastColor = 1;

    let Dshape = function(points) {
        this.points = points.slice();
    }

    Dshape.prototype.hitTest = function(px, py) {
        let inside = false;
        for (let i = 0, j = this.points.length - 1; i < this.points.length; j = i++) {
            let xi = this.points[i][0],
                yi = this.points[i][1];
            let xj = this.points[j][0],
                yj = this.points[j][1];
            let intersect = ((yi > py) !== (yj > py)) &&
                (px < (xj - xi) * (py - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    }

    function drawShape(p5, index) {
        if (shapeColors[index] === 0) {
            p5.fill(color0);
            p5.stroke(color0);
        } else if (shapeColors[index] === 1) {
            p5.fill(color1);
            p5.stroke(color1);
        } else if (shapeColors[index] === 2) {
            p5.fill(color2);
            p5.stroke(color2);
        } else if (shapeColors[index] === 3) {
            p5.fill(color3);
            p5.stroke(color3);
        } else if (shapeColors[index] === 4) {
            p5.fill(color4);
            p5.stroke(color4);
        }
        p5.beginShape();
        for (let i = 0; i < shapes[index].points.length; i++) {
            p5.vertex(shapes[index].points[i][0], shapes[index].points[i][1]);
        }
        p5.endShape(p5.CLOSE);
    }

    let isDrawing = false;

    function getShapeIndex(pX, pY) {
        for (let i = 0; i < shapes.length; i++) {
            if (shapes[i].hitTest(pX, pY)) return i;
        }
        return -1;
    }

    function toggleShape(i) {
        let newColor = shapeColors[i] + 1;
        if (newColor > 4) newColor = 0;
        setShapeColor(i, newColor);
        return newColor;
    }

    function setShapeColor(i, newColor) {
        shapeColors[i] = newColor;
        return newColor;
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        let i = getShapeIndex(pX, pY);
        lastColor = toggleShape(i);
        shapeIndexRecord.push([i, lastColor, current(p5)]);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            let i = getShapeIndex(pX, pY);
            if (shapeColors[i] !== lastColor) {
                setShapeColor(i, lastColor);
                shapeIndexRecord.push([i, lastColor, current(p5)]);
            }
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(pX, pY) {
        isDrawing = false;
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }


    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color0 && parameters.color1 && parameters.color2 && parameters.color3 && parameters.color4) {
                setColor0(parameters.color0);
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
                setColor4(parameters.color4);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        p5.strokeJoin(p5.ROUND);
        const tempScaleFactor = props.displaySize / 500.;
        setScaleFactor(tempScaleFactor); // apply this scale factor to strokeWeight

        let nsegments;
        let r = 60 * tempScaleFactor;
        const cx = p5.width / 2;
        const cy = p5.height / 2;

        const tempShapes = []; // 1D array of Dshapes
        const tempShapeColors = []; // 1D array with 0,1,2 values

        // center of circle
        const nwedges = 8;
        const wang = (2 * Math.PI) / nwedges;
        for (let k = 0; k < nwedges; k++) {
            let piece = [];
            piece.push([cx, cy]);
            for (let incr = 0; incr <= 1; incr += 0.2) {
                piece.push([cx + r * Math.cos((k * wang) + wang * incr),
                    cx + r * Math.sin((k * wang) + wang * incr)
                ]);
            }
            tempShapes.push(new Dshape(piece));
            tempShapeColors.push(0);
        }

        const diag = cy * 1.42; // distance from center to corner
        let angw;

        // each ring
        for (let m = 0; m < (diag / r); m++) {
            let j = (m + 1) * r;
            // go around the ring
            nsegments = m * 8 + 8; //Math.round((2 * j * Math.PI) / segw);
            angw = (2 * Math.PI) / nsegments;

            for (let k = 0; k < nsegments; k++) {
                let i = k * angw + Math.PI * .25;
                let piece = [];

                for (let incr = 0; incr <= 1; incr += 0.2) {
                    piece.push([cx + j * Math.cos(i + angw * incr), cy + j * Math.sin(i + angw * incr)]);
                }
                if (k % 2 === 0) {
                    piece.push([cx + (j + r) * Math.cos(i + angw * 1), cy + (j + r) * Math.sin(i + angw * 1)]);
                } else {
                    piece.push([cx + (j + r) * Math.cos(i + angw * 0), cy + (j + r) * Math.sin(i + angw * 0)]);
                }
                tempShapes.push(new Dshape(piece));
                tempShapeColors.push(0);

                piece = [];

                for (let incr = 1; incr >= 0; incr -= 0.2) {
                    piece.push([cx + (j + r) * Math.cos(i + angw * incr), cy + (j + r) * Math.sin(i + angw * incr)]);
                }
                if (k % 2 === 0) {
                    piece.push([cx + j * Math.cos(i + angw * 0), cy + j * Math.sin(i + angw * 0)]);
                } else {
                    piece.push([cx + j * Math.cos(i + angw * 1), cy + j * Math.sin(i + angw * 1)]);
                }
                tempShapes.push(new Dshape(piece));
                tempShapeColors.push(0);
            }
        }
        shapes = tempShapes;
        shapeColors = tempShapeColors;
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        for (let i = 0; i < shapes.length; i++) {
            if (i < 8) {
                // inner circle
                p5.strokeWeight(0.2 * scaleFactor);
            } else {
                p5.strokeWeight(1 * scaleFactor);
            }
            drawShape(p5, i);
        }
    };

    const clearDrawingHandler = () => {
        for (let i = 0; i < shapeColors.length; i++) {
            shapeColors[i] = 0;
        }
        shapeIndexRecord = [];
        return true;
    }

    const undoHandler = () => {
        if (shapeIndexRecord.length >= 1) {
            shapeIndexRecord.splice(shapeIndexRecord.length - 1);
            for (let i = 0; i < shapeColors.length; i++) {
                shapeColors[i] = 0;
            }
            for (let k = 0; k < shapeIndexRecord.length; k++) {
                let step = shapeIndexRecord[k];
                setShapeColor(step[0], step[1]);
            }
        }
    }

    const submitButtonHandler = () => {
        props.parentCallback([shapeColors, shapeIndexRecord]);
        return true;
    }


    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawGridRadial;
