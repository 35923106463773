import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import classes from './Footer.module.css';
import socialTwitter from '../../assets/social-twitter.svg';
import socialInstagram from '../../assets/social-instagram.svg';
import { checkPageHasDrawing } from '../../services/shared';

function Footer() {
    const location = useLocation();

    if (isMobile && checkPageHasDrawing(location)) {
        return null;
    }

    return (
        <footer>
            <div className={classes.footerContent}>
                <div className={classes.footerText}>
                    <p>©YDays, {(new Date()).getFullYear()}</p> 
                    <p><Link to="/privacy">Privacy Policy</Link></p>
                    <p><Link to="/terms">Terms of Service</Link></p>
                    <p><a href="mailto:support@ydays.com">Support</a></p>
                    <p><Link to="/faq">FAQ</Link></p>
                    <p><a href="https://www.buymeacoffee.com/ydays" target="_blank" rel="noopener noreferrer">Buy us a coffee</a></p>
                </div>
                <div className={classes.socialButtons}>
                    <a href="https://twitter.com/ydays_" target="_blank" rel="noopener noreferrer"><img src={socialTwitter} className={classes.socialButton} alt="Twitter" /></a>
                    <a href="https://www.instagram.com/ydays_" target="_blank" rel="noopener noreferrer"><img src={socialInstagram} className={classes.socialButton} alt="Instagram" /></a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
