import React, { Component, useState, useEffect } from 'react';
import * as firebaseService from '../../services/firebase.js';
import WorkGrid from './WorkGrid';
import ErrorBoundary from '../ErrorBoundary.js';
import classes from './WorksTable.module.css';

const BY_DATE = 'BY_DATE';
const BY_EMOJI_COUNT = 'BY_EMOJI_COUNT';

/**
 * props:
 * - workIds
 * - profileName  // Means this is on other users's public profile
 * - isPageAdmin  // The page is admin's page (the current user may not be an admin)
 */
class WorksTableUser extends Component {
    state = {
        workEntriesForSorting: [],  // This is filtered works (only the last submission of each day) with extra field - emojiCount
        sortedWorkIds: [],
        sortMode: null,
    }

    componentDidMount() {
        this.loadAllData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.workIds !== this.props.workIds) {
            this.loadAllData();
        }
    }

    loadAllData() {
        this.setState({ sortedWorkIds: [] });

        /* We load all works here, and again inside `WorkGrid`. But we have caching in `firebaseService`, so there's no repeating fetch. */
        Promise.all(this.props.workIds.map(async (workId) => ({ workId, workObj: await firebaseService.loadWork(workId) })))
            .then((workEntries) => {
                // Group multiple submissions to the same day
                const uniqueWorkEachDayMap = new Map();
                for (const workEntry of workEntries) {
                    if (!workEntry.workObj) continue;
                    const dayId = `${workEntry.workObj.challengeId}-${workEntry.workObj.promptId}`;
                    if (!uniqueWorkEachDayMap.has(dayId)) {
                        uniqueWorkEachDayMap.set(dayId, []);
                    }
                    uniqueWorkEachDayMap.get(dayId).push(workEntry);
                }

                // Construct the entry object for sorting
                this.setState({
                    workEntriesForSorting: Array.from(uniqueWorkEachDayMap.values()).map((worksOneDay) => {
                        const workEntry = worksOneDay.sort((a, b) => b.workObj.timestamp - a.workObj.timestamp)[0];  // Keep the last submitted work for that day
                        return {
                            workId: workEntry.workId,
                            workObj: workEntry.workObj,
                            emojiCounts: Object.values(workEntry.workObj?.reactions || {}).reduce((sum, row) => sum + row.length, 0),
                        };
                    }),
                });

                // Wait for next cycle, when `workEntriesForSorting` is updated
                setTimeout(() => {
                    this.handleSortTab(BY_DATE);
                });
            });
    };

    handleSortTab(tabId) {
        if (this.state.sortMode === tabId) return;

        let allWorkIds = [];
        switch(tabId) {
            case BY_DATE:
                allWorkIds = this.state.workEntriesForSorting.sort((a, b) => ((a.workObj.timestamp || 0) < (b.workObj.timestamp || 0)) ? 1 : -1).map((entry) => entry.workId);
                break;
            case BY_EMOJI_COUNT:
                allWorkIds = this.state.workEntriesForSorting.sort((a, b) => {
                    if (b.emojiCounts === a.emojiCounts) return a.workId > b.workId ? 1 : -1;
                    return b.emojiCounts - a.emojiCounts;
                }).map((entry) => entry.workId);;
                break;
            default:
                throw new Error(`Invalid sort type ${tabId}`);
        }
        this.setState({ sortMode: tabId, sortedWorkIds: allWorkIds });
    }

    render() {
        return (
            <div className={classes.Works}>
                <div className={classes.content}>
                    <div className={classes.headerTop}>
                        <h2 className={classes.promptTitle}>{this.props.profileName ? `${this.props.profileName}'s` : 'Your'} drawings</h2>
                        <div className={classes.tabs}>
                            <button
                                className={`${classes.tab} ${this.state.sortMode === BY_DATE ? classes.selected : ''}`}
                                onClick={() => this.handleSortTab(BY_DATE)}>By date</button>
                            <button
                                className={`${classes.tab} ${this.state.sortMode === BY_EMOJI_COUNT ? classes.selected : ''}`}
                                onClick={() => this.handleSortTab(BY_EMOJI_COUNT)}>Most emojis</button>
                        </div>
                    </div>
                    <ErrorBoundary>
                        <WorkGrid workIds={this.state.sortedWorkIds}
                            gridColumns={3}
                            skipHidden={!!this.props.profileName}
                            ModalTitle={ModalTitle}
                            ModalDayLabel={ModalDayLabel}
                            skipSentryLog={this.props.isPageAdmin}>
                            <ProfileWorkTemplate sortMode={this.state.sortMode} isCurrentUser={!this.props.profileName} />
                        </WorkGrid>
                    </ErrorBoundary>
                </div>
            </div >
        );
    }
}

function ModalTitle({ modalEntry }) {
    return modalEntry?.challenge?.title || null;
}

function ModalDayLabel({ modalEntry }) {
    try {
        if (!modalEntry.workObj.timestamp) return null;
        return new Date(modalEntry.workObj.timestamp).toLocaleDateString();
    } catch(_) {
        return null;
    }
}

function ProfileWorkTemplate({
    sortMode, isCurrentUser,  // Prop from this file above
    authorProfile, workId, workObj, prompt, challenge, dayIndex, emojiCounts,  // The work
    onClick,  // Pass down event listener (from WorkGrid)
    children,  // Content inside (from WorkGrid)
}) {
    const [forceRefreshFlag, setForceRefreshFlag] = useState(true);  // Used to force update UI when the deep value `hideToPublic` is updated
    const [tagContent, setTagContent] = useState('');
    useEffect(() => {
        switch(sortMode) {
            case BY_DATE:
                const workDate = (new Date(workObj.timestamp)).toLocaleDateString('en-US');
                if (workDate === 'Invalid Date')
                    setTagContent('');
                else
                    setTagContent(workDate);
                break;
            case BY_EMOJI_COUNT:
                setTagContent(`${emojiCounts} 👍`);
                break;
            default:
                setTagContent('');
        }
    }, [sortMode, workObj, emojiCounts]);

    function toggleWorkHideShow(event, workId, workObj) {
        event.stopPropagation();  // Don't open the modal

        firebaseService.toggleWorkHideShow(workId).then((updatedValue) => {
            workObj.hideToPublic = updatedValue;
            setForceRefreshFlag(false);
            setTimeout(() => {
                setForceRefreshFlag(true);
            });
        });
    }

    return <div className={classes.sketchHolder}>
        <div className={classes.hideShowContainer}>
            <ErrorBoundary>
                <div className={classes.sketch} onClick={onClick}>{children}</div>
            </ErrorBoundary>
            { isCurrentUser && forceRefreshFlag &&
                <>
                    <button aria-label="Toggle hide/show"
                        className={`${classes.toggleButton} ${workObj.hideToPublic ? classes.hide : classes.show}`}
                        onClick={(e) => toggleWorkHideShow(e, workId, workObj)}></button>
                    <span className={classes.tooltip}>{workObj.hideToPublic ? 'Hidden' : 'Showing'} on my public profile</span>
                </>
            }
        </div>
        <div className={classes.belowSketch}>
            <p className={classes.authorName}>
                <span className={classes.challengeTitle}>{challenge?.title}</span>
                <span className={classes.tag}>{tagContent}</span>
            </p>
        </div>
    </div>;
}

export default WorksTableUser;
