import React, {useState, useEffect} from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawFilledSclosedShapes(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [color1, setColor1] = useState("#FF5C46C8"); 
    const [color2, setColor2] = useState("#FFF61FC8"); 
    const [color3, setColor3] = useState("#3063FFC8"); 
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function(x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let strokes = [];
    let curStroke = [];
    let isDrawing = false;
    let isShapeMoving = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) return;
        if (isShapeMoving) return;
        isDrawing = true;

        if (isDrawing && curStroke.length > 0) {
            strokes.push(curStroke);
            curStroke = [];
        }
        strokes.push(curStroke);
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            strokes.pop();
            strokes.push(curStroke);
            curStroke = [];
            isDrawing = false;
            isShapeMoving = true;
        }
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }


    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2 && parameters.color3) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.); // apply this scale factor to strokeWeight

        p5.curveTightness(0);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.noStroke();

        if (isShapeMoving && strokes.length >= 1) {
            let s = strokes[strokes.length - 1];
            let A = 0;
            let B = s.length - 1;
            let dx = s[B].x - s[A].x;
            let dy = s[B].y - s[A].y;
            s[A].x += 0.4 * dx;
            s[A].y += 0.4 * dy;
            s[B].x -= 0.4 * dx;
            s[B].y -= 0.4 * dy;

            if (s.length > 4) {
                // move first 1/5 of stroke points
                for (let i = 0; i < s.length * 0.5; i++) {
                    A = i;
                    B = i + 1;
                    dx = s[B].x - s[A].x;
                    dy = s[B].y - s[A].y;
                    // move B towards A
                    s[B].x -= 0.6 * dx;
                    s[B].y -= 0.6 * dy;
                }

                // move last 1/5 of stroke points
                for (let i = 0; i < s.length * 0.5; i++) {
                    A = s.length - 1 - i;
                    B = s.length - 2 - i;
                    dx = s[B].x - s[A].x;
                    dy = s[B].y - s[A].y;
                    // move B towards A
                    s[B].x -= 0.6 * dx;
                    s[B].y -= 0.6 * dy;
                }
            }
            isShapeMoving = false;
        }

        for (let i = 0; i < strokes.length; i++) {
            if (strokes[i][0] != null) {
                // const t = 200;
                if (i % 3 === 0) {
                    p5.fill(color1); //255, 92, 70, t);
                } else if (i % 3 === 1) {
                    p5.fill(color2); //255, 246, 31, t);
                } else {
                    p5.fill(color3); //48, 99, 255, t);
                }
                p5.beginShape();
                for (let j = 0; j < strokes[i].length; j++) {
                    p5.curveVertex(strokes[i][j].x, strokes[i][j].y);
                }
                p5.endShape();
            }
        }
    };

    const clearDrawingHandler = () => {
        strokes = [];
        return true;
    }

    const undoHandler = () => {
        if (strokes.length >= 1) {
            strokes.splice(strokes.length - 1);
        }
    }

    function scaleValues(stroke) {
        let newStroke = [];
        for (let i = 0; i < stroke.length; i++) {
            newStroke.push(new Point(
                stroke[i].x / scaleFactor,
                stroke[i].y / scaleFactor,
                stroke[i].t
            ));
        }
        return newStroke;
    }

    const submitButtonHandler = () => {
        props.parentCallback(strokes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawFilledSclosedShapes;
