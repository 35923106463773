import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawRepeater(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [strokeWidth, setStrokeWidth] = useState(10);
    const [mode, setMode] = useState("NORMAL"); // or: "WORMS" for outlined strokes
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function (x, y, d, t) {
        this.x = x;
        this.y = y;
        this.d = d;
        this.t = t;
    }

    let strokes = [];
    let curStroke = [];
    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, strokeWidth, current(p5)));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) return;

        if (isDrawing && curStroke.length > 0) {
            strokes.push(curStroke);
            curStroke = [];
        }
        strokes.push(curStroke);
        addPoint(p5, pX, pY);
        isDrawing = true;
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            strokes.pop();
            strokes.push(curStroke);
            curStroke = [];
            isDrawing = false;
        }
    }


    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }


    function current(p5) {
        return p5.millis() * .001;
    }

    function cycleBy(time, interval) {
        return (time - interval * Math.floor(time / interval))
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempStrokeWidth = strokeWidth;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.mode) {
                setMode(parameters.mode);
            }
            if (parameters.strokeWidth) {
                tempStrokeWidth = parameters.strokeWidth;
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        const tempScaleFactor = props.displaySize / 500.; // apply this scale factor to strokeWeight
        setScaleFactor(tempScaleFactor);
        setStrokeWidth(tempStrokeWidth);
        p5.strokeCap(p5.ROUND);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        let t1, ti;
        let curTime = current(p5);
        const interval = 5;
        curTime = cycleBy(curTime, interval); // repeats every 5 sec

        if (mode === "WORMS") {
            for (let i = 0; i < strokes.length; i++) {
                if (strokes[i][0] != null) {
                    p5.beginShape(p5.LINES);
                    p5.stroke(0);
                    for (let j = 0; j < strokes[i].length - 1; j++) {
                        t1 = strokes[i][j].t;
                        ti = cycleBy(t1, interval);
                        if ((ti < curTime && ti > curTime - interval * .5) ||
                            (ti < curTime + interval && ti > curTime + interval - interval * .5)) {
                            p5.strokeWeight(strokes[i][j].d * scaleFactor);
                            p5.vertex(strokes[i][j].x, strokes[i][j].y);
                            p5.vertex(strokes[i][j + 1].x, strokes[i][j + 1].y);
                        }
                    }
                    p5.endShape();
                }
            }
            for (let i = 0; i < strokes.length; i++) {
                if (strokes[i][0] != null) {
                    p5.beginShape(p5.LINES);
                    p5.stroke(255);
                    for (let j = 0; j < strokes[i].length - 1; j++) {
                        t1 = strokes[i][j].t;
                        ti = cycleBy(t1, interval);
                        if ((ti < curTime && ti > curTime - interval * .5) ||
                            (ti < curTime + interval && ti > curTime + interval - interval * .5)) {
                            p5.strokeWeight(strokes[i][j].d * .63 * scaleFactor);
                            p5.vertex(strokes[i][j].x, strokes[i][j].y);
                            p5.vertex(strokes[i][j + 1].x, strokes[i][j + 1].y);
                        }
                    }
                    p5.endShape();
                }
            }
        } else {
            // NORMAL mode
            for (let i = 0; i < strokes.length; i++) {
                if (strokes[i][0] != null) {
                    p5.beginShape(p5.LINES);
                    for (let j = 0; j < strokes[i].length - 1; j++) {
                        t1 = strokes[i][j].t;
                        ti = cycleBy(t1, interval);

                        // this accounts for strokes that cross the 5 sec boundary once
                        // assumes no stroke takes more than 5 to 10 secs
                        if ((ti < curTime && ti > curTime - interval * .5) ||
                            (ti < curTime + interval && ti > curTime + interval - interval * .5)) {
                            p5.strokeWeight(strokes[i][j].d * scaleFactor);
                            p5.vertex(strokes[i][j].x, strokes[i][j].y);
                            p5.vertex(strokes[i][j + 1].x, strokes[i][j + 1].y);
                        }
                    }
                    p5.endShape();
                }
            }
        }
    };

    const clearDrawingHandler = () => {
        strokes = [];
        return true;
    }

    const undoHandler = () => {
        if (strokes.length >= 1) {
            strokes.splice(strokes.length - 1);
        }
    }

    function scaleValues(stroke) {
        let newStroke = [];
        for (let i = 0; i < stroke.length; i++) {
            newStroke.push(new Point(
                stroke[i].x / scaleFactor,
                stroke[i].y / scaleFactor,
                stroke[i].d / scaleFactor,
                stroke[i].t
            ));
        }
        return newStroke;
    }

    const submitButtonHandler = () => {
        props.parentCallback(strokes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawRepeater;
