import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import classes from './Login.module.css';
import * as trackingService from '../../services/tracking';
import * as authService from '../../services/auth.js';
import * as sendgridService from '../../services/sendgrid.js';
import { joinSavedChallengeId, saveAllowMarketingForGoogleSignin } from '../../services/shared';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isSignup, setIsSignup] = useState(true);
    const [allowMarketingEmail, setAllowMarketingEmail] = useState(true);
    const [currentUser] = useState(authService.getCurrentUserDirect());
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        let timer;
        if (location.pathname === '/login') setIsSignup(false);
        if (currentUser) {
            timer = setTimeout(() => {
                history.push('/home');
            }, 2000);
        }
        return () => {
            clearTimeout(timer);
        }
    }, [currentUser, history, location.pathname]);

    const preventDefault = (evt) => {
        evt.preventDefault();
    }

    const login = () => {
        trackingService.logAmplitude('Clicked Log In with Email');
        trackingService.logGA('User auth', 'Email login clicked');

        authService.signInEmail(email, password).then((result) => {
            joinSavedChallengeId();
            return authService.getCurrentUserDirect()?.reload();
        }).then(() => {
            const user = authService.getCurrentUserDirect();
            if (!user) {
                throw new Error('Couldn’t log in. Try to refresh the page, or click on Forgot Password to reset your password.');
            }
            trackingService.logAmplitude('Logged In with Email successfully');
            trackingService.logGA('User auth', 'Email login');
            history.push('/home');
        }).catch((error) => {
            console.error(error);
            setMessage(error.message);
            if (error.message?.includes('IDBDatabase')) {
                setMessage('Couldn’t log in. Try to refresh the page, or click on Forgot Password to reset your password.');
                trackingService.logSentry('IDBDatabase error', true);
            }
            trackingService.logSentry(error);
            trackingService.logAmplitude('Log In with Email failed');
            trackingService.logGA('User auth', 'Email login failed');
        });
    }

    const signup = () => {
        trackingService.logAmplitude('Clicked Sign Up with Email');
        trackingService.logGA('User auth', 'Email signup clicked');

        authService.signUpEmail(email, password).then(() => {
            setMessage('Please check your inbox for the verification email');
            joinSavedChallengeId();
            return authService.getCurrentUserDirect()?.reload();
        }).then(() => {
            const user = authService.getCurrentUserDirect();
            if (!user || !user.email) {
                throw new Error('Couldn’t sign up. Try refreshing the page to try again.');
            }
            trackingService.logAmplitude('Signed up with Email successfully');
            trackingService.logGA('User auth', 'Email signup');
            if (allowMarketingEmail) {
                return sendgridService.addToMarketingList({ email: user.email, name: user.displayName });
            }
        }).then(() => {
            history.push('/home');
        }).catch((error) => {
            console.error(error);
            setMessage(error.message);
            if (error.message?.includes('IDBDatabase')) {
                setMessage('Couldn’t sign up. Try refreshing the page to try again.');
                trackingService.logSentry('IDBDatabase error', true);
            }
            trackingService.logSentry(error);
            trackingService.logAmplitude('Sign up with Email failed');
            trackingService.logGA('User auth', 'Email signup failed');
        });
    }

    const loginGoogle = () => {
        trackingService.logAmplitude('Sign Up or Log In with Google');
        trackingService.logGA('User auth', 'Google login/signup');
        if (allowMarketingEmail) {
            saveAllowMarketingForGoogleSignin()
        }
        authService.signInGoogle();
    }

    const changeFormModeCallback = () => {
        setMessage('');
        setIsSignup(!isSignup);
    }

    const signUpForm = <div className={classes.content}>
        <Helmet>
            <title>Sign up | YDays</title>
        </Helmet>
        <div className={classes.formContainer}>
            <form className={classes.form} onSubmit={preventDefault}>
                <h1>Sign up</h1>
                <label className={classes.field}>
                    <span className={classes.fieldLabel}>Email</span>
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)}></input>
                </label>
                <label className={classes.field}>
                    <span className={classes.fieldLabel}>Password</span>
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)}></input>
                </label>
                <label className={classes.checkboxLabel}>
                    <input type="checkbox" checked={allowMarketingEmail} onChange={(e) => setAllowMarketingEmail(e.target.checked)} />
                    <span className={classes.marketingMessage}>Send me occasional news and updates about YDays.</span>
                </label>
                <p className={classes.legalMessage}>By signing up below, I agree to YDays’ <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.</p>
                <button onClick={signup}>Sign up</button>
                {message ? <p className={classes.message}>{message}</p> : null}
            </form>
            <h3 className={classes.centerline}><span>or</span></h3>
            <button className={classes.googleButton} onClick={loginGoogle}>Sign up with Google</button>
            <p>Already have an account? <span className={classes.underlineLink} onClick={changeFormModeCallback}>Log in</span></p>
        </div>
    </div>

    const loginForm = <div className={classes.content}>
        <Helmet>
            <title>Log in | YDays</title>
        </Helmet>
        <div className={classes.formContainer}>
            <form className={classes.form} onSubmit={preventDefault}>
                <h1>Log in</h1>
                <label className={classes.field}>
                    <span className={classes.fieldLabel}>Email</span>
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)}></input>
                </label>
                <label className={classes.field}>
                    <span className={classes.fieldLabel}>Password</span>
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)}></input>
                </label>
                <button onClick={login}>Log in</button>
                {message ? <p className={classes.message}>{message}</p> : null}
            </form>
            <h3 className={classes.centerline}><span>or</span></h3>
            <button className={classes.googleButton} onClick={loginGoogle}>Continue with Google</button>
            <p>Don’t have an account? <span className={classes.underlineLink} onClick={changeFormModeCallback}>Sign up</span></p>
            <p><Link className={classes.underlineLink} to={'/forgot'}>Forgot password?</Link></p>
        </div>
    </div>

    if (!currentUser) {
        return isSignup ? signUpForm : loginForm;
    }

    if (currentUser && !currentUser.emailVerified) {
        return <div className={classes.content}>
            <div className={classes.pageMessage}>
                <h2>You’re signed in as {currentUser.email}</h2>
                <p>Please check your inbox for a verification email.</p></div></div>
    }

    return <div className={classes.content}>
        <div className={classes.pageMessage}>
            <h2>You’re signed in as {currentUser.email}</h2>
            <p>Sending you to your YDays homepage...</p></div>
    </div>
}

export default Login;
