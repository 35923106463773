import React from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import classes from './Doc.module.css';

function FAQ() {
    return (
        <div className={classes.content}>
            <h1>Frequently asked questions</h1>
            <Accordion className={classes.accordion} allowMultipleExpanded allowZeroExpanded>
                <AccordionItem className={classes.accordionItem}>
                    <AccordionItemHeading className={classes.accordionHead}>
                        <AccordionItemButton className={classes.accordionButton}>
                            How does YDays work?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={classes.accordionPanel}>
                        <ul>
                            <li>You start a 5-day drawing challenge in YDays, then you send friends a link where they can sign up to join the challenge.</li>
                            <li>Each day of your challenge, your group will go to the YDays homepage and see a link to a new prompt and custom drawing tool to play with. You’ll get an email in the morning with the link if you sign up for email reminders in Settings.</li>
                            <li>If you’re on the second day or later, you’ll also get to see what people made the previous day and cheer them on with emojis.</li>
                            <li>At the end of the challenge, you’ll see a gallery of all the submissions from the five days and a summary of the emoji reactions you shared as a group. You can share the link to the challenge summary page so anyone on the web can see what you’ve created.</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className={classes.accordionItem}>
                    <AccordionItemHeading className={classes.accordionHead}>
                        <AccordionItemButton className={classes.accordionButton}>
                            Who is YDays for?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={classes.accordionPanel}>
                        <p>YDays might be right for you if you like to draw, but you haven’t gotten a chance to draw since you were in grade school. You might have a creative job and want another way to challenge yourself with the puzzle-like drawing tools. Or you’re looking for a low-key way to connect with loved ones and friends in a supportive and fun way.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className={classes.accordionItem}>
                    <AccordionItemHeading className={classes.accordionHead}>
                        <AccordionItemButton className={classes.accordionButton}>
                            What does it mean to host a challenge?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={classes.accordionPanel}>
                        <ul>
                            <li>As the host, you’ll start the challenge, give it a name, and decide what day the first prompt will begin. The days will be tied to your time zone, so new prompts will become available at midnight your time for everyone.</li>
                            <li>You’ll get a link that you can share with people you want to join in. They’ll have an opportunity to create an account from that page. People can still join any time during the challenge.</li>
                            <li>As the host, you’ll also have the ability to “Edit” the challenge and change the start date.</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className={classes.accordionItem}>
                    <AccordionItemHeading className={classes.accordionHead}>
                        <AccordionItemButton className={classes.accordionButton}>
                            How many people should join?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={classes.accordionPanel}>
                        <p>We’ve found it’s more fun with a few people, say 3 or more, than with just one or two. And we’ve tested it with up to 20 people, but we’d love to see groups larger than that.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className={classes.accordionItem}>
                    <AccordionItemHeading className={classes.accordionHead}>
                        <AccordionItemButton className={classes.accordionButton}>
                            What happens if I miss a day? Can I go back?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={classes.accordionPanel}>
                        <p>There’s no penalty if you miss a day, but you can’t return to a prompt if you miss the 24 window when it’s active.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className={classes.accordionItem}>
                    <AccordionItemHeading className={classes.accordionHead}>
                        <AccordionItemButton className={classes.accordionButton}>
                            Can I submit more than one time per prompt?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={classes.accordionPanel}>
                        <p>You can’t add more than one submission for a prompt, but after you’ve submitted, you have the option to return to the prompt and resubmit. If you do so, your new submission will replace the old one.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className={classes.accordionItem}>
                    <AccordionItemHeading className={classes.accordionHead}>
                        <AccordionItemButton className={classes.accordionButton}>
                            Who can see our challenge?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={classes.accordionPanel}>
                        <p>The challenge page link you use to invite participants or join a challenge is the same page that later becomes a summary of the challenge and gallery of all the submissions. This link is visible by anyone on the internet, so you can share it with friends after your challenge is over.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className={classes.accordionItem}>
                    <AccordionItemHeading className={classes.accordionHead}>
                        <AccordionItemButton className={classes.accordionButton}>
                            Why is it called YDays?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={classes.accordionPanel}>
                        <p>We started with the idea of “x days of drawing” but “x days” sounded too cold and scary. Y is a nicer letter, don’t you think? Plus, YDays is for days that end in ‘y’ – so there’s that too!</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className={classes.accordionItem}>
                    <AccordionItemHeading className={classes.accordionHead}>
                        <AccordionItemButton className={classes.accordionButton}>
                            Who are you?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={classes.accordionPanel}>
                        <p>We’re a small team led by <a href="https://pcho.net/" target="_blank" rel="noopener noreferrer">Peter Cho</a>, and we’ve been working at YDays since August 2020. <a href="mailto:support@ydays.com">Contact us</a> if you have feedback or need help. And if you’d like to support YDays and its continued development, you can <a href="https://www.buymeacoffee.com/ydays" target="_blank" rel="noopener noreferrer">Buy us a coffee</a>.</p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        </div>
    );

}

export default FAQ;
