import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawCirclesSquares(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [bgColor, setBgColor] = useState('#FFFFFF');
    const [fgColor, setFgColor] = useState('#000000');
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function (x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let Shape = function (x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    let curShape = [];
    let shapes = [];

    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curShape.push(new Point(pX, pY, current(p5)));
    }

    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        curShape = [];
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
            isDrawing = false;

            let x1, y1, x2, y2;
            x1 = curShape[0].x;
            y1 = curShape[0].y;
            x2 = pX;
            y2 = pY;
            shapes.push(new Shape(x1, y1, x2, y2));

            curShape = [];
        }
    }

    function drawSquare(p5, x1, y1, x2, y2) {
        // find square with these two points as opposite corners
        let mx = (x1 + x2) / 2;
        let my = (y1 + y2) / 2;

        p5.beginShape();
        p5.vertex(x1, y1);
        p5.vertex(mx - (y1 - my), my + (x1 - mx));
        p5.vertex(x2, y2);
        p5.vertex(mx + (y1 - my), my - (x1 - mx));
        p5.endShape(p5.CLOSE);
    }
    function drawCircle(p5, x1, y1, x2, y2) {
        // find circle with these two points as diameter
        let mx = (x1 + x2) / 2;
        let my = (y1 + y2) / 2;
        let diam = getDistance(x1, y1, x2, y2);

        p5.ellipse(mx, my, diam, diam);
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }


    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.bgColor && parameters.fgColor) {
                setBgColor(parameters.bgColor);
                setFgColor(parameters.fgColor);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.colorMode(p5.RGB);
        p5.ellipseMode(p5.CENTER);
        p5.strokeJoin(p5.ROUND);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(bgColor);
        p5.stroke(fgColor);
        p5.strokeWeight(8 * scaleFactor);
        p5.fill(bgColor + "DC");

        // draw rest of shapes
        for (let i = 0; i < shapes.length; i++) {
            if (i % 2 === 0)
                drawSquare(p5, shapes[i].x1, shapes[i].y1, shapes[i].x2, shapes[i].y2);
            else
                drawCircle(p5, shapes[i].x1, shapes[i].y1, shapes[i].x2, shapes[i].y2);
        }

        // draw current shape
        if (curShape.length >= 1) {
            if (shapes.length % 2 === 0)
                drawSquare(p5, curShape[0].x, curShape[0].y, p5.mouseX, p5.mouseY);
            else
                drawCircle(p5, curShape[0].x, curShape[0].y, p5.mouseX, p5.mouseY);
        }
    }

    const clearDrawingHandler = () => {
        shapes = [];
        return true;
    }

    const undoHandler = () => {
        if (shapes.length >= 1) {
            shapes.splice(shapes.length - 1);
        }
    }

    function scaleValues(shape) {
        return (new Shape(
            shape.x1 / scaleFactor, shape.y1 / scaleFactor,
            shape.x2 / scaleFactor, shape.y2 / scaleFactor
        ));
    }

    const submitButtonHandler = () => {
        props.parentCallback(shapes.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawCirclesSquares;
