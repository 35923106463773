import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classes from './Profile.module.css';
import * as firebaseService from '../../services/firebase.js';
import * as authService from '../../services/auth.js';
import * as trackingService from '../../services/tracking.js';
import { getMonthDayYear, INVALID_USERNAMES } from '../../services/shared.js';
import ErrorBoundary from '../ErrorBoundary.js';
import WorksTableUser from '../UI/WorksTableUser';
import socialTwitter from '../../assets/social-twitter.svg';
import socialInstagram from '../../assets/social-instagram.svg';

function Profile() {
    // Profile handles the display of the user's own profile page, and for now,
    // users can see a version of the profile page of other users at the path: /p/userid.

    const history = useHistory();
    const params = useParams();
    const [userId, setUserId] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [avatarPath, setAvatarPath] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [workIds, setWorkIds] = useState([]);
    const [aboutYou, setAboutYou] = useState('');
    const [username, setUsername] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [isPageAdmin, setIsPageAdmin] = useState(false);
    const location = useLocation();

    // Get the user for this page
    useEffect(() => {
        let userIdFromUrl = params.userId;
        if (userIdFromUrl) {
            if (INVALID_USERNAMES.includes(userIdFromUrl)) {
                history.push({ pathname: '/', search: location.search });
                return;
            }
            trackingService.logAmplitude('Public Profile', { 'username': userIdFromUrl });
            firebaseService.readUsername(userIdFromUrl).then((uid) => {
                setUserId(uid || userIdFromUrl);
            })
        } else {
            setUserId(authService.getCurrentUserDirect()?.uid);
        }
    }, [params.userId, history, location]);

    // Load the data for this user
    useEffect(() => {
        setDisplayName('');
        setAvatarPath(null);
        setStartDate(null);
        setWorkIds([]);
        setAboutYou('');
        setUsername('');
        setInstagram('');
        setTwitter('');
        setIsPageAdmin(false);

        if (!userId) return;

        firebaseService.loadProfile(userId).then((profile) => {
            if (!profile?.email) { // Not a valid profile
                history.push('/');
                return;
            }
            setDisplayName(profile.name);
            setAvatarPath(profile.avatarPath);
            setUsername(profile.username);
            setInstagram(profile.instagram);
            setAboutYou(profile.about);
            setTwitter(profile.twitter);
            setStartDate(getMonthDayYear(new Date(profile.createdTimestamp)));
            setWorkIds(profile.workIds || []);
            setIsPageAdmin(profile.role === 'admin');
        });
    }, [userId, history]);

    return (
        <div className={classes.content}>
            <Helmet>
                <title>Profile | YDays</title>
            </Helmet>
            <Link to={{ pathname: `/home` }} className={classes.back}>← Back to home</Link>
            <div className={classes.pageHeader}>
                <div className={classes.avatarLarge}>
                    {/* Avatar links to edit page for the current user */}
                    {params?.userId ?
                        <img src={avatarPath} alt='profile' />
                        :
                        <Link to={{ pathname: '/edit-image', search: location.search, state: { returnHome: 'true' } }}
                            onClick={() => {trackingService.logSimpleEvent('Edit Avatar', 'Start');}}>
                            <img src={avatarPath} alt='profile' />
                        </Link>}
                </div>

                <div className={classes.nameBlock}>
                    <h1>{displayName}</h1>
                    {aboutYou && <p className={classes.aboutYou}>{aboutYou}</p>}
                    <p className={classes.metaInfo}>
                        {instagram && <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer" className={classes.socialHandle}><img src={socialInstagram} className={classes.socialIcon} alt="Instagram" /> @{instagram}</a>}
                        {twitter && <a href={`https://twitter.com/${twitter}`} target="_blank" rel="noopener noreferrer" className={classes.socialHandle}><img src={socialTwitter} className={classes.socialIcon} alt="Twitter" /> @{twitter}</a>}
                        Joined {startDate}</p>
                    {/* Only show the ydays profile link on current user's profile */}
                    {!params?.userId &&
                        (
                            username ?
                                <p className={classes.publicProfile}>
                                    Your public profile link:&nbsp;
                                    {username && <Link to={{ pathname: username }} className={classes.textlink}>ydays.com/{username}</Link>}
                                </p>
                                :
                                <p className={classes.publicProfile}>
                                    <Link to={{ pathname: `/settings` }} className={classes.textlink}>Set up your public profile →</Link>
                                </p>
                        )
                    }
                </div>
                {/* Only show the settings button on current user's profile */}
                {!params?.userId &&
                    <Link className={classes.mediumBlackOutline} to={{ pathname: '/settings', search: location.search }}>Account settings</Link>
                }
            </div>
            {
                workIds.length > 0 ?
                    <div className={classes.contentBox}>
                        <ErrorBoundary errorMessage="Eh-oh, couldn’t load works.">
                            <WorksTableUser workIds={workIds} profileName={params.userId && displayName} isPageAdmin={isPageAdmin} />
                        </ErrorBoundary>
                    </div>
                    :
                    <div className={classes.contentBox}>
                        {params?.userId ?
                            <>
                                {displayName ? <>
                                    <h2>{displayName}’s drawings</h2>
                                    <p className={classes.smallMessage}>{displayName} has no drawings yet.</p>
                                </> : null}
                            </>
                            :
                            <>
                                <h2>Your drawings</h2>
                                <p className={classes.smallMessage}>Once you start drawing, your submissions will appear here.</p>
                            </>}
                    </div>
            }
        </div >
    );
}

export default Profile;
