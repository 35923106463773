import React, {useState, useEffect} from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayGridRadial(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [shapes, setShapes] = useState([]); // 1D array of Dshapes
    const [shapeColors, setShapeColors] = useState([]); // 1D array with 0,1,2,3,4 values
    const [color0, setColor0] = useState('#FFFFFF');
    const [color1, setColor1] = useState('#FD5146');
    const [color2, setColor2] = useState('#FFD645');
    const [color3, setColor3] = useState('#58C9D0'); 
    const [color4, setColor4] = useState('#1C0049'); 
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Dshape = function(points) {
        this.points = points.slice();
    }

    function drawShape(p5, index) {
        if (shapeColors[index] === 0) {
            p5.fill(color0);
            p5.stroke(color0);
        } else if (shapeColors[index] === 1) {
            p5.fill(color1);
            p5.stroke(color1);
        } else if (shapeColors[index] === 2) {
            p5.fill(color2);
            p5.stroke(color2);
        } else if (shapeColors[index] === 3) {
            p5.fill(color3);
            p5.stroke(color3);
        } else if (shapeColors[index] === 4) {
            p5.fill(color4);
            p5.stroke(color4);
        }
        p5.beginShape();
        for (let i = 0; i < shapes[index].points.length; i++) {
            p5.vertex(shapes[index].points[i][0], shapes[index].points[i][1]);
        }
        p5.endShape(p5.CLOSE);
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color0 && parameters.color1 && parameters.color2 && parameters.color3 && parameters.color4) {
                setColor0(parameters.color0);
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
                setColor4(parameters.color4);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        p5.strokeJoin(p5.ROUND);
        const tempScaleFactor = props.displaySize / 500.;
        setScaleFactor(tempScaleFactor); // apply this scale factor to strokeWeight

        const tempShapeColors = props.data[0];
        const tempShapes = [];
        if (tempShapeColors.length === 0) return;

        let nsegments;
        let r = 60 * tempScaleFactor;
        const cx = p5.width / 2;
        const cy = p5.height / 2;

        // center of circle
        const nwedges = 8;
        const wang = (2 * Math.PI) / nwedges;
        for (let k = 0; k < nwedges; k++) {
            let piece = [];
            piece.push([cx, cy]);
            for (let incr = 0; incr <= 1; incr += 0.2) {
                piece.push([cx + r * Math.cos((k * wang) + wang * incr),
                    cx + r * Math.sin((k * wang) + wang * incr)
                ]);
            }
            tempShapes.push(new Dshape(piece));
            // tempShapeColors.push(0);
        }

        const diag = cy * 1.42; // distance from center to corner
        let angw;

        // each ring
        for (let m = 0; m < (diag / r); m++) {
            let j = (m + 1) * r;
            // go around the ring
            nsegments = m * 8 + 8; //Math.round((2 * j * Math.PI) / segw);
            angw = (2 * Math.PI) / nsegments;

            for (let k = 0; k < nsegments; k++) {
                let i = k * angw + Math.PI * .25;
                let piece = [];

                for (let incr = 0; incr <= 1; incr += 0.2) {
                    piece.push([cx + j * Math.cos(i + angw * incr), cy + j * Math.sin(i + angw * incr)]);
                }
                if (k % 2 === 0) {
                    piece.push([cx + (j + r) * Math.cos(i + angw * 1), cy + (j + r) * Math.sin(i + angw * 1)]);
                } else {
                    piece.push([cx + (j + r) * Math.cos(i + angw * 0), cy + (j + r) * Math.sin(i + angw * 0)]);
                }
                tempShapes.push(new Dshape(piece));

                piece = [];

                for (let incr = 1; incr >= 0; incr -= 0.2) {
                    piece.push([cx + (j + r) * Math.cos(i + angw * incr), cy + (j + r) * Math.sin(i + angw * incr)]);
                }
                if (k % 2 === 0) {
                    piece.push([cx + j * Math.cos(i + angw * 0), cy + j * Math.sin(i + angw * 0)]);
                } else {
                    piece.push([cx + j * Math.cos(i + angw * 1), cy + j * Math.sin(i + angw * 1)]);
                }
                tempShapes.push(new Dshape(piece));
            }
        }
        setShapes(tempShapes);
        setShapeColors(tempShapeColors);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);

        for (let i = 0; i < shapes.length; i++) {
            if (i < 8) {
                // inner circle
                p5.strokeWeight(0.2 * scaleFactor);
            } else {
                p5.strokeWeight(1 * scaleFactor);
            }
            drawShape(p5, i);
        }
        if (props.withWatermark) {
            // This tool is not scaled, so needs to draw the watermark with scaleFactor
            drawWatermark(p5, watermarkImage, scaleFactor);
        }
    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayGridRadial;
