import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './UserTitle.module.css';

function UserTitle({ profile, noImage, children }) {
    const location = useLocation();

    if (!profile) {
        return null;
    }

    if (profile.username) {
        return <Link to={{ pathname: `/${profile.username}`, search: location.search }} className={classes.userTitle}>
            {!noImage && <img src={profile.avatarPath} alt='avatar' />}
            <span className={classes.name}>{profile.name}</span>
            {children}
        </Link>;
    }

    return <p className={classes.userTitle}>
        {!noImage && <img src={profile.avatarPath} alt='avatar' />}
        <span className={classes.name}>{profile.name}</span>
        {children}
    </p>;
}

export default UserTitle;
