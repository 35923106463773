import React, { useState, useEffect } from "react";
import Sketch from "react-p5";
import { isMobile } from "react-device-detect";

import './P5Draw.css';

function P5DrawArcGridConnector(props) {
    const [scaleFactor, setScaleFactor] = useState(1);
    const [squaresPerSide, setSquaresPerSide] = useState(22);
    const [strokeWidth, setStrokeWidth] = useState(16);
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Point = function (x, y, t) {
        this.x = x;
        this.y = y;
        this.t = t;
    }

    let Segment = function (x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    let curStroke = [];
    let segments = [];

    let isDrawing = false;

    function addPoint(p5, pX, pY) {
        curStroke.push(new Point(pX, pY, current(p5)));
    }

    function getGridCoord(p5, pX, pY) {
        // Get the grid coordinate: drawing should go edge to edge, inset with half the strokeWidth on either side
        let i = Math.round(((pX - scaleFactor * strokeWidth * 0.5) / (p5.width - scaleFactor * strokeWidth)) * squaresPerSide);
        let j = Math.round(((pY - scaleFactor * strokeWidth * 0.5) / (p5.height - scaleFactor * strokeWidth)) * squaresPerSide);
        if (i < 0) i = 0;
        if (j < 0) j = 0;
        if (i > squaresPerSide) i = squaresPerSide;
        if (j > squaresPerSide) j = squaresPerSide;
        return [i, j];
    }

    function drawSegment(p5, x1, y1, x2, y2) {
        let [i1, j1] = getGridCoord(p5, x1, y1);
        let [i2, j2] = getGridCoord(p5, x2, y2);
        const gridw = (p5.width - scaleFactor * strokeWidth) / squaresPerSide;
        const cf = 0.5522847498; // bezier approximation of circle

        // Use these values so that drawings go edge to edge, taking the pen width into consideration
        const os = scaleFactor * strokeWidth * 0.5; // offset

        let radius;
        let dx = i2 - i1;
        let dy = j2 - j1;

        let sign = 1;

        if (dy === 0 || dx === 0) {
            // straight line
            p5.line(i1 * gridw + os, j1 * gridw + os, i2 * gridw + os, j2 * gridw + os);
        } else if (Math.abs(dx) >= Math.abs(dy)) {
            // east quarter and west quarter
            // draw straight segment, then quarter arc
            if (dx < 0) sign = -1;

            radius = Math.abs(dy * gridw);
            p5.beginShape();
            p5.vertex(i1 * gridw + os, j1 * gridw + os);
            p5.vertex(i2 * gridw - sign * radius + os, j1 * gridw + os);
            p5.bezierVertex(i2 * gridw - sign * radius + sign * radius * cf + os, j1 * gridw + os,
                i2 * gridw + os, j2 * gridw - (dy * gridw) * cf + os,
                i2 * gridw + os, j2 * gridw + os);
            p5.endShape();
        } else {
            // north quarter and south quarter
            // draw straight segment, then quarter arc
            if (dy < 0) sign = -1;

            radius = Math.abs(dx * gridw);
            p5.beginShape();
            p5.vertex(i1 * gridw + os, j1 * gridw + os);
            p5.vertex(i1 * gridw + os, j2 * gridw - sign * radius + os);
            p5.bezierVertex(i1 * gridw + os, j2 * gridw - sign * radius + sign * radius * cf + os,
                i2 * gridw - (dx * gridw) * cf + os, j2 * gridw + os,
                i2 * gridw + os, j2 * gridw + os);
            p5.endShape();
        }
    }

    function startDrawing(p5, pX, pY) {
        if (pX < 0 || pX > p5.width || pY < 0 || pY > p5.height) {
            isDrawing = false;
            return;
        }
        isDrawing = true;
        curStroke = [];
        addPoint(p5, pX, pY);
    }

    function keepDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
        } else {
            startDrawing(p5, pX, pY);
        }
    }

    function stopDrawing(p5, pX, pY) {
        if (isDrawing) {
            addPoint(p5, pX, pY);
            isDrawing = false;

            let x1, y1, x2, y2;
            x1 = curStroke[0].x;
            y1 = curStroke[0].y;
            x2 = pX;
            y2 = pY;
            segments.push(new Segment(x1, y1, x2, y2));

            curStroke = [];
        }
    }

    const touchStarted = (p5) => {
        startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchMoved = (p5) => {
        keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const touchEnded = (p5) => {
        stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mousePressed = (p5) => {
        if (!isMobile) startDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseDragged = (p5) => {
        if (!isMobile) keepDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    const mouseReleased = (p5) => {
        if (!isMobile) stopDrawing(p5, p5.mouseX, p5.mouseY);
        return false;
    }

    function current(p5) {
        return p5.millis() * .001;
    }


    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.strokeWidth) {
                setStrokeWidth(parameters.strokeWidth);
            }
            if (parameters.squaresPerSide) {
                setSquaresPerSide(parameters.squaresPerSide);
            }
        }
        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);
        p5.strokeCap(p5.PROJECT);
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(255);
        p5.noFill(0);
        p5.stroke(0);
        p5.strokeWeight(scaleFactor * strokeWidth);

        // draw rest of triangles
        for (let i = 0; i < segments.length; i++) {
            drawSegment(p5, segments[i].x1, segments[i].y1, segments[i].x2, segments[i].y2);
        }

        if (curStroke.length >= 1) {
            drawSegment(p5, curStroke[0].x, curStroke[0].y, p5.mouseX, p5.mouseY);
        }
    };

    const clearDrawingHandler = () => {
        segments = [];
        return true;
    }

    const undoHandler = () => {
        if (segments.length >= 1) {
            segments.splice(segments.length - 1);
        }
    }

    function scaleValues(segment) {
        return (new Segment(
            segment.x1 / scaleFactor, segment.y1 / scaleFactor,
            segment.x2 / scaleFactor, segment.y2 / scaleFactor
        ));
    }

    const submitButtonHandler = () => {
        props.parentCallback(segments.map(scaleValues));
        return true;
    }

    return (
        <div className="drawingToolContainer">
            {renderP5 && <Sketch
                setup={setup}
                draw={draw}
                mousePressed={mousePressed}
                mouseDragged={mouseDragged}
                mouseReleased={mouseReleased}
                touchStarted={touchStarted}
                touchMoved={touchMoved}
                touchEnded={touchEnded}
            />}
            {renderP5 && <div className="drawingToolButtons">
                <button onClick={clearDrawingHandler}>Start over</button>
                <button onClick={undoHandler}>Undo</button>
                <button onClick={submitButtonHandler}>Submit</button>
            </div>}
        </div>);
};

export default P5DrawArcGridConnector;
