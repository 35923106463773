import { SENDGRID_API_KEY } from '../SECRET';
import { CLOUD_FUNCTION_ROOT } from './shared';

export function addToMarketingList({ email, name }) {
    return sendgridRequest('marketing/contacts', 'PUT', {
        contacts: [{ email, first_name: name }],
    });
}

/* SendGrid doesn't allow CORS send calls for security reason. So we relay it to Cloud functions
 * https://sendgrid.com/docs/for-developers/sending-email/cors/ */
export function shareToFriend(body) {
    return fetch(`${CLOUD_FUNCTION_ROOT}/sendShareEmails`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(body),
    }).then(async (res) => {
        if (!res.ok) throw await res.json();
    });
}

function sendgridRequest(path, method, body) {
    return fetch(`https://api.sendgrid.com/v3/${path}`, {
        method,
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${SENDGRID_API_KEY}`,
        },
        body: body ? JSON.stringify(body) : null,
    }).then((res) => res.json()).then((response) => {
        if (response.errors) throw response.errors;
        return response;
    });
}
