import React, { useState, useEffect } from "react";
import Sketch from "react-p5";

import './P5Display.css';

import watermark from '../../assets/ydays-watermark.png';
import { drawWatermark } from './p5helper';

function P5DisplayFatThinLines(props) {
    const [watermarkImage, setWatermarkImage] = useState('');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [shapes, setShapes] = useState([]);
    const [color1, setColor1] = useState(0);
    const [color2, setColor2] = useState(0);
    const [color3, setColor3] = useState(0);
    const [color4, setColor4] = useState(0);
    const [color5, setColor5] = useState(0);
    const [color6, setColor6] = useState(0);
    const [bgColor, setBgColor] = useState("#FFFFFF");
    const [strokeScale, setStrokeScale] = useState(0.2);
    const [strokeCap, setStrokeCap] = useState("SQUARE"); // or: "ROUND"
    const [renderP5, setRenderP5] = useState(true);

    // If props changes, re-init p5.
    useEffect(() => {
        setRenderP5(false);
        setTimeout(() => {
            setRenderP5(true);
        })
    }, [props]);

    let Shape = function(x1, y1, x2, y2) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }
    
    function getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    }

    function drawLine(p5, x1, y1, x2, y2) {
        const d = getDistance(x1, y1, x2, y2);
        let myStroke = 1;
        if (d > 0)
            myStroke = (500 - d) * strokeScale;// * (scaleFactor <= 1 ? scaleFactor : 1) * .68;
            // myStroke = ((500 - d) / 500) * 100; // (p5.width - d) * 0.2 / scaleFactor;
        if (myStroke < 1) myStroke = 1;
        p5.strokeWeight(myStroke);
        p5.line(x1, y1, x2, y2);
    }

    function preload(p5) {
        if (props.withWatermark) {
            setWatermarkImage(p5.loadImage(watermark));
        }
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        let parameters = {};
        let tempStrokeCap = strokeCap;
        if (props.parameters) {
            try {
                parameters = JSON.parse(props.parameters);
            } catch (_) {
                console.warn(`Prompt parameter is not valid JSON: ${props.parameters}`);
            }
            if (parameters.color1 && parameters.color2 && parameters.color3 && parameters.color4 && parameters.color5 && parameters.color6) {
                setColor1(parameters.color1);
                setColor2(parameters.color2);
                setColor3(parameters.color3);
                setColor4(parameters.color4);
                setColor5(parameters.color5);
                setColor6(parameters.color6);
            }
            if (parameters.strokeScale) {
                setStrokeScale(parameters.strokeScale);
            }
            if (parameters.strokeCap) {
                tempStrokeCap = parameters.strokeCap;
                setStrokeCap(tempStrokeCap);
            }
        }

        p5.createCanvas(props.displaySize, props.displaySize).parent(canvasParentRef);
        setScaleFactor(props.displaySize / 500.);

        if (tempStrokeCap === "ROUND") {
            p5.strokeCap(p5.ROUND);
        } else {
            p5.strokeCap(p5.SQUARE);
        }

        let shapesData = props.data[0];
        const tempShapes = [];
        for (let i = 0; i < shapesData.length; i++) {
            let s = shapesData[i];
            tempShapes.push(new Shape(s.x1, s.y1, s.x2, s.y2));
        }
        setShapes(tempShapes);

        if (props.data[1] != null) {
            setBgColor(props.data[1]);
        }
    };

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(bgColor);
        p5.scale(scaleFactor, scaleFactor);
        // draw rest of shapes
        let i;
        for (i = 0; i < shapes.length; i++) {
            if (i % 6 === 0) {
                p5.stroke(color1);
            } else if (i % 6 === 1) {
                p5.stroke(color2);
            } else if (i % 6 === 2) {
                p5.stroke(color3);
            } else if (i % 6 === 3) {
                p5.stroke(color4);
            } else if (i % 6 === 4) {
                p5.stroke(color5);
            } else if (i % 6 === 5) {
                p5.stroke(color6);
            }
            drawLine(p5, shapes[i].x1, shapes[i].y1, shapes[i].x2, shapes[i].y2);
        }
        if (props.withWatermark) {
            drawWatermark(p5, watermarkImage);
        }

    };

    return (
        renderP5 && <Sketch className="displaySketch"
            preload={preload}
            setup={setup}
            draw={draw}
        />
    );
};

export default P5DisplayFatThinLines;
