import React, { useState, useEffect } from 'react';
import classes from './Voting.module.css';
import * as firebaseService from '../../services/firebase.js';
import * as authService from '../../services/auth';
import * as trackingService from '../../services/tracking.js';

const EMOJI_LIST = ['👍', '👏', '😂', '😍', '😎', '😯'];

/**
 * Props:
 * - workId
 * - reactions
 * - challengeId
 * - dayIndex
 * - hasChallengeEnded
 * - dailyDate  // Only used to log different event
 */
function Voting(props) {
    const [currentUser, setCurrentUser] = useState(null);
    /* We need to modify the reactions here. Since modifying props is discouraged, we convert it to a state.
     * We currently don't need to propagate it back to parent, since the parent component doesn't use the value of reactions. */
    const [reactions, setReactions] = useState(props.reactions || {});

    useEffect(() => {
        setCurrentUser(authService.getCurrentUserDirect());
    }, []);

    const toggleReaction = (emoji) => {
    // if (props.hasChallengeEnded) return;
        const isAddReaction = !reactions?.[emoji]?.includes(currentUser.uid);
        if (!isAddReaction) {
            // remove emoji reaction
            trackingService.logAmplitude('Remove Emoji', { 'Work ID': props.workId, 'Emoji': emoji, 'Daily Play Day': props.dailyDate });
            trackingService.logGA('Emoji', 'Remove');
            firebaseService.removeReaction(props.workId, emoji);
            setReactions({ ...reactions, [emoji]: reactions[emoji].filter((uid) => uid !== currentUser.uid) });
        } else {
            // add emoji reaction
            trackingService.logAmplitude('Add Emoji', { 'Work ID': props.workId, 'Emoji': emoji, 'Daily Play Day': props.dailyDate });
            trackingService.logGA('Emoji', 'Add');
            firebaseService.addReaction(props.workId, emoji);
            setReactions({ ...reactions, [emoji]: [...(reactions[emoji] || []), currentUser.uid] });
        }
        firebaseService.updateLeaderboard(props.challengeId, props.dayIndex, props.workId, isAddReaction);
    }

    return <div className={classes.emojiBar}>
        {EMOJI_LIST.map((emoji) =>
            <button key={emoji}
                onClick={() => toggleReaction(emoji)}
                className={`${classes.emojiButton} 
                    ${reactions?.[emoji]?.includes(currentUser?.uid) ? classes.activeEmojiButton : ''}`}>
                {emoji}
                {reactions?.[emoji] && reactions?.[emoji].length > 0 ?
                    (<span className={classes.emojiCount}>{reactions?.[emoji].length}</span>)
                    : ''}
                {/* {props.hasChallengeEnded && reactions?.[emoji] ? `(${reactions?.[emoji].length})` : ''} */}
            </button>)}
    </div>
}

export default Voting;
