import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { Helmet } from 'react-helmet';
import * as authService from '../../services/auth.js';
import * as trackingService from '../../services/tracking.js';

import logo from '../../assets/ydays-logo.svg';
import exampleDrawings from '../../assets/example-drawings.png';
import howItWorks1 from '../../assets/illus-start-rect.png';
import howItWorks2 from '../../assets/illus-inspiration-rect.png';
import howItWorks3 from '../../assets/illus-heart-rect.png';
import circle1 from '../../assets/circle-1.svg';
import circle2 from '../../assets/circle-2.svg';
import circle3 from '../../assets/circle-3.svg';
import classes from './Landing.module.css';

import P5SketchRepeater from '../P5js/P5DrawRepeater';


function Landing() {
    const [currentUser, setCurrentUser] = useState(null);
    // const [sketchSize, setSketchSize] = useState(500);
    const sketchSize = 500;
    const history = useHistory();
    const comingSoonRef = React.createRef();

    // TO DO: this doesn't work - want to be able to dynamically resize sketch if window changes size
    // useEffect(() => {
    //     const updateWindowDimensions = (sketchSize) => {
    //         let mySketchSize = sketchSize;
    //         if (window.innerWidth < mySketchSize) {
    //             // for phone browsers
    //             mySketchSize = window.innerWidth;
    //         }
    //         setSketchSize(mySketchSize);
    //     };
    //     updateWindowDimensions(sketchSize);
    //     window.addEventListener('resize', updateWindowDimensions);
    // }, []);

    // If user is logged in they are probably on the /about page
    // Logged in users should see 'Start a challenge' CTAs instead of 'Sign up'
    useEffect(() => {
        setCurrentUser(authService.getCurrentUserDirect());
    }, []);

    const handleSignup = () => {
        trackingService.logSimpleEvent('Landing', 'Signup');
        history.push('/signup');
    }

    const handleStartChallenge = () => {
        trackingService.logSimpleEvent('Landing', 'Create challenge');
        history.push('/create-challenge');
    }

    const handleOpenSample = () => {
        trackingService.logSimpleEvent('Landing', 'Sample drawing');
        history.push('/sample-drawing');
    }

    return (
        <div className={classes.landing}>
            <Helmet>
                <meta property="og:title" content="YDays: Unlock your creativity with daily drawing challenges" />
            </Helmet>
            <header>
                <div className={classes.headerBox}>
                    <img src={logo} className={classes.logo} alt="YDays logo" />
                    {currentUser ?
                        <div className={classes.buttonHolder}><button className={classes.startchallengeTop} onClick={handleStartChallenge}>Start a challenge</button></div>
                        : <div className={classes.buttonHolder}><button className={classes.signupTop} onClick={handleSignup}>Sign up</button>
                            <p className={classes.loginLink}>or <a href="/login">Log in</a></p></div>
                    }
                    <h1>Unlock your creativity and inspire your friends with delightful, surprising drawing prompts each day.</h1>
                </div>
                <div className={classes.productHunt}><a href="https://www.producthunt.com/posts/ydays?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-ydays" target="_blank" rel="noopener noreferrer">
                    <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=271618&theme=light"
                        alt="YDays - Creative drawing challenges with friends | Product Hunt"
                        width="250" height="54" /></a></div>
            </header>
            <div className={classes.examples}>
                <img src={exampleDrawings} className={classes.exampleDrawings} alt="How it works 1" />
            </div>
            <div className={classes.content}>
                <div className={classes.quickDraw}>
                    <h2>Quick on the draw . . .</h2>
                    <p>A calming scene, a person in motion, something you love to eat — YDays sends you and your group a daily instruction and unique drawing tool to discover.
                    </p>
                </div>
                <div className={classes.separatorDash}></div>
                <div className={classes.sampleBox}>
                    <h3>Try a sample prompt</h3>
                    <div className={classes.sampleLayout}>
                        <div className={classes.sampleText}>
                            <h2>You’ll get a different drawing tool every day</h2>
                            <h5>For example:</h5>
                            <p>Using this tool, <span className={classes.promptInstructions}>draw a face.</span> The tool might not behave how you think it will! But once you figure out how it works, how can you make your drawing more expressive? More fun?</p>
                        </div>
                        <div className={classes.sampleSketch}>
                            <div className={classes.sketch}>
                                <BrowserView>
                                    <P5SketchRepeater displaySize={sketchSize} history={null} parentCallback={null} />
                                </BrowserView>
                                <MobileView>
                                    <button className={classes.openModal} onClick={handleOpenSample}>Open drawing tool</button>
                                </MobileView>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={classes.howItWorksBox}>
                    <h3>Making a private challenge</h3>
                    <div className={classes.step}>
                        <div className={classes.stepImage}>
                            <img src={howItWorks1} className={classes.howItWorks1} alt="How it works 1" />
                        </div>
                        <img src={circle1} className={classes.circle} alt="Step 1" />
                        <div className={classes.stepText}>
                            <h2>Start a challenge</h2>
                            <p>Create a 5-day challenge for your group. Invite your friends with a special code to join the party.</p>
                        </div>
                    </div>
                    <div className={classes.step}>
                        <div className={`${classes.stepImage} ${classes.stepImageReverse}`}>
                            <img src={howItWorks2} className={classes.howItWorks2} alt="How it works 2" />
                        </div>
                        <img src={circle2} className={classes.circle} alt="Step 2" />
                        <div className={classes.stepText}>
                            <h2>Complete a fun project each day</h2>
                            <p>Every morning, you’ll unlock a prompt that will spark your creativity. You’ll have the day to complete it. Proud of what you’ve made? Share your creation on social media.</p>
                        </div>
                    </div>
                    <div className={classes.step}>
                        <div className={classes.stepImage}>
                            <img src={howItWorks3} className={classes.howItWorks3} alt="How it works 3" />
                        </div>
                        <img src={circle3} className={classes.circle} alt="Step 3" />
                        <div className={classes.stepText}>
                            <h2>Vote your favorites</h2>
                            <p>Watch what your fellow challengers came up with, vote and cheer them on. When the challenge is up, see a summary of your group’s creations. </p>
                        </div>
                    </div>
                    {currentUser ?
                        <h4>Sound like fun? <Link to='/create-challenge'>Start your YDays challenge</Link> today.</h4>
                        : <h4>Sound like fun? <Link to='/signup'>Sign up</Link> to start your YDays challenge today.</h4>
                    }

                </div>
                {/* <div className={classes.separatorDash}></div>
                <div className={classes.libraryBox}>
                    <h3>Our growing library of challenges</h3>
                    <div className={classes.categories}>
                        <p className={classes.currentlyActive}>Drawing</p>
                        <p>Lettering</p>
                        <p>Creative writing</p>
                        <p>Creative coding</p>
                        <p>Music</p>
                        <p>Humor</p>
                        <p>Puzzles</p>
                        <p>Storytelling</p>
                        <p>Do-it-yourself</p>
                    </div>
                </div> */}
                <div className={classes.separatorDash}></div>
                <div className={classes.testimonialBox}>
                    <h3>Overheard</h3>
                    <div className={classes.quotes}>
                        <div className={classes.quote}>
                            <p className={classes.quoteText}>“You’re doing something very creative with YDays, while sparking other people’s creativity. I love it.”</p>
                            <p className={classes.quoteName}>—Will Shortz</p>
                            <p className={classes.quoteCaption}>NYT puzzle master</p>
                        </div>
                        <div className={classes.quote}>
                            <p className={classes.quoteTextSmall}>“YDays is a little surprise every day, like mislabeled chocolates, and you don’t know what’s inside each truffle. You get something delicious every day!”</p>
                            <p className={classes.quoteName}>—Vera A.</p>
                        </div>
                        <div className={classes.quote}>
                            <p className={classes.quoteText}>“I was just blown away by what other people came up with.”</p>
                            <p className={classes.quoteName}>—David B.</p>
                        </div>
                    </div>
                </div>
                <div className={classes.separatorDash}></div>
                <div className={classes.testimonialBox}>
                    <h3>As featured in</h3>
                    <div className={classes.logoGrid}>
                        <a href="https://www.morningbrew.com/daily/stories/2020/11/19/essentials-issue-83-goodbye-hello" target="_blank" rel="noopener noreferrer">
                            <img className={classes.logo} alt="Morning Brew" src="https://firebasestorage.googleapis.com/v0/b/ydays-618cd.appspot.com/o/assets%2Flanding%2Flogo-morning-brew.png?alt=media&token=23e4c720-ff7c-4518-afad-23b24bb04a74" />
                        </a>
                        <a href="https://www.densediscovery.com/issues/114" target="_blank" rel="noopener noreferrer">
                            <img className={classes.logo} alt="Dense Discovery" src="https://firebasestorage.googleapis.com/v0/b/ydays-618cd.appspot.com/o/assets%2Flanding%2Flogo-dense-discovery.png?alt=media&token=8c824d92-81b3-4780-a2ae-3c57e9845d43" />
                        </a>
                        <a href="https://www.swiss-miss.com/2020/11/link-pack-69.html" target="_blank" rel="noopener noreferrer">
                            <img className={classes.logo} alt="Swiss Miss" src="https://firebasestorage.googleapis.com/v0/b/ydays-618cd.appspot.com/o/assets%2Flanding%2Flogo-swiss-miss.png?alt=media&token=c3f8ed40-0dd8-4168-8232-8ce490e4a16a" />
                        </a>
                        <a href="https://designobserver.substack.com/p/daily-drawing" target="_blank" rel="noopener noreferrer">
                            <img className={classes.logo} alt="Design Observer" src="https://firebasestorage.googleapis.com/v0/b/ydays-618cd.appspot.com/o/assets%2Flanding%2Flogo-design-observer.png?alt=media&token=bea0c4c1-0a69-44dd-806a-3da20594d478" />
                        </a>
                    </div>
                </div>
                <div className={classes.comingSoonBox} ref={comingSoonRef}>
                    <h3>Spark your creativity with YDays</h3>
                    {currentUser ?
                        <button className={classes.startchallengeTop} onClick={handleStartChallenge}>Start a challenge</button>
                        : <div>
                            <button onClick={handleSignup}>Sign up</button>
                            <p className={classes.loginLink}>Already have an account? <a href="/login">Log in</a></p>
                        </div>}
                </div>
            </div>

        </div>
    );

}

export default Landing;
