import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classes from './Admin.module.css';
import * as firebaseService from '../../services/firebase';
import { ONE_DAY_IN_MILLISECONDS, CLOUD_FUNCTION_ROOT, getYearWeekOfDailyChallenge } from '../../services/shared';
import CaptureCanvas from '../UI/CaptureCanvas';

const TABS = ['challenges', 'prompts', 'users', 'daily-challenge', 'debug'];

function Admin() {
    const [challenges, setChallenges] = useState([]);
    const [prompts, setPrompts] = useState([]);
    const [selectedChallenge, setSelectedChallenge] = useState(null);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [selectedWorkData, setSelectedWorkData] = useState(null);
    const [selectedWorkId, setSelectedWorkId] = useState(null);
    const [worksTemplate, setWorksTemplate] = useState(null);
    const [selectedDayIndex, setSelectedDayIndex] = useState(null);
    const [uploadedUrl, setUploadedUrl] = useState('');
    const [saveWorkImageMessage, setSaveWorkImageMessage] = useState('Save as the work image');
    const [users, setUsers] = useState([]);
    const [currentTab, setCurrentTab] = useState(TABS[0]);
    const [dailyChallengeIds, setDailyChallengeIds] = useState([]);
    const [dailyChallengeSaveMessage, setDailyChallengeSaveMessage] = useState('');
    const [promptWithChallengeExpanded, setPromptWithChallengeExpanded] = useState({});

    useEffect(() => {
        firebaseService.loadAllChallenges().then((res) => setChallenges(Array.from(Object.entries(res)).sort((a, b) => b[1].startTimestamp - a[1].startTimestamp)));
        firebaseService.loadAllPrompts().then((res) => setPrompts(Array.from(Object.entries(res))));
        firebaseService.loadAllProfiles().then((res) => setUsers(Array.from(Object.entries(res))));
        firebaseService.readDailyChallenge().then((res) => setDailyChallengeIds(res || []));
    }, []);

    const deleteChallenge = (id, title) => {
        if (window.confirm(`Are you sure to delete challenge "${title}"? This action is NOT REVERSIBLE! (Delete takes a few sec, after finishing, the item will be removed from the table.)`)) {
            firebaseService.deleteChallenge(id).then(() => {
                setChallenges(challenges.filter((entry) => entry[0] !== id));
            });
        }
    }

    const deletePrompt = (id, title) => {
        if (window.confirm(`Are you sure to delete prompt "${title}"? This action is NOT REVERSIBLE! (Delete takes a few sec, after finishing, the item will be removed from the table.)`)) {
            firebaseService.deletePrompt(id).then(() => {
                setPrompts(prompts.filter((entry) => entry[0] !== id));
            });
        }
    }

    const clearWorkSelections = () => {
        setSelectedWorkData(null);
        setSelectedWorkId(null);
    }

    const openWork = (workId, work) => {
        setUploadedUrl('');
        setSelectedWorkId(workId);
        fetch(work.data.strokesUrl).then((res) => res.json()).then((data) => {
            setSelectedWorkData(data);
        });
    }

    const loadWorksForSubmission = (submissions = {}, prompt, dayIndex) => {
        setWorksTemplate(<ul></ul>);
        setSelectedPrompt(prompt);
        setSelectedDayIndex(dayIndex)
        Promise.all(Array.from(Object.entries(submissions)).map(async (submissionEntry) => {
            return Promise.all([
                submissionEntry[1][submissionEntry[1].length - 1],
                firebaseService.loadProfile(submissionEntry[0]),
                firebaseService.loadWork(submissionEntry[1][submissionEntry[1].length - 1]),
            ]);
        })).then((allSubmissionData) => {
            setWorksTemplate(<ul>{allSubmissionData.map(([workId, profile, work]) => <li key={workId}>
                {profile.name}: {workId}
                <button onClick={() => openWork(workId, work)}>Open</button>
            </li>)}</ul>)
        });
    }

    const onCaptured = async (blob) => {
        setSaveWorkImageMessage('Save as the work image');
        const fileExtension = selectedPrompt.extraData.durationInSeconds ? 'gif' : 'png';

        const url = await firebaseService.saveBlob(blob, `works/${selectedWorkId}-${new Date().getTime()}.${fileExtension}`, `image/${fileExtension}`);
        setUploadedUrl(url);
    }

    const saveWorkImage = () => {
        setSaveWorkImageMessage('Saving');
        return firebaseService.addWorkUrl(selectedWorkId, uploadedUrl).then(() => {
            setSaveWorkImageMessage('Saved!');
        });
    }

    const getDailyChallengeDays = (id) => {
        const challengeEntry = challenges.find(([entryId, obj]) => entryId === id);
        if (!challengeEntry) return 'Not found';
        return `${new Date(challengeEntry[1].startTimestamp).toLocaleString()} ~ ${new Date(challengeEntry[1].startTimestamp + (challengeEntry[1].prompts.length - 1) * 24 * 60 * 60 * 1000).toLocaleDateString()}`
    }

    const updateDailyChallengeId = (id, index) => {
        dailyChallengeIds[index] = id;
        setDailyChallengeIds(dailyChallengeIds.slice(0));  // Save a new copy to trigger updates.
    }

    const addNewDailyChallenge = () => {
        setDailyChallengeIds([...dailyChallengeIds, '']);
    }

    const saveDailyChallenge = () => {
        setDailyChallengeSaveMessage('Saving...');
        firebaseService.updateDailyChallenge(dailyChallengeIds).then(() => {
            setDailyChallengeSaveMessage('Saved!');
        }).catch((error) => {
            console.error(error);
            setDailyChallengeSaveMessage(error.message);
        });
    }

    function _getChallengesWithPrompt(promptId) {
        return <ul>{
            challenges.map((entry) => {
                const dayIndex = entry[1]?.prompts?.findIndex((day) => day.promptId === promptId);
                if (dayIndex === -1) return null;

                return <li key={entry[0]}><Link target="_blank" to={`/c/${entry[0]}`}>{entry[0]} (day {dayIndex + 1})</Link></li>;
            }).filter(Boolean)}</ul>;
    }

    function showChallengesWithPrompt(promptId) {
        setPromptWithChallengeExpanded({
            ...promptWithChallengeExpanded,
            [promptId]: _getChallengesWithPrompt(promptId)
        });
    }

    const challengesContent = <>
        <h2>Challenges
            <Link to="/create-challenge">Create a challenge</Link>
        </h2>
        <h3>{challenges.length} challenges created so far</h3>
        <table>
            <thead>
                <tr>
                    <th colSpan="2">Title</th>
                    <th>Host</th>
                    <th>Start time<br />(in {new Date().toString().match(/\((.*)\)/)[1]})</th>
                    <th>Days</th>
                    <th>Members</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {challenges.map((entry) => {
                    const day = Math.floor((new Date() - entry[1].startTimestamp) / ONE_DAY_IN_MILLISECONDS) + 1;
                    let dayDetailString = '';
                    if (day <= 0) dayDetailString = `${entry[1].lengthInDays} - Not started`;
                    else if (day > entry[1].lengthInDays) dayDetailString = `${entry[1].lengthInDays} - Ended`;
                    else dayDetailString = `${entry[1].lengthInDays} - On day ${day}`;

                    const author = users.find((userEntry) => userEntry[0] === entry[1].author);
                    const authorString = author ? author[1].email : '---';

                    return <React.Fragment key={entry[0]}>
                        <tr id={entry[0]}>
                            <td colSpan="2">
                                <Link to={{ pathname: `/c/${entry[0]}` }}>{entry[1].title}</Link>
                                {selectedChallenge === entry[1] ?
                                    <button onClick={() => { setSelectedChallenge(null); setWorksTemplate(null); setSelectedPrompt(null); clearWorkSelections() }}>Prompts –</button>
                                    :
                                    <button onClick={() => { setSelectedChallenge(entry[1]); setWorksTemplate(null); setSelectedPrompt(null); clearWorkSelections() }}>Prompts +</button>
                                }
                            </td>
                            <td className={classes.tiny}>{authorString}</td>
                            <td className={classes.tiny}>{new Date(entry[1].startTimestamp).toLocaleString()}</td>
                            <td className={classes.tiny}>{dayDetailString}</td>
                            <td className={classes.tiny}>{entry[1].members.length}</td>
                            <td>
                                <Link to={{ pathname: `/edit-challenge/${entry[0]}` }}>Edit</Link>
                                <button onClick={() => deleteChallenge(entry[0], entry[1].title)}>Delete</button>
                            </td>
                        </tr>
                        {selectedChallenge === entry[1] ?
                            selectedChallenge.prompts.map((promptInChallenge, index) => {
                                const promptObj = prompts.find((promptEntry) => promptEntry[0] === promptInChallenge.promptId)[1];
                                return <tr key={promptInChallenge.promptId} className={classes.expandedChallenge}>
                                    {index === 0 ? <td rowSpan={selectedChallenge.prompts.length}>Prompts</td> : null}
                                    <td>{promptObj.title}</td>
                                    <td colSpan="3">
                                        Works: {selectedPrompt === promptObj ?
                                            worksTemplate : <button onClick={() => loadWorksForSubmission(promptInChallenge.submissions, promptObj, index)}>Load works</button>}
                                    </td>
                                    <td>{Object.keys(promptInChallenge.submissions || {}).length}</td>
                                    <td></td>
                                </tr>
                            }
                            )
                            : null}
                    </React.Fragment>
                }
                )}
            </tbody>
        </table>
    </>;

    const promptsContent = <>
        <h2>Prompts
            <Link to="/create-prompt">Create a prompt</Link>
        </h2>
        <h3>{prompts.length} prompts in database</h3>
        <table>
            <thead>
                <tr>
                    <th>P5</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Dur (s)</th>
                    <th>Hidden</th>
                    <th>Parameters</th>
                    <th>Challenges</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {prompts.map((entry) =>
                    <tr key={entry[0]} id={entry[0]}>
                        <td>{entry[1].extraData?.p5name}</td>
                        <td className={classes.tiny}>{entry[1].title}</td>
                        <td className={classes.tiny}>{entry[1].description}</td>
                        <td className={classes.tiny}>{entry[1].extraData?.durationInSeconds || '-'}</td>
                        <td className={classes.tiny}>{entry[1].hide ? 'Hidden' : ''}</td>
                        <td className={`${classes.tiny} ${classes.fixedWidth}`}>{entry[1].extraData?.parameters}</td>
                        <td className={`${classes.tiny} ${classes.fixedWidth}`}>
                            {/* Change to `_getChallengesWithPrompt(entry[0])` here if we want to show everything upfront */}
                            {promptWithChallengeExpanded[entry[0]] ?
                                promptWithChallengeExpanded[entry[0]]
                                :
                                <button onClick={() => showChallengesWithPrompt(entry[0])}>Show</button>
                            }
                        </td>
                        <td>
                            <Link to={{ pathname: `/edit-prompt/${entry[0]}` }}>Edit</Link>
                            <button onClick={() => deletePrompt(entry[0], entry[1].title)}>Delete</button>
                        </td>
                    </tr>)}
            </tbody>
        </table>
    </>;

    const usersContent = <>
        <h2>Users</h2>
        <h3>{users.length} user accounts in database</h3>
        <table>
            <thead>
                <tr>
                    <th>Email</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Username</th>
                    {/* <th>Role</th> */}
                    <th>Challenges</th>
                </tr>
            </thead>
            <tbody>
                {users.sort((a, b) => (a[1].email > b[1].email) ? 1 : -1).map((entry) =>
                    <tr key={entry[0]} id={entry[0]}>
                        <td className={classes.tiny}>{entry[1].email}</td>
                        <td className={classes.tiny}>{entry[0]}</td>
                        <td className={classes.tiny}><img src={entry[1].avatarPath} className={classes.avatar} alt='profile' /> {entry[1].name}</td>
                        <td className={classes.tiny}>{entry[1].username ? <Link to={{ pathname: `/${entry[1].username}` }}>{entry[1].username}</Link> : null}</td>
                        {/* <td className={classes.tiny}>{entry[1].role}</td> */}
                        <td><ul>{entry[1].challengeIds?.map((challengeId) => {
                            let challenge = challenges.find(item => item[0] === challengeId);
                            return challenge ? <li key={entry[0] + challenge[0]}><Link to={{ pathname: `/c/${challenge[0]}` }}>{challenge[1].title}</Link></li> : null;
                        })}</ul></td>
                    </tr>)}
            </tbody>
        </table>
    </>;

    const debugContent = <ul>
        <li>
            To debug image capturing of a work, or upload a capturing for a work:
            <Link to={{ pathname: `/_debug/capture` }}>Debug canvas capturing</Link>
        </li>
        <li>
            To find all the abnormalities in our database (including all the works without image capturing):
            <a href={`${CLOUD_FUNCTION_ROOT}/databaseCheck`} target="_blank" rel="noopener noreferrer">Visit this</a>
        </li>
        <li>
            To fix abnormalities found above, run the cloud functions locally, and uncomment the line that fixes the issue found.
        </li>
    </ul>;

    const dailyChallengeContent = <>
        <h2>Daily play admin</h2>
        <p>The ongoing public daily challenges are multiple weekly challenges connected one after another. The real info saved are just a list of challenge ids. Then the customized UI component displays them.</p>
        <p>
            <b>We need to manually make sure all challenges have correct starting date and number of prompts to keep them continuous daily</b>
        </p>
        <table>
            <thead>
                <tr>
                    <th>Index</th>
                    <th>ID</th>
                    <th>Duration</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {dailyChallengeIds.map((id, index) => {
                    const challengeThisRow = challenges.find(([entryId]) => entryId === id)?.[1];
                    if (challengeThisRow) {
                        const { year, week } = getYearWeekOfDailyChallenge(challengeThisRow);
                        return <tr key={id}>
                            <td>{index}: {year}W{week}</td>
                            <td>
                                <input value={id} onChange={(event) => updateDailyChallengeId(event.target.value, index)} />
                            </td>
                            <td>{getDailyChallengeDays(id)}</td>
                            <td>
                                <Link to={{ pathname: `/daily/${year}/week/${week}` }} target="_blank">View</Link>
                                <Link to={{ pathname: `/edit-challenge/${id}` }} target="_blank">Edit</Link>
                            </td>
                        </tr>;
                    } else {
                        return <tr key={id}>
                            <td>{index}: (new)</td>
                            <td>
                                <input value={id} onChange={(event) => updateDailyChallengeId(event.target.value, index)} />
                            </td>
                            <td>(new)</td>
                            <td>
                                <Link to={{ pathname: `/edit-challenge/${id}` }} target="_blank">Edit</Link>
                            </td>
                        </tr>
                    }
                })}
                <tr>
                    <td colSpan="5">
                        <button onClick={addNewDailyChallenge}>Append a new challenge</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button onClick={saveDailyChallenge}>Save all</button>
        <span>{dailyChallengeSaveMessage}</span>
    </>;

    const getCounts = (tab) => {
        switch (tab) {
            case TABS[0]:
                return challenges.length;
            case TABS[1]:
                return prompts.length;
            case TABS[2]:
                return users.length;
            default:
                return null;
        }
    }

    return (
        <div className={classes.content}>
            <Helmet>
                <title>Admin | YDays</title>
            </Helmet>

            <div className={classes.tabs}>
                {TABS.map((tab) => <button key={tab} onClick={() => setCurrentTab(tab)}>{tab} {getCounts(tab) ? `(${getCounts(tab)})` : ''}</button>)}
            </div>

            {currentTab === TABS[0] ? challengesContent : null}
            {currentTab === TABS[1] ? promptsContent : null}
            {currentTab === TABS[2] ? usersContent : null}
            {currentTab === TABS[3] ? dailyChallengeContent : null}
            {currentTab === TABS[4] ? debugContent : null}

            {selectedWorkData ? <div className={classes.popupContainer}>
                <button className={classes.closeButton} onClick={() => clearWorkSelections()}>X</button>
                <CaptureCanvas
                    workData={selectedWorkData}
                    prompt={selectedPrompt}
                    onCaptured={onCaptured}
                    notUsed={selectedDayIndex}  // Quick hack for the linter, delete when re-enable next line
                    // challengeDayParameters={selectedChallenge.prompts[selectedDayIndex].promptParameters}
                    onClick={(e) => e.stopPropagation()} />
                {uploadedUrl ? <p><a href={uploadedUrl} target="_blank" rel="noopener noreferrer">preview</a> <button onClick={saveWorkImage}>{saveWorkImageMessage}</button></p> : null}
            </div> : null}

        </div>
    );
}

export default Admin;
